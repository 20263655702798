import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { RadioButtonUnchecked } from '@mui/icons-material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  SxProps
} from '@mui/material'
import { PasswordPolicy } from './PasswordPolicies'
import theme from 'styles/theme'

const getPasswordComplianceIconAndTextStyles = (
  compliant?: boolean,
  showNegative?: boolean,
  policyDesc?: string
): { icon: ReactNode; textStyle: SxProps } => {
  if (compliant) {
    return {
      textStyle: { fontWeight: 'bold' },
      icon: (
        <CheckCircleIcon
          sx={{ color: theme.palette.success.main }}
          titleAccess={`Policy Met: ${policyDesc}`}
        />
      )
    }
  }

  if (compliant === false && showNegative === true) {
    return {
      textStyle: { color: theme.palette.error.main },
      icon: (
        <CancelIcon
          sx={{ color: theme.palette.error.main }}
          titleAccess={`Policy Not Met: ${policyDesc}`}
        />
      )
    }
  }

  return {
    textStyle: {},
    icon: <RadioButtonUnchecked sx={{ color: 'transparent' }} />
  }
}

export const PolicyComplianceStatus = (props: {
  passwordPoliciesComplianceState: Array<PasswordPolicy>
}) => {
  const { t } = useTranslation('users')

  return (
    <List>
      <ListSubheader>{t('passwordReset.criteria')}</ListSubheader>
      {props.passwordPoliciesComplianceState.map(policy => {
        const policyDesc = t(`passwordReset.policies.${policy.name}` as any)
        const { icon, textStyle } = getPasswordComplianceIconAndTextStyles(
          policy.compliant,
          policy.showNegative,
          policyDesc
        )
        return (
          <ListItem key={`compliance-${policy.name}`}>
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText>
              <Box sx={textStyle}>{policyDesc}</Box>
            </ListItemText>
          </ListItem>
        )
      })}
    </List>
  )
}
