import { DropzoneOptions, FileRejection } from 'react-dropzone'
import { Control, useController } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useCallback, useState } from 'react'

import { FileRejections } from 'types'
import { DropZoneInternals } from './DropZoneInternals'

type Props = {
  control: Control<any>
  name: string
  required?: boolean
  uploadServerError?: FileRejections
  onClear?: () => void
} & Omit<DropzoneOptions, 'onDrop'>

export const DropZone = ({
  control,
  name,
  required,
  multiple,
  ...rest
}: Props) => {
  const { t } = useTranslation('general')
  const [dropzoneErrors, setDropzoneErrors] = useState<FileRejection[]>([])

  const { field } = useController({
    name: name,
    control,
    rules: {
      required: required ? t(`required`) : false
    }
  })

  const onDrop = useCallback(
    (dzFiles: File[], dzErrors: FileRejection[]) => {
      if (dzErrors.length > 0) {
        setDropzoneErrors(dzErrors)
      } else {
        field.onChange(multiple ? dzFiles : dzFiles[0])
      }
    },
    [multiple, field]
  )

  return (
    <DropZoneInternals
      {...{ dropzoneErrors, field, name, onDrop, setDropzoneErrors, ...rest }}
    />
  )
}
