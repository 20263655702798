import { Card, Box, CardContent, Typography, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { StatusChip, VariantType } from 'components/StatusChip'
import { PipelineStatus } from 'types'
import usePermissions, { PERMISSION_SETS } from 'hooks/usePermissions'
import { useAnalyticsWithPipeline } from 'hooks/useAnalytics'

type Props = {
  isComplete?: boolean
  orgId: string
  pipelineId: string
  pipelineStatus: PipelineStatus
}

export const AmbiguousMatchesSection = ({
  isComplete,
  orgId,
  pipelineId,
  pipelineStatus
}: Props) => {
  const navigate = useNavigate()
  const { t } = useTranslation('pipelines')

  const analytics = useAnalyticsWithPipeline({
    clientId: orgId,
    pipelineId: pipelineId
  })

  const hasManageAllPipelinesPermissions = usePermissions(
    PERMISSION_SETS.MANAGE_ALL_PIPELINES
  )

  let status: VariantType
  if (isComplete) {
    status = 'complete'
  } else if (
    pipelineStatus === 'complete' ||
    pipelineStatus === 'readyformatching'
  ) {
    status = pipelineStatus
  } else {
    status = 'not_ready'
  }
  const handleMatchSuppliers = () => {
    analytics.track('Review Ambiguous Clicked', {
      eventSource: 'Pipeline Overview Page',
      eventCategory: 'user'
    })
    navigate(`/opcon/pipelines/${orgId}/${pipelineId}/ambiguous-matches`)
  }

  return (
    <Card variant='outlined'>
      <CardContent>
        <Box
          sx={{
            marginTop: '3px',
            display: 'flex',
            justifyContent: 'space-between',
            marginRight: '6px'
          }}
        >
          <Typography variant='h2' fontWeight='bold'>
            {t('overview.review_ambiguous_matches')}
          </Typography>
          <StatusChip label={status} variant={status} isStartCase />
        </Box>
        {status === 'readyformatching' && hasManageAllPipelinesPermissions && (
          <>
            <Typography>{t('overview.match_suppliers_message')}</Typography>
            <Box sx={{ mt: '20px' }}>
              <Button
                sx={{ mb: '16px' }}
                variant='contained'
                onClick={handleMatchSuppliers}
              >
                {t('overview.review_ambiguous_matches')}
              </Button>
            </Box>
          </>
        )}

        {status === 'complete' && (
          <Typography>{t('overview.no_matches_no_review')}</Typography>
        )}

        {status === 'not_ready' && (
          <Typography>{t('overview.file_corrections_not_complete')}</Typography>
        )}
      </CardContent>
    </Card>
  )
}
