import { useTranslation } from 'react-i18next'
import { useInfiniteQuery } from '@tanstack/react-query'
import { useState } from 'react'

import { getNoMatchSuppliers } from 'api/queries'
import { exportCSV } from 'utils/exportData'
import { processRowData } from './processRowData'
import { Metadata, PipelineStatus } from 'types'
import { Button } from 'components/Button'
import { useAnalyticsWithPipeline } from 'hooks/useAnalytics'

const PAGE_SIZE = 1000
const MAX_PAGES_EXPORTED = 5

export const ExportNoMatchButton = ({
  orgId,
  pipelineId,
  pipelineName,
  pipelineStatus,
  total
}: {
  orgId: string
  pipelineId: string
  pipelineName?: string
  pipelineStatus: PipelineStatus
  total: number
}) => {
  const { t } = useTranslation('pipelines')
  const analytics = useAnalyticsWithPipeline({ clientId: orgId, pipelineId })

  const [exporting, setExporting] = useState(false)

  const numberOfPages = Math.ceil(total / PAGE_SIZE)

  const { fetchNextPage } = useInfiniteQuery({
    queryKey: ['no-match-infinite', pipelineId, PAGE_SIZE],
    queryFn: ({ pageParam }) => {
      return getNoMatchSuppliers({
        pipelineId: pipelineId,
        page: pageParam || 0,
        pageSize: PAGE_SIZE
      })
    },
    onSuccess: data => {
      if (
        data.pages.length <
        (numberOfPages > MAX_PAGES_EXPORTED
          ? MAX_PAGES_EXPORTED
          : numberOfPages)
      ) {
        fetchNextPage({ pageParam: data.pages.length })
      } else {
        const exportData = parseAndJoinPages(data.pages)

        exportCSV(
          exportData,
          `Unmatched Suppliers - ${pipelineName || pipelineId}`
        )

        setExporting(false)
        analytics.track('Unmatched Suppliers Exported', {
          eventSource: 'Pipeline Enrichment Page',
          eventCategory: 'user'
        })
      }
    },
    enabled: !!total && exporting
  })

  let buttonDisabled = false
  let tooltip = ''

  if (pipelineStatus !== 'complete') {
    buttonDisabled = true
    tooltip = t('enrichment.pipelineNotComplete')
  } else if (total === 0) {
    buttonDisabled = true
    tooltip = t('enrichment.noUnmatchedData')
  }

  return (
    <Button
      aria-label={t('enrichment.unmatchedSuppliers.label')}
      disabled={buttonDisabled}
      onClick={() => setExporting(true)}
      tooltip={tooltip}
      isLoading={exporting}
    >
      {t('enrichment.export')}
    </Button>
  )
}

export default ExportNoMatchButton

type T = {
  data: Array<{ metadata: Metadata }>
  metadata: { total_count: number }
}

function parseAndJoinPages(pages: T[]) {
  const parsed = pages.map(p => p.data).flat()
  return processRowData(parsed)
}
