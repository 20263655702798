import {
  Autocomplete as MuiAutocomplete,
  AutocompleteRenderInputParams,
  TextField
} from '@mui/material'
import {
  Controller,
  FieldValues,
  Path,
  Control,
  FieldError
} from 'react-hook-form'
import { useTranslation, Trans } from 'react-i18next'
import { useState } from 'react'

type Option = { value: string; label: string }

type Props<O extends Option, TField extends FieldValues> = {
  name: Path<TField>
  options: O[]
  control: Control<TField>
  placeholder?: string
  errors?: FieldError | { hasError: boolean; message: string }
  required?: boolean
}

export const Autocomplete = <O extends Option, TField extends FieldValues>(
  props: Props<O, TField>
) => {
  const { t } = useTranslation('general')
  const { placeholder, options, control, name, errors, required } = props
  const [inputValue, setInputValue] = useState<string>('')

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: required && t('required')
      }}
      render={({ field: { onChange, value } }) => {
        const currentValue = value
          ? options.find(i => i?.value === value)
          : null
        return (
          <MuiAutocomplete
            size='small'
            value={currentValue}
            isOptionEqualToValue={(option, value) => {
              return value ? option.value === value.value : false
            }}
            onChange={(event, newValue) => {
              onChange(newValue?.value)
            }}
            onInputChange={(event, value) => {
              setInputValue(value)
            }}
            options={options}
            getOptionLabel={option => option && option.label}
            renderInput={(params: AutocompleteRenderInputParams) => (
              <TextField
                {...params}
                placeholder={placeholder || t('search')}
                error={!!errors}
                helperText={errors?.message || null}
              />
            )}
            noOptionsText={
              <Trans
                t={t}
                i18nKey={'noResultsFor'}
                values={{ searchString: inputValue }}
              />
            }
            popupIcon={null}
          />
        )
      }}
    />
  )
}
