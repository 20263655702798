import api from '../api'
import {
  Org,
  Datapack,
  DatapackConfigItem,
  PaginationParams,
  PaginationData
} from 'types'
import { createQueryKeys } from '@lukemorales/query-key-factory'

const ORG_SEARCH_ENDPOINT = '/accounts/org/search'

export const orgsQueries = createQueryKeys('orgs', {
  all: {
    queryKey: ['all'],
    queryFn: getAllOrgs
  },
  allByIds: (ids: string[]) => ({
    queryKey: [ids],
    queryFn: () => getOrgsByIds(ids)
  }),
  byId: (id: string) => ({
    queryKey: [id],
    queryFn: () => getOrgById(id)
  })
})

type OrgsQueryParams = {
  sort_by?: 'created_at' | 'name'
  order?: 'asc' | 'desc'
}

export async function getOrgsByPage(
  params?: PaginationParams & OrgsQueryParams
) {
  const { page = 0, pageSize = 10, ...rest } = params || {}
  const offset = page * pageSize

  const queryStringParams = {
    offset,
    limit: pageSize,
    ...rest
  }

  return api.get<PaginationData<Org>>('/accounts/org', queryStringParams)
}

export async function getAllOrgs() {
  const result = await api.get<{
    data: Org[]
  }>(ORG_SEARCH_ENDPOINT)
  return result.data
}

export async function getOrgsByIds(ids: string[]) {
  const queryString = !!ids.length
    ? `?id=${ids.filter(id => id !== 'string').join(',')}`
    : ''

  const result = await api.get<{
    data: Org[]
  }>(`${ORG_SEARCH_ENDPOINT}${queryString}`)

  return result.data
}

function getOrgById(id: string) {
  return api.get<Org>(`/accounts/org/${id}`)
}

export const getAllDatapacks = async (): Promise<Datapack[]> => {
  const datapacks = await api.get<{
    data: Datapack[]
  }>('/accounts/datapack/search')
  const reducerFn = (
    prev: string[],
    curr: DatapackConfigItem | DatapackConfigItem[]
  ): string[] => {
    if (!Array.isArray(curr)) {
      return [...prev, ...curr.fields]
    }
    return curr.reduce(reducerFn, prev)
  }
  return datapacks.data.map(d => {
    const mergedAttributes = Object.values(d.config).reduce(
      reducerFn,
      [] as string[]
    )
    return { ...d, mergedAttributes }
  })
}

export const getDatapackById = (id: string): Promise<Datapack> =>
  api.get<Datapack>(`/accounts/datapack/${id}`)
