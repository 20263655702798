import { Button, Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import useQuery from 'hooks/useQuery'
import { Link, useParams } from 'react-router-dom'
import queries from 'api/queries'
import UserForm from '../(shared)/UserForm'
import { useSetTitleCluster } from 'state/titleCluster'

type MyParams = {
  userId: string
}

export default function EditUser() {
  const { t } = useTranslation('users')
  const { userId } = useParams() as MyParams

  useSetTitleCluster(t('editUser'), [
    {
      link: '/opcon/users',
      label: t('title')
    }
  ])

  const { data: user, isLoading } = useQuery(queries.users.detail(userId))

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button variant='outlined' component={Link} to={`reset-password`}>
          {t('resetPassword')}
        </Button>
      </Box>
      {!isLoading && <UserForm user={user} />}
    </>
  )
}
