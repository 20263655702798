import { useTranslation } from 'react-i18next'

import ApiCredentialsForms from './(shared)/ApiCredentialsForms'
import { useSetTitleCluster } from 'state/titleCluster'

export default function CreateApiCredentials() {
  const { t } = useTranslation('apiCredentials')

  useSetTitleCluster(t('createApiCredentials'), [
    {
      link: '/opcon/api-credentials',
      label: t('title')
    }
  ])

  return <ApiCredentialsForms />
}
