import { useTranslation } from 'react-i18next'
import { useParams, useSearchParams } from 'react-router-dom'
import { useSetTitleCluster } from 'state/titleCluster'
import useQuery from 'hooks/useQuery'
import { getBreByOrgId, getDatapackById, updateBreByOrgId } from 'api/queries'
import { BreForm } from './BreForm'
import useMutation from 'hooks/useMutation'
import { useCallback } from 'react'
import { BreConfigRules } from 'types'
import useNotify from 'hooks/useNotify'
import { Loading } from 'components/Loading'

export const BreEdit = () => {
  const { t } = useTranslation('bre')
  const { orgId } = useParams() as { orgId: string }
  const [searchParams] = useSearchParams()
  const notify = useNotify()

  const datapackId = searchParams.get('datapackId')

  useSetTitleCluster(t('titleBlock.title'), [
    {
      link: '/opcon/orgs',
      label: t('titleBlock.clients')
    },
    {
      link: `/opcon/orgs/edit/${orgId}`,
      label: t('titleBlock.editClient')
    }
  ])

  const { data: datapackQueryResult } = useQuery({
    queryKey: [`datapack`, datapackId],
    queryFn: () => getDatapackById(datapackId as string)
  })

  const { data: breRulesResponse } = useQuery({
    queryKey: [`breRules`, orgId],
    queryFn: () => getBreByOrgId(orgId!)
  })

  const saveRules = useMutation({
    mutationFn: updateBreByOrgId,
    invalidateQueryKey: [`breRules`, orgId],
    onSuccess: async () => {
      notify({
        message: t('saveSuccess'),
        severity: 'success'
      })
    },
    onError: () => {
      notify({ message: t('saveError'), severity: 'error' })
    }
  })

  const handleSavedRules = useCallback(
    (rules: BreConfigRules) => {
      if (orgId && breRulesResponse?.id && Object.keys(rules).length > 0) {
        saveRules.mutate({
          orgId,
          breId: breRulesResponse.id,
          data: rules
        })
      }
    },
    [breRulesResponse?.id, orgId, saveRules]
  )

  if (!breRulesResponse || !datapackQueryResult) return <Loading />

  return (
    <BreForm
      breConfig={breRulesResponse}
      dataPackConfig={datapackQueryResult?.config!}
      onFormSubmit={handleSavedRules}
    />
  )
}
