import { styled, Theme, CSSObject } from '@mui/material/styles'
import {
  IconButton,
  IconButtonProps as MuiIconButtonProps
} from '@mui/material'

import { DRAWER_WIDTH } from './Sidebar'
import theme from 'styles/theme'

interface IconButtonProps extends MuiIconButtonProps {
  open?: boolean
}

const white = theme.palette.common.white
const grey = theme.palette.grey[500]

const baseMixin = (theme: Theme): CSSObject => ({
  position: 'fixed',
  marginTop: theme.spacing(8.5),
  width: '2rem',
  height: '2rem',
  background: white,
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: grey,
  border: `1px solid ${grey}`,
  boxShadow: '1px 1px 4px rgb(0 0 0 / 25%)',
  boxSizing: 'border-box',
  zIndex: 2000,
  ':hover': {
    background: theme.palette.primary.main,
    color: white
  }
})

const openedMixin = (theme: Theme): CSSObject => ({
  marginLeft: `calc(${DRAWER_WIDTH}px - 1rem)`,
  transition: theme.transitions.create('margin-left', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  })
})

const closedMixin = (theme: Theme): CSSObject => ({
  marginLeft: `calc(${theme.spacing(8)} - 1rem)`,
  transition: theme.transitions.create('margin-left', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  })
})

const SidebarToggle = styled(IconButton, {
  shouldForwardProp: prop => prop !== 'open'
})<IconButtonProps>(({ theme, open }) => ({
  ...(open && {
    ...baseMixin(theme),
    ...openedMixin(theme)
  }),
  ...(!open && {
    ...baseMixin(theme),
    ...closedMixin(theme)
  })
}))

export default SidebarToggle
