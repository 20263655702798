import { atom, useRecoilValue, useSetRecoilState } from 'recoil'
import { useEffect } from 'react'

export type BreadCrumb = {
  link: string
  label: string
}

export type TitleCluster = {
  title: string
  breadcrumbs: BreadCrumb[]
}

export const titleClusterState = atom<TitleCluster>({
  key: 'titleCluster',
  default: {
    title: '',
    breadcrumbs: []
  }
})

export const useTitleCluster = () => useRecoilValue(titleClusterState)

const useManualSetTitleCluster = () => useSetRecoilState(titleClusterState)

export const useSetTitleCluster = (
  title = '',
  breadcrumbs: BreadCrumb[] = []
) => {
  const setTitleCluster = useManualSetTitleCluster()

  useEffect(
    () => setTitleCluster({ title, breadcrumbs }),
    [breadcrumbs, setTitleCluster, title]
  )
}
