import { Typography, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Banner } from 'components/Banner'

export const CompleteBanner = ({
  orgId,
  pipelineId
}: {
  orgId: string
  pipelineId: string
}) => {
  const { t } = useTranslation('pipelines')
  const navigate = useNavigate()

  return (
    <Banner variant='success' title={t('tnvPage.noMoreErrorsToReview')}>
      <Typography>{t('tnvPage.pipelineCompleteMessage')}</Typography>
      <Button
        sx={{ mt: '20px', mb: '16px' }}
        variant='contained'
        onClick={() =>
          navigate(`/opcon/pipelines/${orgId}/${pipelineId}/enrichment`)
        }
      >
        {t('tnvPage.goToEnrichment')}
      </Button>
    </Banner>
  )
}
