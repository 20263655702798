export default function areValuesSame(
  value1: string | string[] | undefined | null,
  value2: string | string[] | undefined | null
): boolean {
  // Helper function to convert any value to a standardized array form
  function standardizeValue(
    value: string | string[] | undefined | null
  ): string[] {
    if (value === undefined || value === null) {
      return []
    }
    return Array.isArray(value) ? value : [value]
  }

  // Helper function to check if all elements of arr1 are in arr2, and vice versa
  function arraysHaveSameValues(arr1: string[], arr2: string[]): boolean {
    return (
      arr1.every(element => arr2.includes(element)) &&
      arr2.every(element => arr1.includes(element))
    )
  }

  const standardizedValue1 = standardizeValue(value1)
  const standardizedValue2 = standardizeValue(value2)

  // Now, both values are in the form of arrays, compare them as string arrays
  return arraysHaveSameValues(standardizedValue1, standardizedValue2)
}
