import { Table } from 'components/Table'
import { TableRow, TableCell, TableBody, TableHead } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { PipelineInput, User } from 'types'
import formatDateTime from 'utils/formatDateTime'
import useQuery from 'hooks/useQuery'
import { formatName } from 'utils/formatName'
import queries from 'api/queries'

const removeFilenameExtension = (filename: string) => {
  return filename?.substring(0, filename.lastIndexOf('.')) || filename
}

export function PipelinesFilesTable({
  inputs
}: {
  inputs: Array<PipelineInput>
}) {
  const { t } = useTranslation('pipelineOverview')

  const fileInputs = inputs.filter(input => !!input.batch_id)

  const { data: users } = useQuery({
    ...queries.users.allByIds(fileInputs.map(input => input.user_id)),
    enabled: !!fileInputs?.length
  })

  const usersMap = users?.reduce((map: { [id: string]: User }, user) => {
    map[user.id] = user
    return map
  }, {})

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>{t('dateLoaded')}</TableCell>
          <TableCell>{t('madeBy')}</TableCell>
          <TableCell>{t('fileName')}</TableCell>
          <TableCell>{t('batchId')}</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {usersMap &&
          fileInputs?.map(row => {
            const user = usersMap[row.user_id]
            return (
              <TableRow key={row.batch_id}>
                <TableCell>
                  {row.updated_at && formatDateTime(row.updated_at)}
                </TableCell>
                <TableCell>
                  {formatName({ ...user, fallback: user?.organization?.name })}
                </TableCell>
                <TableCell>
                  {row.original_name
                    ? removeFilenameExtension(row.original_name)
                    : removeFilenameExtension(row.file_name)}
                </TableCell>

                <TableCell>{row.batch_id}</TableCell>
              </TableRow>
            )
          })}
      </TableBody>
    </Table>
  )
}
