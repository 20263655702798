import { Typography, Box } from '@mui/material'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined'

export const MissingCell = ({
  value,
  status
}: {
  value: string
  status: string
}) => (
  <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
    {status === 'missing-notCritical' ? (
      <>
        <Typography color={'info'}>{value}</Typography>
        <FlagOutlinedIcon color={'info'} />
      </>
    ) : (
      <>
        <Typography color={'error'}>{value}</Typography>
        <WarningAmberIcon color={'error'} />
      </>
    )}
  </Box>
)
