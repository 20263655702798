import { Box } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { useSetTitleCluster } from 'state/titleCluster'
import { PipelineType } from 'types'

import { PipelineSuccess } from './(shared)/PipelineSuccess'
import { PipelineRunnerForm } from './(shared)/PipelineRunnerForm'

export default function PipelineRunner() {
  const { t } = useTranslation('pipelineRunner')
  const navigate = useNavigate()

  const [pipelineType, setPipelineType] = useState<PipelineType>()
  const [success, setSuccess] = useState(false)

  const showSuccess = () => setSuccess(true)
  const hideSuccess = () => setSuccess(false)

  useSetTitleCluster(t('title'))

  const handleViewPipelines = () => {
    if (pipelineType === 'common_certification') {
      navigate('/opcon/pipelines/list/usp')
    } else if (pipelineType === 'client_data_vendor_extended') {
      navigate('/opcon/pipelines/list/vendor')
    } else {
      navigate('/opcon/pipelines/list/spend')
    }
  }

  return (
    <Box mt={4}>
      {success ? (
        <PipelineSuccess {...{ hideSuccess, handleViewPipelines }} />
      ) : (
        <PipelineRunnerForm {...{ showSuccess, setPipelineType }} />
      )}
    </Box>
  )
}
