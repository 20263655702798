import { Card, Box, CardContent, Typography, Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { Pipeline } from 'types'
import { DataPairGroup } from 'components/DataPairGroup'
import formatDateTime from 'utils/formatDateTime'
import { rateToPercent } from 'utils/rateToPercent'
import { TFunction } from 'i18next'

type Props = {
  orgUnitName: string
  pipeline: Pipeline
}

function getGeneralInfo(
  pipeline: Pipeline,
  t: TFunction<'pipelines'>,
  orgUnitName: string
) {
  const data = [
    {
      label: t('pipeline_status'),
      chip: { label: t(pipeline.status), variant: pipeline.status }
    },
    {
      label: t('pipeline_id'),
      info: pipeline.pipeline_id
    }
  ]
  if (pipeline.pipeline_type !== 'common_certification') {
    data.push({
      label: t('client'),
      info: orgUnitName
    })
  }
  return data
}

function getMatchData(pipeline: Pipeline, t: TFunction<'pipelines'>) {
  const data = [
    {
      label: t('original_match_rate'),
      info: pipeline.stats.original_match_rate
        ? rateToPercent(pipeline.stats.original_match_rate)
        : '-',
      tooltip: t('overview.original_rate_tip')
    },
    {
      label: t('hil_matches_to_records_requiring_hil'),
      info: pipeline.stats.hil_to_required_records_rate
        ? rateToPercent(pipeline.stats.hil_to_required_records_rate)
        : '-',
      tooltip: t('overview.hil_rate1_tip')
    },
    {
      label: t('hil_matches_to_total_matches'),
      info: pipeline.stats.hil_to_total_matches_rate
        ? rateToPercent(pipeline.stats.hil_to_total_matches_rate)
        : '-',
      tooltip: t('overview.hil_rate2_tip')
    },
    {
      label: t('hil_matches_to_num_records'),
      info: pipeline.stats.hil_to_record_rate
        ? rateToPercent(pipeline.stats.hil_to_record_rate)
        : '-',
      tooltip: t('overview.hil_rate3_tip')
    }
  ]

  if (pipeline.pipeline_type === 'common_certification') {
    data.push({
      label: t('overall_match_rate'),
      info: pipeline.stats.cumulative_match_rate
        ? rateToPercent(pipeline.stats.cumulative_match_rate)
        : '-',
      tooltip: t('overview.cumulative_rate_tip')
    })
  }

  return data
}

function getDateData(
  pipeline: Pipeline,
  t: TFunction<'pipelines'>,
  lastInput: any
) {
  const data = [
    {
      label: t('date_started'),
      info: formatDateTime(pipeline.created_at)
    },
    {
      label: t('date_last_loaded'),
      info: formatDateTime(lastInput?.updated_at)
    },
    {
      label: t('date_completed'),
      info:
        pipeline.status === 'complete'
          ? formatDateTime(pipeline.updated_at)
          : '-'
    }
  ]

  if (pipeline.pipeline_type !== 'common_certification') {
    data.push({
      label: t('unique_suppliers'),
      info: pipeline.stats.record_count?.toLocaleString() ?? '-'
    })
  }
  return data
}

function getMiscData(pipeline: Pipeline, t: TFunction<'pipelines'>) {
  if (pipeline.pipeline_type === 'common_certification') {
    return [
      {
        label: t('number_of_records_loaded'),
        info: pipeline.stats.record_count?.toLocaleString() ?? '-'
      },
      {
        label: t('number_of_usps_created'),
        info: pipeline.stats.num_usp_created?.toLocaleString() ?? '-'
      },
      {
        label: t('number_of_usps_updated'),
        info: pipeline.stats.num_usp_updated?.toLocaleString() ?? '-'
      }
    ]
  }

  return [
    {
      label: t('overall_match_rate'),
      info: pipeline.stats.cumulative_match_rate
        ? rateToPercent(pipeline.stats.cumulative_match_rate)
        : '-',
      tooltip: t('overview.cumulative_rate_tip')
    },
    {
      label: t('overview.records_deduplicated'),
      info: pipeline.stats.duplicate_rows_count?.toLocaleString() ?? '-',
      tooltip: t('overview.deduplicated_tip')
    }
  ]
}

export const OperationalStatsSection = (props: Props) => {
  const { t } = useTranslation('pipelines')

  const { orgUnitName, pipeline } = props

  const lastInput = pipeline.inputs
    ?.filter(input => !!input.file_storage_id)
    .slice(-1)[0]

  const generalInfo = getGeneralInfo(pipeline, t, orgUnitName)

  const firstColData = getDateData(pipeline, t, lastInput)

  const secondColData =
    pipeline.pipeline_type !== 'common_certification'
      ? getMatchData(pipeline, t)
      : getMiscData(pipeline, t)

  const thirdColData =
    pipeline.pipeline_type !== 'common_certification'
      ? getMiscData(pipeline, t)
      : getMatchData(pipeline, t)

  return (
    <Card variant='outlined'>
      <CardContent>
        <Typography variant='h2' fontWeight='bold' sx={{ mb: 4 }}>
          {t('operational_stats')}
        </Typography>
        <Box sx={{ mb: 4 }}>
          <DataPairGroup dataPairs={generalInfo} />
        </Box>
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid item xs={2} sm={4} md={4}>
            <DataPairGroup dataPairs={firstColData} />
          </Grid>
          <Grid item xs={2} sm={4} md={4}>
            <DataPairGroup
              dataPairs={secondColData}
              {...{
                noRowGap: pipeline.pipeline_type !== 'common_certification'
              }}
            />
          </Grid>
          <Grid item xs={2} sm={4} md={4}>
            <DataPairGroup dataPairs={thirdColData} noRowGap />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
