import DataTableServerSide from 'components/DataTable/DataTableServerSide'
import createColDefs from 'components/DataTable/createColDefs'
import { getUserGroupsByPage } from 'api/queries'
import {
  GridRenderCellParams,
  GridCellParams,
  GridRowId
} from '@mui/x-data-grid'
import { useTranslation } from 'react-i18next'
import { Button } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps
} from 'react-router-dom'
import { forwardRef, useMemo } from 'react'
import { Org } from 'types'
import usePermissions, { PERMISSION_SETS } from 'hooks/usePermissions'

const Link = forwardRef<HTMLAnchorElement, RouterLinkProps>(
  (itemProps, ref) => <RouterLink ref={ref} {...itemProps} />
)

const formatPermissionsSummary = (permissions?: string[]) => {
  let summary = 'none'
  if (!permissions) {
    return summary
  }
  if (permissions?.length > 0) {
    summary = permissions[0]
    if (permissions.length > 1) {
      summary = summary + ` + ${permissions.length - 1} more`
    }
  }
  return summary
}

const EditButton = ({ id }: { id: GridRowId }) => {
  const { t } = useTranslation('general')
  return (
    <Button
      variant='text'
      startIcon={<EditIcon />}
      component={Link}
      to={`${id}/edit`}
    >
      {t('edit')}
    </Button>
  )
}

export default function UserGroups() {
  const { t } = useTranslation('userGroups')
  const canUpdateUserGroup = usePermissions(PERMISSION_SETS.UPDATE_USER_GROUPS)
  const canCreateUserGroup = usePermissions(PERMISSION_SETS.CREATE_USER_GROUPS)
  const columnDefs = useMemo(() => {
    const baseCols = [
      { field: 'id' },
      { field: 'displayName', headerName: t('title'), filterable: false },
      {
        field: 'organization',
        headerName: t('client'),
        filterable: false,
        renderCell: (params: GridRenderCellParams<Org>) => {
          return <div>{params.value?.name}</div>
        }
      },
      {
        field: 'userCount',
        headerName: t('numberOfUsers'),
        maxWidth: 100,
        filterable: false
      },
      {
        field: 'permissions',
        headerName: t('permissionsHeading'),
        filterable: false,
        renderCell: (params: GridRenderCellParams<string[]>) => {
          return <div>{formatPermissionsSummary(params.value)}</div>
        }
      }
    ]
    const actionCol = {
      field: 'Action',
      minWidth: 50,
      maxWidth: 110,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params: GridCellParams) => <EditButton id={params.id} />
    }

    return createColDefs(
      canUpdateUserGroup ? [...baseCols, actionCol] : baseCols
    )
  }, [canUpdateUserGroup, t])

  return (
    <DataTableServerSide
      title={t('title')}
      columns={columnDefs}
      queryKey='userGroups'
      query={getUserGroupsByPage}
      createPageLink={
        canCreateUserGroup ? '/opcon/user-groups/create' : undefined
      }
      createButtonTitle={canCreateUserGroup ? t('createUserGroup') : undefined}
    />
  )
}
