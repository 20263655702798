import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Paper, Stack, FormControl, FormLabel, MenuItem } from '@mui/material'
import { useForm } from 'react-hook-form'

import { DropZone } from 'components/DropZone'
import { FileRejections, FileUploadErrors, Pipeline, PipelineType } from 'types'
import { getAllOrgs } from 'api/queries'
import { Loading } from 'components/Loading'
import { uploadFile, runPipeline } from 'api/mutations'
import { uspQueries } from 'api/queries'
import { useSession } from 'state/session'
import SelectForm from 'components/Form/Select'
import { Button } from 'components/Button'
import { Autocomplete } from 'components/Form/Autocomplete'
import useAnalytics from 'hooks/useAnalytics'
import useMutation from 'hooks/useMutation'
import useNotify from 'hooks/useNotify'
import useQuery from 'hooks/useQuery'

import { SharedFields } from './SharedFields'
import { SpendFileFields } from './SpendFileFields'

export type RunnerFormData = {
  orgId: string
  droppedFile: File | null
  pipelineType: PipelineType
  pipelineName: string
  vendorMaster: string
  certificateSource: string
}

export const PipelineRunnerForm = ({
  showSuccess,
  setPipelineType
}: {
  showSuccess: () => void
  setPipelineType: (type: PipelineType) => void
}) => {
  const { user } = useSession()
  const analytics = useAnalytics()
  const notify = useNotify()

  const uploadFileMutation = useMutation({ mutationFn: uploadFile })

  const [fileUploadRejections, setFileUploadRejections] =
    useState<FileRejections>()

  const resetFileUploadRejections = () => setFileUploadRejections(undefined)

  const { t } = useTranslation('pipelineRunner')
  const { t: generalT } = useTranslation('general')

  const UPLOAD_TYPES = [
    { value: 'client_data_vendor_extended', label: t('vendorMaster') }
  ] as const

  const { data: orgs } = useQuery({
    queryKey: ['orgs'],
    queryFn: getAllOrgs
  })

  const { data: certificateSources } = useQuery(uspQueries.certificateSources())

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    reset
  } = useForm<RunnerFormData>({
    defaultValues: {
      orgId: '',
      pipelineType: undefined,
      pipelineName: '',
      vendorMaster: '',
      droppedFile: null,
      certificateSource: ''
    }
  })

  const watchOrgId = watch('orgId')
  const watchDroppedFile = watch('droppedFile')
  const watchPipelineType = watch('pipelineType')
  const watchPipelineName = watch('pipelineName')
  const watchCertificateSource = watch('certificateSource')

  const vendorOrSpend =
    watchPipelineType === 'client_data_vendor_extended' ||
    watchPipelineType === 'client_data_spend'

  const runPipelineMutation = useMutation({
    mutationFn: runPipeline,
    invalidateQueryKey: [watchPipelineType],
    onSuccess: () => {
      reset()
    }
  })

  const isMutationLoading =
    uploadFileMutation.isLoading || runPipelineMutation.isLoading

  const isFileOk =
    !fileUploadRejections && !isMutationLoading && !!watchDroppedFile

  const isVendorMasterSubmitDisabled = !watchOrgId || !watchPipelineName
  const isSpendFileSubmitDisabled = !watchOrgId || !watchPipelineName // TODO || !watchVendorMaster
  const isCertificationSubmitDisabled = !watchCertificateSource

  const isSubmitDisabled =
    !watchPipelineType ||
    !isFileOk ||
    (watchPipelineType === 'client_data_vendor_extended' &&
      isVendorMasterSubmitDisabled) ||
    (watchPipelineType === 'client_data_spend' && isSpendFileSubmitDisabled) ||
    (watchPipelineType === 'common_certification' &&
      isCertificationSubmitDisabled)

  if (!orgs || !certificateSources) {
    return <Loading />
  }

  const sortedOrgs = orgs.sort((org1, org2) =>
    org1.name.localeCompare(org2.name)
  )

  let acceptType: { [key: string]: string[] } = { 'text/csv': ['.csv'] }

  if (watchPipelineType === 'common_certification') {
    acceptType = { 'application/x-jsonlines': ['.jsonl'] }
  }

  const onSubmit = async (data: RunnerFormData) => {
    if (!data.droppedFile) {
      notify({
        message: generalT('error')
      })
      return
    }

    const formData = new FormData()
    formData.append('file', data.droppedFile)
    formData.append('pipeline_type', data.pipelineType)

    if (vendorOrSpend) {
      formData.append('org_id', data.orgId)
    }

    if (watchPipelineType === 'common_certification') {
      formData.append('certificate_source', data.certificateSource)
    }

    setPipelineType(data.pipelineType)

    const { file_storage_id, errors, original_name }: FileUploadErrors =
      await uploadFileMutation.mutateAsync(formData)

    const client = sortedOrgs.find(org => org.id === data.orgId)

    analytics.track('Run Pipeline Clicked', {
      eventSource: 'Pipeline Runner Page',
      eventCategory: 'user',
      clientName: client?.name,
      fileName: original_name,
      fileAccepted: !errors
    })

    if (!!errors) {
      setFileUploadRejections({
        fileName: original_name,
        validationErrors: errors
      })
      reset(formValues => ({
        ...formValues,
        droppedFile: null
      }))
    } else {
      const payload = {
        ...(vendorOrSpend && {
          org_id: data.orgId,
          pipeline_name: data.pipelineName
        }),
        ...(watchPipelineType === 'common_certification' && {
          certificate_source: data.certificateSource
        }),
        pipeline_type: data.pipelineType,
        source: { file_storage_id: file_storage_id }
      }
      runPipelineMutation.mutate(payload, {
        onSuccess: res => {
          showSuccess()

          const pipeline = res as Pipeline
          analytics.track('Pipeline Started', {
            eventSource: 'Pipeline Runner Page',
            eventCategory: 'user',
            clientName: client?.name,
            fileName: original_name,
            pipelineId: pipeline.pipeline_id,
            pipelineType: pipeline.pipeline_type,
            pipelineName: pipeline.pipeline_name,
            createdBy: `${user.firstName} ${user.lastName}`
          })
        }
      })
    }
  }

  return (
    <Paper sx={{ padding: 4, maxWidth: '700px' }}>
      <Stack spacing={2} sx={{ minWidth: 380, maxWidth: 700 }}>
        <FormControl sx={{ textAlign: 'start' }} size='small'>
          <FormLabel htmlFor='pipelineType'>{t('pipelineType')}</FormLabel>
          <SelectForm
            name='pipelineType'
            control={control}
            errors={errors.pipelineType}
            placeholder={generalT('select')}
          >
            {UPLOAD_TYPES.map(({ label, value }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </SelectForm>
        </FormControl>

        {watchPipelineType && (
          <>
            {vendorOrSpend && (
              <SharedFields
                control={control}
                orgId={errors.orgId}
                pipelineName={errors.pipelineName}
                sortedOrgs={sortedOrgs}
              />
            )}

            {watchPipelineType === 'client_data_spend' && (
              <SpendFileFields control={control} />
            )}

            {watchPipelineType === 'common_certification' && (
              <FormControl sx={{ mt: 1, textAlign: 'start' }}>
                <FormLabel htmlFor='certificateSource'>
                  {t('certificateSource')}
                </FormLabel>
                <Autocomplete
                  name='certificateSource'
                  control={control}
                  placeholder={generalT('search')}
                  options={certificateSources}
                  required
                />
              </FormControl>
            )}

            <DropZone
              name='droppedFile'
              accept={acceptType}
              control={control}
              minSize={200}
              onClear={resetFileUploadRejections}
              onDropAccepted={resetFileUploadRejections}
              required
              uploadServerError={fileUploadRejections}
            />
          </>
        )}
        <Button
          variant='contained'
          sx={{ width: 'fit-content' }}
          disabled={isSubmitDisabled}
          onClick={handleSubmit(onSubmit)}
          isLoading={isMutationLoading}
        >
          {t('runPipeline')}
        </Button>
      </Stack>
    </Paper>
  )
}
