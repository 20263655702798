import { useEffect } from 'react'

import { User } from 'firebase/auth'
import decode from 'jwt-decode'
import { useRecoilState } from 'recoil'

import { sessionState, logoffSession } from 'state/session'
import { firebaseAuth } from '../firebase-config'

import Login from 'components/login/login'

export type AuthContextType = {
  currentUser: User | null
  isAuthenticated: boolean
}

function extractUserInfo(token: string) {
  const user: any = decode(token)
  return {
    user: {
      firstName: user?.given_name || '',
      lastName: user?.family_name || '',
      email: user?.preferred_username || ''
    },
    permissions: user?.tealbook?.permissions || [],
    userId: user?.tealbook?.id || '',
    orgId: user?.tealbook?.orgId || '',
    isAuthenticated: true,
    isStatusKnown: true
  }
}

export function RequireAuth({ children }: { children: JSX.Element }) {
  const [session, setSession] = useRecoilState(sessionState)

  useEffect(() => {
    if (!session.isStatusKnown) {
      //  we use onIdTokenChanged instead of onAuthStateChanged because we change JWT claim on fly and need to track token update
      firebaseAuth.onIdTokenChanged(user => {
        const accessToken = (user as any)?.accessToken
        if (accessToken) {
          const value = extractUserInfo(accessToken)
          if (value.userId) {
            setSession(value)
          } else {
            setSession(logoffSession)
          }
        } else {
          setSession(logoffSession)
        }
      })
    }
  }, [setSession, session.isStatusKnown])

  if (!session.isStatusKnown) {
    return null
  }

  if (!session.userId || session.userId.trim() === '') {
    return <Login />
  }

  return children
}
