/* eslint-disable */
import { FieldValues, useForm } from 'react-hook-form'
import { startCase } from 'lodash'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ChangeEvent, useMemo } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import {
  Paper,
  Typography,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Chip,
  TableFooter,
  FormControl,
  FormLabel,
  Stack,
  Button,
  Radio,
  MenuItem
} from '@mui/material'

import Select from 'components/Form/Select'

import { CellRightBorder } from 'components/Table/CellRightBorder'
import { Loading } from 'components/Loading'
import { Table, TablePinnedRow } from 'components/Table'
import StatusSection from 'components/StatusSection'
import InputText from 'components/Form/InputText'
import RadioGroup from 'components/Form/RadioGroup'
import { formatName } from 'utils/formatName'
import { saveMatchSelection } from 'api/mutations'
import { useSession } from 'state/session'
import { useSetTitleCluster } from 'state/titleCluster'
import theme from 'styles/theme'
import useMutation from 'hooks/useMutation'
import { useAnalyticsWithPipeline } from 'hooks/useAnalytics'
import useQuery from 'hooks/useQuery'
import {
  getAmbiguousMatchByEntityMetadataId,
  getPipelineById
} from 'api/queries'

import { usePipelineBreadcrumbs } from '../../../(shared)/usePipelineBreadcrumbs'

type FormData = {
  matchReasoning: string
  candidateSelection: string
  matchReasoningComment: string
}
type Location = {
  state?: {
    currentPage: number
    firstSkippedEntityMetaId: string
  }
}

export default function AmbiguousMatchSelect() {
  const { entityMetadataId, orgId, pipelineId } = useParams() as {
    entityMetadataId: string
    orgId: string
    pipelineId: string
  }

  const { t } = useTranslation('supplierMatch')
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const session = useSession()
  const location = useLocation() as Location

  const name = formatName({
    ...session.user
  })

  const { currentPage, firstSkippedEntityMetaId } = location.state || {
    currentPage: 0,
    firstSkippedEntityMetaId: ''
  }

  const analytics = useAnalyticsWithPipeline({ clientId: orgId, pipelineId })
  const matchTime = useMemo(() => {
    return entityMetadataId ? new Date() : undefined
  }, [entityMetadataId])

  const { data: pipeline } = useQuery({
    queryKey: ['pipelines', pipelineId, orgId],
    queryFn: () => getPipelineById(orgId, pipelineId),
    enabled: !!pipelineId && !!orgId,
    cacheTime: 0
  })

  const breadcrumbs = usePipelineBreadcrumbs(orgId, pipelineId)
  useSetTitleCluster(t('matchSuppliers'), breadcrumbs.slice(0, 3))

  const { data: currentlyMatching } = useQuery({
    queryKey: ['ambiguousMatch', entityMetadataId, orgId, pipelineId],
    queryFn: () =>
      getAmbiguousMatchByEntityMetadataId({
        page: 0,
        pageSize: 1,
        pipelineId: pipelineId,
        entityMetadataId: entityMetadataId,
        orgId
      }),
    enabled: !!entityMetadataId && !!pipelineId
  })

  const numIdsLeft = currentlyMatching?.entity_metadata_ids.length || 0

  const nextPage = currentPage === numIdsLeft ? 0 : currentPage + 1

  const nextEntityMetaId = useMemo(() => {
    const nextId = numIdsLeft > 1
    return !!nextId
      ? currentlyMatching?.entity_metadata_ids[nextPage]
      : undefined
  }, [currentlyMatching?.entity_metadata_ids, nextPage])

  const saveMatchSelectionMutation = useMutation({
    mutationFn: saveMatchSelection,
    successMessage: 'Match was successfully saved.',
    onSuccess: () => {
      reset({
        matchReasoning: '',
        candidateSelection: '',
        matchReasoningComment: ''
      })
      queryClient.invalidateQueries(['ambiguousMatch'], {
        refetchType: 'none'
      })
      return queryClient.invalidateQueries(['ambiguousMatches'], {
        refetchType: 'none'
      })
    }
  })

  const { handleSubmit, control, watch, reset } = useForm<FormData>({
    defaultValues: {
      matchReasoning: '',
      candidateSelection: '',
      matchReasoningComment: ''
    }
  })

  const candidateSelection = watch('candidateSelection')
  const matchReasoning = watch('matchReasoning')

  const matchReasoningOptions =
    candidateSelection === 'noMatch'
      ? ([
          'differentAddress',
          'differentDomain',
          'differentCompanyName',
          'other'
        ] as const)
      : (['mostComplete', 'mostAccurate', 'other'] as const)

  const watchAllFields = watch()
  //every time a match is saved the allAmbiguousMatches query is refetched
  const onSubmit = ({
    candidateSelection,
    matchReasoning,
    matchReasoningComment
  }: FieldValues) => {
    const selectedMatch = currentlyMatching?.candidates?.find(
      supplier => supplier.matched_entity_id === candidateSelection
    )

    const newMatchReasoning =
      matchReasoning === 'other' ? matchReasoningComment : matchReasoning
    const diffInSeconds =
      matchTime && Math.abs(new Date().getTime() - matchTime.getTime()) / 1000
    analytics.track('Match Submitted', {
      eventSource: 'Match Suppliers Page',
      eventCategory: 'user',
      incomingSupplierName: currentlyMatching?.incoming?.company_name,
      incomingSupplierAddress: currentlyMatching?.incoming?.address,
      incomingSupplierDomain: currentlyMatching?.incoming?.domain,
      numberOfPotentialMatches: currentlyMatching?.candidates?.length || 0,
      selectedMatchName: selectedMatch?.supplier_name || 'no match',
      selectedMatchAddress: selectedMatch?.addresses,
      selectedMatchDomain: selectedMatch?.web_domain,
      selectedMatchAccuracy: selectedMatch && Number(selectedMatch?.score || 0),
      matchReasoning: newMatchReasoning,
      timeToSubmit: diffInSeconds
    })

    const body = {
      suggestions: {
        batch_id: currentlyMatching?.batch_id,
        matches: {
          ...(candidateSelection !== 'noMatch' && {
            tealbook_supplier_id: candidateSelection
          }),
          entity_id: currentlyMatching?.entity_id,
          entity_metadata_id: entityMetadataId,
          source: { notes: newMatchReasoning }
        }
      },
      org_id: (pipeline?.inputs && pipeline?.inputs[0].org_id) || ''
    }
    saveMatchSelectionMutation.mutate(body, {
      onSuccess: () => {
        if (nextEntityMetaId) {
          navigate(
            `/opcon/pipelines/${orgId}/${pipelineId}/ambiguous-matches/${nextEntityMetaId}/match-suppliers`,
            {
              state: {
                currentPage: nextPage,
                firstSkippedEntityMetaId
              }
            }
          )
        } else if (firstSkippedEntityMetaId && !nextEntityMetaId) {
          navigate(
            `/opcon/pipelines/${orgId}/${pipelineId}/ambiguous-matches/${firstSkippedEntityMetaId}/match-suppliers`,
            {
              state: {
                currentPage: 0,
                firstSkippedEntityMetaId: ''
              }
            }
          )
        } else {
          navigate(`/opcon/pipelines/${orgId}/${pipelineId}/ambiguous-matches`)
        }
      }
    })
  }

  const linebreakAddress = (addresses: string[]) => {
    return addresses.map((address, index) => {
      return <Typography key={index}>{address}</Typography>
    })
  }

  const handleCandidateChange = (event: ChangeEvent<HTMLInputElement>) => {
    reset({
      matchReasoning: '',
      candidateSelection: event.target.value,
      matchReasoningComment: ''
    })
  }

  const handleSkip = () => {
    const diffInSeconds =
      matchTime && Math.abs(new Date().getTime() - matchTime.getTime()) / 1000

    analytics.track('Match Skipped', {
      eventSource: 'Match Suppliers Page',
      eventCategory: 'user',
      incomingSupplierName: currentlyMatching?.incoming?.company_name,
      incomingSupplierAddress: currentlyMatching?.incoming?.address,
      incomingSupplierDomain: currentlyMatching?.incoming?.domain,
      numberOfPotentialMatches: currentlyMatching?.candidates?.length || 0,
      timeToSkip: diffInSeconds
    })
    queryClient.invalidateQueries(['ambiguousMatch'], {
      refetchType: 'none'
    })
    if (nextEntityMetaId) {
      navigate(
        `/opcon/pipelines/${orgId}/${pipelineId}/ambiguous-matches/${nextEntityMetaId}/match-suppliers`,
        {
          state: {
            currentPage: nextPage,
            firstSkippedEntityMetaId:
              firstSkippedEntityMetaId || entityMetadataId
          }
        }
      )
    } else if (firstSkippedEntityMetaId) {
      navigate(
        `/opcon/pipelines/${orgId}/${pipelineId}/ambiguous-matches/${firstSkippedEntityMetaId}/match-suppliers`,
        {
          state: {
            currentPage: 0,
            firstSkippedEntityMetaId: ''
          }
        }
      )
    }
  }

  if (!currentlyMatching) {
    return <Loading />
  }

  if (!currentlyMatching.entity_metadata_ids.length) {
    return (
      <StatusSection title={t('matchingComplete')}>
        <Typography>{t('pipelineCompleteMessage')}</Typography>
        <Stack direction='row' spacing={1}>
          <Button
            variant='contained'
            onClick={() =>
              navigate(`/opcon/pipelines/${orgId}/${pipelineId}/enrichment`)
            }
          >
            {t('goToEnrichment')}
          </Button>
        </Stack>
      </StatusSection>
    )
  }

  return (
    <Stack spacing={2} sx={{ marginTop: 2 }}>
      <Paper sx={{ p: 3 }}>
        <Stack direction='row' spacing={1} alignItems='center'>
          <Typography>{t('status')}</Typography>
          <Chip
            size='medium'
            label={`${t('inProgressBy')} ${name}`}
            sx={{ backgroundColor: theme.palette.warning.light }}
          />
        </Stack>
        {currentlyMatching.incoming && (
          <Table>
            <TableHead>
              <TableRow>
                <CellRightBorder>{t('type')}</CellRightBorder>
                <CellRightBorder>{t('supplierName')}</CellRightBorder>
                <CellRightBorder>{t('supplierAddress')}</CellRightBorder>
                <CellRightBorder>{t('domain')}</CellRightBorder>
                <CellRightBorder>{t('accuracy')}</CellRightBorder>
                <TableCell>{t('selection')}</TableCell>
              </TableRow>
              <TablePinnedRow>
                <CellRightBorder>{t('incoming')}</CellRightBorder>
                <CellRightBorder>
                  {currentlyMatching.incoming.company_name}
                </CellRightBorder>
                <CellRightBorder>
                  {currentlyMatching.incoming.address}
                </CellRightBorder>
                <CellRightBorder>
                  {currentlyMatching.incoming.domain}
                </CellRightBorder>
                <CellRightBorder>{''}</CellRightBorder>
                <TableCell>{''}</TableCell>
              </TablePinnedRow>
            </TableHead>
            <TableBody>
              {currentlyMatching.candidates.map(row => {
                return (
                  <TableRow key={row.matched_entity_id}>
                    <CellRightBorder>{t('existing')}</CellRightBorder>
                    <CellRightBorder>
                      {startCase(row.supplier_name)}
                    </CellRightBorder>
                    {row.addresses.length > 1 ? (
                      <CellRightBorder>
                        {linebreakAddress(row.addresses)}
                      </CellRightBorder>
                    ) : (
                      <CellRightBorder>
                        {row.addresses.join(', ')}
                      </CellRightBorder>
                    )}
                    <CellRightBorder>
                      {Array.isArray(row.web_domain)
                        ? row.web_domain.join(', ')
                        : row.web_domain}
                    </CellRightBorder>
                    <CellRightBorder>
                      <Chip
                        size='medium'
                        label={row.score}
                        sx={{
                          backgroundColor:
                            currentlyMatching.topScore === row.score
                              ? theme.palette.warning.light
                              : ''
                        }}
                      />
                    </CellRightBorder>
                    <TableCell>
                      <FormControl onChange={handleCandidateChange}>
                        <RadioGroup name='candidateSelection' control={control}>
                          <Radio value={row.matched_entity_id} />
                        </RadioGroup>
                      </FormControl>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <CellRightBorder sx={{ color: theme.palette.text.primary }}>
                  {t('noMatch')}
                </CellRightBorder>
                <CellRightBorder></CellRightBorder>
                <CellRightBorder />
                <CellRightBorder />
                <CellRightBorder>
                  <Chip size='medium' label={0} />
                </CellRightBorder>
                <TableCell>
                  <FormControl onChange={handleCandidateChange}>
                    <RadioGroup name='candidateSelection' control={control}>
                      <Radio value={'noMatch'} />
                    </RadioGroup>
                  </FormControl>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        )}

        <FormControl sx={{ mt: 3, width: '50%', float: 'right' }}>
          <FormLabel sx={{ color: 'text.primary' }} htmlFor='matchReasoning'>
            {t('matchReasoning')}
          </FormLabel>
          <Select
            name='matchReasoning'
            control={control}
            placeholder={t('selectReasonForMatch')}
            disabled={!candidateSelection}
          >
            {matchReasoningOptions.map(option => (
              <MenuItem key={option} value={option}>
                {t(option)}
              </MenuItem>
            ))}
          </Select>
          {matchReasoning === 'other' && (
            <InputText
              name='matchReasoningComment'
              control={control}
              placeholder={t('explainMatch')}
              required
            />
          )}
        </FormControl>
      </Paper>
      <Stack direction='row' spacing={1} justifyContent='flex-end'>
        <Button
          variant='text'
          onClick={() =>
            navigate(
              `/opcon/pipelines/${orgId}/${pipelineId}/ambiguous-matches`
            )
          }
        >
          {t('cancel')}
        </Button>
        {numIdsLeft > 1 && <Button onClick={handleSkip}>{t('skip')}</Button>}
        <Button
          variant='contained'
          onClick={handleSubmit(onSubmit)}
          disabled={
            !watchAllFields.candidateSelection ||
            (watchAllFields.matchReasoning === 'other' &&
              !watchAllFields.matchReasoningComment)
          }
        >
          {t('submitMatch')}
        </Button>
      </Stack>
    </Stack>
  )
}
