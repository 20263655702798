import { TableCell } from '@mui/material'
import { ReactNode } from 'react'
import theme from 'styles/theme'

type Props = {
  children?: ReactNode
  variant?: 'mge'
  isHighlighted?: boolean
  sx?: any
}

export function CellRightBorder({
  children,
  isHighlighted,
  sx,
  variant
}: Props) {
  return (
    <TableCell
      sx={{
        borderRightStyle: 'solid',
        borderRightColor: theme.components?.MuiTableCell?.styleOverrides?.root,
        backgroundColor: isHighlighted ? theme.palette.warning.lighter : '',
        color: variant ? theme.palette[variant].main : '',
        ...sx
      }}
    >
      {children}
    </TableCell>
  )
}
