import api from '../api'
import { BreConfigResponse, BreConfigRules } from 'types'

export const getBreByOrgId = (orgId: string): Promise<BreConfigResponse> =>
  api.get<BreConfigResponse>(`/accounts/org/${orgId}/bre`)

export const updateBreByOrgId = (args: {
  orgId: string
  breId: string
  data: BreConfigRules
}): Promise<BreConfigResponse> =>
  api.put(`/accounts/org/${args.orgId}/bre/${args.breId}`, args.data)
