import { FieldValues, useForm } from 'react-hook-form'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  Paper,
  Typography,
  FormControl,
  FormLabel,
  Stack,
  Button,
  MenuItem
} from '@mui/material'
import { ChangeEvent, useMemo } from 'react'

import { ambiguousMatchesQueries } from 'api/queries'
import { formatName } from 'utils/formatName'
import { useSession } from 'state/session'
import { useSetTitleCluster } from 'state/titleCluster'
import { UspFormData } from 'types'
import theme from 'styles/theme'
import useQuery from 'hooks/useQuery'
import { useAnalyticsWithPipeline } from 'hooks/useAnalytics'

import { Banner } from 'components/Banner'
import { Chip } from 'components/Chip'
import { Loading } from 'components/Loading'
import Select from 'components/Form/Select'
import StatusSection from 'components/StatusSection'

import { OneToManyMatchTable } from './(shared)/OneToManyMatchTable'
import { usePipelineBreadcrumbs } from '../../../(shared)/usePipelineBreadcrumbs'
import useMutation from 'hooks/useMutation'
import { saveUspMatchSelection } from 'api/mutations'

type Location = {
  state?: {
    currentPage: number
    firstSkippedEntityMetadataId: string
  }
}

const NO_MATCH_REASONS: { [key: string]: string } = {
  differentAddress: 'different_address',
  differentDomain: 'different_domain',
  differentCompanyName: 'different_company_name'
}

const SINGLE_MATCH_REASONS: { [key: string]: string } = {
  mostComplete: 'most_attributes_populated',
  mostAccurate: 'most_accurate_option'
}

export const MgeMatchSelect = () => {
  const { entityMetadataId, orgId, pipelineId } = useParams() as {
    entityMetadataId: string
    orgId: string
    pipelineId: string
  }

  const { t } = useTranslation('supplierMatch')
  const navigate = useNavigate()
  const session = useSession()
  const location = useLocation() as Location
  const analytics = useAnalyticsWithPipeline({ clientId: orgId, pipelineId })

  const breadcrumbs = usePipelineBreadcrumbs(orgId, pipelineId)
  useSetTitleCluster(t('matchSuppliers'), breadcrumbs.slice(0, 3))

  const matchTime = useMemo(() => {
    return entityMetadataId ? new Date() : undefined
  }, [entityMetadataId])

  const name = formatName({
    ...session.user
  })

  const { currentPage, firstSkippedEntityMetadataId } = location.state || {
    currentPage: 0,
    firstSkippedEntityMetadataId: ''
  }

  const { handleSubmit, control, watch, reset } = useForm<UspFormData>({
    defaultValues: {
      matchReasoning: '',
      candidateSelection: ''
    }
  })

  const { data: ambiguousMgeMatches, isError } = useQuery(
    ambiguousMatchesQueries.ambiguousMgeMatches(pipelineId, 0, 1000000)
  )

  const saveMatchSelectionMutation = useMutation({
    mutationFn: saveUspMatchSelection,
    successMessage: 'Match was successfully saved.',
    invalidateQueryKey: ['pipelines', 'uspAmbiguousMatchesOverview'],
    onSuccess: () => {
      reset({
        matchReasoning: '',
        candidateSelection: ''
      })
    }
  })

  if (isError) {
    return <p>{'Error'}</p>
  }

  if (!ambiguousMgeMatches) {
    return <Loading />
  }

  const candidateSelection = watch('candidateSelection')
  const matchReasoning = watch('matchReasoning')

  const matchReasoningOptions =
    candidateSelection === 'NO_MATCH' ? NO_MATCH_REASONS : SINGLE_MATCH_REASONS

  const nextEntityMetadataId =
    ambiguousMgeMatches.data[currentPage + 1]?.incoming_entity
      .entity_metadata_id

  const currentlyMatching = ambiguousMgeMatches.data.find(
    record => record.incoming_entity.entity_metadata_id === entityMetadataId
  )

  if (!currentlyMatching) {
    return <p>{'Error'}</p>
  }

  const highestScore = currentlyMatching?.existing_entities.reduce(
    (prev, current) => (prev.score > current.score ? prev : current)
  ).score

  const handleSkip = () => {
    if (nextEntityMetadataId) {
      navigate(
        `/opcon/pipelines/${orgId}/${pipelineId}/ambiguous-matches/${nextEntityMetadataId}/match-MGE-suppliers`,
        {
          state: {
            currentPage: currentPage + 1,
            firstSkippedEntityMetadataId:
              firstSkippedEntityMetadataId || entityMetadataId
          }
        }
      )
    } else if (firstSkippedEntityMetadataId) {
      navigate(
        `/opcon/pipelines/${orgId}/${pipelineId}/ambiguous-matches/${firstSkippedEntityMetadataId}/match-MGE-suppliers`,
        {
          state: {
            currentPage: 0,
            firstSkippedEntityMetadataId: ''
          }
        }
      )
    }
  }

  const handleCandidateChange = (event: ChangeEvent<HTMLInputElement>) => {
    reset({
      matchReasoning: '',
      candidateSelection: event.target.value
    })
  }

  const onSubmit = ({ candidateSelection, matchReasoning }: FieldValues) => {
    const selectedMatch = currentlyMatching?.existing_entities?.find(
      supplier => supplier.supplier_id === candidateSelection
    )

    const diffInSeconds =
      matchTime && Math.abs(new Date().getTime() - matchTime.getTime()) / 1000

    analytics.track('Match Submitted', {
      eventSource: 'Match Suppliers Page',
      eventCategory: 'user',
      incomingSupplierName: currentlyMatching?.incoming_entity?.supplier_name,
      incomingSupplierAddress:
        currentlyMatching?.incoming_entity?.complete_address,
      incomingSupplierDomain: currentlyMatching?.incoming_entity?.web_domain,
      numberOfPotentialMatches:
        currentlyMatching?.existing_entities?.length || 0,
      selectedMatchName: selectedMatch?.supplier_name || 'no match',
      selectedMatchAddress: selectedMatch?.complete_address,
      selectedMatchDomain: selectedMatch?.web_domain,
      selectedMatchAccuracy: selectedMatch && Number(selectedMatch?.score || 0),
      matchReasoning,
      timeToSubmit: diffInSeconds
    })

    const existing_record_id =
      candidateSelection === 'NO_MATCH' ? undefined : candidateSelection
    const match_scenario =
      candidateSelection === 'NO_MATCH' ? candidateSelection : 'SINGLE_MATCH'
    const body = {
      entity_metadata_id: entityMetadataId,
      existing_record_id,
      batch_id: currentlyMatching.incoming_entity.batch_id!,
      reasoning: matchReasoning,
      match_scenario
    }

    saveMatchSelectionMutation.mutate(body, {
      onSuccess: () => {
        if (nextEntityMetadataId) {
          navigate(
            `/opcon/pipelines/${orgId}/${pipelineId}/ambiguous-matches/${nextEntityMetadataId}/match-MGE-suppliers`,
            {
              state: {
                currentPage: currentPage,
                firstSkippedEntityMetadataId
              }
            }
          )
        } else if (firstSkippedEntityMetadataId && !nextEntityMetadataId) {
          navigate(
            `/opcon/pipelines/${orgId}/${pipelineId}/ambiguous-matches/${firstSkippedEntityMetadataId}/match-MGE-suppliers`,
            {
              state: {
                currentPage: 0,
                firstSkippedEntityMetaId: ''
              }
            }
          )
        } else {
          navigate(`/opcon/pipelines/${orgId}/${pipelineId}/ambiguous-matches`)
        }
      }
    })
  }
  if (!ambiguousMgeMatches.metadata.total_count) {
    return (
      <StatusSection title={t('matchingComplete')}>
        <Typography>{t('pipelineCompleteMessage')}</Typography>
      </StatusSection>
    )
  }

  return (
    <Stack spacing={2} sx={{ marginTop: 2 }}>
      <Paper sx={{ p: 3 }}>
        <Banner variant='mge' title={t('mgeBannerTitle')}>
          <Typography color={theme.palette.mge.main}>
            {t('mgeBannerMessage')}
          </Typography>
        </Banner>

        <Stack direction='row' spacing={1} alignItems='center'>
          <Typography>{t('status')}</Typography>
          <Chip
            size='medium'
            label={`${t('inProgressBy')} ${name}`}
            strength='light'
            color='warning'
          />
        </Stack>

        {currentlyMatching?.incoming_entity && (
          <OneToManyMatchTable
            candidates={currentlyMatching.existing_entities}
            control={control}
            highestScore={highestScore}
            uspRecord={currentlyMatching.incoming_entity}
            onCandidateChange={handleCandidateChange}
          />
        )}

        <FormControl sx={{ mt: 3, width: '50%' }}>
          <FormLabel htmlFor='matchReasoning'>{t('matchReasoning')}</FormLabel>
          <Select
            aria-label={t('matchReasoning')}
            name='matchReasoning'
            control={control}
            placeholder={t('selectReasonForMatch')}
            disabled={!candidateSelection}
          >
            {Object.keys(matchReasoningOptions).map(key => (
              <MenuItem key={key} value={matchReasoningOptions[key]}>
                {t(key)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Paper>

      <Stack direction='row' spacing={1} justifyContent='flex-end'>
        <Button
          variant='text'
          onClick={() =>
            navigate(
              `/opcon/pipelines/${orgId}/${pipelineId}/ambiguous-matches`
            )
          }
        >
          {t('cancel')}
        </Button>

        {(nextEntityMetadataId || firstSkippedEntityMetadataId) && (
          <Button onClick={handleSkip}>{t('skip')}</Button>
        )}

        <Button
          variant='contained'
          disabled={!matchReasoning || !candidateSelection}
          onClick={handleSubmit(onSubmit)}
        >
          {t('submitMatch')}
        </Button>
      </Stack>
    </Stack>
  )
}
