import { Box, Stack, Typography } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import theme from 'styles/theme'
import { ReactNode } from 'react'

export type Props = {
  title: string
  children?: ReactNode
}

export default function StatusSection({ title, children }: Props) {
  return (
    <Stack
      spacing={2}
      sx={{
        placeItems: 'center',
        textAlign: 'center',
        minHeight: '100%',
        justifyContent: 'center'
      }}
    >
      <Box
        sx={{
          backgroundColor: theme.palette.success.light,
          borderRadius: '100%',
          padding: 2.5
        }}
      >
        <CheckIcon fontSize='large' color='success' />
      </Box>
      <Typography variant='h4' gutterBottom>
        {title}
      </Typography>
      {children}
    </Stack>
  )
}
