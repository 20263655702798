import { useState } from 'react'
import { Button, Box } from '@mui/material'
import { useParams, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import useQuery from 'hooks/useQuery'
import useMutation from 'hooks/useMutation'
import { getUserGroupById } from 'api/queries'
import { removeUserGroup } from 'api/mutations'
import UserGroupForm from '../(shared)/UserGroupForm'
import { UserGroup } from 'types'
import { useSetTitleCluster } from 'state/titleCluster'
import { Dialog } from 'components/Dialog'

type MyParams = {
  userGroupId: string
}

export default function EditUserGroup() {
  const { userGroupId } = useParams() as MyParams
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false)
  const { t } = useTranslation('userGroups')
  const { t: generalT } = useTranslation('general')

  useSetTitleCluster(t('editUserGroup'), [
    {
      link: '/opcon/user-groups',
      label: t('title')
    }
  ])

  const { data: userGroup = {} as UserGroup, isLoading } = useQuery({
    queryKey: ['userGroups', userGroupId],
    queryFn: () => getUserGroupById(userGroupId)
  })

  const navigate = useNavigate()

  const remove = useMutation({
    mutationFn: removeUserGroup,
    invalidateQueryKey: ['userGroups'],
    successMessage: 'UserGroup was successfully removed',
    onSuccess: () => navigate('/opcon/user-groups')
  })

  const onRemoveUserGroup = () => {
    remove.mutate(userGroup.id)
    setOpenConfirmationDialog(false)
  }

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button color='error' onClick={() => setOpenConfirmationDialog(true)}>
          {generalT('delete')}
        </Button>
      </Box>

      {!isLoading && <UserGroupForm userGroup={userGroup} />}

      <Dialog
        open={openConfirmationDialog}
        onClose={() => setOpenConfirmationDialog(false)}
        isDanger
        onSubmit={onRemoveUserGroup}
        title={t('removeConfirmationTitle')}
        submitButtonText={generalT('delete')}
      >
        {t('removeConfirmationContent', {
          group: userGroup?.displayName
        })}
      </Dialog>
    </>
  )
}
