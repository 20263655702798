import {
  DataGrid,
  DataGridProps,
  GridColDef,
  GridEventListener,
  GridRowIdGetter,
  GridSortModel,
  GridFilterModel
} from '@mui/x-data-grid'
import { useCallback, useMemo, useState, ChangeEvent } from 'react'
import { Button, Stack, Paper, TextField, InputAdornment } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { Link as RouterLink } from 'react-router-dom'
import useQuery from 'hooks/useQuery'
import { PaginationParams, PaginationData } from 'types'
import { useTranslation } from 'react-i18next'
import { useSetTitleCluster } from 'state/titleCluster'

type Props = {
  queryKey: string
  query: (params?: PaginationParams) => Promise<PaginationData<any> | any[]>
  columns: GridColDef[]
  title: string
  createPageLink?: string
  createButtonTitle?: string
  onRowClick?: GridEventListener<'rowClick'>
  tableHeight?: number
  searchOnColumns?: string[]
  pageSize?: number
  scope?: string
  additionalQueryOptions?: { [key: string]: string }
  cacheTime?: number
} & Omit<DataGridProps, 'rows'>

export default function DataTableServerSide(props: Props) {
  const {
    query,
    queryKey,
    columns,
    title,
    createPageLink,
    createButtonTitle,
    onRowClick,
    tableHeight = 634,
    pageSize = 10,
    scope,
    searchOnColumns,
    additionalQueryOptions,
    cacheTime,
    ...rest
  } = props

  const { t } = useTranslation('general')

  useSetTitleCluster(title)

  const visibleColumns = useMemo(
    () => (columns.length > 0 ? columns.slice(1) : columns),
    [columns]
  )
  const [searchStr, setSearchStr] = useState<string>('')
  const [totalCount, setTotalCount] = useState<number>(0)
  const [queryOptions, setQueryOptions] = useState<PaginationParams>({
    page: 0,
    pageSize,
    scope,
    ...additionalQueryOptions
  })

  const { isLoading, data } = useQuery({
    queryKey: [queryKey, queryOptions, searchStr],
    queryFn: () => query({ ...queryOptions, search: searchStr }),
    onSuccess(data) {
      // preserve total count for next fetch
      setTotalCount(getTotalCount(data))
    },
    cacheTime: cacheTime
  })

  // handle the case where using query cache w/o calling onSuccess
  const rowCount = getTotalCount(data) || totalCount

  const isFilterActive = useMemo(() => {
    const { page, pageSize, sort_by, order, scope, ...filters } = queryOptions
    return !!Object.keys(filters).length
  }, [queryOptions])

  const rows = useMemo(() => {
    return (data as PaginationData<any>)?.data || []
  }, [data])

  const getRowId: GridRowIdGetter<any> = useCallback(
    row => row[columns[0].field],
    [columns]
  )

  const handlePageChange = (page: number) => {
    setQueryOptions(options =>
      Object.assign({}, options, { page, pageSize }, additionalQueryOptions)
    )
  }

  const handleSortModelChange = (sortModel: GridSortModel) => {
    setQueryOptions(options =>
      Object.assign(
        {},
        options,
        {
          page: 0,
          sort_by: sortModel[0]?.field,
          order: sortModel[0]?.sort
        },
        additionalQueryOptions
      )
    )
  }

  const handleFilterChange = (filterModel: GridFilterModel) => {
    const filter = filterModel.items[0]
    const filterKey = filter?.columnField
    const filterValue = filter?.value

    const shouldQueryOptionsUpdate =
      filter &&
      ((isFilterActive && !filter.hasOwnProperty('value')) || // open filter panel for different column, reset table
        (!!queryOptions[filterKey] &&
          filter.value !== queryOptions[filterKey]) || // change current filter value
        (!queryOptions[filterKey] && filter.hasOwnProperty('value'))) // switch filter column from filter panel

    if (shouldQueryOptionsUpdate) {
      setQueryOptions(options => {
        const { page, pageSize, sort_by, order } = options
        return {
          page,
          pageSize,
          sort_by,
          order,
          scope: filterKey === 'org_id' && !!filterValue ? undefined : scope, // special case for filtering pipeline
          ...additionalQueryOptions,
          [filterKey]: filterValue
        }
      })
    }
  }

  return (
    <>
      <Stack
        direction='row'
        alignItems='center'
        justifyContent={!!searchOnColumns?.length ? 'space-between' : 'end'}
      >
        {!!searchOnColumns?.length && (
          <TextField
            name='searchTable'
            aria-label='Search'
            variant='outlined'
            placeholder={t('search')}
            size='small'
            value={searchStr}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
              setSearchStr(e.target.value)
            }
            sx={{ width: '50%' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <SearchIcon />
                </InputAdornment>
              )
            }}
          />
        )}
        {createPageLink && createButtonTitle && (
          <Button
            component={RouterLink}
            to={createPageLink}
            variant='contained'
          >
            {createButtonTitle}
          </Button>
        )}
      </Stack>
      <Paper
        variant='elevation'
        elevation={1}
        sx={{ marginTop: 3, height: tableHeight }}
      >
        <DataGrid
          paginationMode='server'
          pageSize={pageSize}
          rowsPerPageOptions={[10]}
          getRowId={getRowId}
          {...rest}
          columns={visibleColumns}
          loading={isLoading}
          rowCount={rowCount}
          rows={rows}
          onRowClick={onRowClick}
          onPageChange={handlePageChange}
          filterMode='server'
          sortingMode='server'
          onFilterModelChange={handleFilterChange}
          onSortModelChange={handleSortModelChange}
          page={queryOptions.page}
          disableColumnSelector
          disableSelectionOnClick
        />
      </Paper>
    </>
  )
}

function getTotalCount(data: any[] | PaginationData<any> | undefined) {
  return Array.isArray(data) ? data.length : data?.metadata.total_count || 0
}
