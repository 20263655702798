import { useTranslation } from 'react-i18next'
import {
  useInfiniteQuery,
  useMutation,
  useQueryClient
} from '@tanstack/react-query'
import { useState } from 'react'

import { getFileCorrectionErrors } from 'api/queries'
import { exportVMFile } from './exportVMFile'
import { updatePipeline } from 'api/mutations'
import { useAnalyticsWithPipeline } from 'hooks/useAnalytics'
import { Pipeline } from 'types'
import { Button } from 'components/Button'

const PAGE_SIZE = 1000

export const ExportErrorsButton = ({
  total,
  pipelineId,
  orgId
}: {
  total: number
  pipelineId: string
  orgId: string
}) => {
  const { t } = useTranslation('pipelines')
  const queryClient = useQueryClient()

  const [exporting, setExporting] = useState(false)

  const numberOfPages = Math.ceil(total / PAGE_SIZE)

  const analytics = useAnalyticsWithPipeline({ clientId: orgId, pipelineId })

  const updatePipelineStatusMutation = useMutation({
    mutationFn: updatePipeline,
    onSuccess: updatedPipelineResponse => {
      return queryClient.setQueryData(
        ['pipelines', pipelineId],
        (oldData: Pipeline | undefined) => {
          return { ...oldData, ...updatedPipelineResponse }
        }
      )
    }
  })

  const { fetchNextPage } = useInfiniteQuery({
    queryKey: ['export', 'errors', orgId, pipelineId, PAGE_SIZE],
    queryFn: ({ pageParam }) => {
      return getFileCorrectionErrors({
        pipelineId,
        orgId,
        page: pageParam || 0,
        pageSize: PAGE_SIZE
      })
    },
    onSuccess: data => {
      if (data.pages.length < numberOfPages) {
        fetchNextPage({ pageParam: data.pages.length })
      } else {
        const exportData = parseAndJoinPages(data.pages)
        exportVMFile(exportData || [], 'vm-errors', pipelineId)
        setExporting(false)

        analytics.track('Errors Exported', {
          eventSource: 'File Corrections Page',
          eventCategory: 'user',
          fileName: `vm-errors-for-${pipelineId}.csv`,
          numberOfErrors: total
        })

        updatePipelineStatusMutation.mutate({
          pipeline_id: pipelineId,
          body: {
            status: 'reviewing',
            org_id: orgId
          }
        })
      }
    },
    enabled: !!total && exporting
  })

  return (
    <Button
      variant='outlined'
      disabled={!total}
      onClick={() => setExporting(true)}
      isLoading={exporting}
    >
      {t('tnvPage.exportButtonLabel')}
    </Button>
  )
}

export default ExportErrorsButton

type T = Awaited<
  Promise<PromiseLike<ReturnType<typeof getFileCorrectionErrors>>>
>

function parseAndJoinPages(pages: T[]) {
  return pages.map(p => p.data).flat()
}
