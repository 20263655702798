import { RefObject, useState, useEffect, Dispatch, SetStateAction } from 'react'

const checkOverflow = (
  ref: RefObject<HTMLSpanElement>,
  setIsOverflow: Dispatch<SetStateAction<boolean>>
) => {
  const clientWidth = ref.current!.getBoundingClientRect().width

  ref.current!.style.overflow = 'visible'
  const contentWidth = ref.current!.getBoundingClientRect().width
  ref.current!.style.overflow = 'hidden'

  setIsOverflow(contentWidth > clientWidth)
}

// funky solution for MUI overflow weirdness
// adapted from here:
// https://github.com/mui/mui-x/issues/1040#issuecomment-1361092416
export const useIsOverflow = (ref: RefObject<HTMLSpanElement>) => {
  const [isOverflowed, setIsOverflow] = useState(false)

  useEffect(() => {
    const check = () => checkOverflow(ref, setIsOverflow)
    check()
    window.addEventListener('resize', check)
    return () => window.removeEventListener('resize', check)
  }, [ref])

  return isOverflowed
}
