import { useTranslation } from 'react-i18next'

import { FileCorrectionsErrors } from 'types'
import { Banner } from 'components/Banner'

export const TnvBanner = ({
  fileCorrectionsErrors
}: {
  fileCorrectionsErrors: FileCorrectionsErrors
}) => {
  const { t } = useTranslation('pipelines')

  return (
    <Banner
      variant='error'
      title={t('tnvPage.tnvUnfixableErrors', {
        criticalCount: fileCorrectionsErrors.high,
        notCriticalCount:
          fileCorrectionsErrors.low + fileCorrectionsErrors.medium
      })}
      description={t('tnvPage.bannerDescription')}
    />
  )
}
