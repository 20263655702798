import { Tab, Tabs } from '@mui/material'
import {
  Outlet,
  useLocation,
  useParams,
  Link as RouterLink
} from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { usePipelineBreadcrumbs } from 'pages/opcon/pipelines/(shared)/usePipelineBreadcrumbs'
import { useSetTitleCluster } from 'state/titleCluster'
import { startCase } from 'lodash'
import useQuery from 'hooks/useQuery'
import { getPipelineById } from 'api/queries'
import { Loading } from 'components/Loading'
import formatDateTime from 'utils/formatDateTime'
import { useAnalyticsWithPipeline } from 'hooks/useAnalytics'

export default function PipelineOverviewLayout() {
  const { pathname } = useLocation()
  const { t } = useTranslation('pipelines')

  const { orgId, pipelineId } = useParams() as {
    orgId: string
    pipelineId: string
  }
  const analytics = useAnalyticsWithPipeline({ clientId: orgId, pipelineId })

  const breadcrumbs = usePipelineBreadcrumbs(orgId, pipelineId)

  const { data: pipeline, isLoading } = useQuery({
    queryKey: ['pipelines', pipelineId, orgId],
    queryFn: () => getPipelineById(orgId, pipelineId),
    enabled: !!orgId && !!pipelineId,
    cacheTime: 0
  })

  const BASE_PATH = `/opcon/pipelines/${orgId}/${pipelineId}`
  let title
  if (pipeline) {
    if (pipeline.pipeline_type === 'common_certification') {
      title = `${
        (pipeline.certificate_source && pipeline.certificate_source.label) ||
        t('pipelineTitle')
      } - ${formatDateTime(pipeline.created_at)}`
    } else {
      title = startCase(pipeline.pipeline_name)
    }
  }
  title = title || t('pipelineTitle')

  useSetTitleCluster(title, [breadcrumbs[0]])

  const pipelineType = pipeline?.pipeline_type

  const tabValue =
    pathname.includes('enrichment') ||
    ((pipelineType === 'client_data_spend' ||
      pipelineType === 'common_certification') &&
      pathname.includes('history'))
      ? 1
      : pathname.includes('history')
      ? 2
      : 0

  if (isLoading) {
    return <Loading />
  }

  return (
    <>
      <Tabs
        aria-label='pipeline overview navigation'
        value={tabValue}
        sx={{
          borderBottom: '1px solid #B9B9B9',
          marginTop: 2,
          marginBottom: 2
        }}
      >
        <Tab
          component={RouterLink}
          to={`${BASE_PATH}/overview`}
          label={
            pipelineType !== 'client_data_spend'
              ? t('tabBar.overview')
              : t('tabBar.spendOverview')
          }
        />
        {pipelineType?.includes('vendor') && (
          <Tab
            component={RouterLink}
            to={`${BASE_PATH}/enrichment`}
            label={t('tabBar.enrichment')}
          />
        )}
        <Tab
          component={RouterLink}
          to={`${BASE_PATH}/history`}
          label={t('tabBar.history')}
          onClick={() => {
            analytics.track('History Tab Clicked', {
              eventSource: 'Pipeline Overview Page',
              eventCategory: 'user'
            })
          }}
        />
      </Tabs>
      <Outlet />
    </>
  )
}
