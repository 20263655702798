export type PasswordPolicy = {
  name: string
  compliant?: boolean
  validate: (password: string, confirmPassword?: string) => boolean | undefined
  showNegative?: boolean
}

export const InitialPasswordPolicies = [
  {
    name: 'length',
    validate: (password: string) =>
      password.length >= 8 && password.length <= 64
  },
  {
    name: 'uppercase',
    validate: (password: string) => password.match(/[A-Z]/) !== null
  },
  {
    name: 'lowercase',
    validate: (password: string) => password.match(/[a-z]/) !== null
  },
  {
    name: 'number',
    validate: (password: string) => password.match(/\d/) !== null
  },
  {
    name: 'specialChar',
    validate: (password: string) => password.match(/[^A-Za-z\d\s]/) !== null
  },
  {
    name: 'noSpaces',
    validate: (password: string) =>
      password.length > 0 ? password.match(/^\S+$/) !== null : undefined,
    showNegative: true
  },
  {
    name: 'matches',
    validate: (password: string, confirmPassword: string) =>
      password.length > 0
        ? password === confirmPassword &&
          password !== '' &&
          confirmPassword !== ''
        : undefined,
    showNegative: true
  }
] as const
