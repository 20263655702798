const getDomainName = (url: string) =>
  url
    .replace(/^https?:\/\//, '')
    .replace(/^www\./, '')
    .replace(/\..*/, '')

export const areDomainsEqual = (url1: string, url2: string) => {
  if (!url1 || !url2) {
    return !!url1 === !!url2
  }

  const domain1 = getDomainName(url1)
  const domain2 = getDomainName(url2)

  return domain1 === domain2
}
