import { useTranslation } from 'react-i18next'
import { Control } from 'react-hook-form'
import {
  TableRow,
  TableBody,
  TableHead,
  TableFooter,
  FormControl,
  Radio,
  Typography
} from '@mui/material'
import { ChangeEvent } from 'react'

import { Table, TablePinnedRow } from 'components/Table'
import RadioGroup from 'components/Form/RadioGroup'
import { CellRightBorder } from 'components/Table/CellRightBorder'
import { Chip } from 'components/Chip'
import { UspRecord, Candidates, UspFormData } from 'types'

import { areDomainsEqual } from './areDomainsEqual'
import areValuesSame from 'utils/areValuesSame'

export const OneToManyMatchTable = ({
  candidates,
  control,
  highestScore,
  uspRecord,
  onCandidateChange
}: {
  candidates: Candidates
  control: Control<UspFormData, any>
  highestScore: number
  uspRecord: UspRecord
  onCandidateChange: (event: ChangeEvent<HTMLInputElement>) => void
}) => {
  const { t } = useTranslation('supplierMatch')

  return (
    <Table>
      <TableHead>
        <TableRow>
          <CellRightBorder>{t('selection')}</CellRightBorder>
          <CellRightBorder>{t('accuracy')}</CellRightBorder>
          <CellRightBorder>{t('type')}</CellRightBorder>
          <CellRightBorder>{t('supplierName')}</CellRightBorder>
          <CellRightBorder>{t('supplierAddress')}</CellRightBorder>
          <CellRightBorder>{t('domain')}</CellRightBorder>
          <CellRightBorder>{t('duns')}</CellRightBorder>
          <CellRightBorder>{t('supplierDbaName')}</CellRightBorder>
        </TableRow>
        <TablePinnedRow variant='mge'>
          <CellRightBorder />
          <CellRightBorder />
          <CellRightBorder variant='mge'>{t('incoming')}</CellRightBorder>
          <CellRightBorder variant='mge'>
            {uspRecord.supplier_name}
          </CellRightBorder>
          <CellRightBorder variant='mge'>
            {uspRecord.complete_address.map(address => (
              <Typography fontWeight='bold' sx={{ mb: 2 }} key={address}>
                {address}
              </Typography>
            ))}
          </CellRightBorder>
          <CellRightBorder variant='mge'>
            {uspRecord.web_domain}
          </CellRightBorder>
          <CellRightBorder variant='mge'>{uspRecord.duns_id}</CellRightBorder>
          <CellRightBorder variant='mge'>
            {uspRecord.supplier_dba_name}
          </CellRightBorder>
        </TablePinnedRow>
      </TableHead>

      <TableBody>
        {candidates.map(candidate => (
          <TableRow key={candidate.supplier_id}>
            <CellRightBorder>
              <FormControl onChange={onCandidateChange}>
                <RadioGroup name='candidateSelection' control={control}>
                  <Radio value={candidate.supplier_id} />
                </RadioGroup>
              </FormControl>
            </CellRightBorder>
            <CellRightBorder>
              <Chip
                size='medium'
                label={candidate.score.toFixed(1) || '0'}
                strength='light'
                color={highestScore === candidate.score ? 'warning' : 'default'}
              />
            </CellRightBorder>
            <CellRightBorder>{t('existing')}</CellRightBorder>
            <CellRightBorder
              isHighlighted={
                !areValuesSame(uspRecord.supplier_name, candidate.supplier_name)
              }
            >
              {candidate.supplier_name}
            </CellRightBorder>
            <CellRightBorder
              isHighlighted={
                !areValuesSame(
                  uspRecord.complete_address,
                  candidate.complete_address
                )
              }
            >
              {candidate.complete_address.map(address => (
                <Typography key={address} sx={{ mb: 2 }}>
                  {address}
                </Typography>
              ))}
            </CellRightBorder>
            <CellRightBorder
              isHighlighted={
                !areDomainsEqual(uspRecord.web_domain, candidate.web_domain)
              }
            >
              {candidate.web_domain}
            </CellRightBorder>
            <CellRightBorder
              isHighlighted={
                !areValuesSame(uspRecord.duns_id, candidate.duns_id)
              }
            >
              {candidate.duns_id}
            </CellRightBorder>
            <CellRightBorder
              isHighlighted={
                !areValuesSame(
                  uspRecord.supplier_dba_name,
                  candidate.supplier_dba_name
                )
              }
            >
              {candidate.supplier_dba_name}
            </CellRightBorder>
          </TableRow>
        ))}
      </TableBody>

      <TableFooter>
        <TableRow>
          <CellRightBorder>
            <FormControl onChange={onCandidateChange}>
              <RadioGroup name='candidateSelection' control={control}>
                <Radio value={'NO_MATCH'} />
              </RadioGroup>
            </FormControl>
          </CellRightBorder>
          <CellRightBorder>
            <Chip size='medium' label={0} strength='light' />
          </CellRightBorder>
          <CellRightBorder>{t('noMatchCreateNew')}</CellRightBorder>
          <CellRightBorder />
          <CellRightBorder />
          <CellRightBorder />
          <CellRightBorder />
          <CellRightBorder />
        </TableRow>
      </TableFooter>
    </Table>
  )
}
