import {
  Paper,
  Table as MUITable,
  TableContainer,
  TableProps
} from '@mui/material'

export const Table = ({ children, ...rest }: TableProps) => (
  <TableContainer
    component={Paper}
    sx={{
      marginTop: '20px',
      border: '1px solid #E0E0E0',
      borderRadius: '4px',
      overflowY: 'auto'
    }}
  >
    <MUITable sx={{ minWidth: 700 }} {...rest}>
      {children}
    </MUITable>
  </TableContainer>
)
