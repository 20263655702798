import { useCallback } from 'react'
import { Snackbar as MuiSnackbar, Alert } from '@mui/material'
import { useRecoilState } from 'recoil'
import { notificationsState } from 'state/notifications'

export default function Snackbar() {
  const [notification, setNotifications] = useRecoilState(notificationsState)
  const handleClose = useCallback(() => setNotifications(), [setNotifications])

  return (
    <MuiSnackbar
      key={notification?.key}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      open={!!notification}
      onClose={handleClose}
      autoHideDuration={5000}
      message={notification?.message}
      children={
        notification?.severity && (
          <Alert severity={notification.severity}>{notification.message}</Alert>
        )
      }
    />
  )
}
