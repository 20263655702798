import { Metadata, PipelineStatus } from 'types'

export type StageType =
  | 'tnv'
  | 'supplier_match'
  | 'spend_mapping'
  | 'data_enrichment'
export type ActionType = 'export_spend' | 'export_error' | 'export_vendor'

export type PipelineDerivedStatus = PipelineStatus | 'not_ready'

export type PipelineErrorOutput = {
  stage: StageType
  status: PipelineDerivedStatus
  action?: ActionType
}

export const processRowData = (data: Array<{ metadata: Metadata }>) => {
  return data.map(({ metadata }) => {
    const incomingEntity = metadata?.source_metadata?.payload
    const hilReason = metadata.matching_info.notes
    return {
      'Internal source name':
        incomingEntity?.internal_source_name ||
        incomingEntity?.internal_supplier_id_source,
      'Internal Supplier ID or Vendor Number':
        incomingEntity?.internal_supplier_id,
      'Company Name': incomingEntity?.company_name,
      'Complete Full Address': incomingEntity?.complete_address,
      'Address (Street Name and Number)': `${
        incomingEntity?.street_number ? incomingEntity.street_number : ''
      } ${incomingEntity?.street_name ? incomingEntity.street_name : ''}`,
      'Address (City)': incomingEntity?.city,
      'Address (State/Province)': incomingEntity?.state,
      'Address (Country)': incomingEntity?.country,
      'Address (ZIP Code / Postal Code)': incomingEntity?.pcode,
      'Tax ID': incomingEntity?.taxpayer_id,
      Website: incomingEntity?.website_url || incomingEntity?.domain,
      'Contact Email': incomingEntity?.contact_email,
      'NAICS Code': incomingEntity?.naics,
      DUNS: incomingEntity?.duns_id,
      LEI: incomingEntity?.lei,
      'Head Count': incomingEntity?.head_count,
      Type: metadata.matching_info.source === 'HIL' ? 'HIL' : 'Pipeline',
      Reason: hilReason
    }
  })
}
