import { useState } from 'react'
import { Stack, Box, Button } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { getFileCorrectionsErrors, getPipelineById } from 'api/queries'
import useQuery from 'hooks/useQuery'
import { Banner } from 'components/Banner'
import { Loading } from 'components/Loading'
import { useSetTitleCluster } from 'state/titleCluster'

import { usePipelineBreadcrumbs } from '../../(shared)/usePipelineBreadcrumbs'
import { AcceptAllErrorsDialog } from './(shared)/AcceptAllErrorsDialog'
import { ErrorsDataGrid } from './(shared)/ErrorsDataGrid'
import ExportErrorsButton from './(shared)/ExportErrorsButton'
import { CorrectionsUploadDialog } from './(shared)/CorrectionsUploadDialog'
import { ReadyForMatchingBanner } from './(shared)/ReadyForMatchingBanner'
import { CompleteBanner } from './(shared)/CompleteBanner'
import { TnvBanner } from './(shared)/TnvBanner'

export const Tnv = () => {
  const { orgId, pipelineId } = useParams() as {
    orgId: string
    pipelineId: string
  }

  const { t } = useTranslation('pipelines')
  const { t: generalT } = useTranslation('general')

  const [openCorrectionsDialog, setOpenCorrectionsDialog] = useState(false)
  const [showAcceptAsIsDialog, setShowAcceptAsIsDialog] = useState(false)

  const breadcrumbs = usePipelineBreadcrumbs(orgId, pipelineId)
  useSetTitleCluster(t('tnvPage.file_corrections'), breadcrumbs.slice(0, 2))

  const { data: pipeline } = useQuery({
    queryKey: ['pipelines', pipelineId, orgId],
    queryFn: () => getPipelineById(orgId, pipelineId),
    cacheTime: 0
  })

  const { data: fileCorrectionsErrors } = useQuery({
    queryKey: ['pipelines', 'fileCorrectionsErrors', pipelineId],
    queryFn: () => getFileCorrectionsErrors(pipelineId)
  })

  if (!pipeline || !fileCorrectionsErrors) {
    return <Loading />
  }

  //Always use the latest upload correction/input; This will enable you to always display the latest errors
  const pipelineInputs = pipeline.inputs.filter(input => !!input.batch_id)

  const input = pipelineInputs && pipelineInputs[pipelineInputs.length - 1]

  const hasTnvStarted =
    input &&
    pipeline.outputs.some(
      o =>
        o.task_id?.startsWith('tnv_task_group') && o.batch_id === input.batch_id
    )

  const totalFileCorrectionsErrors =
    fileCorrectionsErrors.low +
    fileCorrectionsErrors.medium +
    fileCorrectionsErrors.high

  if (pipeline.status === 'readyformatching') {
    return <ReadyForMatchingBanner orgId={orgId} pipelineId={pipelineId} />
  }

  if (pipeline.status === 'complete') {
    return <CompleteBanner orgId={orgId} pipelineId={pipelineId} />
  }

  if (!hasTnvStarted) {
    return (
      <Banner
        variant='warning'
        title={t('tnvPage.fileProcessing')}
        description={t('tnvPage.checkBackLater')}
      />
    )
  }

  if (totalFileCorrectionsErrors !== 0) {
    return (
      <>
        <Stack spacing={2}>
          <TnvBanner fileCorrectionsErrors={fileCorrectionsErrors} />

          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Stack direction='row' spacing={1}>
              <ExportErrorsButton
                total={totalFileCorrectionsErrors}
                pipelineId={pipelineId}
                orgId={orgId}
              />
              <Button
                variant='outlined'
                disabled={totalFileCorrectionsErrors === 0}
                onClick={() => setOpenCorrectionsDialog(true)}
              >
                {t('tnvPage.uploadCorrections')}
              </Button>
            </Stack>
          </Box>

          <ErrorsDataGrid pipelineId={pipelineId} orgId={orgId} />

          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              onClick={() => setShowAcceptAsIsDialog(true)}
              variant='contained'
            >
              {t('tnvPage.acceptAllErrors')}
            </Button>
          </Box>
        </Stack>

        <AcceptAllErrorsDialog
          pipelineId={pipelineId}
          open={showAcceptAsIsDialog}
          onClose={() => setShowAcceptAsIsDialog(false)}
          orgId={orgId}
        />

        <CorrectionsUploadDialog
          fileName={pipeline.inputs[0].file_name}
          onClose={() => setOpenCorrectionsDialog(false)}
          open={openCorrectionsDialog}
          orgId={pipeline.inputs[0].org_id}
          pipelineId={pipeline.pipeline_id}
        />
      </>
    )
  }

  return <p>{generalT('error')}</p>
}
