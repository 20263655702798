import {
  getAuth,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithPopup,
  GoogleAuthProvider,
  signOut,
  setPersistence,
  browserSessionPersistence,
  browserLocalPersistence,
  User
} from 'firebase/auth'
import api from './api'

export interface EmailPassword {
  email: string
  password: string
  rememberMe: boolean
}

export interface UserWithToken extends User {
  idToken: string
}

async function setCustomToken(user: User) {
  const idToken = await user?.getIdToken()

  if (idToken != null) {
    await api.post<UserWithToken>('/accounts/auth/validate', {
      ...user,
      idToken
    })
    await user.getIdToken(true)
  }
}

/*
  https://firebase.google.com/docs/auth/admin/custom-claims#client_side_implementation_javascript_2
*/
export const loginWithEmailAndPassword = async ({
  email,
  password,
  rememberMe
}: EmailPassword) => {
  const auth = getAuth()

  setPersistence(
    auth,
    rememberMe ? browserLocalPersistence : browserSessionPersistence
  )

  const userCreds = await signInWithEmailAndPassword(auth, email, password)

  // User authenticated with Google, set Tealbook claims:
  await setCustomToken(userCreds.user)

  return userCreds
}

export const loginWithGoogle = async (rememberMe: boolean) => {
  const auth = getAuth()
  const provider = new GoogleAuthProvider()

  setPersistence(
    auth,
    rememberMe ? browserLocalPersistence : browserSessionPersistence
  )

  const userCreds = await signInWithPopup(auth, provider)

  // User authenticated with Google, set Tealbook claims:
  await setCustomToken(userCreds.user)

  return userCreds
}

export const forgetPassword = async (email: string) => {
  const auth = getAuth()
  await sendPasswordResetEmail(auth, email)
}

export const logout = () => {
  signOut(getAuth())
}
