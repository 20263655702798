import { useState, MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'

import MuiAppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Avatar from '@mui/material/Avatar'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import Logout from '@mui/icons-material/Logout'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

import { useSession } from 'state/session'
import theme from 'styles/theme'
import { TitleCluster } from './TitleCluster'
import ScrollToElevate from './ScrollToElevate'
import { formatName } from 'utils/formatName'
import { logout } from 'api/auth'

export const AppBar = () => {
  const { t } = useTranslation('general')
  const session = useSession()

  const currentUser = session.user

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseUserMenu = () => {
    setAnchorEl(null)
  }

  const name = formatName({
    firstName: currentUser?.firstName,
    lastName: currentUser?.lastName
  })

  return (
    <ScrollToElevate>
      <MuiAppBar
        color='inherit'
        sx={{
          border: 'none',
          boxShadow: 'none',
          position: 'sticky',
          top: 0
        }}
      >
        <Toolbar
          sx={{
            paddingLeft: { xs: theme.spacing(6) },
            mt: 2,
            mb: 2
          }}
        >
          <TitleCluster />

          <Box sx={{ flexGrow: 1 }}></Box>

          <Box
            sx={{
              flexGrow: 0,
              display: 'flex',
              alignItems: 'center',
              gap: '20px'
            }}
          >
            <Typography sx={{ fontWeight: 'bold', fontSize: '16px' }}>
              {name}
            </Typography>

            <Avatar
              alt={name}
              sx={{
                height: '2rem',
                width: '2rem',
                bgcolor: theme.palette.text.primary
              }}
            />

            <Tooltip title={t('accountMenu')}>
              <IconButton
                onClick={handleOpenUserMenu}
                sx={{ padding: 0, color: theme.palette.text.primary }}
                aria-controls={open ? 'account-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup='true'
                size='small'
              >
                <KeyboardArrowDownIcon sx={{ width: 32, height: 32 }} />
              </IconButton>
            </Tooltip>

            <Menu
              sx={{ mt: '45px' }}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              open={open}
              onClose={handleCloseUserMenu}
            >
              <MenuItem onClick={logout} id='account-menu'>
                <ListItemIcon>
                  <Logout fontSize='small' />
                </ListItemIcon>
                <Typography variant='body1'>{t('logOut')}</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </MuiAppBar>
    </ScrollToElevate>
  )
}
