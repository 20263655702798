import { styled, Theme, CSSObject, useTheme } from '@mui/material/styles'
import { Box, Drawer as MuiDrawer } from '@mui/material'
import { PropsWithChildren } from 'react'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

import TealbookLogo from 'assets/TealBook-Logo.svg'
import TealbookIcon from 'assets/TealBook-Icon.svg'
import SidebarToggle from './SidebarToggle'

export const DRAWER_WIDTH = 260

const openedMixin = (theme: Theme): CSSObject => ({
  width: DRAWER_WIDTH,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
})

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`
  }
})

export const HeaderSpace = styled('div')(({ theme }) => ({
  paddingTop: '1.5rem',
  paddingBottom: '1.5rem',
  height: '5.5rem',
  display: 'flex',
  flexShrink: 0,
  alignItems: 'center',
  justifyContent: 'center',
  // necessary for content to be below app bar
  ...theme.mixins.toolbar
}))

const Logo = styled('img')(({ theme }) => ({
  objectFit: 'contain',
  width: '100%',
  height: '100%'
}))

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== 'open'
})(({ theme, open }) => ({
  width: DRAWER_WIDTH,
  whiteSpace: 'nowrap',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme)
  })
}))

type Props = {
  open: boolean
  onOpen: () => void
  onClose: () => void
} & PropsWithChildren

const Sidebar = (props: Props) => {
  const { open, onOpen, onClose, children } = props
  const theme = useTheme()

  return (
    <>
      <Drawer
        variant='permanent'
        color='sidebar'
        PaperProps={{ sx: { backgroundColor: 'sidebar.main' } }}
        open={open}
      >
        <HeaderSpace>
          <Logo src={open ? TealbookLogo : TealbookIcon} alt='TealBook' />
        </HeaderSpace>
        <Box component={'nav'} sx={{ marginTop: theme.spacing(1) }}>
          {children}
        </Box>
      </Drawer>
      {open ? (
        <SidebarToggle
          open={open}
          onClick={onClose}
          aria-label='Collapse sidebar'
        >
          <ChevronLeftIcon />
        </SidebarToggle>
      ) : (
        <SidebarToggle open={open} onClick={onOpen} aria-label='Expand sidebar'>
          <ChevronRightIcon />
        </SidebarToggle>
      )}
    </>
  )
}
export default Sidebar
