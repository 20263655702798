import {
  useMutation as useTanstackMutation,
  UseMutationOptions,
  useQueryClient,
  QueryKey
} from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import useNotify from './useNotify'
import { ApiError } from 'api/ApiError'

type ExtraOptions = {
  successMessage?: string
  invalidateQueryKey?: QueryKey
}
export default function useMutation<
  TData = unknown,
  TError = unknown,
  TVariables = unknown,
  TContext = unknown
>(
  options: ExtraOptions &
    UseMutationOptions<TData, TError, TVariables, TContext>
) {
  const notify = useNotify()
  const { t } = useTranslation('general')
  const queryClient = useQueryClient()
  const { successMessage, invalidateQueryKey, ...restOfOptions } = options

  return useTanstackMutation({
    ...restOfOptions,
    onSuccess: (...successArgs) => {
      if (successMessage) {
        notify({
          message: successMessage,
          severity: 'success'
        })
      }

      if (invalidateQueryKey) {
        queryClient.invalidateQueries({ queryKey: invalidateQueryKey })
      }

      if (options.onSuccess) {
        options.onSuccess(...successArgs)
      }
    },
    onError: (error, ...errorArgs) => {
      console.error(error)
      const { responseData } = error as ApiError
      notify({
        message: responseData?.message || t('error'),
        severity: 'error'
      })
      if (options.onError) {
        options.onError(error, ...errorArgs)
      }
    }
  })
}
