import api from '../api'
import {
  AmbiguousMatches,
  AmbiguousMgeMatches,
  CandidateSupplierMatch,
  MatchStatus,
  Metadata,
  PaginationParams,
  SourcePayload,
  CertMatchOverview,
  PaginationData,
  Address,
  AmbiguousMatchingDetails
} from 'types'
import { getUsersByIds } from './users'
import { MATCH_SCENARIO, EXPAND } from './pipelines'
import { createQueryKeys } from '@lukemorales/query-key-factory'

export const ambiguousMatchesQueries = createQueryKeys('ambiguousMatches', {
  ambiguousMatchesPaginated: (
    orgId: string,
    pipelineId: string,
    page: number,
    pageSize: number
  ) => ({
    queryKey: [orgId, pipelineId, page, pageSize],
    queryFn: () => getAmbiguousMatches({ page, pageSize, pipelineId, orgId }),
    enabled: !!pipelineId && !!orgId
  }),
  ambiguousMatchesMetadata: (orgId: string, pipelineId: string) => ({
    queryKey: [orgId, pipelineId],
    queryFn: () => getAmbiguousMatchesMetadata(orgId, pipelineId),
    enabled: !!pipelineId && !!orgId
  }),
  uspAmbiguousMatchesOverview: (
    pipelineId: string,
    page: number,
    pageSize: number
  ) => ({
    queryKey: [pipelineId, 'overview', page, pageSize],
    queryFn: () =>
      getUspAmbiguousMatchesOverview({
        pipelineId,
        page,
        pageSize
      })
  }),
  ambiguousMgeMatches: (
    pipelineId: string,
    page: number,
    pageSize: number
  ) => ({
    queryKey: [pipelineId, page, pageSize],
    queryFn: () => getAmbiguousMgeMatches({ pipelineId, page, pageSize }),
    enabled: !!pipelineId
  })
})

const parseAddresses = (
  addresses: Array<{ address?: { address_string: string } }>
) => {
  return addresses.reduce((result: Array<string>, currentAddress) => {
    if (currentAddress.address && currentAddress.address.address_string) {
      result = [...result, currentAddress.address.address_string]
    }
    return result
  }, [])
}

const createCompleteAddress = (address: Address | undefined) => {
  return (
    address &&
    (address.address?.address_string ||
      `${address.address?.addressline || ''} ${address.address?.city || ''} ${
        address.address?.state || ''
      } ${address.address?.country || ''} ${address.address?.pcode || ''}`)
  )
}

export const getAmbiguousMatchesMetadata = (
  orgId: string,
  pipelineId: string
) => {
  const queryStringParams = {
    limit: 50,
    org_id: orgId,
    match_scenario: MATCH_SCENARIO.AMBIGUOUS,
    expand: EXPAND.MATCHES
  }

  return api.get<AmbiguousMatches>(
    `/data/metadata/pipeline/${pipelineId}`,
    queryStringParams
  )
}

export const getAmbiguousMatches = async function (
  params: PaginationParams & { pipelineId: string; orgId: string }
) {
  const { page = 0, pageSize = 50, pipelineId, orgId } = params
  const offset = page * pageSize

  const queryStringParams = {
    limit: pageSize,
    offset,
    org_id: orgId,
    match_scenario: MATCH_SCENARIO.AMBIGUOUS,
    expand: EXPAND.MATCHES
  }

  const { data, metadata } = await api.get<AmbiguousMatches>(
    `/data/metadata/pipeline/${pipelineId}`,
    queryStringParams
  )

  const ambiguousMatches: Array<{
    match_status: MatchStatus
    matches: CandidateSupplierMatch[]
    sourceData: SourcePayload
    metadata: Metadata
  }> = data?.map(({ metadata, ...rest }) => {
    const sourceData = metadata.source_metadata.payload
    return { sourceData, metadata, ...rest, match_status: 'not_started' }
  })
  return { ambiguousMatches, numAmbigMatches: metadata.total_count }
}

export const getAmbiguousMatchByEntityMetadataId = async function (
  params: PaginationParams & {
    pipelineId: string
    entityMetadataId: string
    orgId: string
  }
) {
  const {
    page = 0,
    pageSize = 10,
    pipelineId,
    entityMetadataId,
    orgId
  } = params
  const offset = page * pageSize
  const queryStringParams = {
    offset,
    limit: pageSize,
    org_id: orgId,
    entity_metadata_id: entityMetadataId,
    match_scenario: MATCH_SCENARIO.AMBIGUOUS,
    expand: EXPAND.MATCHES
  }
  const { data } = await api.get<AmbiguousMatchingDetails>(
    `/data/metadata/pipeline/${pipelineId}`,
    queryStringParams
  )
  if (data && !data[0]?.entity_metadata_ids.length) {
    return { entity_metadata_ids: [] }
  }
  const startMatching = data[0]

  const {
    batch_id,
    entity_id,
    entity_metadata_id,
    source_metadata: { payload },
    matching_info: { candidates }
  } = startMatching.metadata

  const entity = startMatching.entity

  const incomingEntity = {
    web_domain: entity.web_domain?.join(', '),
    address: createCompleteAddress(
      entity.addresses && entity.addresses[entity.addresses.length - 1]
    ),
    ...payload
  }

  const ambiguousCandidates = startMatching.matches
    ?.map(({ supplier_id, addresses, ...rest }) => {
      const findScore = candidates.find(
        info => info.matched_entity_id === supplier_id
      )
      return {
        matched_entity_id: supplier_id,
        addresses: addresses.length ? parseAddresses(addresses) : [],
        score: findScore?.score.toFixed(1) || '0',
        ...rest
      }
    })
    .sort((a, b) => {
      if (a.score > b.score) {
        return -1
      }
      if (a.score < b.score) {
        return 1
      } else return 0
    })
  const topScore = ambiguousCandidates[0].score
  return {
    incoming: incomingEntity,
    candidates: ambiguousCandidates,
    batch_id,
    entity_id,
    entity_metadata_id,
    topScore,
    entity_metadata_ids: startMatching.entity_metadata_ids
  }
}

export const getCompletedAmbiguousMatchesByUser = async function (
  params: PaginationParams & {
    pipelineId: string
    match_updated_at: string
    match_updated_by?: string
  }
) {
  const {
    page = 0,
    pageSize = 10,
    pipelineId,
    match_updated_at,
    match_updated_by
  } = params
  const offset = page * pageSize
  const { data, metadata } = await api.get<AmbiguousMatches>(
    `/data/metadata/pipeline/${pipelineId}?&offset=${offset}&limit=${pageSize}&expand=${EXPAND.COMPLETED_MATCHING}&match_updated_at=${match_updated_at}&match_updated_by=${match_updated_by}`
  )

  const matchUpdatedBy = await getUsersByIds([match_updated_by!])
  const matchUpdatedName = `${matchUpdatedBy[0].firstName} ${matchUpdatedBy[0].lastName}`

  type AmbiguousCandidates = {
    selectedMatch: boolean
    supplier_name: string
    web_domain?: string[] | undefined
    internal_supplier_id?: string | undefined
    matched_entity_id: string
    addresses: string[]
    score: string
  }

  const completedMatches: Array<{
    match_status?: MatchStatus
    incomingEntity: SourcePayload
    metadata: Metadata
    ambiguousCandidates: Array<AmbiguousCandidates>
    topScore: string
    matchUpdatedName: string
  }> = data?.map(({ metadata, matches, entity, ...rest }) => {
    const sourceData = metadata.source_metadata.payload

    let web_domain = sourceData.web_domain || sourceData.website_url
    let address = sourceData.complete_address
    if (entity) {
      web_domain = entity.web_domain?.join(', ') || web_domain
      address = entity.addresses
        ? createCompleteAddress(entity.addresses[entity.addresses.length - 1])
        : address
    }
    const incomingEntity = {
      web_domain,
      address,
      ...sourceData
    }

    const ambiguousCandidates = matches
      .map(({ supplier_id, addresses, ...rest }) => {
        const findScore = metadata?.original_matching?.candidates.find(
          info => info.matched_entity_id === supplier_id
        )
        const selectedMatchId =
          !!metadata.matching_info?.candidates?.length &&
          metadata.matching_info?.candidates[0].matched_entity_id
        return {
          matched_entity_id: supplier_id,
          addresses: addresses.length ? parseAddresses(addresses) : [],
          score: findScore?.score.toFixed(1) || '0',
          selectedMatch: selectedMatchId === supplier_id,
          ...rest
        }
      })
      .sort((a, b) => {
        if (a.score > b.score) {
          return -1
        }
        if (a.score < b.score) {
          return 1
        } else return 0
      })
    const topScore = ambiguousCandidates[0].score
    return {
      incomingEntity,
      metadata,
      ...rest,
      ambiguousCandidates,
      topScore,
      matchUpdatedName
    }
  })

  return {
    completedMatches,
    numAmbigMatches: metadata.total_count
  }
}

export const getUspAmbiguousMatchesOverview = async (
  params: PaginationParams & {
    pipelineId: string
  }
) => {
  const { pipelineId, page = 0, pageSize = 10 } = params
  const offset = page * pageSize
  const queryStringParams = {
    offset,
    limit: pageSize
  }

  return api.get<Promise<PaginationData<CertMatchOverview[]>>>(
    `/data/usp_metadata/match/overview/${pipelineId}`,
    queryStringParams
  )
}

export const getAmbiguousMgeMatches = async function (
  params: PaginationParams & { pipelineId: string }
) {
  const { page = 0, pageSize = 50, pipelineId } = params
  const offset = page * pageSize

  const queryStringParams = {
    limit: pageSize,
    offset
  }

  return api.get<AmbiguousMgeMatches>(
    `/data/usp_metadata/match/multiple_good_existing/${pipelineId}`,
    queryStringParams
  )
}
