import { ReactNode } from 'react'
import { PopoverProps, Popover } from '@mui/material'

import theme from 'styles/theme'

export const DataTablePopover = ({
  children,
  bindPopover
}: {
  children: ReactNode
  bindPopover: PopoverProps
}) => (
  <Popover
    {...bindPopover}
    sx={{ ml: '-10px' }}
    PaperProps={{
      sx: {
        border: '2px solid ' + theme.palette.primary.main,
        borderRadius: '0px'
      }
    }}
    anchorOrigin={{
      vertical: 'center',
      horizontal: 'left'
    }}
    transformOrigin={{
      vertical: 'center',
      horizontal: 'left'
    }}
  >
    {children}
  </Popover>
)
