import { forwardRef, ReactNode } from 'react'
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
  useTheme
} from '@mui/material'
import Collapse from '@mui/material/Collapse'
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
  useLocation,
  useMatch
} from 'react-router-dom'
import { RequirePermission } from 'providers/RequirePermission'

export type SideNavLinkProps = {
  pathTo: string
  text: string
  icon: ReactNode
  isSidebarOpen?: boolean
  subNavList?: Array<Omit<NestedSideNavLinkProps, 'open'>>
  customHighlighted?: string
  customContrastText?: string
}

const Link = forwardRef<HTMLAnchorElement, RouterLinkProps>(
  (itemProps, ref) => <RouterLink ref={ref} {...itemProps} />
)

const colors = {
  highlighted: 'primary.lighter',
  contrastText: 'primary.contrastText'
}

export default function SideNavLink(props: SideNavLinkProps) {
  const {
    pathTo,
    text,
    icon,
    isSidebarOpen,
    subNavList,
    customContrastText,
    customHighlighted
  } = props
  const location = useLocation()
  const isLinkActive = location.pathname.startsWith(pathTo)
  const isChildActive = !!subNavList?.some(nav => location.pathname === nav.to)

  return (
    <>
      <ListItemButton
        component={Link}
        to={pathTo}
        sx={{
          color: isLinkActive
            ? customHighlighted || colors.highlighted
            : customContrastText || colors.contrastText,
          textDecoration: 'none',
          '&:hover, &.Mui-focusVisible': {
            backgroundColor: 'rgba(226, 226, 226, 0.4)'
          }
        }}
      >
        <ListItemIcon
          sx={{
            color: isChildActive
              ? customHighlighted || colors.highlighted
              : 'inherit'
          }}
        >
          {icon}
        </ListItemIcon>
        <ListItemText
          primary={text}
          primaryTypographyProps={{
            fontWeight: 'bold',
            fontSize: '16px',
            color: 'inherit'
          }}
        />
      </ListItemButton>
      {subNavList &&
        isSidebarOpen &&
        subNavList.map(nav => (
          <NestedSideNavLink
            key={nav.to}
            open={isLinkActive || isChildActive}
            to={nav.to}
            text={nav.text}
            permissions={nav.permissions}
            customContrastText={customContrastText}
            customHighlighted={customHighlighted}
          />
        ))}
    </>
  )
}

export type NestedSideNavLinkProps = {
  open: boolean
  to: string
  text: string
  permissions: string[]
  customHighlighted?: string
  customContrastText?: string
}

function NestedSideNavLink(props: NestedSideNavLinkProps) {
  const theme = useTheme()
  const { open, to, text, permissions, customHighlighted, customContrastText } =
    props
  const isLinkActive = !!useMatch(to)
  return (
    <RequirePermission permissions={permissions}>
      <Collapse in={open} timeout='auto' unmountOnExit>
        <ListItemButton
          component={Link}
          to={to}
          sx={{
            color: isLinkActive
              ? customHighlighted || colors.highlighted
              : customContrastText || colors.contrastText,
            textDecoration: 'none',
            '&:hover, &.Mui-focusVisible': {
              backgroundColor: 'rgba(226, 226, 226, 0.4)'
            }
          }}
        >
          {isLinkActive && (
            <ListItemIcon>
              <Box
                component='div'
                sx={{
                  display: 'inline-block',
                  height: '1.75rem',
                  width: 6,
                  marginLeft: theme.spacing(-2),
                  backgroundColor:
                    customHighlighted || theme.palette.primary.lighter
                }}
              />
            </ListItemIcon>
          )}
          <ListItemText
            primary={text}
            primaryTypographyProps={{
              fontWeight: 'bold',
              fontSize: '16px',
              color: 'inherit'
            }}
            sx={{
              marginLeft: isLinkActive ? '0' : '55px'
            }}
          />
        </ListItemButton>
      </Collapse>
    </RequirePermission>
  )
}
