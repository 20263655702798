import { FormRowProps } from './FormRow'
import { Trans } from 'react-i18next'
import { DataScenario, TrustScore } from 'types'
import { Box, Button, Stack, Typography, useTheme } from '@mui/material'
import { useState } from 'react'
import { BreScenarioDescriptionPopover } from './Popover'
import { trustScoreColors } from './TrustScoreColors'

type TrustScoreSelectorButtonProps = {
  trustScore: TrustScore
  selected?: boolean
  inSelectedPath?: boolean
  first?: boolean
  last?: boolean
  field: string
  selectedScenario: DataScenario
  onClick: () => void
}

const FullTextButtonContent = ({
  trustScore
}: TrustScoreSelectorButtonProps) => {
  return (
    <Stack>
      <Typography
        fontWeight='bold'
        fontSize='0.85rem'
        marginBottom='-0.25rem'
        color={trustScoreColors.get(trustScore)}
      >
        <Trans ns='bre' i18nKey={`trustScoreLevels.${trustScore}`} />
      </Typography>
      <Typography
        fontWeight='bold'
        textTransform='uppercase'
        fontSize='0.65rem'
      >
        <Trans ns='bre' i18nKey={`trustScoreSubLabel.${trustScore}`} />
      </Typography>
    </Stack>
  )
}

const WaypointButtonContent = ({
  first,
  last,
  inSelectedPath
}: TrustScoreSelectorButtonProps) => {
  const { palette } = useTheme()

  return (
    <Stack width='100%' position='relative'>
      <Box
        height='2px'
        width={first || last ? '50%' : undefined}
        justifySelf={'flex-end'}
        bgcolor={inSelectedPath ? palette.primary.main : palette.grey[400]}
        sx={{
          transform: first
            ? 'translateX(100%)'
            : last
            ? 'translateX(0)'
            : undefined
        }}
      />
      <Box
        height='0.75rem'
        width='0.75rem'
        bgcolor={inSelectedPath ? palette.primary.main : palette.grey[400]}
        borderRadius='50%'
        position='absolute'
        top='50%'
        left='50%'
        sx={{ transform: 'translateX(-50%) translateY(-50%)' }}
      />
    </Stack>
  )
}

const TrustScoreSelectorButton = (props: TrustScoreSelectorButtonProps) => {
  const { palette } = useTheme()

  const [isHover, setIsHover] = useState(false)

  const updateHover = (state: boolean) => setIsHover(state)

  const { field, selectedScenario, trustScore, selected, last, onClick } = props

  return (
    <Box position='relative'>
      <BreScenarioDescriptionPopover
        {...{
          field,
          selectedScenario,
          trustScore,
          isHover,
          buttonSelected: selected ?? false,
          shouldOffset: last ?? false
        }}
      />

      <Button
        onClick={onClick}
        role='button'
        onMouseEnter={() => updateHover(true)}
        onMouseLeave={() => updateHover(false)}
        sx={{
          width: '7rem',
          background: selected ? palette.common.white : 'none',
          padding: '0.15rem 0',
          height: '100%'
        }}
      >
        {selected ? (
          <FullTextButtonContent {...props} />
        ) : (
          <WaypointButtonContent {...props} />
        )}
      </Button>
    </Box>
  )
}

export const TrustScoreSelector = ({
  section,
  field,
  type,
  config: { configs, selectedScenario, hasTrustScore },
  onTrustScoreSelect,
  hasVeryHighTrustScore
}: FormRowProps) => {
  const { palette } = useTheme()
  const selectedTrustScore = configs[selectedScenario]
  const showSelector = selectedScenario !== 'never' && hasTrustScore

  const selectedPaths: Map<TrustScore, Array<TrustScore>> = new Map([
    ['very_high', []],
    ['high', ['very_high']],
    ['medium', ['very_high', 'high']],
    ['low', ['very_high', 'high', 'medium']],
    ['none', ['very_high', 'high', 'medium', 'low']]
  ])

  if (!hasVeryHighTrustScore) {
    selectedPaths.delete('very_high')
  }

  return (
    <Stack
      direction='row'
      bgcolor={palette.grey[200]}
      padding='0.25rem'
      borderRadius='7px'
      alignItems='stretch'
      height='3rem'
      role='group'
    >
      {showSelector ? (
        Array.from(selectedPaths.keys()).map((trustScore, idx) => (
          <TrustScoreSelectorButton
            trustScore={trustScore}
            field={field}
            selectedScenario={selectedScenario}
            selected={selectedTrustScore === trustScore}
            inSelectedPath={
              !selectedPaths
                .get(trustScore)
                ?.includes(selectedTrustScore ?? 'none')
            }
            onClick={() =>
              onTrustScoreSelect({
                section,
                field,
                type,
                trustScore,
                selectedScenario
              })
            }
            first={idx === 0}
            last={idx === selectedPaths.size - 1}
            key={`${section}.${type ?? 'default'}.${field}.${trustScore}`}
          />
        ))
      ) : (
        <Box
          width={hasVeryHighTrustScore ? '35rem' : '28rem'}
          padding='1.27rem 0'
        />
      )}
    </Stack>
  )
}
