import { useTranslation } from 'react-i18next'
import { DialogContentText } from '@mui/material'

import { Dialog } from 'components/Dialog'

export function WarningPrompt({
  openPrompt,
  onConfirmNavigation,
  onCancelNavigation
}: {
  openPrompt: boolean
  onConfirmNavigation: () => void
  onCancelNavigation: () => void
}) {
  const { t } = useTranslation('pipelineRunner')
  return (
    <Dialog
      cancelButtonText={t('cancelNavigationMessage')}
      isSwapCancelConfirmButtons
      onClose={onCancelNavigation}
      onSubmit={onConfirmNavigation}
      open={openPrompt}
      submitButtonText={t('confirmNavigationMessage')}
      title={t('warningPromptTitle')}
    >
      <DialogContentText>{t('warningPromptContent')}</DialogContentText>
    </Dialog>
  )
}
