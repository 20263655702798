import { Typography } from '@mui/material'
import { TFunction } from 'i18next'

import formatDateTime from 'utils/formatDateTime'
import { PipelineStatus } from 'types'
import { getGridSingleSelectOperators, ValueOptions } from '@mui/x-data-grid'
import createColDefs from 'components/DataTable/createColDefs'
import { StatusChip } from 'components/StatusChip'
import theme from 'styles/theme'
import { rateToPercent } from 'utils/rateToPercent'

export const getPipelineUSPColumns = (
  t: TFunction<'pipelines'>,
  filterValueOptions?: { [key: string]: ValueOptions[] }
) =>
  createColDefs([
    { field: 'pipeline_id' },
    {
      field: 'certificate_source',
      headerName: t('certSource'),
      minWidth: 250,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: params => (
        <Typography
          noWrap
          variant='body1'
          sx={{ color: theme.palette.primary.main }}
        >
          {params.value?.label}
        </Typography>
      )
    },
    {
      field: 'status',
      headerName: t('loadStatus'),
      width: 200,
      renderCell: params => (
        <StatusChip
          label={t(params.value)}
          variant={params.value as PipelineStatus}
          isStartCase
        />
      ),
      type: 'singleSelect',
      filterOperators: getGridSingleSelectOperators().filter(
        operator => operator.value === 'is'
      ),
      valueOptions: filterValueOptions?.status
    },
    {
      field: 'user_id',
      headerName: t('createdBy'),
      minWidth: 200,
      sortable: false,
      type: 'singleSelect',
      filterOperators: getGridSingleSelectOperators().filter(
        operator => operator.value === 'is'
      ),
      valueOptions: filterValueOptions?.createdBy,
      renderCell: params => (
        <Typography variant='body1'>{params.row.createdBy}</Typography>
      )
    },
    {
      field: 'updated_at',
      headerName: t('lastUpdated'),
      width: 200,
      filterable: false,
      disableColumnMenu: true,
      renderCell: params => (
        <Typography variant='body1'>{formatDateTime(params.value)}</Typography>
      )
    },
    {
      field: 'created_at',
      headerName: t('dateCreated'),
      width: 200,
      filterable: false,
      disableColumnMenu: true,
      renderCell: params => (
        <Typography variant='body1'>{formatDateTime(params.value)}</Typography>
      )
    },
    {
      field: 'original_match_rate',
      headerName: t('originalMatchRate'),
      width: 125,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: params => (
        <Typography variant='body1'>
          {rateToPercent(params.row.stats.original_match_rate)}
        </Typography>
      )
    },
    {
      field: 'hil_to_required_records_rate',
      headerName: t('hilMatchRate'),
      width: 125,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: params => (
        <Typography variant='body1'>
          {rateToPercent(params.row.stats.hil_to_required_records_rate)}
        </Typography>
      )
    },
    {
      field: 'cumulative_match_rate',
      headerName: t('overallMatchRate'),
      width: 125,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: params => (
        <Typography variant='body1'>
          {rateToPercent(params.row.stats.cumulative_match_rate)}
        </Typography>
      )
    }
  ])
