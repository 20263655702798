import { GridColDef } from '@mui/x-data-grid'

// Note: the first column is being removed in DataTable using visibleColumns method
// This must be the id column, which is required as a unique key for the table, but not to be visible in the table
export default function createColDefs(columns: GridColDef[]): GridColDef[] {
  return columns.map(({ width, flex, ...restCol }) => ({
    ...restCol,
    width,
    flex: !width ? flex || 1 : undefined
  }))
}
