import { useForm, useController, useWatch } from 'react-hook-form'
import {
  Button,
  MenuItem,
  Stack,
  Typography,
  ListItemText,
  Checkbox,
  Paper,
  FormControl
} from '@mui/material'
import InputText from 'components/Form/InputText'
import { createUser, updateUser } from 'api/mutations'
import useMutation from 'hooks/useMutation'
import useQuery from 'hooks/useQuery'
import { useNavigate } from 'react-router-dom'
import queries, {
  getAllOrgs,
  getOrgGroups,
  getAvailablePermissions
} from 'api/queries'
import FormSwitch from 'components/Form/Switch'
import FormSelect from 'components/Form/Select'
import MultipleSelect from 'components/Form/MultipleSelect'
import { Permissions } from 'components/Permissions'
import { Loading } from 'components/Loading'
import { User, Org } from 'types'
import { useTranslation } from 'react-i18next'

type Props = {
  user?: User
  isLoading?: boolean
}

type FormData = User

export default function UserFormWithOrgsData(props: Props) {
  const { data, isLoading } = useQuery({
    queryKey: ['orgs'],
    queryFn: getAllOrgs
  })

  if (isLoading || !data) {
    return <Loading />
  }

  return <UserForm orgs={data} {...props} />
}

function UserForm(props: Props & { orgs: Org[] }) {
  const { user, orgs } = props
  const navigate = useNavigate()
  const { t } = useTranslation('users')
  const { t: userGroupsT } = useTranslation('userGroups')
  const { t: generalT } = useTranslation('general')

  const defaultSelectedOrgId = orgs.find(
    org => org.name.toLowerCase() === 'tealbook'
  )?.id

  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<FormData>({
    defaultValues: {
      id: user?.id,
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      email: user?.email || '',
      orgId: user?.orgId || defaultSelectedOrgId,
      enabled: user?.enabled,
      permissions: user?.permissions || [],
      permissionGroupIds: user?.permissionGroupIds || []
    }
  })

  const orgId = useWatch({ control, name: 'orgId' })

  const { field: permissionGroupsField } = useController({
    name: 'permissionGroupIds',
    control
  })

  const { data: orgGroups = [] } = useQuery({
    queryKey: ['getOrgGroups', orgId],
    queryFn: () => {
      if (!orgId) {
        return []
      }
      return getOrgGroups(orgId)
    },
    refetchOnMount: 'always'
  })

  const { data: availablePermissions } = useQuery({
    queryKey: ['availablePermissions'],
    queryFn: getAvailablePermissions
  })

  const update = useMutation({
    mutationFn: updateUser,
    invalidateQueryKey: queries.users._def,
    successMessage: 'User was successfully edited',
    onSuccess: () => navigate('/opcon/users')
  })

  // When this mutation succeeds, invalidate any queries with the `users` query key
  const create = useMutation({
    mutationFn: createUser,
    invalidateQueryKey: queries.users._def,
    successMessage: 'User was successfully created',
    onSuccess: () => navigate('/opcon/users')
  })

  const onSubmit = (data: FormData) => {
    //If User is to be edited
    let payload
    if (user) {
      payload = {
        ...data,
        id: user.id
      }
    } else {
      payload = {
        ...data,
        enabled: true
      }
    }
    if (!payload.id) {
      create.mutate(payload)
    } else {
      update.mutate(payload)
    }
  }
  const isSelectedGroup = (group: any, useGroupIds?: any[]) => {
    if (!useGroupIds || useGroupIds.length === 0) {
      return false
    }
    return !!useGroupIds.find(id => id === group.id)
  }

  const renderUserGroupItems = (selectedGroups?: string[]) => {
    if (!orgGroups || orgGroups.length === 0) {
      return [
        <MenuItem key='disabled' disabled>
          <ListItemText primary={userGroupsT('noUserGroup')} />
        </MenuItem>
      ]
    }

    return orgGroups.map(element => (
      <MenuItem key={element.id} value={element.id}>
        <Checkbox checked={isSelectedGroup(element, selectedGroups)} />
        <ListItemText primary={element.displayName} />
      </MenuItem>
    ))
  }

  return (
    <Stack mt={4} spacing={2} sx={{ minWidth: 380, maxWidth: 700 }}>
      <Paper sx={{ padding: 2 }}>
        <FormControl fullWidth sx={{ mt: 1, mb: 2 }} variant='outlined'>
          <InputText
            required
            name='email'
            control={control}
            label='Email'
            pattern={{
              value: /^[\w.!#$%&'*+/=?^`{|}~-]+@[a-z\d-]+(?:\.[a-z\d-]+)*$/i,
              message: 'Invalid email address'
            }}
            errors={errors.email}
            inputProps={{
              readOnly: !!user?.id
            }}
          />
        </FormControl>
        <FormControl fullWidth sx={{ mb: 2 }} variant='outlined'>
          <InputText
            required
            name='firstName'
            control={control}
            label='First Name'
            errors={errors.firstName}
          />
        </FormControl>
        <FormControl fullWidth sx={{ mb: 2 }} variant='outlined'>
          <InputText
            required
            name='lastName'
            control={control}
            label='Last Name'
            errors={errors.lastName}
          />
        </FormControl>
        {user && (
          <FormControl fullWidth sx={{ mb: 2 }}>
            <FormSwitch name='enabled' control={control} label='Enabled' />
          </FormControl>
        )}
        <FormControl
          fullWidth
          sx={{ mb: 2 }}
          size='small'
          error={!!errors.orgId}
        >
          <FormSelect
            name='orgId'
            control={control}
            label='Client'
            disabled={!!user}
            errors={errors.orgId}
            required
          >
            {orgs.map(org => (
              <MenuItem key={org.id} value={org.id}>
                {org.name}
              </MenuItem>
            ))}
          </FormSelect>
        </FormControl>
        <FormControl fullWidth sx={{ mb: 2 }} size='small'>
          <MultipleSelect
            label={
              orgId ? t('userForm.userGroups') : t('userForm.selectClientFirst')
            }
            {...permissionGroupsField}
            name='groups'
            disabled={!orgId}
            renderValue={(value: unknown) => {
              const userGroupIds = value as string[]
              const userGroups = orgGroups
                .filter(({ id }) => userGroupIds.includes(id))
                .map(({ displayName }) => displayName)
                .join(',')
              return <div>{userGroups}</div>
            }}
          >
            {renderUserGroupItems(permissionGroupsField.value)}
          </MultipleSelect>
        </FormControl>
      </Paper>
      <Paper sx={{ padding: 2 }}>
        <Typography variant='h5' gutterBottom>
          {userGroupsT('permissionsHeading')}
        </Typography>

        {availablePermissions && (
          <Permissions
            control={control}
            groupedPermissions={availablePermissions.globalAdmin}
          />
        )}
      </Paper>
      <Button
        onClick={handleSubmit(onSubmit)}
        variant='contained'
        sx={{
          width: 'fit-content'
        }}
      >
        {generalT('save')}
      </Button>
    </Stack>
  )
}
