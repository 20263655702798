import {
  BreConfigRules,
  BreConfigRulesField,
  BreConfigSupplierRules,
  BreFormData,
  BreFormRow,
  SupplierAttributes,
  TypedBreConfigRules
} from 'types'
import { composeFormRow } from './breDataMapperUtil'
import { BreScenarioSetting } from './BreScenarioSetting'

const trustScoreToValue: { [score: string]: number } = {
  none: BreScenarioSetting.ALWAYS,
  low: BreScenarioSetting.LOW,
  medium: BreScenarioSetting.MEDIUM,
  high: BreScenarioSetting.HIGH,
  very_high: BreScenarioSetting.VERY_HIGH
} as const

export const supplierBreConfigRulesToFormData = (
  supplierBreConfigRules?: Array<BreConfigSupplierRules>
): Record<SupplierAttributes, BreFormRow> | undefined =>
  supplierBreConfigRules
    ? supplierBreConfigRules.reduce((acc, { fields, rules }) => {
        const fieldConfigs = fields.map(attr => [
          attr,
          composeFormRow('supplier', attr, rules)
        ])
        return { ...acc, ...Object.fromEntries(fieldConfigs) }
      }, {} as Record<SupplierAttributes, BreFormRow>)
    : undefined

export const typedConfigRulesToFormData = (
  section: string,
  typedBreConfigRules?: Array<TypedBreConfigRules>,
  hasVeryHighTrustScore?: boolean
): Record<string, BreFormRow> | undefined =>
  typedBreConfigRules
    ? Object.fromEntries(
        typedBreConfigRules.map(({ type, rules }) => [
          type,
          composeFormRow(section, type, rules, hasVeryHighTrustScore)
        ])
      )
    : undefined

export const breConfigRulesToFormData = ({
  supplier,
  addresses,
  certifications
}: BreConfigRules): BreFormData => ({
  supplier: supplierBreConfigRulesToFormData(supplier),
  address: typedConfigRulesToFormData('address', addresses),
  certifications: typedConfigRulesToFormData(
    'certifications',
    certifications,
    true
  )
})

// Mapper from Form Data to BRE API Request
const breRowToBreFieldRules = ({
  selectedScenario,
  configs,
  hasTrustScore
}: BreFormRow): BreConfigRulesField => {
  // Never selected scenario doesn't have a
  // trust score threshold. Other scenarios do.

  if (!hasTrustScore) {
    if (selectedScenario === 'never') {
      return {
        onEmptyClientValue: BreScenarioSetting.NEVER,
        onClientValue: BreScenarioSetting.NEVER
      }
    }
    if (selectedScenario === 'unprovided') {
      return {
        onEmptyClientValue: BreScenarioSetting.ALWAYS,
        onClientValue: BreScenarioSetting.NEVER
      }
    }
    if (selectedScenario === 'always') {
      return {
        onEmptyClientValue: BreScenarioSetting.ALWAYS,
        onClientValue: BreScenarioSetting.ALWAYS
      }
    } else {
      throw Error('Invalid form data')
    }
  }

  if (selectedScenario === 'never') {
    return {
      onEmptyClientValue: BreScenarioSetting.NEVER,
      onClientValue: BreScenarioSetting.NEVER
    }
  }

  if (selectedScenario === 'always' && configs.always) {
    return {
      onEmptyClientValue:
        trustScoreToValue[configs.always as keyof typeof trustScoreToValue],
      onClientValue:
        trustScoreToValue[configs.always as keyof typeof trustScoreToValue]
    }
  }

  if (configs.unprovided && configs.dissimilar) {
    return {
      onEmptyClientValue:
        trustScoreToValue[configs.unprovided as keyof typeof trustScoreToValue],
      onClientValue:
        trustScoreToValue[configs.dissimilar as keyof typeof trustScoreToValue]
    }
  } else {
    throw Error('Invalid form data')
  }
}

const attributeFormConfigToBreConfigRules = (
  rows?: Record<string, BreFormRow>
): Array<BreConfigSupplierRules> | undefined => {
  if (!rows) {
    return undefined
  }

  const attributeArray = Object.entries(rows).map(([field, breRow]) => ({
    fields: [field],
    rules: breRowToBreFieldRules(breRow)
  }))

  const deduppedAttributeMap = attributeArray.reduce(
    (result, { fields, rules }) => {
      const key = JSON.stringify(rules)

      if (result.has(key)) {
        result.get(key)?.push(...fields)
      } else {
        result.set(key, fields)
      }

      return result
    },
    new Map<string, string[]>()
  )

  return Array.from(deduppedAttributeMap).map(([rulesString, fields]) => ({
    fields,
    rules: JSON.parse(rulesString) as BreConfigRulesField
  })) as Array<BreConfigSupplierRules>
}

const typedFormConfigToBreConfigRules = (
  typedRows?: Record<string, BreFormRow>
): Array<TypedBreConfigRules> | undefined =>
  typedRows
    ? Object.entries(typedRows).map(([type, breRow]) => ({
        type,
        rules: breRowToBreFieldRules(breRow)
      }))
    : undefined

export const formDataToBreConfigRules = ({
  supplier,
  address,
  certifications
}: BreFormData): BreConfigRules => ({
  supplier: attributeFormConfigToBreConfigRules(supplier),
  addresses: typedFormConfigToBreConfigRules(address),
  certifications: typedFormConfigToBreConfigRules(certifications)
})
