import { useTranslation } from 'react-i18next'

type Props = {
  errors: { original: string; suggested: string }[]
}

const HeadingErrors = (props: Props) => {
  const { errors } = props
  const { t } = useTranslation('pipelineRunner')

  return (
    <ul>
      {errors.map(e => (
        <li key={e.original}>{`${e.original} => ${t('try')} ${
          e.suggested
        }`}</li>
      ))}
    </ul>
  )
}

export default HeadingErrors
