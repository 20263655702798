import { CircularProgress, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'

export const Loading = () => {
  const { t } = useTranslation('general')
  return (
    <Stack
      sx={{
        placeItems: 'center',
        textAlign: 'center',
        minHeight: '100%',
        justifyContent: 'center'
      }}
    >
      <CircularProgress aria-label={t('loading')} />
    </Stack>
  )
}
