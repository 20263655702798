import { Fragment, ReactNode } from 'react'
import { Box, Typography } from '@mui/material'
import { StatusChip, StatusChipProps } from '../StatusChip'
import { InfoIcon } from 'components/Info'

export type DataPair = {
  label: string
  info?: string | number | ReactNode
  chip?: StatusChipProps
  tooltip?: string
}

export type DataPairGroupProps = {
  dataPairs: DataPair[]
  noRowGap?: boolean
}

export const DataPairGroup = ({ dataPairs, noRowGap }: DataPairGroupProps) => (
  <Box
    sx={{
      display: 'inline-grid',
      gridTemplateColumns: '1fr auto',
      columnGap: '1rem',
      rowGap: noRowGap ? 0 : '0.5rem',
      alignItems: 'center'
    }}
  >
    {dataPairs.map(({ label, info, chip, tooltip }) => (
      <Fragment key={`${label}`}>
        <Typography fontWeight='bold' sx={chip ? chip.sx : null}>
          {tooltip && <InfoIcon info={label} displayInfo={tooltip} />}
          {`${label}:`}
        </Typography>
        {info && !chip && <Typography>{info}</Typography>}
        {!info && chip && (
          <span>
            <StatusChip {...chip} isStartCase />
          </span>
        )}
      </Fragment>
    ))}
  </Box>
)
