import {
  Stack,
  List,
  ListItem,
  ListItemText,
  ListItemTextProps,
  Paper,
  Typography
} from '@mui/material'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Loading } from 'components/Loading'
import { Button } from 'components/Button'
import { useOrgAndPipelineQuery } from 'hooks/useOrgAndPipelineQuery'

import ExportNoMatchButton from './(shared)/ExportNoMatchButton'
import { getEnrichmentsDisabled } from './(shared)/getEnrichmentsDisabled'
import { getNoMatchSuppliers } from 'api/queries'
import useQuery from 'hooks/useQuery'
import {
  getEnrichedMetadataFileByPipeline,
  getEnrichedMetadataInfo
} from 'api/queries'
import useNotify from 'hooks/useNotify'
import { useAnalyticsWithPipeline } from 'hooks/useAnalytics'

const ListContent = ({ primary, secondary }: ListItemTextProps) => (
  <ListItemText
    disableTypography
    primary={<Typography fontWeight='bold'>{primary}</Typography>}
    secondary={secondary}
  />
)

const ENRICHEDTYPES = {
  FIRMOGRAPHICS: 'firmographics',
  DIVERSITY: 'diversity'
}

const ENRICHEDINTERVALS = {
  ORIGINAL: 'original',
  CUMULATIVE: 'cumulative'
}

export default function PipelineEnrichment() {
  const { orgId, pipelineId } = useParams() as {
    orgId: string
    pipelineId: string
  }

  const { t } = useTranslation('pipelines')
  const { t: generalT } = useTranslation('general')

  const notify = useNotify()
  const { pipeline, org } = useOrgAndPipelineQuery({ orgId, pipelineId })
  const analytics = useAnalyticsWithPipeline({ clientId: orgId, pipelineId })
  const orgName = org?.name

  const { data: unmatchedSuppliers } = useQuery({
    queryKey: ['unmatchedSuppliers', pipelineId],
    queryFn: () =>
      getNoMatchSuppliers({ pipelineId: pipelineId, page: 0, pageSize: 2 })
  })

  const { data: enrichmentDataInfo, isLoading: isEnrichmentInfoLoading } =
    useQuery({
      queryKey: ['enrichmentDataInfo', orgId, pipelineId],
      queryFn: () => {
        if (pipelineId)
          return getEnrichedMetadataInfo({
            pipelineId,
            orgId
          })
      },
      enabled: !!pipelineId
    })

  const orgHasCertificationsDatapack = !!org?.datapacks?.find(
    datapack => datapack.tag === 'diversity_certifications'
  )

  const isSingleBatch = pipeline?.inputs.length === 1
  const handleExport = async (type: string, interval: string) => {
    const newFileName = t(`enrichment.${interval}.${type}`, { orgName })
    try {
      await getEnrichedMetadataFileByPipeline({
        pipelineId,
        orgId,
        page: 0,
        pageSize: 1000000,
        type,
        interval,
        newFileName
      })
      analytics.track(`${interval} Enrichment Exported`, {
        eventSource: 'Pipeline Enrichment Page',
        eventCategory: 'user',
        exportType: type,
        fileName: newFileName
      })
    } catch (error) {
      notify({
        message: generalT('noExportData'),
        severity: 'error'
      })
    }
  }
  const {
    originalFirmographic,
    originalCertificates,
    cumulativeFirmographic,
    cumulativeCertificates
  } = getEnrichmentsDisabled({
    noEnrichedDataText: t('enrichment.noEnrichedData'),
    pipelineNotCompleteText: t('enrichment.pipelineNotComplete'),
    certificatesDisabledText: t('enrichment.certificatesDisabled'),
    isNoEnrichmentData: !enrichmentDataInfo?.enrichment_data_exists,
    isSingleBatch,
    orgHasCertificationsDatapack,
    pipelineStatus: pipeline?.status,
    hasNoCertifications: !enrichmentDataInfo?.certifications_data_exists
  })

  return !pipeline || !org || !unmatchedSuppliers || isEnrichmentInfoLoading ? (
    <Loading />
  ) : (
    <Paper>
      <List>
        <ListItem divider sx={{ py: 3 }}>
          <ListContent
            primary={t('enrichment.original.primary')}
            secondary={t('enrichment.original.secondary')}
          />
          <Stack direction='row' spacing={2}>
            <Button
              disabled={originalFirmographic.disabled}
              onClick={() =>
                handleExport(
                  ENRICHEDTYPES.FIRMOGRAPHICS,
                  ENRICHEDINTERVALS.ORIGINAL
                )
              }
              tooltip={originalFirmographic.tooltip}
            >
              {t('enrichment.exportFirmographics')}
            </Button>
            <Button
              disabled={originalCertificates.disabled}
              onClick={() =>
                handleExport(
                  ENRICHEDTYPES.DIVERSITY,
                  ENRICHEDINTERVALS.ORIGINAL
                )
              }
              tooltip={originalCertificates.tooltip}
            >
              {t('enrichment.exportCertificates')}
            </Button>
          </Stack>
        </ListItem>

        <ListItem divider sx={{ py: 3 }}>
          <ListContent
            primary={t('enrichment.cumulative.primary')}
            secondary={t('enrichment.cumulative.secondary')}
          />
          <Stack direction='row' spacing={2}>
            <Button
              disabled={cumulativeFirmographic.disabled}
              onClick={() =>
                handleExport(
                  ENRICHEDTYPES.FIRMOGRAPHICS,
                  ENRICHEDINTERVALS.CUMULATIVE
                )
              }
              tooltip={cumulativeFirmographic.tooltip}
            >
              {t('enrichment.exportFirmographics')}
            </Button>
            <Button
              disabled={cumulativeCertificates.disabled}
              onClick={() =>
                handleExport(
                  ENRICHEDTYPES.DIVERSITY,
                  ENRICHEDINTERVALS.CUMULATIVE
                )
              }
              tooltip={cumulativeCertificates.tooltip}
            >
              {t('enrichment.exportCertificates')}
            </Button>
          </Stack>
        </ListItem>

        <ListItem sx={{ py: 3 }}>
          <ListContent
            primary={t('enrichment.unmatchedSuppliers.primary')}
            secondary={t('enrichment.unmatchedSuppliers.secondary')}
          />
          <ExportNoMatchButton
            orgId={orgId}
            pipelineId={pipelineId}
            pipelineName={pipeline.pipeline_name}
            pipelineStatus={pipeline.status}
            total={unmatchedSuppliers?.metadata.total_count || 0}
          />
        </ListItem>
      </List>
    </Paper>
  )
}
