import { Stack, FormControl, FormLabel, MenuItem } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Control, FieldError } from 'react-hook-form'

import { InfoLabel } from 'components/Info/Info'
import InputText from 'components/Form/InputText'
import SelectForm from 'components/Form/Select'
import { Org } from 'types'

import { RunnerFormData } from './PipelineRunnerForm'

export const SharedFields = ({
  control,
  orgId,
  pipelineName,
  sortedOrgs
}: {
  control: Control<RunnerFormData, any>
  orgId?: FieldError
  pipelineName?: FieldError
  sortedOrgs: Org[]
}) => {
  const { t } = useTranslation('pipelineRunner')

  return (
    <>
      <FormControl sx={{ mt: 1, textAlign: 'start' }}>
        <FormLabel htmlFor='orgId'>{t('clientName')}</FormLabel>
        <SelectForm name='orgId' control={control} errors={orgId}>
          <MenuItem value=''>{t('select')}</MenuItem>
          {sortedOrgs.map(org => (
            <MenuItem key={org.id} value={org.id}>
              {org.name}
            </MenuItem>
          ))}
        </SelectForm>
      </FormControl>
      <FormControl>
        <Stack direction='row' alignItems='center'>
          <InfoLabel
            label={
              <FormLabel htmlFor='pipelineName'>{t('pipelineName')}</FormLabel>
            }
            info={t('pipelineNameTooltip')}
          />
        </Stack>

        <InputText
          required
          name='pipelineName'
          control={control}
          errors={pipelineName}
          placeholder={t('pipelineNamePlaceholder')}
        />
      </FormControl>
    </>
  )
}
