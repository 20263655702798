import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box, Divider, Paper, Stack } from '@mui/material'

import useQuery from 'hooks/useQuery'
import useMutation from 'hooks/useMutation'
import queries from 'api/queries'
import { updateUserPassword } from 'api/mutations'
import { useSetTitleCluster } from 'state/titleCluster'

import {
  PasswordResetForm,
  PasswordResetFormData,
  UserDisplay
} from '../(shared)'
import { formatName } from 'utils/formatName'

export const ResetPassword = () => {
  const { userId } = useParams() as { userId: string }

  const { t } = useTranslation('users')

  const { data: user } = useQuery(queries.users.detail(userId))

  const { data: org } = useQuery({
    ...queries.orgs.byId(user?.orgId!),
    enabled: !!user?.orgId
  })

  useSetTitleCluster(
    t('resetPassword'),
    user && [
      {
        link: '/opcon/users',
        label: t('title')
      },
      {
        link: `/opcon/users/${user.id}/edit`,
        label: formatName(user)
      }
    ]
  )

  const updatePassword = useMutation({
    mutationFn: updateUserPassword,
    successMessage: 'Password successfully reset'
  })

  const onSubmit = (data: PasswordResetFormData) => {
    updatePassword.mutate({
      userId,
      password: data.password
    })
  }

  return (
    <Box mt={4}>
      <Paper>
        <Stack
          padding={4}
          divider={<Divider orientation='horizontal' flexItem />}
          rowGap={4}
        >
          <UserDisplay user={user} org={org} />
          <PasswordResetForm onSubmit={onSubmit} />
        </Stack>
      </Paper>
    </Box>
  )
}
