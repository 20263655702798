import {
  Outlet,
  Navigate,
  createBrowserRouter,
  RouterProvider,
  RouteObject
} from 'react-router-dom'
import { RequireAuth } from 'providers/RequireAuth'
import { ErrorPage } from 'components/ErrorPage'
import opconRoutes from './opcon/routes'
import OpconLayout from './opcon/layout'
import diversityReportRoutes from './diversity-report/routes'
import Layout from './diversity-report/layout'

const noMatchRoute: RouteObject = {
  path: '*',
  element: <ErrorPage error={{ statusText: 'No routes matched location' }} />
}

export const routes: RouteObject[] = [
  {
    element: (
      <RequireAuth>
        <Outlet />
      </RequireAuth>
    ),
    children: [
      {
        path: '/diversity-report',
        element: <Layout />,
        children: [...diversityReportRoutes, noMatchRoute]
      },
      {
        path: '/opcon',
        element: <OpconLayout />,
        children: [...opconRoutes, noMatchRoute]
      },
      {
        path: '*',
        element: <Navigate to='/opcon' replace />
      }
    ]
  }
]

const router = createBrowserRouter(routes)

export default function AppRoutes() {
  return <RouterProvider router={router} />
}
