import { initReactI18next } from 'react-i18next'
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

/**
 * Translations should be grouped into namespaces with their own json file
 * each namespace needs to be added to the nameSpaces array to be imported,
 * and make sure to update src/i18n/react-i18next.d.ts with the new namespace
 * for typechecking
 *
 * Run yarn i18n-parse to scan the project for translation keys,
 * they will be added to the right namespace file automatically.
 *
 * New languages must be added in i18next-parser.config.js.
 */
const NAME_SPACES = [
  'apiCredentials',
  'apps',
  'bre',
  'components',
  'datapacks',
  'general',
  'orgs',
  'pipelineOverview',
  'pipelineRunner',
  'pipelines',
  'supplierMatch',
  'userGroups',
  'users',
  'login',
  'usp'
] as const

const SUPPORTED_LANGUAGES = ['en'] as const

// this function loops through the namespaces and supported languages to
// import all the needed language files
const resources = NAME_SPACES.reduce((accumulator: any, nameSpace) => {
  SUPPORTED_LANGUAGES.forEach(language => {
    if (!accumulator[language]) accumulator[language] = {}
    accumulator[language] = {
      ...accumulator[language],
      [nameSpace]: require(`./locales/${language}/${nameSpace}.json`)
    }
  })
  return accumulator
}, {})

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: 'en',
    ns: NAME_SPACES,
    interpolation: { escapeValue: false },
    supportedLngs: SUPPORTED_LANGUAGES,
    resources
  })

export default i18n
