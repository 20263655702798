import { useTranslation } from 'react-i18next'
import { usePipelineBreadcrumbs } from '../../../(shared)/usePipelineBreadcrumbs'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useSetTitleCluster } from 'state/titleCluster'
import { getCompletedAmbiguousMatchesByUser } from 'api/queries'
import useQuery from 'hooks/useQuery'
import {
  Paper,
  Typography,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  TableFooter,
  FormControl,
  FormLabel,
  Stack,
  Button,
  Radio,
  RadioGroup,
  TextField,
  Chip
} from '@mui/material'
import { Table, TablePinnedRow } from 'components/Table'
import formatDateTime from 'utils/formatDateTime'
import { StatusChip } from 'components/StatusChip'
import { CellRightBorder } from 'components/Table/CellRightBorder'
import { useState } from 'react'
import theme from 'styles/theme'

type Location = {
  state?: {
    from: number
  }
}

export default function CompletedAmbiguousMatch() {
  const { matchUpdatedBy, matchUpdatedAt, orgId, pipelineId } = useParams() as {
    matchUpdatedBy: string
    matchUpdatedAt: string
    orgId: string
    pipelineId: string
  }
  const { t } = useTranslation('supplierMatch')

  const navigate = useNavigate()
  const location = useLocation() as Location

  const { from } = location.state || {
    from: 0
  }

  const { data } = useQuery({
    queryKey: [
      'completedAmbiguousMatches',
      pipelineId,
      matchUpdatedAt,
      matchUpdatedBy
    ],
    queryFn: () =>
      getCompletedAmbiguousMatchesByUser({
        page: 0,
        pageSize: 12,
        pipelineId: pipelineId,
        match_updated_at: matchUpdatedAt,
        match_updated_by: matchUpdatedBy
      }),
    enabled: !!matchUpdatedBy
  })

  const breadcrumbs = usePipelineBreadcrumbs(orgId, pipelineId)

  useSetTitleCluster(
    t('completedMatches'),
    breadcrumbs.filter(i => i.label !== 'Review Ambiguous Matches').slice(0, 3)
  )

  const handleBackToHistory = () => {
    navigate(`/opcon/pipelines/${orgId}/${pipelineId}/history`)
  }

  const [page, setPage] = useState(from)
  const currentMatch = data?.completedMatches[page]

  return (
    <Stack spacing={2} sx={{ marginTop: 2 }}>
      <Paper sx={{ p: 3 }}>
        <Stack direction='row' spacing={1} alignItems='center'>
          <Typography>{t('status')}</Typography>
          <StatusChip label='complete' variant='complete' isStartCase />
        </Stack>
        <Stack direction='row' spacing={1} alignItems='center'>
          <Typography>{t('by')}:</Typography>
          <Typography>{currentMatch?.matchUpdatedName}</Typography>
        </Stack>
        <Stack direction='row' spacing={1} alignItems='center'>
          <Typography>{t('lastUpdated')}:</Typography>
          <Typography>
            {currentMatch &&
              formatDateTime(
                currentMatch.metadata.matching_info.match_updated_at!
              )}
          </Typography>
        </Stack>
        {currentMatch && (
          <Table>
            <TableHead>
              <TableRow>
                <CellRightBorder>{t('type')}</CellRightBorder>
                <CellRightBorder>{t('supplierName')}</CellRightBorder>
                <CellRightBorder>{t('supplierAddress')}</CellRightBorder>
                <CellRightBorder>{t('domain')}</CellRightBorder>
                <CellRightBorder>{t('accuracy')}</CellRightBorder>
                <TableCell>{t('selection')}</TableCell>
              </TableRow>
              <TablePinnedRow>
                <CellRightBorder>{t('incoming')}</CellRightBorder>
                <CellRightBorder>
                  {currentMatch.incomingEntity.company_name}
                </CellRightBorder>
                <CellRightBorder>{}</CellRightBorder>
                <CellRightBorder>
                  {currentMatch.incomingEntity.web_domain}
                </CellRightBorder>
                <CellRightBorder>{''}</CellRightBorder>
                <TableCell>{''}</TableCell>
              </TablePinnedRow>
            </TableHead>
            <TableBody>
              {currentMatch.ambiguousCandidates.map(row => {
                return (
                  <TableRow key={row.matched_entity_id}>
                    <CellRightBorder>{t('existing')}</CellRightBorder>
                    <CellRightBorder>{row.supplier_name}</CellRightBorder>
                    <CellRightBorder>{row.addresses}</CellRightBorder>
                    <CellRightBorder>{row.web_domain}</CellRightBorder>
                    <CellRightBorder>
                      <Chip
                        size='medium'
                        label={row.score}
                        sx={{
                          backgroundColor:
                            currentMatch.topScore === row.score
                              ? theme.palette.warning.light
                              : ''
                        }}
                      />
                    </CellRightBorder>
                    <TableCell>
                      <FormControl>
                        <RadioGroup name='candidateSelection'>
                          <Radio
                            value={row.matched_entity_id}
                            checked={row.selectedMatch}
                            disabled
                          />
                        </RadioGroup>
                      </FormControl>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <CellRightBorder>{t('noMatch')}</CellRightBorder>
                <CellRightBorder></CellRightBorder>
                <CellRightBorder />
                <CellRightBorder />
                <CellRightBorder>
                  <Chip size='medium' label={0} />
                </CellRightBorder>
                <TableCell>
                  <FormControl>
                    <RadioGroup name='candidateSelection'>
                      <Radio
                        value={'noMatch'}
                        disabled
                        checked={
                          !currentMatch.metadata.matching_info.candidates
                        }
                      />
                    </RadioGroup>
                  </FormControl>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        )}

        <FormControl sx={{ mt: 3, width: '50%' }}>
          <FormLabel
            id='match-reason-textfield-label'
            sx={{ color: theme.palette.text.primary }}
            htmlFor='matchReasoning'
          >
            {t('matchReasoning')}
          </FormLabel>
          <TextField
            name='matchReasoning'
            id='matchReasoning'
            aria-labelledby='match-reason-textfield-label'
            placeholder={t('explainMatch')}
            value={currentMatch?.metadata.matching_info.notes || ''}
            variant='outlined'
            size='small'
            InputProps={{
              readOnly: true
            }}
          />
        </FormControl>
      </Paper>
      <Stack direction='row' spacing={1} justifyContent='flex-end'>
        <Button variant='text' onClick={handleBackToHistory}>
          {t('backToHistory')}
        </Button>{' '}
        {!!page && (
          <Button variant='outlined' onClick={() => setPage(page => page - 1)}>
            {t('viewPrevious')}
          </Button>
        )}
        {data?.numAmbigMatches && page < data?.numAmbigMatches - 1 && (
          <Button variant='contained' onClick={() => setPage(page => page + 1)}>
            {t('viewNext')}
          </Button>
        )}
      </Stack>
    </Stack>
  )
}
