import {
  BreConfigRulesField,
  BreFormRow,
  DataScenario,
  SupplierAttributes,
  TrustScore
} from 'types'
import { BreScenarioSetting } from './BreScenarioSetting'

const trustScoreToString = (
  value: number,
  hasVeryHighTrustScore: boolean
): TrustScore => {
  if (value >= 0 && value <= 1) {
    if (hasVeryHighTrustScore && value >= BreScenarioSetting.VERY_HIGH) {
      return 'very_high'
    }

    if (value >= BreScenarioSetting.HIGH) {
      return 'high'
    }

    if (value >= BreScenarioSetting.MEDIUM) {
      return 'medium'
    }

    if (value >= BreScenarioSetting.LOW) {
      return 'low'
    }
  }

  return 'none'
}

const fieldsWithoutTrustScores: Map<
  string,
  Array<SupplierAttributes | string>
> = new Map([
  ['supplier', ['supplier_description']],
  ['address', []],
  ['certifications', []]
])

const getConfigs = (
  { onEmptyClientValue, onClientValue }: BreConfigRulesField,
  hasVeryHighTrustScore: boolean
): Record<DataScenario, TrustScore> => ({
  always:
    onEmptyClientValue === onClientValue
      ? trustScoreToString(onClientValue, hasVeryHighTrustScore)
      : 'high',
  unprovided:
    trustScoreToString(onEmptyClientValue, hasVeryHighTrustScore) ?? 'low',
  dissimilar:
    trustScoreToString(onClientValue, hasVeryHighTrustScore) ?? 'high',
  never: 'none'
})

const getSelectedScenario = (
  hasTrustScore: boolean,
  { onEmptyClientValue, onClientValue }: BreConfigRulesField
): DataScenario => {
  if (onEmptyClientValue === onClientValue) {
    return onEmptyClientValue === BreScenarioSetting.NEVER ? 'never' : 'always'
  }

  return 'unprovided'
}

export const composeFormRow = (
  section: string,
  field: string,
  rules: BreConfigRulesField,
  hasVeryHighTrustScore: boolean = false
): BreFormRow => {
  const hasTrustScore =
    !fieldsWithoutTrustScores.get(section)?.includes(field) ?? true
  const selectedScenario = getSelectedScenario(hasTrustScore, rules)
  const configs = getConfigs(rules, hasVeryHighTrustScore)

  return { hasTrustScore, selectedScenario, configs }
}
