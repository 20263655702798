/*
    https://cloud.google.com/identity-platform/docs/sign-in-user-email#access_identity_platform_in_your_javascript
*/
import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'

// TODO: Use env variables
const firebaseConfig = {
  apiKey: process.env.REACT_APP_GIP_API_KEY,
  authDomain: process.env.REACT_APP_GIP_AUTH_DOMAIN
}
const app = initializeApp(firebaseConfig)
const auth = getAuth(app)

export const firebaseApp = app
export const firebaseAuth = auth
