import { Controller } from 'react-hook-form'
import { Switch as MuiSwitch } from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel'

type InputProps = {
  name: string
  control: any
  label: string
  defaultValue?: any
  errorMessage?: string
}

export default function Switch(props: InputProps) {
  return (
    <Controller
      {...props}
      render={({ field: { onChange, value, ...field } }) => (
        <FormControlLabel
          control={
            <MuiSwitch
              {...field}
              id={props.name}
              onChange={onChange}
              checked={value}
            />
          }
          label={props.label}
        />
      )}
    />
  )
}
