import { Card, CardContent, Typography, DialogContent } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { Button } from 'components/Button'
import DateRangeIcon from '@mui/icons-material/DateRangeOutlined'
import { Dialog } from 'components/Dialog'
import { useState } from 'react'
import useNotify from 'hooks/useNotify'
import SelectQuarter from './SelectQuarter'

export type FormData = {
  q1StartDate: Date
  q2StartDate: Date
  q3StartDate: Date
  q4StartDate: Date
}

export const FiscalYear = () => {
  const notify = useNotify()
  const { t } = useTranslation('apps')
  const [openDialog, setOpenDialog] = useState(false)
  const { control, setError, handleSubmit } = useForm<FormData>({
    defaultValues: {
      q1StartDate: new Date(new Date().getFullYear(), 0, 1),
      q2StartDate: new Date(new Date().getFullYear(), 0, 1),
      q3StartDate: new Date(new Date().getFullYear(), 0, 1),
      q4StartDate: new Date(new Date().getFullYear(), 0, 1)
    }
  })

  return (
    <Card variant='outlined'>
      <CardContent>
        <Typography variant='subtitle1' sx={{ fontWeight: 'bold' }}>
          {t('diversity.settings.fiscalYear')}
        </Typography>
        <Typography variant='body1'>
          {t('diversity.settings.selectFiscalYear')}
        </Typography>
        <Button
          onClick={() => setOpenDialog(true)}
          startIcon={<DateRangeIcon />}
          variant='outlined'
          color='inherit'
          size='large'
          sx={{
            mt: 2,
            paddingY: 1,
            paddingX: 2
          }}
        >
          {t('diversity.settings.selectDate')}
        </Button>
        <Dialog
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          title={t('diversity.settings.configureFiscalYear')}
          submitButtonText='Apply'
          onSubmit={handleSubmit(values => {
            const quarters = [
              values.q1StartDate,
              values.q2StartDate,
              values.q3StartDate,
              values.q4StartDate
            ]

            let quartersOverlap = false
            quarters.forEach((quarter, index) => {
              // skip the first input
              if (index === 0) {
                return
              }

              const previousQuarter = quarters[index - 1]
              if (quarter.getTime() <= previousQuarter.getTime()) {
                quartersOverlap = true
                setError(`q${index + 1}StartDate` as any, {
                  message: t('diversity.settings.quartersCantOverlap')
                })
              }
            })

            // check if the last quarter overlaps with the first quarter
            const firstQuarterPlusAYear = new Date(
              quarters[0].getFullYear() + 1,
              quarters[0].getMonth(),
              quarters[0].getDate()
            )
            if (
              values.q4StartDate.getTime() >= firstQuarterPlusAYear.getTime()
            ) {
              quartersOverlap = true
              setError('q4StartDate', {
                message: t('diversity.settings.quartersCantOverlapWithFirst')
              })
            }

            if (!quartersOverlap) {
              setOpenDialog(false)
              notify({
                message: t('diversity.settings.savedSuccessfully'),
                severity: 'success'
              })
            }
          })}
        >
          <DialogContent>
            <SelectQuarter quarter={1} control={control} />
            <SelectQuarter quarter={2} control={control} />
            <SelectQuarter quarter={3} control={control} />
            <SelectQuarter quarter={4} control={control} />
          </DialogContent>
        </Dialog>
      </CardContent>
    </Card>
  )
}
