import { ReactNode } from 'react'
import {
  Dialog as MuiDialog,
  SxProps,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  IconButton,
  ButtonProps
} from '@mui/material'
import { ReportProblem, Close } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

import theme from 'styles/theme'

type DialogProps = {
  cancelButtonText?: string
  children: ReactNode
  describedBy?: string
  isDanger?: boolean
  isSubmitDisabled?: boolean
  isSwapCancelConfirmButtons?: boolean
  onClose?: (...args: any) => void
  onSubmit?: () => void
  open: boolean
  submitButtonText?: string
  sx?: SxProps
  title: string
}

export const Dialog = ({
  cancelButtonText,
  children,
  describedBy,
  isDanger = false,
  isSubmitDisabled = false,
  isSwapCancelConfirmButtons = false,
  onClose,
  onSubmit,
  open,
  submitButtonText,
  sx,
  title
}: DialogProps) => {
  const { t } = useTranslation('components')
  const { t: generalT } = useTranslation('general')

  const buttonProps: ButtonProps[] = []

  if (onSubmit) {
    if (onClose) {
      buttonProps.push({
        children: cancelButtonText || generalT('cancel'),
        onClick: onClose,
        key: 'dialog-cancel-button'
      })
    }

    buttonProps.push({
      children: submitButtonText || generalT('submit'),
      disabled: isSubmitDisabled,
      onClick: onSubmit,
      variant: 'contained',
      color: isDanger ? 'error' : 'primary',
      sx: isDanger ? { color: theme.palette.common.white } : undefined,
      key: 'dialog-submit-button'
    })

    if (isSwapCancelConfirmButtons) {
      buttonProps[0].autoFocus = true
      buttonProps[0].variant = 'contained'

      buttonProps[1].autoFocus = false
      buttonProps[1].variant = undefined

      buttonProps.reverse()
    }
  }

  return (
    <MuiDialog
      open={open}
      onClose={onClose}
      aria-labelledby='dialog-title'
      aria-describedby={describedBy}
      sx={sx}
    >
      <DialogTitle
        id='dialog-title'
        sx={{ display: 'flex', alignItems: 'center', fontWeight: 'bold' }}
      >
        {isDanger && <ReportProblem color='error' sx={{ mr: '4px' }} />}
        {title}
        {onClose && (
          <IconButton
            sx={{ ml: 'auto', color: theme.palette.common.black }}
            onClick={onClose}
            aria-label={t('dialog.close')}
          >
            <Close fontSize='small' />
          </IconButton>
        )}
      </DialogTitle>

      <DialogContent>{children}</DialogContent>

      <DialogActions>
        {buttonProps.map(buttonProp => (
          <Button {...buttonProp} />
        ))}
      </DialogActions>
    </MuiDialog>
  )
}
