import { useSession } from 'state/session'

const USERS = 'users'
const CLIENTS = 'client-org'

interface Permission {
  resources: string[]
  action: string
  scope: string
}

interface PermissionScope {
  userId?: string
  orgId?: string
}

export const PERMISSION_SETS: Record<string, string[]> = {
  READ_USERS: [`${USERS}.read`],
  CREATE_USERS: [`${USERS}.create`],
  UPDATE_USERS: [`${USERS}.update`],
  DELETE_USERS: [`${USERS}.delete`],
  READ_USER_GROUPS: [`${USERS}/groups.read`],
  CREATE_USER_GROUPS: [`${USERS}/groups.create`],
  UPDATE_USER_GROUPS: [`${USERS}/groups.update`],
  DELETE_USER_GROUPS: [`${USERS}/groups.delete`],

  READ_CLIENTS: [`${CLIENTS}/settings.read`],
  CREATE_CLIENTS: [`${CLIENTS}/settings.create`],
  UPDATE_CLIENTS: [`${CLIENTS}/settings.update`],

  PIPELINES_ACCESS: [`${CLIENTS}/pipelines`],
  MANAGE_ALL_PIPELINES: [`${CLIENTS}/pipelines.admin`],
  READ_PIPELINES: [`${CLIENTS}/pipelines.read`],

  UPS_PIPELINES_ACCESS: [`${CLIENTS}/usp-pipelines.admin`]
}

const hasResources = (
  resources: string[],
  requestedResources: string[]
): boolean => {
  if (requestedResources.length < resources.length) {
    return false
  }
  return resources.every(
    (resource, index) => resource === requestedResources[index]
  )
}
const hasAction = (action: string, requestedAction: string): boolean => {
  return (
    action === requestedAction ||
    action === 'admin' ||
    (action === 'update' && requestedAction === 'read') ||
    (action === 'create' && requestedAction === 'read') ||
    (action === 'delete' && requestedAction === 'read')
  )
}

const hasPermission = ({
  userId,
  orgId,
  permission,
  requiredPermission,
  scope
}: {
  userId: string
  orgId: string
  permission: Permission
  requiredPermission: Permission
  scope?: PermissionScope
}): boolean => {
  if (
    !scope ||
    permission.scope === '*' ||
    (permission.scope === 'org' && orgId === scope.orgId) ||
    (permission.scope === 'self' && userId === scope.userId)
  ) {
    return (
      hasResources(permission.resources, requiredPermission.resources) &&
      hasAction(permission.action, requiredPermission.action)
    )
  }
  return false
}

const parsePermission = (data: string): Permission => {
  const [resources, other = ''] = data.split('.')
  const [action, scope] = other.split(':')
  return {
    resources: resources.split('/'),
    action,
    scope
  }
}

/***
 * Determines if the authenticated user has the correct
 * @param requiredPermissions required permission
 */
const usePermissions = (
  requiredPermissions: string[],
  scope?: PermissionScope
): boolean => {
  const session = useSession()
  const userPermissions = session.permissions.map(parsePermission) // From token
  const userId = session.userId
  const orgId = session.orgId

  if (!userPermissions) {
    return false
  }

  // For each required permission, check that it is also included in the user
  // permission from the token (userSessionPermissions) - via string match.
  // Every specified required permission needs to be possessed by the user to
  // return true, otherwise, it will return false (user does not have permission)
  return requiredPermissions.every(rp => {
    const requiredPermission = parsePermission(rp)
    return userPermissions.some(permission =>
      hasPermission({
        userId,
        orgId,
        permission,
        requiredPermission,
        scope
      })
    )
  })
}

export default usePermissions
