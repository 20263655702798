//TODO: this hook should be added to the back-end

import { Pipeline, PipelineStatus } from 'types'
import { useCheckActionRequiredQuery } from 'hooks/useCheckActionRequiredQuery'

type Props = {
  pipelineId: string
  pipeline?: Pipeline
  orgId: string
}

export const useProcessSuccessStatus = ({
  pipelineId,
  pipeline,
  orgId
}: Props) => {
  const { tnvCorrections, ambiguousMatches } = useCheckActionRequiredQuery(
    pipeline,
    pipelineId,
    orgId
  )
  const hasCorrections = !!tnvCorrections?.metadata.total_count
  const hasAmbiguousMatch = !!ambiguousMatches?.metadata.total_count

  let newStatus: PipelineStatus | undefined = undefined
  if (pipeline?.status === 'success') {
    if (hasCorrections) {
      newStatus = 'readyforcorrections'
    } else if (hasAmbiguousMatch) {
      newStatus = 'readyformatching'
    } else if (!hasCorrections && !hasAmbiguousMatch) {
      newStatus = 'complete'
    }
  } else if (
    pipeline?.status === 'readyformatching' &&
    ambiguousMatches &&
    !hasAmbiguousMatch
  ) {
    newStatus = 'complete'
  }
  return newStatus
}
