import { useRef } from 'react'
import { Typography, Box } from '@mui/material'
import {
  bindPopover,
  bindFocus,
  usePopupState
} from 'material-ui-popup-state/hooks'

import { useIsOverflow } from 'hooks/useIsOverflow'
import { DataTablePopover } from './DataTablePopover'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined'

export const ExpandableTextCell = ({
  value,
  status
}: {
  value?: string
  status?: string
}) => {
  const popupState = usePopupState({ variant: 'popover' })
  const overflowingElementRef = useRef<HTMLSpanElement>(null)

  const isOverflowed = useIsOverflow(overflowingElementRef)

  return (
    <>
      <Box
        {...bindFocus(popupState)}
        display={'flex'}
        width='100%'
        justifyContent='space-between'
        tabIndex={isOverflowed ? 0 : -1}
      >
        <Typography noWrap ref={overflowingElementRef}>
          {value}
        </Typography>
        {status === 'notMissing-critical' ? (
          <WarningAmberIcon color={'error'} />
        ) : status === 'notMissing-notCritical' ? (
          <FlagOutlinedIcon color={'info'} />
        ) : null}
      </Box>
      {isOverflowed && (
        <DataTablePopover bindPopover={bindPopover(popupState)}>
          <Box sx={{ m: 1 }}>
            <Typography noWrap>{value}</Typography>
            {status === 'notMissing-critical' ? (
              <WarningAmberIcon color={'error'} />
            ) : status === 'notMissing-notCritical' ? (
              <FlagOutlinedIcon color={'info'} />
            ) : null}
          </Box>
        </DataTablePopover>
      )}
    </>
  )
}
