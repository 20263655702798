import api from '../api'
import {
  ApiCredentials,
  ApiCredentialsListItem,
  PaginationParams,
  PaginationData
} from 'types'

const BASE_URL = '/accounts/api-credentials'

type ApiCredentialsQueryParams = {
  sort_by?: 'created_at' | 'title' | 'clientId' | 'clientName'
  order?: 'asc' | 'desc'
}

export async function getApiCredentialsByPage(
  params?: PaginationParams & ApiCredentialsQueryParams
) {
  const { page = 0, pageSize = 10, ...rest } = params || {}
  const offset = page * pageSize

  const queryStringParams = {
    offset,
    limit: pageSize,
    ...rest
  }

  return api.get<PaginationData<ApiCredentialsListItem>>(
    '/accounts/api-credentials',
    queryStringParams
  )
}

export const getApiCredentialsById = (id: string) => {
  return api.get<ApiCredentials>(`${BASE_URL}/${id}`)
}

export const getApiCredentialsSecretById = (id: string) => {
  return api.get<{ secret: string }>(`${BASE_URL}/${id}/secret`)
}

export const searchApiCredentialsById = async (ids: string[] = []) => {
  ids = ids.filter(Boolean)
  const queryString = !!ids.length ? `?id=${ids.join(',')}` : ''
  const result = await api.get<{
    data: ApiCredentialsListItem[]
  }>(`${BASE_URL}/search${queryString}`)
  return result.data
}
