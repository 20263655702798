import { useDropzone, DropzoneOptions, FileRejection } from 'react-dropzone'
import { ControllerRenderProps } from 'react-hook-form'
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined'
import { Box, FormControl, Container, Typography, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Dispatch, SetStateAction } from 'react'
import theme from 'styles/theme'
import DroppedFileErrors from './DroppedFileErrors'
import DroppedFile from './DroppedFile'
import { FileRejections } from 'types'

type Props = {
  name: string
  uploadServerError?: FileRejections
  onClear?: () => void
  field: ControllerRenderProps<any, string>
  onDrop: (dzFiles: File[], dzErrors: FileRejection[]) => void
  dropzoneErrors: FileRejection[]
  setDropzoneErrors: Dispatch<SetStateAction<FileRejection[]>>
} & Omit<DropzoneOptions, 'onDrop'>

export const DropZoneInternals = ({
  name,
  onClear,
  uploadServerError,
  noClick = true,
  noKeyboard = true,
  accept = { 'text/csv': ['.csv'] },
  field,
  onDrop,
  dropzoneErrors,
  setDropzoneErrors,
  ...rest
}: Props) => {
  const { t } = useTranslation('general')

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick,
    noKeyboard,
    accept,
    ...rest,
    onDrop
  })

  if (dropzoneErrors.length > 0 || !!uploadServerError) {
    return (
      <DroppedFileErrors
        fileName={uploadServerError?.fileName || dropzoneErrors[0]?.file.name}
        dropZoneErrors={dropzoneErrors[0]?.errors}
        uploadServerError={uploadServerError}
        onClose={() => {
          setDropzoneErrors([])
          if (onClear) {
            onClear()
          }
        }}
      />
    )
  }

  if (!!field.value) {
    return (
      <DroppedFile
        fileName={field.value.name}
        onClose={() => {
          field.onChange(null)
          if (onClear) {
            onClear()
          }
        }}
      />
    )
  }

  const acceptFormat = Object.values(accept).concat().join(', ')

  return (
    <FormControl>
      <Box
        sx={{
          padding: '2rem',
          border: '1px dashed',
          borderRadius: '4px',
          borderColor: theme.palette.grey[600],
          textAlign: 'center'
        }}
      >
        <div {...getRootProps()}>
          <input {...getInputProps({ name, id: name })} />
          <CloudUploadOutlinedIcon />
          <Container>
            <Typography
              variant='h6'
              component='label'
              htmlFor={name}
              sx={{ display: 'block' }}
            >
              {t('dragDropToUpload', { format: acceptFormat })}
            </Typography>
            <Button
              variant='text'
              onClick={open}
              size='large'
              sx={{ fontSize: '22px', pt: 0 }}
            >
              {t('clickBrowse')}
            </Button>
            <Typography
              variant='body1'
              component='p'
              color={theme.palette.grey[600]}
            >
              {t('mustBeFileType', { format: acceptFormat })}
            </Typography>
          </Container>
        </div>
      </Box>
    </FormControl>
  )
}
