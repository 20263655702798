import { ChevronRight } from '@mui/icons-material'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Switch,
  SwitchProps,
  Typography
} from '@mui/material'
import { Box } from '@mui/system'
import { startCase } from 'lodash'
import { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import theme from 'styles/theme'
import { Datapack } from 'types'
import { Link } from 'react-router-dom'

type Props = {
  datapack: Datapack
  enableBREButton: boolean
  checked?: boolean
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  defaultIncluded?: boolean
} & SwitchProps

const DatapackDetail = forwardRef(
  (
    {
      datapack,
      enableBREButton,
      handleChange,
      checked,
      defaultIncluded,
      ...rest
    }: Props,
    ref
  ) => {
    const { t } = useTranslation('datapacks')
    const castType = (key: string) => key as unknown as TemplateStringsArray
    return (
      <Card
        sx={{
          mr: 2,
          mb: 2,
          width: 210,
          height: 350,
          maxHeight: 350,
          background: checked ? '#01C0B5' : '',
          color: checked
            ? theme.palette.primary.contrastText
            : theme.palette.common.black
        }}
      >
        <CardContent sx={{ height: '85%' }}>
          {!defaultIncluded ? (
            <Switch
              {...rest}
              id={datapack.tag}
              name={datapack.displayName}
              onChange={handleChange}
              value={datapack.tag}
              checked={checked}
              inputProps={{
                'aria-label': datapack.displayName
              }}
              color='primary'
            />
          ) : (
            <>
              <Chip
                label={t('included').toUpperCase()}
                color='primary'
                sx={{ marginBottom: '4px' }}
              />
              <input
                type='hidden'
                id={datapack.tag}
                value={datapack.tag}
                name={datapack.displayName}
              />
            </>
          )}

          <Box>
            <Typography variant='h6' sx={{ fontSize: 20 }}>
              {datapack.displayName}
            </Typography>
            {datapack.mergedAttributes && (
              <Typography sx={{ fontSize: 11 }}>
                {datapack.mergedAttributes
                  .map(attribute =>
                    t(castType(attribute), startCase(attribute))
                  )
                  .join(', ')}
              </Typography>
            )}
          </Box>
        </CardContent>
        <CardActions>
          <Button
            variant='contained'
            disabled={enableBREButton || !checked}
            endIcon={<ChevronRight />}
            component={Link}
            to={`./bre?datapackId=${datapack.tag}`}
          >
            {t('customizeRules')}
          </Button>
        </CardActions>
      </Card>
    )
  }
)

export default DatapackDetail
