import { useSetTitleCluster } from 'state/titleCluster'
import { useTranslation } from 'react-i18next'
import { Card, CardContent, Typography, Divider, Button } from '@mui/material'
import { FiscalYear } from './(shared)/FiscalYear/FiscalYear'
import useMutation from 'hooks/useMutation'
import { saveQualificationSettings } from 'api/mutations'
import { useForm } from 'react-hook-form'
import qualificationRulesData from '../(shared)/qualificationRulesData.json'
import { SettingsCheckboxSection } from './(shared)/SettingsCheckboxSection'
import { DiversityQualificationSubCategory } from 'types/diversity'
import useQuery from 'hooks/useQuery'
import queries from 'api/queries'
import { Loading } from 'components/Loading'

export type SettingsFormData = {
  fiscalDate: Date | null
  sourceTypes: string[]
  subCategories: string[]
  agencies: string[]
}

const SettingsWithData = () => {
  const { data } = useQuery(queries.diversity.settingsOptions)

  if (!data) {
    return <Loading />
  }

  return (
    <Settings
      // there are 100+ options in the db, so hard coding this for now
      agencies={qualificationRulesData.qualificationAgencies}
      subCategories={data.subcategories}
    />
  )
}

type Props = {
  subCategories: DiversityQualificationSubCategory[]
  agencies: string[]
}

const Settings = (props: Props) => {
  const { t } = useTranslation('apps')
  useSetTitleCluster(t('diversity.settings.pageTitle'), [
    {
      link: '',
      label: t('diversityReporting')
    }
  ])

  const { control, handleSubmit } = useForm<SettingsFormData>({
    defaultValues: {
      agencies: [],
      sourceTypes: [],
      subCategories: props.subCategories
    }
  })

  const saveQualificationSettingsMutation = useMutation({
    mutationFn: saveQualificationSettings,
    successMessage: t('diversity.settings.savedSuccessfully')
  })

  const handleFormSubmit = handleSubmit(formValues => {
    saveQualificationSettingsMutation.mutate(formValues)
  })

  return (
    <form onSubmit={handleFormSubmit}>
      <FiscalYear />

      <Card variant='outlined' sx={{ mt: '15px' }}>
        <CardContent>
          <Typography variant='h2' sx={{ fontWeight: 'bold' }}>
            {t('diversity.settings.qualificationRules')}
          </Typography>
          <Typography variant='body1'>
            {t('diversity.settings.qualificationRulesInfo')}
          </Typography>

          <SettingsCheckboxSection
            control={control}
            fieldName='subCategories'
            checkboxOptions={props.subCategories}
            optionLabelsTranslationKey='qualificationSubCategories'
            title={t('diversity.settings.subCategory')}
            checkAllLabel={t(`diversity.allTypes`)}
            canRankOptions
          />

          <SettingsCheckboxSection
            control={control}
            fieldName='sourceTypes'
            checkboxOptions={qualificationRulesData.qualificationSourceTypes}
            optionLabelsTranslationKey='qualificationSourceTypes'
            title={t('diversity.settings.sourceType')}
            checkAllLabel={t(`diversity.allTypes`)}
          />

          <SettingsCheckboxSection
            control={control}
            fieldName='agencies'
            checkboxOptions={props.agencies}
            optionLabelsTranslationKey='qualificationAgencies'
            title={t('diversity.settings.agency')}
            checkAllLabel={t(`diversity.allTypes`)}
          />
        </CardContent>
      </Card>

      <Divider sx={{ mt: 4 }} />
      <Button variant='contained' sx={{ mt: 1 }} type='submit'>
        {t('Save')}
      </Button>
    </form>
  )
}

export default SettingsWithData
