import { mergeQueryKeys } from '@lukemorales/query-key-factory'
import { ambiguousMatchesQueries } from './ambiguousMatches'
import { diversityQueries } from './diversity'
import { orgsQueries } from './orgs'
import { userGroupQueries } from './userGroups'
import { userQueries } from './users'
import { uspQueries } from './usp'

export * from './ambiguousMatches'
export * from './apiCredentials'
export * from './bre'
export * from './orgs'
export * from './pipelines'
export * from './userGroups'
export * from './users'
export * from './usp'

export default mergeQueryKeys(
  ambiguousMatchesQueries,
  diversityQueries,
  orgsQueries,
  userGroupQueries,
  userQueries,
  uspQueries
)
