import { Box, IconButton, Typography } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import { useTranslation } from 'react-i18next'

import theme from 'styles/theme'

type Props = {
  fileName: string
  onClose: () => void
}

export default function DroppedFile({ fileName, onClose }: Props) {
  const { t } = useTranslation('pipelineRunner')

  return (
    <Box
      sx={{
        border: '1px dashed',
        padding: '1rem',
        borderRadius: '4px',
        borderColor: theme.palette.grey[600],
        backgroundColor: theme.palette.grey[300],
        display: 'flex',
        justifyContent: 'space-between',
        alignContent: 'start'
      }}
    >
      <Box sx={{ textAlign: 'start' }}>
        <Typography>{fileName}</Typography>
      </Box>
      <Box>
        <IconButton sx={{ p: 0 }} aria-label={t('clearFile')} onClick={onClose}>
          <ClearIcon />
        </IconButton>
      </Box>
    </Box>
  )
}
