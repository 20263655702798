import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Org, User } from 'types'
import { DataPairGroup } from 'components/DataPairGroup'
import { formatName } from 'utils/formatName'

export const UserDisplay = ({
  user,
  org
}: {
  user?: User
  org?: Org | null
}) => {
  const { t } = useTranslation('users')

  const dataPairs = [
    {
      label: t('passwordReset.name'),
      info: formatName({ ...user })
    },
    {
      label: t('passwordReset.email'),
      info: user?.email
    },
    {
      label: t('passwordReset.clientName'),
      info: org?.name
    }
  ]

  return (
    <Box>
      <Typography variant='h2'>{t('passwordReset.userProfile')}</Typography>
      <Box
        sx={{
          padding: '1rem 1.5rem'
        }}
      >
        <DataPairGroup dataPairs={dataPairs} />
      </Box>
    </Box>
  )
}
