import { Stack, Checkbox, Typography, FormControlLabel } from '@mui/material'
import { useState, ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from '@tanstack/react-query'

import useMutation from 'hooks/useMutation'
import { updatePipeline } from 'api/mutations'
import useNotify from 'hooks/useNotify'
import { ambiguousMatchesQueries } from 'api/queries'
import useQuery from 'hooks/useQuery'
import { useAnalyticsWithPipeline } from 'hooks/useAnalytics'
import { Pipeline } from 'types'
import { Dialog } from 'components/Dialog'

type Props = {
  pipelineId: string
  open: boolean
  onClose: () => void
  orgId: string
}

export const AcceptAllErrorsDialog = ({
  pipelineId,
  open,
  onClose,
  orgId
}: Props) => {
  const notify = useNotify()

  const { t } = useTranslation('pipelines')

  const queryClient = useQueryClient()

  const analytics = useAnalyticsWithPipeline({ clientId: orgId, pipelineId })

  const [acknowledge, setAcknowledge] = useState(false)

  const { data: ambiguousMatches } = useQuery(
    ambiguousMatchesQueries.ambiguousMatchesMetadata(orgId, pipelineId)
  )

  const updatePipelineStatusMutation = useMutation({
    mutationFn: updatePipeline,
    invalidateQueryKey: ['pipelines', pipelineId],
    onSuccess: async updatedPipelineResponse => {
      onClose()
      notify({ message: t('tnvPage.successfulSubmit'), severity: 'success' })
      queryClient.setQueryData(
        ['pipelines', pipelineId],
        (oldData: Pipeline | undefined) => {
          return { ...oldData, ...updatedPipelineResponse }
        }
      )
    }
  })

  const handleAccept = async () => {
    await updatePipelineStatusMutation.mutate({
      pipeline_id: pipelineId,
      body: {
        status: !!ambiguousMatches?.metadata.total_count
          ? 'readyformatching'
          : 'complete',
        org_id: orgId
      }
    })
    analytics.track('Errors Accepted', {
      eventSource: 'File Corrections Page',
      eventCategory: 'user'
    })
  }

  const handleClose = (
    event: ChangeEvent,
    reason: 'escapeKeyDown' | 'backdropClick'
  ) => {
    if (reason !== 'backdropClick') {
      onClose()
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      title={t('tnvPage.acceptAllErrorsDialogTitle')}
      onSubmit={handleAccept}
      isSubmitDisabled={!acknowledge}
      submitButtonText={t('tnvPage.acceptAllErrors')}
    >
      <Stack spacing={2} sx={{ minWidth: 400, maxWidth: 800 }}>
        <Typography variant='subtitle1' id='accept-all-errors-description'>
          {t('tnvPage.acceptAllErrorsDialogMessage')}
        </Typography>
        <FormControlLabel
          name='acknowledgeErrors'
          labelPlacement='end'
          label={t('tnvPage.acceptAllErrorsDialogAcknowledge')}
          checked={acknowledge}
          control={
            <Checkbox
              onChange={(props: ChangeEvent<HTMLInputElement>) =>
                setAcknowledge(props.currentTarget.checked)
              }
              sx={{
                paddingLeft: 0
              }}
            />
          }
        />
      </Stack>
    </Dialog>
  )
}
