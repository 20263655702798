import {
  DiversityQualificationAgency,
  DiversityQualificationSubCategory
} from 'types/diversity'
import api from '../api'
import { createQueryKeys } from '@lukemorales/query-key-factory'

export const diversityQueries = createQueryKeys('diversity', {
  settingsOptions: {
    queryKey: ['settingsOptions'],
    queryFn: async () => {
      // TODO: these two endpoints need to be combined into one that gets all the options
      const result = await Promise.all([
        api.get<{ subcategories: DiversityQualificationSubCategory[] }>(
          '/data/diversity/subcategories'
        ),
        api.get<{ agencies: DiversityQualificationAgency[] }>(
          '/data/diversity/certifying_agencies'
        )
      ])
      return {
        subcategories: result[0].subcategories,
        agencies: result[1].agencies
      }
    }
  }
})
