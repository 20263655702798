import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormGroup,
  FormHelperText,
  ListItemText,
  MenuItem,
  Paper,
  Typography
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { FieldValues, useController, useForm } from 'react-hook-form'
import InputText from 'components/Form/InputText'
import MultipleSelect from 'components/Form/MultipleSelect'
import { startCase } from 'lodash'
import { useTranslation } from 'react-i18next'
import DatapackDetail from './DatapackDetail'
import { Datapack } from 'types'

type FormData = {
  orgTypes: string[]
  orgName: string
  classicOrgId?: string
  datapacks?: Set<string>
}
type Props = {
  onSubmit: (data: FieldValues) => void
  datapacks: Datapack[]
  defaultValues: {
    orgTypes: string[]
    orgName: string
    classicOrgId?: string
    datapacks?: Set<string>
  }
  disableCustomizeBreButton?: boolean
}

const orgTypes = ['buyer', 'supplier', 'partner']

export function OrgsForm({
  onSubmit,
  defaultValues,
  datapacks,
  disableCustomizeBreButton
}: Props) {
  const navigate = useNavigate()
  const { t } = useTranslation('general')

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue
  } = useForm<FormData>({
    defaultValues: defaultValues
  })

  const { field: orgTypesField } = useController({
    name: 'orgTypes',
    control,
    rules: {
      required: t(`required`),
      deps: ['datapacks']
    }
  })

  const { field: datapacksField } = useController({
    name: 'datapacks',
    control,
    rules: {
      validate: (value?: Set<string>) => {
        if (!value || value.size === 0) {
          return true
        }

        if (!orgTypesField.value.includes('buyer')) {
          return `Datapacks require "Buyer" Client Type`
        }

        return true
      }
    }
  })

  const handleDatapackChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target
    if (checked) {
      datapacksField.value?.add(value)
    } else {
      datapacksField.value?.delete(value)
    }
    setValue('datapacks', datapacksField.value)
  }

  return (
    <Box mt={4}>
      <Paper sx={{ padding: 3, maxWidth: '700px' }}>
        <Box
          component='form'
          sx={{
            '& .MuiInputBase-input:invalid': {
              marginBottom: 1
            }
          }}
        >
          <FormControl fullWidth>
            <InputText
              required
              name='orgName'
              control={control}
              label='Client name'
              errors={errors.orgName}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mt: 3 }}>
            <InputText
              name='classicOrgId'
              control={control}
              label='Classic OrgUnitId'
            />
          </FormControl>
          <FormControl fullWidth size='small' sx={{ mt: 3 }}>
            <MultipleSelect
              label='Client types'
              required
              errors={errors.orgTypes}
              {...orgTypesField}
            >
              {orgTypes.map(element => (
                <MenuItem key={element} value={element}>
                  <Checkbox checked={orgTypesField.value.includes(element)} />
                  <ListItemText primary={startCase(element)} />
                </MenuItem>
              ))}
            </MultipleSelect>
          </FormControl>
          {!!datapacks.length && (
            <Box sx={{ display: 'block', mt: 3 }}>
              <Typography>{t('dataPackLabel')}</Typography>
              <FormGroup row>
                {datapacks
                  .sort((a, b) => a.tag.localeCompare(b.tag))
                  .map(datapack => (
                    <FormControl key={datapack.tag}>
                      <DatapackDetail
                        datapack={datapack}
                        enableBREButton={!!disableCustomizeBreButton}
                        handleChange={handleDatapackChange}
                        checked={
                          datapacksField.value?.has(datapack.tag) ||
                          datapack.tag === 'company_firmographics'
                        }
                        defaultIncluded={
                          datapack.tag === 'company_firmographics'
                        }
                        {...datapacksField}
                      />
                    </FormControl>
                  ))}
              </FormGroup>
              {errors && errors.datapacks?.message && (
                <FormHelperText error>
                  {errors.datapacks.message}
                </FormHelperText>
              )}
            </Box>
          )}
        </Box>

        <Box sx={{ display: 'flex', mt: 3 }}>
          <Box sx={{ mr: 2 }}>
            <Button variant='contained' onClick={handleSubmit(onSubmit)}>
              {t('save')}
            </Button>
            <Button onClick={() => navigate('/opcon/orgs')}>
              {t('cancel')}
            </Button>
          </Box>
        </Box>
      </Paper>
    </Box>
  )
}
