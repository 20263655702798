import { SxProps, Theme, ChipProps } from '@mui/material'
import { startCase } from 'lodash'
import { useTranslation } from 'react-i18next'

import { PipelineStatus } from 'types'
import { Chip } from 'components/Chip'

type Color = ChipProps['color']

export type VariantType = PipelineStatus | 'not_ready'
type StatusMap = {
  [key in Partial<VariantType>]: Color
}

const STATUS_MAP: StatusMap = {
  failed: 'error',
  not_ready: 'secondary',
  pending: 'warning',
  queued: 'warning',
  running: 'warning',
  success: 'error',
  complete: 'success',
  reviewing: 'corrections',
  cancelled: 'error',
  readyforcorrections: 'corrections',
  stopped: 'secondary',
  readyformatching: 'matching',
  readyformapping: 'matching',
  mappingunderreview: 'corrections'
}

export type StatusChipProps = {
  label: string
  variant: VariantType
  sx?: SxProps<Theme>
  isStartCase?: boolean
}

export const StatusChip = ({
  label,
  variant,
  sx,
  isStartCase = false
}: StatusChipProps) => {
  const { t } = useTranslation('pipelines')
  const chipLabel = t(label, isStartCase ? startCase(label) : label)

  return (
    <Chip
      strength='light'
      color={STATUS_MAP[variant]}
      label={chipLabel}
      sx={sx}
    />
  )
}
