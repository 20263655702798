import { Box, Button, Stack, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'

export type BreFormActionBarProps = {
  onFormSubmit: () => void
}
export const BreFormActionBar = ({ onFormSubmit }: BreFormActionBarProps) => {
  const { t } = useTranslation('general')
  const { palette, spacing } = useTheme()
  return (
    <Stack
      direction='row-reverse'
      width='100%'
      padding={spacing(1, 5)}
      bgcolor={palette.grey['100']}
      borderBottom={`2px solid ${palette.common.white}`}
    >
      <Box>
        <Button variant='contained' onClick={onFormSubmit}>
          {t('save')}
        </Button>
      </Box>
    </Stack>
  )
}
