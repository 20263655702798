import { FormControl, FormGroup, Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ControllerRenderProps } from 'react-hook-form'
import { useCallback } from 'react'
import { arrayMidpoint } from 'utils/arrayMidpoint'
import DraggableCheckbox from './DraggableCheckbox'

type Props = {
  checkboxOptions: string[]
  labelsTranslationKey: string
  field: ControllerRenderProps<any, any>
  enableDragMode?: boolean
}

export default function CheckboxGroup(props: Props) {
  const { checkboxOptions } = props
  const { t } = useTranslation('apps')

  // move the checkbox at the given index to the new index
  const moveCheckbox = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragCheckbox = checkboxOptions[dragIndex]
      const newSortedCheckboxes = [...checkboxOptions]
      newSortedCheckboxes.splice(dragIndex, 1)
      newSortedCheckboxes.splice(hoverIndex, 0, dragCheckbox)
      props.field.onChange(newSortedCheckboxes)
    },
    [checkboxOptions, props.field]
  )

  const checkboxOptionsMidpoint = arrayMidpoint(props.checkboxOptions)

  return (
    <FormControl
      sx={{ mt: 1, display: 'block' }}
      component='fieldset'
      variant='standard'
    >
      <FormGroup>
        <Grid container>
          <Grid item xs={6} data-testid='checkbox-column-1'>
            {checkboxOptions
              .slice(0, checkboxOptionsMidpoint)
              .map((option, index) => (
                <DraggableCheckbox
                  key={option}
                  field={props.field}
                  option={option}
                  index={index}
                  onDrag={moveCheckbox}
                  label={t(`${props.labelsTranslationKey}.${option}`)}
                  enableDragMode={props.enableDragMode}
                />
              ))}
          </Grid>
          <Grid item xs={6} data-testid='checkbox-column-2'>
            {checkboxOptions
              .slice(checkboxOptionsMidpoint)
              .map((option, index) => (
                <DraggableCheckbox
                  key={option}
                  field={props.field}
                  option={option}
                  index={index + checkboxOptionsMidpoint}
                  onDrag={moveCheckbox}
                  label={t(`${props.labelsTranslationKey}.${option}`)}
                  enableDragMode={props.enableDragMode}
                />
              ))}
          </Grid>
        </Grid>
      </FormGroup>
    </FormControl>
  )
}
