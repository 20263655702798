import { Tab, Tabs, Button } from '@mui/material'
import { Outlet, useLocation, Link as RouterLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import usePermissions from 'hooks/usePermissions'
import { PERMISSION_SETS } from 'hooks/usePermissions'
import useIsInDevelopment from 'hooks/useIsInDevelopment'

export default function PipelinesNav() {
  const { pathname } = useLocation()
  const { t } = useTranslation('pipelines')

  const isInDev = useIsInDevelopment()

  const hasManageAllPipelinesPermissions = usePermissions(
    PERMISSION_SETS.MANAGE_ALL_PIPELINES
  )

  const hasUspPipelinesPermission = usePermissions(
    PERMISSION_SETS.UPS_PIPELINES_ACCESS
  )

  const BASE_PATH = '/opcon/pipelines/list'

  let tabValue = 0
  if (pathname.includes('spend')) {
    tabValue = 1
  } else if (pathname.includes('usp')) {
    tabValue = 2
  }

  if (isInDev) {
    return (
      <>
        <Tabs
          aria-label='pipelines navigation'
          value={tabValue}
          sx={{
            borderBottom: '1px solid #B9B9B9',
            marginTop: 1
          }}
        >
          <Tab
            component={RouterLink}
            to={`${BASE_PATH}/vendor`}
            label={t('vendorMaster')}
            aria-label='Vendor Master'
          />
          <Tab
            component={RouterLink}
            to={`${BASE_PATH}/spend`}
            label={t('spend')}
            aria-label='Spend'
          />
          {hasUspPipelinesPermission && (
            <Tab
              component={RouterLink}
              to={`${BASE_PATH}/usp`}
              label={t('usp')}
              aria-label='USP'
            />
          )}
          {hasManageAllPipelinesPermissions && (
            <Tab
              disableFocusRipple
              sx={{ ml: 'auto', p: 0 }}
              label={
                <Button
                  variant='contained'
                  component={RouterLink}
                  to={'/opcon/pipelines/runner'}
                >
                  {t('startNewPipeline')}
                </Button>
              }
            />
          )}
        </Tabs>

        <Outlet />
      </>
    )
  }

  return <Outlet />
}
