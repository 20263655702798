import { GridColDef } from '@mui/x-data-grid'
import { startCase } from 'lodash'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import clsx from 'clsx'

import { Chip } from 'components/Chip'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined'
import {
  ExpandableTextCell,
  MissingCell,
  ExpandableContentCell
} from 'components/DataTable'
import { Content } from 'components/DataTable/ExpandableContentCell'

const tnvColumns = [
  'internal_supplier_id_source',
  'internal_supplier_id',

  'company_name',

  'complete_address',
  'street_address',
  'city',
  'state',
  'country',
  'postal',
  'taxpayer_id',
  'web_domain',
  'contact_email',
  'diversity_status',
  'preferred_supplier_status',
  'naics',
  'duns_id',
  'lei',
  'head_count'
] as const

const spendMappingColumns = [
  'internal_supplier_id',
  'company_name',
  'spend_date',
  'spend_cadence',
  'amount',
  'spend_country',
  'domain',
  'business_unit',
  'currency',
  'spend_category1',
  'spend_category2',
  'spend_category_name',
  'legislative_supplier_name'
] as const

const MISSING = ['TEAL-NOTPROVIDED', 'UNKNOWN']
const missingCritical = 'missing-critical'
const notMissingCritical = 'notMissing-critical'
const missingNotCritical = 'missing-notCritical'
const notMissingNotCritical = 'notMissing-notCritical'

const cellStatus = (value: string, field: string, row: any) => {
  const highSeverityErrors = row.errors
    .filter((d: any) => d.severity === 'high')
    .map((d: any) =>
      d.error_name === 'address' ? 'complete_address' : d.error_name
    )

  const lowSeverityErrors = row.errors
    .filter((d: any) => d.severity !== 'high')
    .map((d: any) => d.error_name)

  const missing =
    value === undefined ||
    value === null ||
    value === 'null' ||
    value === '' ||
    MISSING.includes(value)
      ? 'missing'
      : 'notMissing'

  if (missing === 'missing' && highSeverityErrors.includes(field)) {
    return missingCritical
  } else if (missing === 'notMissing' && highSeverityErrors.includes(field)) {
    return notMissingCritical
  } else if (missing === 'notMissing' && lowSeverityErrors.includes(field)) {
    return notMissingNotCritical
  } else if (missing === 'missing' && lowSeverityErrors.includes(field)) {
    return missingNotCritical
  } else {
    return value
  }
}

const Cell = ({
  value,
  field,
  row
}: {
  value: string
  field: string
  row: any
}) => {
  const { t } = useTranslation('pipelines')
  const status = cellStatus(value, field, row)

  return status === missingCritical || status === missingNotCritical ? (
    <MissingCell value={t('missing')} status={status} />
  ) : status === 'unavailable' ? (
    <>{t('unavailable')}</>
  ) : (
    <ExpandableTextCell value={value} status={status} />
  )
}

const ErrorsCell = ({ errorsInFragment }: { errorsInFragment: any }) => {
  const content: Content = errorsInFragment
    .map(
      ({
        error_name,
        severity,
        error_reason
      }: {
        error_name?: string
        severity: string
        error_reason?: string
      }) => ({
        id: error_name,
        element: (
          <Chip
            strength='light'
            color={severity === 'high' ? 'error' : 'warning'}
            label={startCase(error_reason)}
            icon={
              severity === 'high' ? (
                <WarningAmberIcon color={'error'} />
              ) : (
                <FlagOutlinedIcon color={'info'} />
              )
            }
          />
        )
      })
    )
    .sort((a: any, b: any) => (a.severity > b.severity ? 1 : -1))

  return <ExpandableContentCell content={content} height={43} />
}

const getErrorsColumn = (t: TFunction<'pipelines'>): GridColDef => ({
  field: 'errors',
  headerName: t('errors'),
  width: 250,
  sortable: false,
  renderCell: params => <ErrorsCell errorsInFragment={params.value} />
})

const getColumn = (
  t: TFunction<'pipelines'>,
  column: (typeof tnvColumns)[number] | (typeof spendMappingColumns)[number],
  page: Page
): GridColDef => {
  const prefix = page === 'tnv' ? 'tnvPage' : 'spendMappingPage'

  return {
    field: column,
    width: 180,
    headerName: t(`${prefix}.${column}`),
    sortable: false,
    renderCell: params => (
      <Cell value={params.value} field={params.field} row={params.row} />
    ),
    cellClassName: params =>
      clsx({
        'tnv-missing-critical':
          cellStatus(params.value, params.field, params.row) ===
          missingCritical,
        'tnv-notMissing-critical':
          cellStatus(params.value, params.field, params.row) ===
          notMissingCritical,
        'tnv-notCritical':
          cellStatus(params.value, params.field, params.row) ===
          notMissingNotCritical,
        'tnv-missingnotCritical':
          cellStatus(params.value, params.field, params.row) ===
          missingNotCritical
      })
  }
}

type Page = 'tnv' | 'spend'

export const getPipelineErrorColumns = (
  t: TFunction<'pipelines'>,
  page: Page = 'tnv'
) => {
  const columns = page === 'tnv' ? tnvColumns : spendMappingColumns
  const defs: GridColDef[] = [
    { field: 'entity_id' },
    {
      field: 'index',
      headerName: '',
      width: 15,
      align: 'center',
      sortable: false
    },
    getErrorsColumn(t),
    ...columns.flatMap(column => getColumn(t, column, page))
  ]

  return defs
}
