import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Control } from 'react-hook-form'
import { FormData } from './FiscalYear'
import InputDate from 'components/Form/InputDate'

type Props = {
  control: Control<FormData>
  quarter: 1 | 2 | 3 | 4
}

export default function SelectQuarter(props: Props) {
  const { t } = useTranslation('apps')
  return (
    <Box
      sx={{
        display: 'block',
        mt: 3,
        width: 300
      }}
    >
      <InputDate
        name={`q${props.quarter}StartDate`}
        label={t('diversity.settings.startDate', {
          quarter: `Q${props.quarter}`
        })}
        control={props.control}
        sx={{
          width: '100%'
        }}
      />
    </Box>
  )
}
