import { useState } from 'react'
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { DataGrid } from '@mui/x-data-grid'

import { PaginationParams, PaginationData } from 'types'
import { getFileCorrectionErrors } from 'api/queries'
import { getPipelineErrorColumns } from './getPipelineErrorColumns'
import useQuery from 'hooks/useQuery'
import theme from 'styles/theme'

const PAGE_SIZE = 10

type Props = { pipelineId: string; orgId: string }

export const ErrorsDataGrid = (props: Props) => {
  const { pipelineId, orgId } = props
  const { t } = useTranslation('pipelines')

  const colDefs = getPipelineErrorColumns(t, 'tnv')

  const [totalCount, setTotalCount] = useState(0)
  const [queryOptions, setQueryOptions] = useState<PaginationParams>({
    page: 0,
    pageSize: PAGE_SIZE
  })

  const { data, isLoading } = useQuery({
    queryKey: ['metadata', 'errors', 'tnv', orgId, pipelineId, queryOptions],
    queryFn: () =>
      getFileCorrectionErrors({ pipelineId, orgId, ...queryOptions }),
    onSuccess(data) {
      // preserve total count for next fetch
      setTotalCount(getTotalCount(data))
    }
  })

  // handle the case where using query cache w/o calling onSuccess
  const rowCount = totalCount || getTotalCount(data)

  const handlePageChange = (page: number) => {
    setQueryOptions(options =>
      Object.assign({}, options, { page, pageSize: PAGE_SIZE })
    )
  }

  return (
    <Box sx={{ marginTop: 3, height: 531 }}>
      <DataGrid
        paginationMode='server'
        rowHeight={43}
        headerHeight={43}
        pageSize={PAGE_SIZE}
        rowsPerPageOptions={[PAGE_SIZE]}
        getRowId={row => row[colDefs[0].field]}
        columns={colDefs.slice(1)}
        loading={isLoading}
        rows={data?.data || []}
        rowCount={rowCount}
        onPageChange={handlePageChange}
        showCellRightBorder
        sx={{
          '& .MuiDataGrid-columnHeader:first-of-type': {
            backgroundColor: theme.palette.grey[100],
            borderRight: '1px solid ' + theme.palette.grey[300]
          },
          '& .MuiDataGrid-cell:first-of-type': {
            backgroundColor: theme.palette.grey[100]
          },
          '& .tnv-missing-critical': {
            border: '2px solid ' + theme.palette.error.main
          },
          '& .tnv-notMissing-critical': {
            border: '2px solid ' + theme.palette.error.main
          },
          '& .tnv-notCritical': {
            border: '2px solid ' + theme.palette.warning.light
          },
          '& .tnv-missingnotCritical': {
            border: '2px solid ' + theme.palette.warning.light
          }
        }}
        disableColumnMenu
      />
    </Box>
  )
}

function getTotalCount(data: any[] | PaginationData<any> | undefined) {
  return Array.isArray(data) ? data.length : data?.metadata.total_count || 0
}
