import { AlertProps, SnackbarProps } from '@mui/material'
import { atom } from 'recoil'

export type Notification = {
  key: number
  message: SnackbarProps['message']
  severity?: AlertProps['severity']
}
export const notificationsState = atom<Notification | void>({
  key: 'notifications',
  default: undefined
})
