import { Control, Controller } from 'react-hook-form'
import { union } from 'lodash'
import { MenuItem, Typography, Checkbox, Box } from '@mui/material'
import { GroupedPermissions } from 'types'

type Props = {
  control: Control<any>
  groupedPermissions: GroupedPermissions[]
}

export const Permissions = ({ control, groupedPermissions }: Props) => {
  const isChecked = (permissions: string[], keys: string[]): boolean => {
    return union(permissions, keys).length === union(permissions).length
  }
  const recomposePermission = (
    oldPermissions: string[],
    changedName: string,
    selected: boolean
  ): string[] => {
    const newPermissions: string[] = []
    groupedPermissions.forEach(({ permissions }) => {
      permissions.forEach(({ name, keys }) => {
        if (name === changedName) {
          if (selected) {
            newPermissions.push(...keys)
          }
        } else if (isChecked(oldPermissions, keys)) {
          newPermissions.push(...keys)
        }
      })
    })
    return newPermissions
  }

  return (
    <Controller
      name='permissions'
      control={control}
      render={({ field: { onChange, value = '' } }) => (
        <Box>
          {groupedPermissions.map(({ name, permissions }) => (
            <Box key={name}>
              <Typography variant='h6' style={{ marginTop: 20 }}>
                {name}
              </Typography>
              <Box>
                {permissions.map(({ name, keys }) => (
                  <MenuItem key={name} value={keys}>
                    <Checkbox
                      checked={isChecked(value, keys)}
                      onChange={e =>
                        onChange(
                          recomposePermission(value, name, e.target.checked)
                        )
                      }
                    />
                    <Typography>{name}</Typography>
                  </MenuItem>
                ))}
              </Box>
            </Box>
          ))}
        </Box>
      )}
    />
  )
}
