import { useState } from 'react'
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'

import useMutation from 'hooks/useMutation'
import { updatePipeline, uploadFile } from 'api/mutations'
import { FileUploadErrors, FileRejections } from 'types'
import { useAnalyticsWithPipeline } from 'hooks/useAnalytics'
import { DropZone } from 'components/DropZone'
import { Dialog } from 'components/Dialog'
import { Banner } from 'components/Banner'
import { InfoLabel } from 'components/Info'

type DialogFormData = {
  droppedFile: File | null
  pipelineType: 'client_data_vendor' | 'client_data_vendor_extended'
}

type Props = {
  fileName: string
  onClose: () => void
  open: boolean
  orgId: string
  pipelineId: string
}

export const CorrectionsUploadDialog = ({
  fileName,
  onClose,
  open,
  orgId,
  pipelineId
}: Props) => {
  const { t } = useTranslation('pipelines')
  const analytics = useAnalyticsWithPipeline({ clientId: orgId, pipelineId })

  const [fileRejections, setFileRejections] = useState<FileRejections>()

  const { handleSubmit, control, watch, reset, register } =
    useForm<DialogFormData>({
      defaultValues: { pipelineType: 'client_data_vendor' }
    })

  const watchDroppedFile = watch('droppedFile')

  const uploadFileMutation = useMutation({ mutationFn: uploadFile })

  const updatePipelineMutation = useMutation({
    mutationFn: updatePipeline,
    invalidateQueryKey: ['pipelines', pipelineId]
  })

  const handleClearFile = () => {
    reset(formValues => ({
      ...formValues,
      droppedFile: null
    }))
    setFileRejections(undefined)
  }

  const onSubmit = async (data: DialogFormData) => {
    const { pipelineType, droppedFile } = data

    if (!droppedFile) return

    const formData = new FormData()

    formData.append('file', droppedFile)
    formData.append('org_id', orgId)
    formData.append('pipeline_type', pipelineType)

    const { file_storage_id, errors, original_name }: FileUploadErrors =
      await uploadFileMutation.mutateAsync(formData)

    if (!!errors) {
      setFileRejections({
        fileName: original_name,
        validationErrors: errors
      })
      reset(formValues => ({
        ...formValues,
        droppedFile: null
      }))
    } else {
      updatePipelineMutation.mutate({
        pipeline_id: pipelineId,
        body: {
          org_id: orgId,
          pipeline_type: pipelineType,
          source: { file_storage_id: file_storage_id },
          status: 'pending'
        }
      })

      analytics.track('Corrections File Uploaded', {
        eventSource: 'File Corrections Page',
        eventCategory: 'user',
        fileName: original_name
      })

      onClose()
    }
  }

  return (
    <Dialog
      open={open}
      title={t('correctionsDialog.title')}
      onClose={onClose}
      isSubmitDisabled={!watchDroppedFile}
      submitButtonText={t('tnvPage.uploadCorrections')}
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '700px'
          }
        }
      }}
    >
      <Stack spacing={2} sx={{ minWidth: 400, maxWidth: 700 }}>
        <Banner
          variant='info'
          title={t('correctionsDialog.bannerTitle', { fileName })}
          description={t('correctionsDialog.bannerDescription')}
        />

        <DropZone
          name='droppedFile'
          control={control}
          onClear={handleClearFile}
          required
          uploadServerError={fileRejections}
        />

        <FormControl>
          <FormLabel id='corrections-type-group-label'>
            <Stack direction='row'>
              <InfoLabel
                info={t('correctionsDialog.venderMasterTooltip')}
                label={t('correctionsDialog.fileType')}
              />
            </Stack>
          </FormLabel>

          <RadioGroup
            aria-labelledby='corrections-type-group-label'
            defaultValue='client_data_vendor'
          >
            <FormControlLabel
              value='client_data_vendor'
              control={<Radio />}
              label={t('correctionsDialog.vendorMaster')}
              {...register('pipelineType')}
            />
            <FormControlLabel
              value='client_data_vendor_extended'
              control={<Radio />}
              label={t('correctionsDialog.extendedVendorMaster')}
              {...register('pipelineType')}
            />
          </RadioGroup>
        </FormControl>
      </Stack>
    </Dialog>
  )
}
