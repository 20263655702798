import { ReactNode, Key } from 'react'
import {
  bindPopover,
  usePopupState,
  bindFocus
} from 'material-ui-popup-state/hooks'
import { Box, List, ListItem } from '@mui/material'

import { DataTablePopover } from './DataTablePopover'

export type Content = {
  id: Key
  element: ReactNode
}[]

export const ExpandableContentCell = ({
  content,
  height
}: {
  content: Content
  height: number
}) => {
  const popupState = usePopupState({ variant: 'popover' })

  return (
    <>
      <span {...bindFocus(popupState)} tabIndex={0}>
        <List sx={{ display: 'flex', px: 0.5, py: 0 }}>
          {content.map(({ id, element }, index) => (
            <ListItem key={`${id}-${index}`} sx={{ pl: 0, pr: 1 }}>
              {element}
            </ListItem>
          ))}
        </List>
      </span>
      <DataTablePopover bindPopover={bindPopover(popupState)}>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            px: 0.5,
            py: 0,
            minHeight: height - 4 + 'px'
          }}
        >
          {content.map(({ id, element }) => (
            <Box key={id} sx={{ pr: 1, py: 0.5 }}>
              {element}
            </Box>
          ))}
        </Box>
      </DataTablePopover>
    </>
  )
}
