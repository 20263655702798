import { Typography, TextField, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import theme from 'styles/theme'
import { Dialog } from 'components/Dialog'

export function DeleteConfirmationDialog({
  onClose,
  onConfirm,
  open
}: {
  onClose: () => void
  onConfirm: () => void
  open: boolean
}) {
  const { t } = useTranslation('apiCredentials')

  const [text, setText] = useState('')

  const onConfirmHandler = () => {
    if (text === 'yes') {
      onConfirm()
    }
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      onSubmit={onConfirmHandler}
      title={t('deleteCredentialsTitle')}
      submitButtonText={t('deleteForever')}
      isSubmitDisabled={text !== 'yes'}
      isDanger
    >
      <Stack spacing={1}>
        <Typography variant='body1' sx={{ mt: 2 }}>
          {t('deleteCredentialsContent1')}
        </Typography>
        <Typography variant='body1' sx={{ mt: 2 }}>
          {t('deleteCredentialsContent2')}
        </Typography>
      </Stack>

      <Stack spacing={1} alignItems={'center'}>
        <Stack flexDirection={'row'}>
          <Typography variant='body1' sx={{ mt: 2 }}>
            {t('deleteInstruction1')}
          </Typography>
          <Typography
            variant='body1'
            sx={{ mt: 2, color: theme.palette.primary.light }}
          >
            &nbsp;{t('deleteInstruction2')}&nbsp;
          </Typography>
          <Typography variant='body1' sx={{ mt: 2 }}>
            {t('deleteInstruction3')}
          </Typography>
        </Stack>
        <TextField
          name='confirmation'
          value={text}
          onChange={e => setText(e.target.value)}
          sx={{ width: '100%' }}
          size='small'
        />
      </Stack>
    </Dialog>
  )
}
