import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography
} from '@mui/material'
import { useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

import { InitialPasswordPolicies, PasswordPolicy } from './PasswordPolicies'
import { PolicyComplianceStatus } from './PolicyComplianceStatus'

export type PasswordResetFormData = {
  password: string
  confirmPassword: string
}

export const PasswordResetForm = (props: {
  onSubmit: (data: PasswordResetFormData) => void
}) => {
  const { t } = useTranslation('users')

  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  const { control, handleSubmit, watch } = useForm({
    defaultValues: {
      password: '',
      confirmPassword: ''
    }
  })
  const [watchedPassword, watchedConfirmed] = watch([
    'password',
    'confirmPassword'
  ])

  const handleShowPasswordClick = (field: String) => {
    if (field === 'password') {
      setShowPassword(!showPassword)
    }
    if (field === 'confirmPassword') {
      setShowConfirmPassword(!showConfirmPassword)
    }
  }

  const { updatedPasswordPolicyCompliance, isPasswordCompliant } =
    useMemo(() => {
      let isPasswordCompliant = true

      const updatedPasswordPolicyCompliance = InitialPasswordPolicies.map(
        policy => {
          const policyCompliance = policy.validate(
            watchedPassword,
            watchedConfirmed
          )

          isPasswordCompliant =
            policyCompliance === false ? policyCompliance : isPasswordCompliant

          return {
            ...policy,
            compliant: policyCompliance
          }
        }
      )

      return { updatedPasswordPolicyCompliance, isPasswordCompliant }
    }, [watchedPassword, watchedConfirmed])

  return (
    <Grid container>
      <Grid item sm={12} md={4}>
        <Typography variant='h2' sx={{ marginBottom: '1.5rem' }}>
          {t('passwordReset.setANewPassword')}
        </Typography>
        <form onSubmit={handleSubmit(props.onSubmit)}>
          <Box
            sx={{
              marginRight: '1rem',
              display: 'flex',
              flexDirection: 'column',
              rowGap: '1.25rem;'
            }}
          >
            <Controller
              name='password'
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  id='password'
                  label='New Password'
                  variant='outlined'
                  type={showPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position={'end'}>
                        <IconButton
                          onMouseDown={() =>
                            handleShowPasswordClick('password')
                          }
                          onClick={() => handleShowPasswordClick('password')}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              )}
            />

            <Controller
              name='confirmPassword'
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  id='confirm-password'
                  label='Confirm Password'
                  variant='outlined'
                  type={showConfirmPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position={'end'}>
                        <IconButton
                          onMouseDown={() =>
                            handleShowPasswordClick('confirmPassword')
                          }
                          onClick={() =>
                            handleShowPasswordClick('confirmPassword')
                          }
                        >
                          {showConfirmPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              )}
            />

            <Box sx={{ marginTop: '1rem' }}>
              <Button
                variant='contained'
                type='submit'
                disabled={!isPasswordCompliant}
              >
                {t('resetPassword')}
              </Button>
            </Box>
          </Box>
        </form>
      </Grid>
      <Grid item sm={12} md={8}>
        <PolicyComplianceStatus
          passwordPoliciesComplianceState={
            updatedPasswordPolicyCompliance as Array<PasswordPolicy>
          }
        />
      </Grid>
    </Grid>
  )
}
