import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import queries, { getPipelinesByPage, getAllOrgs } from 'api/queries'
import DataTableServerSide from 'components/DataTable/DataTableServerSide'
import { getPipelinesColumns } from './(shared)/getPipelinesColumns'
import { GridRowParams, ValueOptions } from '@mui/x-data-grid'
import { useNavigate } from 'react-router-dom'
import useQuery from 'hooks/useQuery'
import usePermissions from 'hooks/usePermissions'
import { PERMISSION_SETS } from 'hooks/usePermissions'
import { formatName } from 'utils/formatName'
import useIsInDevelopment from 'hooks/useIsInDevelopment'
import useAnalytics from 'hooks/useAnalytics'
import { PipelineType } from 'types'

const STATUS_FILTER = [
  'pending',
  'queued',
  'failed',
  'stopped',
  'running',
  'reviewing',
  'cancelled',
  'success',
  'complete',
  'readyforcorrections',
  'readyformatching'
]

export default function Pipelines({
  type = 'client_data_vendor_extended'
}: {
  type: PipelineType
}) {
  const navigate = useNavigate()
  const { t } = useTranslation('pipelines')
  const analytics = useAnalytics()

  const isInDev = useIsInDevelopment()

  const hasManageAllPipelinesPermissions = usePermissions(
    PERMISSION_SETS.MANAGE_ALL_PIPELINES
  )

  const { data: orgs = [] } = useQuery({
    queryKey: ['orgs'],
    queryFn: getAllOrgs
  })

  const { data: users = [] } = useQuery(queries.users.all)

  const filterValueOptions: { [key: string]: ValueOptions[] } = useMemo(
    () => ({
      orgName: orgs
        .map(org => ({
          value: org.id,
          label: org.name
        }))
        .sort((org1, org2) =>
          org1.label.toLowerCase() > org2.label.toLowerCase() ? 1 : -1
        ),
      createdBy: users
        .map(user => ({
          value: user.id,
          label: formatName(user)
        }))
        .sort((user1, user2) =>
          user1.label.toLowerCase() > user2.label.toLowerCase() ? 1 : -1
        ),
      status: STATUS_FILTER.map(sf => ({
        value: sf,
        label: t(sf)
      })).sort((sf1, sf2) =>
        sf1.label.toLowerCase() > sf2.label.toLowerCase() ? 1 : -1
      )
    }),
    [orgs, users, t]
  )

  const columns = getPipelinesColumns(type, t, filterValueOptions)

  const handleRowClick = (params: GridRowParams<any>) => {
    analytics.track('Pipelines Table Row Clicked', {
      eventSource: 'Pipelines Page',
      eventCategory: 'user',
      clientName: params.row.orgName,
      pipelineId: params.row.pipeline_id,
      pipelineType: params.row.pipeline_type,
      pipelineName: params.row.pipeline_name,
      createdBy: params.row.createdBy
    })

    navigate(`/opcon/pipelines/${params.row.org_id}/${params.id}/overview`)
  }

  return (
    <DataTableServerSide
      title={t('title')}
      columns={columns}
      queryKey={type}
      query={getPipelinesByPage}
      onRowClick={handleRowClick}
      createPageLink={
        !isInDev && hasManageAllPipelinesPermissions
          ? '/opcon/pipelines/runner'
          : undefined
      }
      createButtonTitle={
        !isInDev && hasManageAllPipelinesPermissions
          ? t('startNewPipeline')
          : undefined
      }
      scope='all'
      additionalQueryOptions={{
        pipeline_type: type
      }}
      cacheTime={0}
    />
  )
}
