import { useCallback } from 'react'
import { updateOrg } from 'api/mutations'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { FieldValues } from 'react-hook-form'
import { Org } from 'types'
import { OrgsForm } from '../(shared)/OrgForms'
import useQuery from 'hooks/useQuery'
import { getAllDatapacks } from 'api/queries'
import { Loading } from 'components/Loading'
import { useSetTitleCluster } from 'state/titleCluster'
import useMutation from 'hooks/useMutation'
import queries from 'api/queries'

const OrgsEdit = () => {
  const { orgId } = useParams() as { orgId: string }
  const { t } = useTranslation('orgs')
  const navigate = useNavigate()

  const { data: org } = useQuery({
    ...queries.orgs.byId(orgId),
    enabled: !!orgId
  })

  const { data: datapacks = [] } = useQuery({
    queryKey: ['datapacks'],
    queryFn: getAllDatapacks
  })

  const editClientTitle = !org
    ? ''
    : org?.name
    ? t('editClient.withClientName', {
        clientName: org.name
      })
    : t('editClient.withNoName')

  useSetTitleCluster(editClientTitle, [
    {
      link: '/opcon/orgs',
      label: t('title')
    }
  ])

  const saveUpdatedRow = useMutation({
    mutationFn: updateOrg,
    invalidateQueryKey: ['orgs'],
    successMessage: t('editSuccess'),
    onSuccess: () => navigate('/opcon/orgs')
  })

  const onSubmit = useCallback(
    (data: FieldValues) => {
      const updatedOrg: Partial<Org> = {
        id: orgId,
        name: data.orgName,
        orgTypes: data.orgTypes,
        classicOrgId: data.classicOrgId === '' ? null : data.classicOrgId,
        datapacks: Array.from(data.datapacks)
      }
      saveUpdatedRow.mutate(updatedOrg)
    },
    [orgId, saveUpdatedRow]
  )

  if (!org) return <Loading />

  return (
    <OrgsForm
      datapacks={datapacks.sort((a, b) => a.tag.localeCompare(b.tag))}
      onSubmit={onSubmit}
      defaultValues={{
        orgTypes: org.orgTypes || [],
        orgName: org.name || '',
        classicOrgId: org.classicOrgId || '',
        datapacks: new Set(org.datapacks?.map(datapack => datapack.tag) || [])
      }}
    />
  )
}

export default OrgsEdit
