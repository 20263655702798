import { DataScenario, TrustScore } from 'types'
import { Box, Grow, useTheme } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import { trustScoreColors } from './TrustScoreColors'

export type PopoverProps = {
  field: string
  selectedScenario: DataScenario
  trustScore: TrustScore
  isHover: boolean
  buttonSelected: boolean
  shouldOffset: boolean
}

export const BreScenarioDescriptionPopover = ({
  field,
  selectedScenario,
  trustScore,
  isHover,
  buttonSelected,
  shouldOffset
}: PopoverProps) => {
  const { palette } = useTheme()
  const { t } = useTranslation()

  const getPopoverOffset = (last?: boolean): string => {
    return last
      ? 'translate(-47%, -80%) !important'
      : 'translate(-27%, -80%) !important'
  }

  const getPopoverArrowIndicatorOffset = (last?: boolean): string => {
    return last
      ? 'translate(180%, -55%) rotate(29deg) skew(-35deg) '
      : 'translate(-50%, -55%) rotate(29deg) skew(-35deg) '
  }

  return (
    <Grow in={isHover && buttonSelected}>
      <Box
        position='absolute'
        display='block'
        top='-100%'
        left='0'
        width='15rem'
        sx={{
          transform: getPopoverOffset(shouldOffset)
        }}
        bgcolor='#FFF'
        boxShadow='0 0 18px 0px rgba(0,0,0,0.25)'
        padding='0.6rem'
        fontSize='0.75rem'
        borderRadius='8px'
      >
        <Trans
          ns='bre'
          i18nKey={`popoverText.${selectedScenario}`}
          values={{
            field: t(field, { ns: 'datapacks' }),
            trustScoreText: t(`popoverTrustScoreText.${trustScore}`, {
              ns: 'bre'
            })
          }}
          components={{
            fieldName: (
              <Box
                display='inline-block'
                bgcolor={palette['grey']['100']}
                padding='0 3px'
              />
            ),
            scenario: <em />,
            action: (
              <Box display='inline-block' color={palette['primary']['main']} />
            ),
            bold: <strong />,
            trustScore: (
              <Box
                display='inline-block'
                color={trustScoreColors.get(trustScore)}
                textTransform='uppercase'
              />
            )
          }}
        />
        <Box
          position='absolute'
          display='block'
          height='16px'
          width='20px'
          bgcolor='#FFF'
          boxShadow='5px 5px 4px rgba(0,0,0,0.07)'
          sx={{
            transform: getPopoverArrowIndicatorOffset(shouldOffset)
          }}
          left='50%'
          top='100%'
        />
      </Box>
    </Grow>
  )
}
