import { API_BASE_PATH } from 'config'
import { ApiError } from './ApiError'
import FileSaver from 'file-saver'
import queryString from 'query-string'
import { getAuth } from 'firebase/auth'

const api = {
  get: async <T>(url: string, params?: Object) => {
    const qs = params && `?${queryString.stringify(params)}`
    const authHeader = await getAuthHeaders()
    const response = await fetch(`${API_BASE_PATH}${url}${qs || ''}`, {
      method: 'GET',
      headers: authHeader
    })
    return handleResponse<T>(response)
  },
  post: async <T>(url: string, body: Object) => {
    const authHeader = await getAuthHeaders()
    const response = await fetch(`${API_BASE_PATH}${url}`, {
      method: 'POST',
      headers: { ...authHeader, 'Content-Type': 'application/json' },
      body: JSON.stringify(body)
    })
    return handleResponse<T>(response)
  },
  put: async <T>(url: string, body?: Object) => {
    const authHeader = await getAuthHeaders()
    const response = await fetch(`${API_BASE_PATH}${url}`, {
      headers: {
        'Content-Type': 'application/json',
        ...authHeader
      },
      body: JSON.stringify(body),
      method: 'PUT'
    })
    return handleResponse<T>(response)
  },
  download: async (url: string, fileName: string) => {
    fetch(`${url}`, {
      method: 'GET'
    }).then(async response => {
      if (response.ok) {
        const blob = await response.blob()
        FileSaver.saveAs(blob, fileName)
      }
    })
  },
  delete: async <T>(url: string) => {
    const authHeader = await getAuthHeaders()
    const response = await fetch(`${API_BASE_PATH}${url}`, {
      headers: {
        ...authHeader
      },
      method: 'DELETE'
    })
    return handleResponse<T>(response)
  },
  postWithFile: async <T>(
    url: string,
    body: BodyInit,
    headers?: HeadersInit
  ) => {
    const authHeader = await getAuthHeaders()
    const response = await fetch(`${API_BASE_PATH}${url}`, {
      method: 'POST',
      headers: { ...authHeader, ...headers },
      body: body
    })
    return handleResponse<T>(response)
  }
}

async function handleResponse<T>(response: Response) {
  const responseData = response.status !== 204 && (await response.json())
  if (!response.ok) {
    throw new ApiError({
      message: response.statusText,
      status: response.status,
      responseData,
      ...responseData
    })
  }
  return Promise.resolve(responseData) as Promise<T>
}

async function getAuthHeaders() {
  const token = await getAuth()?.currentUser?.getIdToken()

  return {
    Authorization: `Bearer ${token}`,
    'TEALBOOK-origin': 'opcon'
  }
}

export default api
