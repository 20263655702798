import { Control, FieldError, useController } from 'react-hook-form'
import { RadioGroup, RadioGroupProps } from '@mui/material'

type RadioGroupExpProps = RadioGroupProps & {
  name: string
  control: Control<any>
  required?: string
  errors?: FieldError
}
export default function RadioGroupForm({
  name,
  control,
  children,
  required,
  errors,
  ...rest
}: RadioGroupExpProps) {
  const { field } = useController({
    name,
    control,
    rules: { required }
  })
  return (
    <RadioGroup
      {...field}
      {...rest}
      id={name}
      onChange={field.onChange}
      value={field.value}
    >
      {children}
    </RadioGroup>
  )
}
