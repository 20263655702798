import { Breadcrumbs as MuiBreadcrumbs, Link, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'

import { BreadCrumb } from 'state/titleCluster'

export const Breadcrumbs = ({ breadcrumbs }: { breadcrumbs: BreadCrumb[] }) => {
  const { t } = useTranslation('general')

  return (
    <MuiBreadcrumbs aria-label={t('breadcrumbs')} sx={{ mt: 0 }}>
      {breadcrumbs.map((crumb, index) =>
        crumb.link ? (
          <Link
            component={RouterLink}
            underline='hover'
            to={crumb.link}
            key={index}
          >
            {crumb.label}
          </Link>
        ) : (
          <Typography key={index} variant='body1'>
            {crumb.label}
          </Typography>
        )
      )}
    </MuiBreadcrumbs>
  )
}
