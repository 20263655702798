import DataTableServerSide from 'components/DataTable/DataTableServerSide'
import createColDefs from 'components/DataTable/createColDefs'
import { getUsersByPage } from 'api/queries'
import {
  GridRenderCellParams,
  GridCellParams,
  GridColDef,
  GridRowId
} from '@mui/x-data-grid'
import CheckIcon from '@mui/icons-material/Check'
import XIcon from '@mui/icons-material/Close'
import { useTranslation } from 'react-i18next'
import { Button, Typography } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps
} from 'react-router-dom'
import { forwardRef, useMemo } from 'react'
import usePermissions, { PERMISSION_SETS } from 'hooks/usePermissions'
import { User } from 'types'

const Link = forwardRef<HTMLAnchorElement, RouterLinkProps>(
  (itemProps, ref) => <RouterLink ref={ref} {...itemProps} />
)

const EditButton = ({ id }: { id: GridRowId }) => {
  const { t } = useTranslation('general')
  return (
    <Button
      variant='text'
      startIcon={<EditIcon />}
      component={Link}
      to={`${id}/edit`}
    >
      {t('edit')}
    </Button>
  )
}

export default function Users() {
  const { t } = useTranslation('users')
  const canUpdateUsers = usePermissions(PERMISSION_SETS.UPDATE_USERS)
  const canCreateUsers = usePermissions(PERMISSION_SETS.CREATE_USERS)

  const columnDefs = useMemo(() => {
    const baseDefs = [
      { field: 'id' },
      {
        field: 'name',
        headerName: t('name'),
        renderCell: (params: GridRenderCellParams<User>) => (
          <Typography variant='body1'>{`${params.row.firstName} ${params.row.lastName}`}</Typography>
        ),
        filterable: false
      },
      { field: 'email', headerName: t('email'), filterable: false },
      {
        field: 'permissionGroupNames',
        headerName: t('userGroup'),
        renderCell: (params: GridRenderCellParams) => {
          if (params.value && params.value.length > 0) {
            return (
              <Typography variant='body1'>
                {params.value?.join(', ')}
              </Typography>
            )
          }
          return 'none'
        },
        filterable: false
      },
      { field: 'clientName', headerName: t('client'), filterable: false },
      {
        field: 'enabled',
        headerName: t('enabled'),
        maxWidth: 90,
        align: 'center',
        renderCell: (params: GridRenderCellParams) =>
          params.value ? <CheckIcon /> : <XIcon />,
        filterable: false
      }
    ]

    const actionColDef = {
      field: 'Action',
      minWidth: 50,
      maxWidth: 110,
      renderCell: (params: GridCellParams) => <EditButton id={params.id} />,
      disableColumnMenu: true,
      sortable: false
    }

    return createColDefs(
      canUpdateUsers
        ? ([...baseDefs, actionColDef] as GridColDef[])
        : (baseDefs as GridColDef[])
    )
  }, [canUpdateUsers, t])

  return (
    <DataTableServerSide
      title={t('title')}
      columns={columnDefs}
      queryKey='users'
      query={getUsersByPage}
      createPageLink={canCreateUsers ? '/opcon/users/create' : undefined}
      createButtonTitle={canCreateUsers ? t('createNewUser') : undefined}
      searchOnColumns={['email']}
    />
  )
}
