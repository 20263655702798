import DataTableServerSide from 'components/DataTable/DataTableServerSide'
import { GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid'
import { useNavigate } from 'react-router-dom'
import { startCase } from 'lodash'
import { useTranslation } from 'react-i18next'
import { getOrgsByPage } from 'api/queries'
import usePermissions, { PERMISSION_SETS } from 'hooks/usePermissions'
import { getOrgsColumns } from './(shared)/getOrgsColumns'

const getOrgTypes = (params: GridValueGetterParams | GridRenderCellParams) => {
  return params?.row.orgTypes
    ?.map((type: string) => startCase(type))
    .sort()
    .join(', ')
}

const Orgs = () => {
  const navigate = useNavigate()
  const { t } = useTranslation('orgs')
  const canUpdateClient = usePermissions(PERMISSION_SETS.UPDATE_CLIENTS)
  const canCreateClient = usePermissions(PERMISSION_SETS.CREATE_CLIENTS)

  const handleEdit = (params: GridRenderCellParams) =>
    navigate(`/opcon/orgs/edit/${params.id}`)

  return (
    <DataTableServerSide
      queryKey={'orgs'}
      query={getOrgsByPage}
      columns={getOrgsColumns(
        t,
        getOrgTypes,
        canUpdateClient ? handleEdit : undefined
      )}
      title={t('title')}
      createPageLink={canCreateClient ? '/opcon/orgs/create' : undefined}
      createButtonTitle={canCreateClient ? t('createNewClient') : undefined}
      searchOnColumns={['name']}
    />
  )
}
export default Orgs
