import {
  Stack,
  Button,
  TableRow,
  TableBody,
  TableHead,
  Paper,
  ChipProps,
  Typography,
  TablePagination,
  TableFooter
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { startCase } from 'lodash'

import { ambiguousMatchesQueries } from 'api/queries'
import useQuery from 'hooks/useQuery'
import { Chip } from 'components/Chip'
import { Table } from 'components/Table'
import { Loading } from 'components/Loading'
import { MatchStatus } from 'types'
import { usePipelineBreadcrumbs } from '../../../(shared)/usePipelineBreadcrumbs'
import { useSetTitleCluster } from 'state/titleCluster'
import { useState } from 'react'
import StatusSection from 'components/StatusSection'
import { useQueryClient } from '@tanstack/react-query'
import { useAnalyticsWithPipeline } from 'hooks/useAnalytics'
import { Cell } from 'components/Table'

type Color = ChipProps['color']

type StatusMap = {
  [key in MatchStatus]: Color
}

export const STATUS_MAP: StatusMap = {
  not_started: 'secondary',
  complete: 'success'
}

const ROWS_PER_PAGE = 50

type Props = {
  orgId: string
  pipelineId: string
}

export default function AmbiguousMatchesOverview(props: Props) {
  const { orgId, pipelineId } = props

  const { t } = useTranslation('supplierMatch')
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const analytics = useAnalyticsWithPipeline({ clientId: orgId, pipelineId })

  const [page, setPage] = useState(0)
  const { data, isRefetching } = useQuery(
    ambiguousMatchesQueries.ambiguousMatchesPaginated(
      orgId,
      pipelineId,
      page,
      ROWS_PER_PAGE
    )
  )

  const breadcrumbs = usePipelineBreadcrumbs(orgId, pipelineId)
  useSetTitleCluster(t('review_ambiguous_matches'), breadcrumbs.slice(0, 2))

  const handleMatchSuppliers = () => {
    queryClient.invalidateQueries(['ambiguousMatches'])
    analytics.track('Match Suppliers Clicked', {
      eventSource: 'Review Ambiguous Matches Page',
      eventCategory: 'user'
    })
    navigate(
      `/opcon/pipelines/${orgId}/${pipelineId}/ambiguous-matches/${data?.ambiguousMatches[0].metadata?.entity_metadata_id}/match-suppliers`
    )
  }
  const getRowNumber = (row: number) =>
    !page ? row : row + ROWS_PER_PAGE * page

  if (!data || isRefetching) {
    return <Loading />
  }

  if (!data.numAmbigMatches) {
    return (
      <StatusSection title={t('matchingComplete')}>
        <Typography>{t('pipelineCompleteMessage')}</Typography>
        <Stack direction='row' spacing={1}>
          <Button
            variant='contained'
            onClick={() =>
              navigate(`/opcon/pipelines/${orgId}/${pipelineId}/enrichment`)
            }
          >
            {t('goToEnrichment')}
          </Button>
        </Stack>
      </StatusSection>
    )
  }
  return (
    <Stack spacing={3}>
      <Paper sx={{ p: 3 }}>
        <Typography mb={2}>
          {t('ambiguousMatchesToReview', {
            numMatches: data.numAmbigMatches
          })}
        </Typography>
        <Button variant='contained' onClick={handleMatchSuppliers}>
          {t('matchSuppliers')}
        </Button>
      </Paper>

      <Table>
        <TableHead>
          <TableRow>
            <Cell width={100} align='center'>
              {t('row')}
            </Cell>
            <Cell width={200}>{t('internalSupplierId')}</Cell>
            <Cell width={250}>{t('record')}</Cell>
            <Cell>{t('matchStatus')}</Cell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.ambiguousMatches?.map((rows, index) => {
            return (
              <TableRow key={index + 1}>
                <Cell align='center'>{getRowNumber(index + 1)}</Cell>
                <Cell>{rows.sourceData.internal_supplier_id}</Cell>
                <Cell sx={{ fontWeight: 'bold' }}>
                  {startCase(rows.sourceData.company_name)}
                </Cell>

                <Cell>
                  <Chip
                    strength='light'
                    label={startCase(rows.match_status)}
                    color={rows.match_status && STATUS_MAP[rows.match_status]}
                  />
                </Cell>
              </TableRow>
            )
          })}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              count={data.numAmbigMatches}
              rowsPerPage={ROWS_PER_PAGE}
              rowsPerPageOptions={[]}
              page={page}
              onPageChange={(event, page) => setPage(page)}
              aria-label='rows being displayed on this page'
            />
          </TableRow>
        </TableFooter>
      </Table>
    </Stack>
  )
}
