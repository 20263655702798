import { useTranslation } from 'react-i18next'
import { useOrgAndPipelineQuery } from 'hooks/useOrgAndPipelineQuery'
import { BreadCrumb } from 'state/titleCluster'
import formatDateTime from 'utils/formatDateTime'

export const usePipelineBreadcrumbs = (orgId: string, pipelineId: string) => {
  const { t } = useTranslation('pipelines')

  const { org, pipeline } = useOrgAndPipelineQuery({ orgId, pipelineId })

  const pipelineType =
    pipeline?.pipeline_type === 'client_data_spend'
      ? 'spend'
      : pipeline?.pipeline_type === 'common_certification'
      ? 'usp'
      : 'vendor'

  const pipelineName =
    pipeline && pipelineType === 'usp'
      ? `${pipeline.certificate_source?.label} - ${formatDateTime(
          pipeline.created_at
        )}`
      : pipeline?.pipeline_name || org?.orgName

  return [
    {
      link: `/opcon/pipelines/list/${pipelineType}`,
      label: t('title')
    },
    {
      link: `/opcon/pipelines/${orgId}/${pipelineId}/overview`,
      label: pipelineName
    },
    {
      link: `/opcon/pipelines/${orgId}/${pipelineId}/ambiguous-matches`,
      label: t('overview.review_ambiguous_matches')
    },
    {
      link: `/opcon/pipelines/${orgId}/${pipelineId}/history`,
      label: t('tabBar.history')
    }
  ] as BreadCrumb[]
}
