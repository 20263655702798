import { Box, Drawer as MuiDrawer, Button } from '@mui/material'
import { styled, Theme, CSSObject, useTheme } from '@mui/material/styles'
import { PropsWithChildren } from 'react'
import { logout } from 'api/auth'
import LogoutIcon from '@mui/icons-material/Logout'
import { useTranslation } from 'react-i18next'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

import TealbookLogo from 'assets/TealBook-Logo.svg'
import TealbookIcon from 'assets/TealBook-Icon.svg'
import SidebarToggle from './SidebarToggle'
import { HeaderSpace } from './Sidebar'
import IconButton from '@mui/material/IconButton'

export const DRAWER_WIDTH = 280

const openedMixin = (theme: Theme): CSSObject => ({
  width: DRAWER_WIDTH,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
})

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`
  }
})

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== 'open'
})(({ theme, open }) => ({
  width: DRAWER_WIDTH,
  whiteSpace: 'nowrap',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme)
  })
}))

const LogoutContainer = styled(Box)(({ theme }) => ({
  width: DRAWER_WIDTH,
  backgroundColor: theme.palette.background.default,
  boxSizing: 'border-box',
  border: 'none',
  padding: theme.spacing(3),
  paddingLeft: theme.spacing(6),
  position: 'absolute',
  marginBottom: 70,
  bottom: 0
}))

const ClosedLogoutContainer = styled(Box)(({ theme }) => ({
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`
  },
  backgroundColor: theme.palette.background.default,
  boxSizing: 'border-box',
  border: 'none',
  position: 'absolute',
  marginBottom: 70,
  bottom: 0
}))

type Props = {
  open: boolean
  onOpen: () => void
  onClose: () => void
} & PropsWithChildren

const AppSidebar = ({ open, onOpen, onClose, children }: Props) => {
  const { t } = useTranslation('general')
  const { t: componentsT } = useTranslation('components')

  const theme = useTheme()

  return (
    <>
      <Drawer
        color='sidebar'
        PaperProps={{ sx: { backgroundColor: 'sidebar.main' } }}
        variant='permanent'
        open={open}
      >
        <HeaderSpace>
          <Logo src={open ? TealbookLogo : TealbookIcon} alt='TealBook' />
        </HeaderSpace>
        <Box component={'nav'} sx={{ marginTop: theme.spacing(1) }}>
          {children}
        </Box>

        {open ? (
          <LogoutContainer>
            <Button variant='text' startIcon={<LogoutIcon />} onClick={logout}>
              {t('logout')}
            </Button>
          </LogoutContainer>
        ) : (
          <ClosedLogoutContainer>
            <IconButton
              color={'primary'}
              onClick={logout}
              aria-label={t('logout')}
            >
              <LogoutIcon />
            </IconButton>
          </ClosedLogoutContainer>
        )}
      </Drawer>
      {open ? (
        <SidebarToggle
          open={open}
          onClick={onClose}
          aria-label={componentsT('appSidebar.collapse')}
        >
          <ChevronLeftIcon />
        </SidebarToggle>
      ) : (
        <SidebarToggle
          open={open}
          onClick={onOpen}
          aria-label={componentsT('appSidebar.expand')}
        >
          <ChevronRightIcon />
        </SidebarToggle>
      )}
    </>
  )
}

const Logo = styled('img')(() => ({
  objectFit: 'contain',
  width: '100%',
  maxWidth: 173
}))

export default AppSidebar
