import { useParams } from 'react-router-dom'
import { Stack, Box, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from '@tanstack/react-query'

import useMutation from 'hooks/useMutation'
import { updatePipeline } from 'api/mutations'
import { useOrgAndPipelineQuery } from 'hooks/useOrgAndPipelineQuery'
import { FileCorrectionsSection } from './(shared)/FileCorrectionsSection'
import { AmbiguousMatchesSection } from './(shared)/AmbiguousMatchesSection'
import { OperationalStatsSection } from './(shared)/OperationalStatsSection'
import { Loading } from 'components/Loading'
import usePermissions, { PERMISSION_SETS } from 'hooks/usePermissions'
import { useProcessSuccessStatus } from 'hooks/useProcessSuccessStatus'
import { useAnalyticsWithPipeline } from 'hooks/useAnalytics'
import { Pipeline } from 'types'

export default function PipelineOverview() {
  const { t } = useTranslation('pipelines')
  const { orgId, pipelineId } = useParams() as {
    orgId: string
    pipelineId: string
  }
  const analytics = useAnalyticsWithPipeline({ clientId: orgId, pipelineId })

  const hasManageAllPipelinesPermissions = usePermissions(
    PERMISSION_SETS.MANAGE_ALL_PIPELINES
  )

  const { org, pipeline, isLoading, pipelineQuery } = useOrgAndPipelineQuery({
    orgId,
    pipelineId
  })
  const { error } = pipelineQuery

  const newStatus = useProcessSuccessStatus({ pipelineId, pipeline, orgId })

  const queryClient = useQueryClient()

  const updatePipelineStatusMutation = useMutation({
    mutationFn: updatePipeline,
    onSuccess: updatedPipelineResponse => {
      queryClient.invalidateQueries(['pipelines', 'server'], {
        type: 'inactive'
      })
      return queryClient.setQueryData(
        ['pipelines', pipelineId, orgId],
        (oldData: Pipeline | undefined) => {
          return { ...oldData, ...updatedPipelineResponse }
        }
      )
    }
  })

  const handleUpdatePipelineStatus = async () => {
    if (newStatus) {
      await updatePipelineStatusMutation.mutate({
        pipeline_id: pipelineId,
        body: {
          status: newStatus,
          org_id: orgId
        }
      })
      if (newStatus === 'complete') {
        analytics.track('Pipeline Closed', {
          eventSource: 'Pipeline Overview Page',
          eventCategory: 'user'
        })
      }
    }
  }

  if (isLoading) {
    return <Loading />
  }

  return (
    <Stack spacing={2}>
      {!error && pipeline && (
        <>
          <OperationalStatsSection
            pipeline={pipeline}
            orgUnitName={org?.name || orgId}
          />
          {pipeline.pipeline_type === 'common_certification' && (
            <>
              <AmbiguousMatchesSection
                isComplete={newStatus === 'complete'}
                orgId={orgId}
                pipelineId={pipeline.pipeline_id}
                pipelineStatus={pipeline.status}
              />
              {hasManageAllPipelinesPermissions && newStatus && (
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    variant='contained'
                    onClick={handleUpdatePipelineStatus}
                  >
                    {t(`overview.${newStatus}`)}
                  </Button>
                </Box>
              )}
            </>
          )}
          {(pipeline.pipeline_type === 'client_data_vendor' ||
            pipeline.pipeline_type === 'client_data_vendor_extended') && (
            <>
              <FileCorrectionsSection
                inputs={pipeline.inputs}
                isComplete={newStatus === 'complete'}
                orgId={orgId}
                pipelineId={pipeline.pipeline_id}
                pipelineStatus={pipeline.status}
              />
              <AmbiguousMatchesSection
                isComplete={newStatus === 'complete'}
                orgId={orgId}
                pipelineId={pipeline.pipeline_id}
                pipelineStatus={pipeline.status}
              />
              {hasManageAllPipelinesPermissions && newStatus && (
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    variant='contained'
                    onClick={handleUpdatePipelineStatus}
                  >
                    {t(`overview.${newStatus}`)}
                  </Button>
                </Box>
              )}
            </>
          )}
        </>
      )}
      <> {error && <div>{t('notFound')}</div>}</>
    </Stack>
  )
}
