import { useTranslation } from 'react-i18next'
import UserGroupForm from '../(shared)/UserGroupForm'
import { useSetTitleCluster } from 'state/titleCluster'

export function CreateUserGroup() {
  const { t } = useTranslation('userGroups')

  useSetTitleCluster(t('createUserGroup'), [
    {
      link: '/opcon/user-groups',
      label: t('title')
    }
  ])

  return <UserGroupForm />
}
