import { FileErrors } from 'types'

const UPLOAD_ERRORS = {
  unknownHeading: 'unknownHeading',
  missingRequiredHeading: 'missingRequiredHeading',
  missingRequiredField: 'missingRequiredField',
  incorrectUploadType: 'incorrectUploadType', //this error is not in production yet. Right now upload type is not validated at file upload. Pipeline fails later in the process
  duplicatedKey: 'duplicatedKey',
  futureDatedSpend: 'futureDatedSpend',
  invalidIsGovernment: 'invalidIsGovernment',
  other: 'other' // catch any new error messages that might come up that we haven't parsed in front-end yet
}
const HEADERS = {
  internal_supplier_id: 'Internal Supplier Id or Vendor Master',
  company_name: 'Company Name'
}
export default function parseUploadErrors(errors: FileErrors[] = []) {
  const errorsByType = errors.reduce(
    (result: { [key: string]: FileErrors[] }, error) => {
      const errorType = error.type || 'other'

      if (result[errorType]) {
        result[errorType] = [...result[errorType], error]
      } else {
        result[errorType] = [error]
      }
      return result
    },
    {}
  )

  const cleanErrorsByType: { [errorType: string]: any } = {}
  Object.keys(errorsByType).forEach(errorType => {
    if (errorType === UPLOAD_ERRORS.unknownHeading) {
      cleanErrorsByType[errorType] = errorsByType[errorType].map(e =>
        parseIncorrectHeading(e)
      )
    } else if (errorType === UPLOAD_ERRORS.missingRequiredHeading) {
      cleanErrorsByType[errorType] = errorsByType[errorType][0].headings?.map(
        heading => {
          return HEADERS[heading]
        }
      )
    } else if (errorType === UPLOAD_ERRORS.missingRequiredField) {
      cleanErrorsByType[errorType] = errorsByType[errorType].map(
        ({ missing_field, row }) => {
          // row is returned using array index, update to user understandable row numbers
          row = row?.map(r => r + 1)
          return { missingField: missing_field && HEADERS[missing_field], row }
        }
      )
    } else if (errorType === UPLOAD_ERRORS.duplicatedKey) {
      cleanErrorsByType[errorType] = errorsByType[errorType].map(
        ({ duplicated_field, row }) => {
          // row is returned using array index, update to user understandable row numbers
          row = row?.map(r => r + 1)
          return {
            duplicatedField: duplicated_field && HEADERS[duplicated_field],
            row
          }
        }
      )
    } else if (
      errorType === UPLOAD_ERRORS.futureDatedSpend ||
      errorType === UPLOAD_ERRORS.invalidIsGovernment
    ) {
      cleanErrorsByType[errorType] = errorsByType[errorType].map(({ row }) => {
        // row is returned using array index, update to user understandable row numbers
        row = row?.map(r => r + 1)
        return row
      })
    } else {
      cleanErrorsByType[errorType] = errorsByType[errorType].map(e =>
        e.toString()
      )
    }
  })
  return cleanErrorsByType
}

function parseIncorrectHeading(error: FileErrors) {
  const { original, suggested } = error
  return (
    original &&
    suggested && {
      original,
      suggested: `"${suggested.map(s => s.value).join(', ')}"`
    }
  )
}
