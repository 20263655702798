import {
  Box,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableHead,
  TablePagination,
  TableFooter
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import useQuery from 'hooks/useQuery'
import { Table } from 'components/Table'
import { Loading } from 'components/Loading'
import { useState } from 'react'
import { Cell } from 'components/Table'
import { ambiguousMatchesQueries } from 'api/queries'

const ROWS_PER_PAGE = 5

type Props = {
  orgId: string
  pipelineId: string
}

export const CertAmbiguousOverview = (props: Props) => {
  const { orgId, pipelineId } = props

  const [page, setPage] = useState(0)
  const { t } = useTranslation('usp')
  const navigate = useNavigate()

  const { data, isRefetching, isLoading } = useQuery({
    ...ambiguousMatchesQueries.uspAmbiguousMatchesOverview(
      pipelineId,
      page,
      ROWS_PER_PAGE
    )
  })

  if (!data) {
    // for initial load, any subsequence load (paging) will handle within the table
    return <Loading />
  }

  const totalCount = data.metadata.total_count
  const ambiguousMatchData = data.data

  const handleReviewButtonClick = () => {
    const entity_metadata_id =
      ambiguousMatchData && ambiguousMatchData[0][0].entity_metadata_id

    navigate(
      `/opcon/pipelines/${orgId}/${pipelineId}/ambiguous-matches/${entity_metadata_id}/match-mge-suppliers`
    )
  }

  return (
    <Stack spacing={2} sx={{ mt: 2, mb: 4 }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center'
        }}
      >
        <Button variant='contained' onClick={handleReviewButtonClick}>
          {t('reviewRecords', { totalCount: totalCount?.toLocaleString() })}
        </Button>
      </Box>

      <Table>
        <TableHead>
          <TableRow>
            <Cell width={100} align='center'>
              {t('row')}
            </Cell>
            <Cell>{t('incomingRecords')}</Cell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading || isRefetching ? (
            <TableRow>
              <Cell colSpan={2} height={200}>
                <Loading />
              </Cell>
            </TableRow>
          ) : (
            ambiguousMatchData.map((row, index) => (
              <TableRow key={index}>
                <Cell align='center'>{page * ROWS_PER_PAGE + index + 1}</Cell>
                <Cell>{row.map(cert => cert.supplier_name).join(', ')}</Cell>
              </TableRow>
            ))
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              count={totalCount}
              rowsPerPage={ROWS_PER_PAGE}
              rowsPerPageOptions={[]}
              page={page}
              onPageChange={(event, page) => setPage(page)}
              aria-label='table pages navigation'
              labelDisplayedRows={({ from, to }) =>
                `${from}-${to} of ${totalCount?.toLocaleString()}`
              }
            />
          </TableRow>
        </TableFooter>
      </Table>
    </Stack>
  )
}
