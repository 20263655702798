import api from '../api'
import { User, PaginationParams, PaginationData } from 'types'
import { createQueryKeys } from '@lukemorales/query-key-factory'

const USER_ENDPOINT = '/accounts/user'

export const userQueries = createQueryKeys('users', {
  all: {
    queryKey: ['all'],
    queryFn: getAllUsers
  },
  allByIds: (ids: string[]) => ({
    queryKey: [ids],
    queryFn: () => getUsersByIds(ids)
  }),
  allByOrgId: (orgId: string) => ({
    queryKey: [orgId],
    queryFn: () => getAllUsersByOrgId(orgId)
  }),
  detail: (userId: string) => ({
    queryKey: [userId],
    queryFn: () => getUserById(userId)
  })
})

export async function getAllUsers(): Promise<User[]> {
  const users = await api.get<{
    data: User[]
  }>(`${USER_ENDPOINT}/search`)

  return users.data
}

export async function getUsersByIds(ids: string[]): Promise<User[]> {
  const uniqueIds = Array.from(new Set(ids.filter(Boolean)))
  const queryString = !!uniqueIds.length ? `?id=${uniqueIds.join(',')}` : ''
  const result = await api.get<{
    data: User[]
  }>(`${USER_ENDPOINT}/search${queryString}`)

  return result.data
}

async function getAllUsersByOrgId(orgId: string): Promise<User[]> {
  const users = await api.get<{
    data: User[]
  }>(`${USER_ENDPOINT}/search?orgId=${orgId}`)
  return users.data
}

function getUserById(userId: string) {
  return api.get<User>(`${USER_ENDPOINT}/${userId}`)
}

type UserQueryParams = {
  sort_by?:
    | 'created_at'
    | 'name'
    | 'email'
    | 'permissionGroupNames'
    | 'clientName'
  order?: 'asc' | 'desc'
}

export async function getUsersByPage(
  params?: PaginationParams & UserQueryParams
) {
  const { page = 0, pageSize = 10, ...rest } = params || {}
  const offset = page * pageSize

  const queryStringParams = {
    offset,
    limit: pageSize,
    ...rest
  }

  return api.get<PaginationData<User>>(USER_ENDPOINT, queryStringParams)
}
