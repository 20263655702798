import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useSetTitleCluster } from 'state/titleCluster'

interface DefinedError extends Partial<Error> {
  statusText?: string
}
export function ErrorPage({ error }: { error: DefinedError }) {
  const { t } = useTranslation('general')

  useSetTitleCluster(t('oops'))

  return (
    <Typography sx={{ fontStyle: 'italic' }}>
      {error.statusText || error.message}
    </Typography>
  )
}
