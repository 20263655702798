import api from '../api'
import {
  UserGroup,
  PaginationParams,
  PaginationData,
  AvailablePermissions
} from 'types'
import { createQueryKeys } from '@lukemorales/query-key-factory'

export const userGroupQueries = createQueryKeys('userGroups', {
  all: () => ({
    queryKey: ['all'],
    queryFn: () => getUserGroups()
  }),
  allByOrgId: (orgId: string) => ({
    queryKey: [orgId],
    queryFn: () => getOrgGroups(orgId)
  }),
  byId: (userGroupId: string) => ({
    queryKey: [userGroupId],
    queryFn: () => getUserGroupById(userGroupId)
  }),
  availablePermissions: {
    queryKey: ['availablePermissions'],
    queryFn: getAvailablePermissions
  }
})

export async function getUserGroups(userId?: string) {
  const queryString = typeof userId === 'string' ? `?userId=${userId}` : ''
  const result = await api.get<{ data: any[]; account: number }>(
    `/accounts/permission-group/search${queryString}`
  )
  return result.data
}

type UserGroupsQueryParams = {
  sort_by?: 'created_at' | 'displayName' | 'organization'
  order?: 'asc' | 'desc'
}

export async function getUserGroupsByPage(
  params?: PaginationParams & UserGroupsQueryParams
) {
  const { page = 0, pageSize = 10, ...rest } = params || {}
  const offset = page * pageSize

  const queryStringParams = {
    offset,
    limit: pageSize,
    ...rest
  }

  return api.get<PaginationData<UserGroup>>(
    '/accounts/permission-group',
    queryStringParams
  )
}

export async function getOrgGroups(orgId: string) {
  const result = await api.get<{ data: any[]; account: number }>(
    `/accounts/permission-group/search?orgId=${orgId}`
  )
  return result.data
}

export async function getUserGroupById(userGroupId: string) {
  return api.get<UserGroup>(`/accounts/permission-group/${userGroupId}`)
}

export async function getAvailablePermissions() {
  return api.get<AvailablePermissions>(
    '/accounts/permission-group/available-permissions'
  )
}
