import CssBaseline from '@mui/material/CssBaseline'
import { AppBar } from 'components/AppBar'
import { useCallback, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'
import { ErrorBoundary } from 'react-error-boundary'

import Snackbar from 'components/Snackbar'
import Sidebar from 'components/Sidebar/Sidebar'
import { ErrorPage } from 'components/ErrorPage'
import SideNav from './(shared)/SideNav'

const SidebarLayout = styled('aside')({
  flexShrink: 0,
  minHeight: '100%'
})

const Main = styled('main')({
  flexGrow: 1,
  minHeight: '100%'
})

const Content = styled('div')(({ theme }) => ({
  paddingBottom: theme.spacing(6),
  paddingLeft: theme.spacing(6),
  paddingRight: theme.spacing(6)
}))

export default function Layout() {
  const { pathname } = useLocation()
  const [isSidebarOpen, setIsSidebarOpen] = useState(true)

  const handleOpenSidebar = useCallback(() => setIsSidebarOpen(true), [])
  const handleCloseSidebar = useCallback(() => setIsSidebarOpen(false), [])

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Snackbar />
      <SidebarLayout>
        <Sidebar
          open={isSidebarOpen}
          onOpen={handleOpenSidebar}
          onClose={handleCloseSidebar}
        >
          <SideNav isSidebarOpen={isSidebarOpen} />
        </Sidebar>
      </SidebarLayout>

      <Main>
        <AppBar />
        <Content>
          <ErrorBoundary key={pathname} FallbackComponent={ErrorPage}>
            <Outlet />
          </ErrorBoundary>
        </Content>
      </Main>
    </Box>
  )
}
