import { TFunction } from 'i18next'

import { PipelineType } from 'types'
import { ValueOptions } from '@mui/x-data-grid'
import { getPipelinesVendorColumns } from './getPipelineVendorColumns'
import { getPipelineSpendColumns } from './getPipelineSpendColumns'
import { getPipelineUSPColumns } from './getPipelineUspColumns'

export const getPipelinesColumns = (
  type: PipelineType,
  t: TFunction<'pipelines'>,
  filterValueOptions?: { [key: string]: ValueOptions[] }
) => {
  if (type === 'client_data_spend') {
    return getPipelineSpendColumns(t, filterValueOptions)
  } else if (type === 'common_certification') {
    return getPipelineUSPColumns(t, filterValueOptions)
  } else {
    return getPipelinesVendorColumns(t, filterValueOptions)
  }
}
