import { FieldError, Merge } from 'react-hook-form'
import { FormHelperText, InputLabel, Select, SelectProps } from '@mui/material'
import { startCase } from 'lodash'
import { forwardRef, useCallback } from 'react'

type Props = {
  errors?: Merge<FieldError, (FieldError | undefined)[]>
} & Omit<SelectProps, 'multiple'>

const MultipleSelect = forwardRef(
  (
    { label, errors, required, children, name, renderValue, ...rest }: Props,
    ref
  ) => {
    const formatValue = useCallback(
      (selected: unknown) =>
        Array.isArray(selected)
          ? selected
              .map((value: string | undefined) => startCase(value))
              .join(', ')
          : 'Invalid value',
      []
    )

    return (
      <>
        <InputLabel id={name + '-label'} required={required} error={!!errors}>
          {label}
        </InputLabel>
        <Select
          size='small'
          ref={ref}
          id={name}
          labelId={name + '-label'}
          label={label}
          error={!!errors}
          {...rest}
          variant='outlined'
          required={required}
          multiple
          renderValue={renderValue || formatValue}
        >
          {children}
        </Select>
        {errors && errors.message && (
          <FormHelperText error>{errors.message}</FormHelperText>
        )}
      </>
    )
  }
)
export default MultipleSelect
