import { Button } from '@mui/material'
import { GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid'
import createColDefs from 'components/DataTable/createColDefs'
import EditIcon from '@mui/icons-material/Edit'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'

export const getOrgsColumns = (
  t: TFunction<'orgs'>,
  getOrgTypes: (params: GridValueGetterParams | GridRenderCellParams) => void,
  handleEdit?: (params: GridRenderCellParams) => void
) => {
  const cols = createColDefs([
    {
      field: 'id'
    },
    {
      field: 'name',
      filterable: false,
      headerName: t('name')
    },
    {
      field: 'orgTypesCustom',
      filterable: false,
      disableColumnMenu: true,
      sortable: false,
      headerName: t('orgTypesCustom'),
      valueGetter: getOrgTypes
    }
  ])
  if (handleEdit !== undefined) {
    cols.push({
      field: 'Action',
      disableColumnMenu: true,
      sortable: false,
      minWidth: 50,
      maxWidth: 110,
      renderCell: (params: GridRenderCellParams) => (
        <Action params={params} handleEdit={handleEdit} />
      )
    })
  }

  return cols
}

type ActionProps = {
  params: GridRenderCellParams
  handleEdit: (params: GridRenderCellParams) => void
}
const Action = ({ params, handleEdit }: ActionProps) => {
  const { t } = useTranslation('general')
  const handleClick = useCallback(
    () => handleEdit(params),
    [params, handleEdit]
  )
  return (
    <Button
      variant='text'
      key={params.id}
      startIcon={<EditIcon />}
      onClick={handleClick}
    >
      {t('edit')}
    </Button>
  )
}
