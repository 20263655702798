import { Typography } from '@mui/material'
import { TFunction } from 'i18next'

import formatDateTime from 'utils/formatDateTime'
import { PipelineStatus } from 'types'
import {
  getGridStringOperators,
  getGridSingleSelectOperators,
  ValueOptions
} from '@mui/x-data-grid'
import createColDefs from 'components/DataTable/createColDefs'
import { StatusChip } from 'components/StatusChip'
import theme from 'styles/theme'

export const getPipelineSpendColumns = (
  t: TFunction<'pipelines'>,
  filterValueOptions?: { [key: string]: ValueOptions[] }
) =>
  createColDefs([
    { field: 'pipeline_id' },
    {
      field: 'pipeline_name',
      headerName: t('pipelineName'),
      minWidth: 250,
      filterOperators: getGridStringOperators().filter(
        operator => operator.value === 'contains'
      ),
      renderCell: params => (
        <Typography
          noWrap
          variant='body1'
          sx={{ color: theme.palette.primary.main }}
        >
          {params.value}
        </Typography>
      )
    },
    {
      field: 'org_id',
      headerName: t('client'),
      width: 200,
      sortable: false,
      type: 'singleSelect',
      filterOperators: getGridSingleSelectOperators().filter(
        operator => operator.value === 'is'
      ),
      valueOptions: filterValueOptions?.orgName,
      renderCell: params => (
        <Typography variant='body1'>{params.row.orgName}</Typography>
      )
    },
    {
      field: 'record_count',
      headerName: t('numberOfRecords'),
      width: 100,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: params => (
        <Typography variant='body1'>
          {params.row.stats.record_count?.toLocaleString()}
        </Typography>
      )
    },
    {
      field: 'status',
      headerName: t('loadStatus'),
      width: 200,
      renderCell: params => (
        <StatusChip
          label={t(params.value)}
          variant={params.value as PipelineStatus}
          isStartCase
        />
      ),
      type: 'singleSelect',
      filterOperators: getGridSingleSelectOperators().filter(
        operator => operator.value === 'is'
      ),
      valueOptions: filterValueOptions?.status
    },
    {
      field: 'user_id',
      headerName: t('createdBy'),
      width: 200,
      sortable: false,
      type: 'singleSelect',
      filterOperators: getGridSingleSelectOperators().filter(
        operator => operator.value === 'is'
      ),
      valueOptions: filterValueOptions?.createdBy,
      renderCell: params => (
        <Typography variant='body1'>{params.row.createdBy}</Typography>
      )
    },
    {
      field: 'updated_at',
      headerName: t('lastUpdated'),
      width: 200,
      filterable: false,
      disableColumnMenu: true,
      renderCell: params => (
        <Typography variant='body1'>{formatDateTime(params.value)}</Typography>
      )
    },
    {
      field: 'created_at',
      headerName: t('dateCreated'),
      width: 200,
      filterable: false,
      disableColumnMenu: true,
      renderCell: params => (
        <Typography variant='body1'>{formatDateTime(params.value)}</Typography>
      )
    }
  ])
