import { cloneElement, ReactElement } from 'react'
import { useScrollTrigger } from '@mui/material'
import theme from 'styles/theme'

const SHADOW =
  'rgb(0 0 0 / 12%) 0px 3px 5px -1px, rgb(0 0 0 / 6%) 0px 5px 8px 0px, rgb(0 0 0 / 4%) 0px 1px 14px 0px'

const ScrollToElevate = ({ children }: { children: ReactElement }) => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0
  })

  return cloneElement(children, {
    style: {
      boxShadow: trigger ? SHADOW : 'none',
      transition: theme.transitions.create('box-shadow', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    }
  })
}

export default ScrollToElevate
