import MuiAppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Avatar from '@mui/material/Avatar'

import { useSession } from 'state/session'
import theme from 'styles/theme'
import { TitleCluster } from './TitleCluster'
import ScrollToElevate from './ScrollToElevate'
import { formatName } from 'utils/formatName'

const ClientAppBar = () => {
  const session = useSession()
  const currentUser = session.user

  const name = formatName({ ...currentUser })

  return (
    <ScrollToElevate>
      <MuiAppBar
        color='inherit'
        sx={{
          border: 'none',
          boxShadow: 'none',
          position: 'sticky',
          top: 0
        }}
      >
        <Toolbar
          sx={{
            minHeight: 0,
            paddingLeft: { xs: theme.spacing(2) },
            paddingRight: { xs: theme.spacing(2) },
            mb: 2,
            mt: 2
          }}
        >
          <TitleCluster />

          <Box sx={{ flexGrow: 1 }}></Box>

          <Box
            sx={{
              flexGrow: 0,
              display: 'flex',
              alignItems: 'center',
              gap: '20px'
            }}
          >
            <Avatar
              alt={name}
              sx={{
                height: '2rem',
                width: '2rem',
                bgcolor: theme.palette.text.primary
              }}
            />
          </Box>
        </Toolbar>
      </MuiAppBar>
    </ScrollToElevate>
  )
}

export default ClientAppBar
