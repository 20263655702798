import { useSession } from 'state/session'
import analytics from 'utils/analytics'
import useQuery from 'hooks/useQuery'
import queries, { getPipelineById } from 'api/queries'
import { formatName } from 'utils/formatName'

export type AnalyticsKeys = {
  eventSource: string
  eventCategory: string
  orgName?: string
  clientName?: string
  pipelineId?: string
  pipelineType?: string
  pipelineName?: string
  dateCreated?: string
  createdBy?: string
  fileAccepted?: boolean
  fileName?: string
  numberOfErrors?: number
  incomingSupplierName?: string
  incomingSupplierAddress?: string[] | string
  incomingSupplierDomain?: string[] | string
  numberOfPotentialMatches?: number
  selectedMatchName?: string
  selectedMatchAddress?: string[] | string
  selectedMatchDomain?: string[] | string
  selectedMatchAccuracy?: number
  matchReasoning?: string
  timeToSubmit?: number
  timeToSkip?: number
  sourceName?: string
  historyMadeBy?: string
  historyDate?: string
  exportType?: string
}

const useAnalytics = () => {
  const session = useSession()
  const { user, userId, orgId } = session

  return {
    track: (event: string, properties?: AnalyticsKeys) => {
      analytics.track(event, {
        userId,
        orgId,
        ...user,
        ...properties
      })
    }
  }
}

export const useAnalyticsWithPipeline = ({
  clientId,
  pipelineId
}: {
  clientId: string
  pipelineId: string
}) => {
  const session = useSession()
  const { user, userId, orgId } = session

  const { client, pipeline, createdBy } = usePipelineInfo({
    orgId: clientId,
    pipelineId
  })

  const createdByName =
    createdBy &&
    (createdBy.firstName ? formatName(createdBy) : createdBy.organization?.name)

  return {
    track: (event: string, properties?: AnalyticsKeys) => {
      analytics.track(event, {
        userId,
        orgId,
        clientName: client?.name,
        pipelineId: pipeline?.pipeline_id,
        pipelineType: pipeline?.pipeline_type,
        pipelineName: pipeline?.pipeline_name,
        dateCreated: pipeline?.created_at,
        createdBy: createdByName,
        ...user,
        ...properties
      })
    }
  }
}

const usePipelineInfo = ({
  orgId,
  pipelineId
}: {
  orgId: string
  pipelineId: string
}) => {
  const { data: pipeline } = useQuery({
    queryKey: ['pipelines', pipelineId, orgId],
    queryFn: () => getPipelineById(orgId, pipelineId),
    enabled: !!orgId && !!pipelineId,
    cacheTime: 0
  })

  const { data: client } = useQuery({
    ...queries.orgs.byId(orgId),
    enabled: !!orgId
  })

  const { data: createdBy } = useQuery({
    enabled: !!pipeline?.inputs?.[0].user_id,
    ...queries.users.detail(pipeline?.inputs?.[0].user_id || '')
  })

  return {
    pipeline,
    client,
    createdBy
  }
}

export default useAnalytics
