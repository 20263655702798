import { BreFormSectionProps, ScenarioSelectParams } from './BreForm'
import { BreFormRow } from 'types'
import { useTranslation } from 'react-i18next'
import { Stack, Typography, useTheme } from '@mui/material'
import { startCase } from 'lodash'
import { DataScenarioSelector } from './DataScenarioSelector'
import { TrustScoreSelector } from './TrustScoreSelector'

export type FormRowProps = Omit<ScenarioSelectParams, 'selectedScenario'> & {
  config: BreFormRow
  hasVeryHighTrustScore?: boolean
} & Pick<BreFormSectionProps, 'onDataScenarioSelect' | 'onTrustScoreSelect'>

export const FormRow = (props: FormRowProps) => {
  const { t } = useTranslation('datapacks')
  const { field, section, type } = props
  const { spacing, palette } = useTheme()

  return (
    <Stack
      key={`${section}.${type ?? 'default'}.${field}`}
      padding={spacing(1, 2.5)}
      bgcolor={palette.grey[100]}
      direction='row'
      alignItems='center'
      justifyContent='space-between'
    >
      <Typography width='20%'>
        {t(field as unknown as TemplateStringsArray, startCase(field))}
      </Typography>

      <DataScenarioSelector {...props} />
      <TrustScoreSelector {...props} />
    </Stack>
  )
}
