import { Typography, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { useAnalyticsWithPipeline } from 'hooks/useAnalytics'
import { Banner } from 'components/Banner'

export const ReadyForMatchingBanner = ({
  orgId,
  pipelineId
}: {
  orgId: string
  pipelineId: string
}) => {
  const { t } = useTranslation('pipelines')
  const navigate = useNavigate()

  const analytics = useAnalyticsWithPipeline({ clientId: orgId, pipelineId })

  return (
    <Banner variant='success' title={t('tnvPage.noMoreErrorsToReview')}>
      <Typography>{t('tnvPage.noMoreErrorsToReviewMessage')}</Typography>
      <Button
        sx={{ mt: '20px', mb: '16px' }}
        variant='contained'
        onClick={() => {
          analytics.track('Review Ambiguous Clicked', {
            eventSource: 'File Corrections Page',
            eventCategory: 'user'
          })
          navigate(`/opcon/pipelines/${orgId}/${pipelineId}/ambiguous-matches`)
        }}
      >
        {t('overview.review_ambiguous_matches')}
      </Button>
    </Banner>
  )
}
