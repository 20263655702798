import { MatchingHistory } from 'types'

export const getMatchingDescription = (match_type_count: {
  match: number
  no_match: number
  ambiguous_match: number
}) => {
  let description = ''
  if (match_type_count.match) {
    description = 'matchesMade'
  }
  if (match_type_count.no_match) {
    if (!match_type_count.match) {
      description = 'noMatch'
    } else {
      description = 'matchesMade_noMatch'
    }
  }
  if (match_type_count.ambiguous_match) {
    description = getAmbiguousDescription(match_type_count, description)
  }
  return description
}

const getAmbiguousDescription = (
  match_type_count: {
    match: number
    no_match: number
    ambiguous_match: number
  },
  description: string
) => {
  if (!match_type_count.match && !match_type_count.no_match) {
    description = 'ambiguousDetected'
  }
  if (match_type_count.match && match_type_count.no_match) {
    description = 'matchesMade_noMatch_ambiguousDetected'
  }
  if (match_type_count.match && !match_type_count.no_match) {
    description = 'matchesMade_ambiguousDetected'
  }
  if (!match_type_count.match && match_type_count.no_match) {
    description = 'noMatch_ambiguousDetected'
  }
  return description
}

export const updateAmbiguousCount = (data: MatchingHistory[]) => {
  const ambiguousCount = data.reduce((result, currentGroup) => {
    if (currentGroup.updated_by !== 'Pipeline') {
      result += currentGroup.match_type_count.match
      result += currentGroup.match_type_count.no_match
    }
    return result
  }, 0)

  const index = data.findIndex(group => group.updated_by === 'Pipeline')
  if (index !== -1) {
    data[index].match_type_count.ambiguous_match += ambiguousCount
  }
  return data
}
