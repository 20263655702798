import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Paper,
  Typography,
  FormLabel,
  Checkbox,
  FormControlLabel
} from '@mui/material'
import { useController, useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import useNotify from 'hooks/useNotify'

import Snackbar from 'components/Snackbar'
import InputText from 'components/Form/InputText'
import {
  loginWithEmailAndPassword,
  loginWithGoogle,
  forgetPassword,
  EmailPassword
} from '../../api/auth'
import TealbookLogo from 'assets/tealbook-logo.png'
import Background from 'assets/background.png'

export default function Login() {
  const { t: tGeneral } = useTranslation('general')
  const { t } = useTranslation('login')
  const notify = useNotify()

  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<EmailPassword>({
    defaultValues: {
      email: '',
      password: '',
      rememberMe: true
    }
  })
  const { field: email } = useController({
    name: 'email',
    control,
    rules: {
      required: tGeneral(`required`)
    }
  })
  const { field: rememberMe } = useController({
    name: 'rememberMe',
    control
  })

  const onLogin = async ({ email, password, rememberMe }: EmailPassword) => {
    try {
      await loginWithEmailAndPassword({ email, password, rememberMe })
    } catch (error) {
      notify({
        message: t('loginError'),
        severity: 'error'
      })
    }
  }

  const onResetPassword = async (e: any) => {
    e.preventDefault()
    if (!email.value) {
      notify({
        message: t('forgetPasswordError'),
        severity: 'error'
      })
      return
    }
    try {
      await forgetPassword(email.value)
      notify({
        message: t('checkYourEmail'),
        severity: 'info'
      })
    } catch (error) {
      notify({
        message: t('forgetPasswordError'),
        severity: 'error'
      })
    }
  }

  const onGoogleLogin = async (e: any) => {
    e.preventDefault()
    try {
      await loginWithGoogle(!!rememberMe?.value)
    } catch (error) {
      notify({
        message: t('loginError'),
        severity: 'error'
      })
    }
  }

  return (
    <Box
      sx={{
        background: `#00101d url(${Background})`,
        height: '100vh',
        width: '100vw',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Snackbar />
      <Paper>
        <Box
          component='form'
          display='flex'
          flexDirection='column'
          alignItems='center'
          rowGap='14px'
          padding='3rem'
          maxWidth='768px'
          width='480px'
        >
          <img
            style={{ width: 250, marginBottom: 20 }}
            src={TealbookLogo}
            aria-label='logo'
          ></img>
          <Typography
            variant='h6'
            sx={{ fontSize: 18, width: '100%', color: 'grey' }}
          >
            {t('signWithSSO')}
          </Typography>

          <Button
            sx={{ width: '100%' }}
            variant='contained'
            onClick={onGoogleLogin}
          >
            {t('google')}
          </Button>
          <Typography variant='h6' sx={{ fontSize: 16, width: '100%' }}>
            {t('orSignInWithEmail')}
          </Typography>
          <FormControl fullWidth>
            <FormLabel required htmlFor='email'>
              {t('email')}
            </FormLabel>
            <InputText
              required
              name='email'
              control={control}
              errors={errors.email}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mt: 3 }}>
            <FormLabel required htmlFor='password'>
              {t('password')}
            </FormLabel>
            <InputText name='password' control={control} type='password' />
          </FormControl>

          {errors && errors.email?.message && (
            <FormHelperText error>{errors.email.message}</FormHelperText>
          )}
          <Box display='flex' width='100%' alignItems='center'>
            <Controller
              control={control}
              name='rememberMe'
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  labelPlacement='end'
                  label={t('rememberMe')}
                  control={<Checkbox checked={value} onChange={onChange} />}
                />
              )}
            />
          </Box>

          <Button
            sx={{ width: '100%' }}
            variant='outlined'
            onClick={handleSubmit(onLogin)}
          >
            {t('signIn')}
          </Button>

          <Button
            sx={{ width: '100%' }}
            variant='outlined'
            onClick={onResetPassword}
          >
            {t('resetPassword')}
          </Button>
        </Box>
      </Paper>
    </Box>
  )
}
