import { RouteObject } from 'react-router-dom'
import Dashboard from './dashboard/dashboard'
import SpendReport from './report/report'
import Settings from './settings/settings'

const diversityReportRoutes: RouteObject[] = [
  {
    index: true,
    element: <Dashboard />
  },
  {
    path: 'spend-report',
    element: <SpendReport />
  },
  {
    path: 'settings',
    element: <Settings />
  }
]

export default diversityReportRoutes
