import {
  Button as MuiButton,
  ButtonProps,
  Tooltip,
  CircularProgress
} from '@mui/material'
import { useTranslation } from 'react-i18next'

import theme from 'styles/theme'

export const Button = ({
  children,
  isLoading,
  tooltip,
  ...rest
}: {
  isLoading?: boolean
  tooltip?: string
} & ButtonProps) => {
  const { t } = useTranslation('general')

  if (isLoading) {
    return (
      <MuiButton variant='contained' disabled {...rest}>
        <CircularProgress
          size={24}
          sx={{ mr: 2, color: theme.palette.grey[500] }}
          aria-label={t('loading')}
        />
        {children}
      </MuiButton>
    )
  }

  if (tooltip) {
    return (
      <Tooltip title={tooltip}>
        {/* This span is here because without it tooltips cannot appear on disabled buttons*/}
        {/* https://mui.com/material-ui/react-tooltip/#disabled-elements*/}
        <span>
          <MuiButton variant='contained' aria-haspopup {...rest}>
            {children}
          </MuiButton>
        </span>
      </Tooltip>
    )
  }

  return (
    <MuiButton variant='contained' {...rest}>
      {children}
    </MuiButton>
  )
}
