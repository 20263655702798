import { FormControlLabel, Checkbox as MuiCheckbox } from '@mui/material'

type Props = {
  option: string
  field: any
  label: string
}
export function Checkbox(props: Props) {
  return (
    <FormControlLabel
      label={props.label}
      control={
        <MuiCheckbox
          {...props.field}
          checked={props.field.value.includes(props.option)}
          onChange={e => {
            if (e.target.checked) {
              props.field.onChange([...props.field.value, props.option])
            } else {
              props.field.onChange(
                props.field.value.filter((val: string) => val !== props.option)
              )
            }
          }}
        />
      }
    />
  )
}
