import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import { useSetTitleCluster } from 'state/titleCluster'

export const NoAccessPage = () => {
  const { t } = useTranslation('general')
  useSetTitleCluster(t('noAccess'))

  return (
    <Typography sx={{ fontStyle: 'italic' }}>{t('contactAdmin')}</Typography>
  )
}
