type ApiErrorBody = {
  message?: string
  status?: number
  code?: number
  errors?: any
  responseData?: any
}

export class ApiError extends Error {
  status?: number
  code?: number
  errors?: any
  responseData?: any

  constructor(apiError: ApiErrorBody) {
    super(apiError.message)
    Object.setPrototypeOf(this, ApiError.prototype)
    this.status = apiError.status
    this.code = apiError.code
    this.errors = apiError.errors
    this.responseData = apiError.responseData
  }
}
