import useQuery from 'hooks/useQuery'
import { getPipelineById } from 'api/queries'
import queries from 'api/queries'

export const useOrgAndPipelineQuery = ({
  orgId,
  pipelineId
}: {
  orgId: string
  pipelineId: string
}) => {
  const pipelineQuery = useQuery({
    queryKey: ['pipelines', pipelineId, orgId],
    queryFn: () => getPipelineById(orgId, pipelineId),
    cacheTime: 0
  })

  const orgQuery = useQuery({
    ...queries.orgs.byId(orgId),
    enabled: !!orgId
  })

  const { data: pipeline, isLoading: isPipelineLoading } = pipelineQuery

  const { data: org, isLoading: isOrgLoading } = orgQuery
  const isLoading = isPipelineLoading || isOrgLoading

  return { pipeline, org, isLoading, orgQuery, pipelineQuery }
}
