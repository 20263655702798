import { createQueryKeys } from '@lukemorales/query-key-factory'

import api from '../api'

export const uspQueries = createQueryKeys('usp', {
  certificateSources: () => ({
    queryKey: ['certificateSources'],
    queryFn: () => getUspCertificateSources()
  })
})

export const getUspCertificateSources = async () =>
  api
    .get<{ certificate_sources: { label: string; value: string }[] }>(
      '/data/usp_metadata/certificate_sources'
    )
    .then(({ certificate_sources }) => certificate_sources)
