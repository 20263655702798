import {
  FileUpload,
  Org,
  User,
  UserGroup,
  UpdatePipelineInput,
  UpdatePipelineStatus,
  ApiCredentials,
  CreateApiCredentials,
  FileErrors,
  SaveMatchSuggestion,
  Pipeline,
  UspMatchPayload
} from 'types'
import api from './api'
import { ApiError } from './ApiError'

export const updateOrg = (updatedRow: Partial<Org>) =>
  api.put<Org>(`/accounts/org/${updatedRow.id}`, updatedRow)

export const createOrg = (newOrg: Partial<Org>) =>
  api.post<Org>(`/accounts/org`, newOrg)

export const uploadFile = async (body: FormData) => {
  let uploadResponse
  let validationErrors = undefined
  try {
    uploadResponse = await api.postWithFile<FileUpload>(
      '/data/files/upload',
      body
    )
  } catch (error) {
    if (error instanceof ApiError) {
      uploadResponse = error.responseData
      const { validations } = uploadResponse
      const fileUploadErrors = !!validations.length
        ? validations.find(
            (validation: { errors: FileErrors }) => validation.errors
          )
        : undefined
      validationErrors = fileUploadErrors?.errors
    }
  }

  return {
    file_storage_id: uploadResponse.file_storage_id,
    original_name: uploadResponse.original_name,
    errors: validationErrors
  }
}

export const runPipeline = (body: {
  org_id?: string
  pipeline_type: string
  source: { file_storage_id: string }
  pipeline_name?: string
  certificate_source?: string
}) => api.post('/data/pipelines', body)

export const updatePipeline = (
  data: UpdatePipelineInput | UpdatePipelineStatus
) => api.put<Pipeline>(`/data/pipelines/${data.pipeline_id}`, data.body)

export const createUser = (data: User) => {
  return api.post<User>(`/accounts/user`, data)
}

export const updateUser = (data: User) => {
  const { id, ...body } = data
  return api.put<User>(`/accounts/user/${id}`, body)
}

export const createUserGroup = (
  data: Omit<UserGroup, 'id' | 'organization'>
) => {
  return api.post<UserGroup>(`/accounts/permission-group`, data)
}

export const updateUserGroup = (data: Omit<UserGroup, 'organization'>) => {
  return api.put<UserGroup>(`/accounts/permission-group/${data.id}`, data)
}
export const removeUserGroup = (id: string) => {
  return api.delete<UserGroup>(`/accounts/permission-group/${id}`)
}

export const updateUserPassword = (data: {
  userId: string
  password: string
}) => {
  const { userId, password } = data
  return api.put<void>(`/accounts/user/${userId}/password`, {
    password
  })
}

export const createApiCredentials = (data: CreateApiCredentials) => {
  return api.post<ApiCredentials>(`/accounts/api-credentials`, data)
}

export const updateApiCredentials = (data: ApiCredentials) => {
  return api.put<ApiCredentials>(`/accounts/api-credentials/${data.id}`, data)
}

export const resetSecretApiCredentials = (data: ApiCredentials) => {
  return api.put<ApiCredentials>(
    `/accounts/api-credentials/${data.id}/secret`,
    data
  )
}
export const saveMatchSelection = (suggestion: SaveMatchSuggestion) =>
  api.put<SaveMatchSuggestion>(`/data/metadata/entity`, suggestion)

export const removeSecretApiCredentials = (data: ApiCredentials) => {
  return api.delete<ApiCredentials>(`/accounts/api-credentials/${data.id}`)
}

type QualificationSettings = {
  fiscalDate: Date | null
  sourceTypes: string[]
  subCategories: string[]
  agencies: string[]
}
export const saveQualificationSettings = (data: QualificationSettings) => {
  console.log('TODO: store qualification settings', data)
  return Promise.resolve(true)
}

export const saveUspMatchSelection = (
  data: UspMatchPayload & { entity_metadata_id: string }
) => {
  const { entity_metadata_id, ...payload } = data
  return api.put(
    `/data/usp_metadata/match/${entity_metadata_id}/select`,
    payload
  )
}
