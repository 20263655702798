import { useForm } from 'react-hook-form'
import {
  Button,
  MenuItem,
  Stack,
  Typography,
  Paper,
  FormControl,
  Box,
  FormLabel,
  Grid
} from '@mui/material'
import {
  createApiCredentials,
  updateApiCredentials,
  resetSecretApiCredentials,
  removeSecretApiCredentials
} from 'api/mutations'
import dayjs from 'dayjs'
import { useQueryClient } from '@tanstack/react-query'
import useNotify from 'hooks/useNotify'
import { useState } from 'react'
import useMutation from 'hooks/useMutation'
import useQuery from 'hooks/useQuery'
import { useNavigate } from 'react-router-dom'
import queries, { getApiCredentialsSecretById } from 'api/queries'
import FormSelect from 'components/Form/Select'
import FormSwitch from 'components/Form/Switch'
import { Loading } from 'components/Loading'
import InputText from 'components/Form/InputText'
import { ApiCredentials, Org } from 'types'
import { useTranslation } from 'react-i18next'
import { DeleteConfirmationDialog } from './DeleteConfirmationDialog'
import { styled } from '@mui/material/styles'
import IconButton from '@mui/material/IconButton'
import CopyAllIcon from '@mui/icons-material/CopyAll'
import { omit } from 'lodash'
import OpenEyeIcon from 'assets/openEye.svg'

const StyledField = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderRadius: theme.shape.borderRadius,
  background: 'transparent',
  borderColor: theme.palette.grey[400],
  borderStyle: 'solid',
  borderWidth: '1px',
  padding: '0px 14px',
  marginBottom: '14px'
}))

type Props = {
  apiCredentials?: ApiCredentials
  isLoading?: boolean
}

const apiCredentialsPath = '/opcon/api-credentials'

export default function ApiCredentialsFormWithOrgsData(props: Props) {
  const { data, isLoading } = useQuery(queries.orgs.all)

  if (isLoading || !data) {
    return <Loading />
  }

  return <ApiCredentialsForm orgs={data} {...props} />
}

function ApiCredentialsForm(props: Props & { orgs: Org[] }) {
  const { apiCredentials, orgs } = props
  const navigate = useNavigate()
  const { t } = useTranslation('apiCredentials')
  const { t: generalT } = useTranslation('general')
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const [secret, setSecret] = useState('')
  const queryClient = useQueryClient()
  const notify = useNotify()

  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<ApiCredentials>({
    defaultValues: {
      id: apiCredentials?.id || '',
      orgId: apiCredentials?.orgId || '',
      enabled: apiCredentials == null ? true : apiCredentials.enabled,
      title: apiCredentials?.title || '',
      description: apiCredentials?.description || ''
    }
  })

  const apiCredentialsQueryKey = 'api-credentials'
  const secretQueryKey = 'api-secret'

  const update = useMutation({
    mutationFn: updateApiCredentials,
    invalidateQueryKey: [apiCredentialsQueryKey],
    successMessage: 'ApiCredentials was successfully updated',
    onSuccess: () => navigate(apiCredentialsPath)
  })

  const create = useMutation({
    mutationFn: createApiCredentials,
    invalidateQueryKey: [apiCredentialsQueryKey],
    successMessage: 'ApiCredentials was successfully created',
    onSuccess: data => {
      navigate(`${apiCredentialsPath}/${data.id}/edit`)
    }
  })

  const resetSecret = useMutation({
    mutationFn: resetSecretApiCredentials,
    successMessage: 'ApiCredentials secret was successfully reset',
    invalidateQueryKey: [secretQueryKey, { id: apiCredentials?.id || '' }],
    onSuccess: data => {
      setSecret(data?.secret ?? '')
    }
  })

  const remove = useMutation({
    mutationFn: removeSecretApiCredentials,
    successMessage: 'ApiCredentials was successfully removed',
    invalidateQueryKey: [secretQueryKey, { id: apiCredentials?.id }],
    onSuccess: () => navigate(apiCredentialsPath)
  })

  const onSubmit = (data: ApiCredentials) => {
    let payload
    if (apiCredentials) {
      payload = {
        ...data,
        id: apiCredentials.id
      }
    } else {
      payload = {
        ...data,
        enabled: true
      }
    }
    if (!payload.id) {
      create.mutate(omit(payload, ['id']))
    } else {
      update.mutate(payload)
    }
  }

  const onDelete = () => {
    if (apiCredentials) {
      setShowDeleteConfirmation(false)
      remove.mutate(apiCredentials)
    }
  }

  const onCopyClientIdToClipboard = () => {
    if (apiCredentials?.clientId) {
      navigator.clipboard.writeText(apiCredentials.clientId)
      notify({
        message: generalT('Copied'),
        severity: 'info'
      })
    }
  }
  const onCopyClientSecretToClipboard = async () => {
    if (secret) {
      navigator.clipboard.writeText(secret)
      notify({
        message: generalT('Copied'),
        severity: 'info'
      })
    } else if (apiCredentials?.id) {
      try {
        const data = await queryClient.fetchQuery({
          queryKey: [secretQueryKey, { id: apiCredentials.id }],
          queryFn: () => getApiCredentialsSecretById(apiCredentials.id)
        })
        navigator.clipboard.writeText(data.secret)
        notify({
          message: generalT('Copied'),
          severity: 'info'
        })
      } catch (e) {
        notify({
          message: t('cannotGetSecret'),
          severity: 'error'
        })
      }
    }
  }
  const onToggleClientSecret = async () => {
    if (apiCredentials?.id != null && !secret) {
      try {
        const data = await queryClient.fetchQuery({
          queryKey: [secretQueryKey, { id: apiCredentials.id }],
          queryFn: () => getApiCredentialsSecretById(apiCredentials.id)
        })
        setSecret(data.secret)
      } catch (e) {
        notify({
          message: t('cannotGetSecret'),
          severity: 'error'
        })
      }
    }
  }

  return (
    <>
      <Stack mt={4} spacing={2} sx={{ minWidth: 380, maxWidth: 700 }}>
        <Paper sx={{ padding: 3, mb: 3 }}>
          <FormControl fullWidth sx={{ mb: 4 }} error={!!errors.title}>
            <FormLabel required htmlFor='title'>
              {t('titleColumn')}
            </FormLabel>
            <InputText
              required
              name='title'
              control={control}
              errors={errors.title}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: 4 }}>
            <FormLabel htmlFor='description'>{t('description')}</FormLabel>
            <InputText
              name='description'
              control={control}
              errors={errors.description}
            />
          </FormControl>
          {orgs.length > 0 && (
            <FormControl fullWidth sx={{ mb: 4 }} error={!!errors.orgId}>
              <FormLabel required htmlFor='orgId'>
                {generalT('orgs')}
              </FormLabel>
              <FormSelect
                name='orgId'
                control={control}
                required
                disabled={apiCredentials?.id != null}
                errors={errors.orgId}
              >
                {orgs.map(org => (
                  <MenuItem key={org.id} value={org.id}>
                    {org.name}
                  </MenuItem>
                ))}
              </FormSelect>
            </FormControl>
          )}
          <FormControl fullWidth sx={{ mb: 4 }}>
            <FormSwitch name='enabled' control={control} label='Enabled' />
          </FormControl>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Button onClick={handleSubmit(onSubmit)} variant='contained'>
                {!!apiCredentials ? generalT('save') : t('saveAndGenerateKey')}
              </Button>
              <Button onClick={() => navigate(apiCredentialsPath)}>
                {generalT('cancel')}
              </Button>
            </Grid>
            <Grid item xs={4} display={'flex'} justifyContent={'flex-end'}>
              {!!apiCredentials && (
                <Button
                  variant='delete'
                  onClick={() => setShowDeleteConfirmation(true)}
                  disabled
                >
                  {t('deleteCredentials')}
                </Button>
              )}
            </Grid>
          </Grid>
        </Paper>
        {!!apiCredentials && (
          <Paper sx={{ mb: 4 }}>
            <Stack padding={3} rowGap={4}>
              <Typography variant='h2'>{t('ApiClientSecret')}</Typography>
              <Box>
                <StyledField>
                  <Stack flexDirection='row'>
                    <FormLabel sx={{ width: 60 }}>{`${t('id')}:`}</FormLabel>
                    <div>{apiCredentials?.clientId}</div>
                  </Stack>
                  <Stack flexDirection='row'>
                    <IconButton
                      onClick={onCopyClientIdToClipboard}
                      name='copy-client-id'
                    >
                      <CopyAllIcon />
                    </IconButton>
                  </Stack>
                </StyledField>
                <StyledField>
                  <Stack flexDirection='row'>
                    <FormLabel sx={{ width: 60 }}>{`${t(
                      'secret'
                    )}:`}</FormLabel>
                    <div>{secret ? secret : '******'}</div>
                  </Stack>
                  <Stack flexDirection='row'>
                    {!secret && (
                      <IconButton onClick={onToggleClientSecret}>
                        <img src={OpenEyeIcon} aria-label='show secret' />
                      </IconButton>
                    )}
                    <IconButton
                      onClick={onCopyClientSecretToClipboard}
                      name='copy-client-secret'
                    >
                      <CopyAllIcon />
                    </IconButton>
                  </Stack>
                </StyledField>
                <Stack direction='row' spacing={2}>
                  <Button
                    variant='contained'
                    onClick={() => resetSecret.mutate(apiCredentials)}
                  >
                    {t('resetSecret')}
                  </Button>
                  <Box>{`${generalT('updated')}: ${
                    dayjs(apiCredentials.updatedAt).isValid()
                      ? dayjs(apiCredentials.updatedAt).format(
                          'YYYY-MM-DD HH:mm'
                        )
                      : '-'
                  }`}</Box>
                </Stack>
              </Box>
            </Stack>
          </Paper>
        )}
      </Stack>

      <DeleteConfirmationDialog
        open={showDeleteConfirmation}
        onClose={() => setShowDeleteConfirmation(false)}
        onConfirm={onDelete}
      />
    </>
  )
}
