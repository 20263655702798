import { Controller } from 'react-hook-form'
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider, DateView } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { useTranslation } from 'react-i18next'

type InputProps = {
  name: string
  control: any
  label: string
  views?: DateView[]
  inputFormat?: string
  minDate?: Date
  maxDate?: Date
  sx?: DatePickerProps<Date>['sx']
}

export default function InputDate({
  name,
  control,
  label,
  views,
  inputFormat,
  minDate,
  maxDate,
  sx
}: InputProps) {
  const { t } = useTranslation('general')
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: t('required')
      }}
      defaultValue={null}
      render={({ field: { onChange, value }, fieldState }) => (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label={label}
            value={value}
            onChange={onChange}
            views={views}
            format={inputFormat}
            minDate={minDate}
            maxDate={maxDate}
            sx={sx}
            slotProps={{
              textField: {
                error: !!fieldState.error?.message,
                helperText: fieldState.error?.message
              }
            }}
          />
        </LocalizationProvider>
      )}
    />
  )
}
