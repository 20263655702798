import { TableRow as MUITableRow } from '@mui/material'
import { ReactNode } from 'react'
import theme from 'styles/theme'

type Props = {
  children?: ReactNode
  variant?: 'mge'
}

export function TablePinnedRow({ children, variant }: Props) {
  return (
    <MUITableRow
      sx={{
        background: variant
          ? theme.palette[variant].lighter
          : theme.palette.common.white
      }}
    >
      {children}
    </MUITableRow>
  )
}
