import api from '../api'

import {
  ApiCredentials,
  DroppedDuplicateRecords,
  Enrichment,
  FileCorrectionsErrors,
  FileDownload,
  MatchingHistory,
  Metadata,
  NoMatch,
  Org,
  PaginationData,
  PaginationParams,
  Pipeline,
  PipelineFile,
  PipelineHistory,
  PipelineInput,
  PipelineMetadata,
  PipelineStatus,
  User,
  EnrichmentMetadataInfo,
  PipelineStats
} from 'types'

import { getOrgsByIds } from './orgs'
import { getUsersByIds } from './users'
import { searchApiCredentialsById } from './apiCredentials'
import { formatName } from 'utils/formatName'
import {
  updateAmbiguousCount,
  getMatchingDescription
} from 'utils/getMatchingDescription'

const PIPELINE = 'pipeline'
const SUPPLIER_MATCH = 'supplierMatch'

const missingInput: PipelineInput = {
  request_id: '',
  batch_id: '',
  bucket_name: '',
  buyer_id: '',
  dag_id: '',
  file_name: '',
  org_id: '',
  user_id: '',
  updated_at: '',
  original_name: ''
}

function mapPipeline(
  pipeline: Pipeline,
  orgsMap: { [orgId: string]: Org },
  usersMap: { [userId: string]: User },
  apiCredentialsMap: { [apiCredentialId: string]: ApiCredentials }
) {
  if (!pipeline.inputs) {
    pipeline.inputs = [missingInput]
  }
  const input = pipeline.inputs[0]
  const org_id = input.org_id
  const org = org_id ? orgsMap[org_id] : undefined
  const user_id = input.user_id

  const created_by = usersMap[user_id]
    ? formatName(usersMap[user_id])
    : apiCredentialsMap[user_id]?.title

  return {
    org_id,
    orgName: org ? org.name : org_id,
    createdBy: created_by || user_id,
    ...pipeline
  }
}

type PipelinesQueryParams = {
  sort_by?: 'created_at' | 'updated_at' | 'status' | 'pipeline_name'
  order?: 'desc' | 'asc'
  status?: PipelineStatus
  user_id?: string
  org_id?: string
  pipeline_name?: string
}

export const getPipelinesByPage = async function (
  params?: PaginationParams & PipelinesQueryParams
): Promise<
  PaginationData<
    Pipeline & {
      org_id: string
      orgName: string
      createdBy: string
    }
  >
> {
  const { page = 0, pageSize = 10, ...rest } = params || {}
  const offset = page * pageSize

  const queryStringParams = {
    offset,
    limit: pageSize,
    ...rest
  }

  const result = await api.get<PaginationData<Pipeline>>(
    `/data/pipelines`,
    queryStringParams
  )

  const orgIds = new Set<string>()
  const userIds = new Set<string>()
  result.data.forEach(p => {
    if (p.inputs) {
      p.inputs[0].org_id && orgIds.add(p.inputs[0].org_id)
      p.inputs[0].user_id && userIds.add(p.inputs[0].user_id)
    }
  })

  const orgs = await getOrgsByIds(Array.from(orgIds))
  const orgsMap = orgs.reduce((map: { [id: string]: Org }, org) => {
    map[org.id] = org
    return map
  }, {})

  const users = await getUsersByIds(Array.from(userIds))
  const usersMap = users.reduce((map: { [id: string]: User }, user) => {
    map[user.id] = user
    return map
  }, {})

  const apiCredentials = await searchApiCredentialsById(Array.from(userIds))
  const apiCredentialsMap = apiCredentials.reduce(
    (map: { [id: string]: ApiCredentials }, apiCredential) => {
      map[apiCredential.id] = apiCredential
      return map
    },
    {}
  )

  result.data = result.data.map(p =>
    mapPipeline(p, orgsMap, usersMap, apiCredentialsMap)
  )

  return result as PaginationData<
    Pipeline & {
      org_id: string
      orgName: string
      createdBy: string
    }
  >
}

export const getPipelineById = async (orgId: string, pipelineId: string) =>
  api.get<Pipeline>(`/data/pipelines/${pipelineId}`, { org_id: orgId })

export const getPipelineHistory = async function (
  orgId: string,
  pipelineId: string
): Promise<PipelineHistory[]> {
  const pipeline = await getPipelineById(orgId, pipelineId)
  const matching = await getMatchingHistory(orgId, pipelineId)

  // parse pipeline to history by using inputs
  const { inputs, outputs, stats } = pipeline

  const userIds = new Set<string>()
  inputs
    .filter(input => input.user_id)
    .forEach(input => userIds.add(input.user_id))
  //add userIds from matching to userIds query
  matching
    .filter(match => match.updated_by !== 'Pipeline')
    .forEach(match => userIds.add(match.updated_by))

  const users = await getUsersByIds(Array.from(userIds))
  const apiCredentials = await searchApiCredentialsById(Array.from(userIds))

  const apiCredentialsFormatted = apiCredentials.map(apiCredential => [
    apiCredential.id,
    apiCredential.title
  ])
  const usersFormatted = users.map(user => [user.id, formatName(user)])

  const combinedFormatted: readonly (readonly [string, string])[] =
    apiCredentialsFormatted
      .concat(usersFormatted)
      .map(([element1, element2]) => [element1, element2])
  const combinedMapped: Map<string, string> = new Map(combinedFormatted)

  const batchIds = inputs
    .filter(input => input.batch_id)
    .map(input => input.batch_id)

  // gather batch processed and error records
  const promiseArr: Array<Promise<PaginationData<{ metadata: Metadata }>>> = []
  batchIds.forEach(batchId => {
    // processed records
    promiseArr.push(
      api.get<PaginationData<{ metadata: Metadata }>>(
        `/data/metadata/batch/${batchId}`,
        {
          offset: 0,
          limit: 1,
          org_id: orgId
        }
      )
    )
    // errors records
    promiseArr.push(
      api.get<PaginationData<{ metadata: Metadata }>>(
        `/data/metadata/batch/${batchId}`,
        {
          offset: 0,
          limit: 1,
          expand: 'errors',
          org_id: orgId
        }
      )
    )
  })
  const result = await Promise.all(promiseArr)

  const recordCountsMap = batchIds.reduce((map, id, index) => {
    const processedRecords = result[index * 2].metadata.total_count
    const errorRecords = result[index * 2 + 1].metadata.total_count
    map.set(id, {
      processedRecords,
      errorRecords
    })
    return map
  }, new Map())

  // get batch run end time
  const batchEndTimeMap = batchIds.reduce((map, id) => {
    const batchOutputs = outputs.filter(
      output =>
        output.batch_id === id && output.dag_run_id?.startsWith('manual__')
    )
    const lastOutput = batchOutputs?.[batchOutputs.length - 1]

    // extract timestamp from dag run id
    const dag_run_id = lastOutput?.dag_run_id
    const timestamp =
      dag_run_id && dag_run_id.substring(dag_run_id.indexOf('__') + 2)
    map.set(id, timestamp)
    return map
  }, new Map())

  return parseHistory(
    inputs,
    combinedMapped,
    recordCountsMap,
    batchEndTimeMap,
    matching,
    stats
  )
}

// helper functions for parseHistory to reduce cognitive complexity
function statusToStage(status: PipelineStatus) {
  if (status === 'reviewing') {
    return 'tnv'
  } else if (status === 'complete') {
    return 'complete'
  } else {
    return ''
  }
}

function statusToDescription(status: PipelineStatus) {
  if (status === 'reviewing') {
    return 'errorsFileExported'
  } else if (status === 'complete') {
    return 'fileStatusComplete'
  } else {
    return ''
  }
}

function handleInputWithBatchId(input: PipelineInput, index: number) {
  const filename = input.original_name || input.file_name
  return {
    org_id: input.org_id,
    filename,
    file_storage_id: input.file_storage_id,
    source: filename,
    stage: 'upload',
    description: index === 0 ? 'uploadFile' : 'uploadCorrections'
  }
}

function initHistory(
  input: PipelineInput,
  pipelineStart: string,
  dupCount: number = 0,
  usersMap: Map<string, string>
) {
  const history: PipelineHistory[] = [
    {
      source: PIPELINE,
      stage: 'created',
      description: 'pipelineCreated',
      updatedAt: pipelineStart,
      updatedBy: usersMap.get(input.user_id!) || input.user_id
    }
  ]
  if (dupCount) {
    history.push({
      source: PIPELINE,
      stage: 'deduplication',
      description: 'deduplicateRecordsRemoved',
      updatedAt: pipelineStart,
      updatedBy: PIPELINE,
      values: { count: dupCount }
    })
  }

  return history
}

function sortHistory(h1: PipelineHistory, h2: PipelineHistory) {
  const sortStageOrder = ['created', 'upload', 'deduplication', 'supplierMatch']
  return sortStageOrder.includes(h1.stage) && sortStageOrder.includes(h2.stage)
    ? sortStageOrder.indexOf(h2.stage) - sortStageOrder.indexOf(h1.stage)
    : h1.updatedAt < h2.updatedAt
    ? 1
    : -1
}

async function parseHistory(
  inputs: PipelineInput[],
  usersMap: Map<string, string>,
  recordCountsMap: Map<
    string,
    { processedRecords: number; errorRecords: number }
  >,
  batchEndTimeMap: Map<string, string>,
  matching: Array<MatchingHistory>,
  stats: PipelineStats
) {
  const pipelineStart = inputs[0].updated_at!

  const history: PipelineHistory[] = initHistory(
    inputs[0],
    pipelineStart,
    stats.duplicate_rows_count,
    usersMap
  )

  let currentInputFile = ''
  let currentBatchId = ''
  // currentInputFile holds the filename the stage and change referring to.
  inputs = inputs.filter(
    input =>
      input.status !== 'readyforcorrections' &&
      input.status !== 'readyformatching'
  )
  inputs.forEach((input, index) => {
    const updatedAt = input.updated_at
    const updatedBy = usersMap.get(input.user_id) || input.user_id

    let partialHistory: Partial<PipelineHistory> = {}
    if (input.batch_id) {
      partialHistory = handleInputWithBatchId(input, index)
      currentInputFile = partialHistory.filename!
      currentBatchId = input.batch_id
    } else if (input.status) {
      partialHistory = {
        source: currentInputFile,
        stage: statusToStage(input.status),
        description: statusToDescription(input.status)
      }
    }

    if (partialHistory.source) {
      history.push({
        ...(partialHistory as PipelineHistory),
        updatedAt,
        updatedBy
      })
      if (input.batch_id) {
        history.push({
          source: PIPELINE,
          stage: 'tnv',
          updatedBy: PIPELINE,
          description: 'supplierMatchChanges',
          values: recordCountsMap.get(currentBatchId),
          updatedAt: batchEndTimeMap.get(currentBatchId) || updatedAt
        })
      }
    }
  })

  matching.forEach(group => {
    const description = getMatchingDescription(group.match_type_count)
    const updatedAt = group.dates?.sort().pop() || group.updated_at
    if (group.updated_by === 'Pipeline') {
      history.push({
        source: PIPELINE,
        stage: SUPPLIER_MATCH,
        description: description,
        values: group.match_type_count,
        updatedAt: pipelineStart,
        updatedBy: PIPELINE
      })
    } else {
      history.push({
        source: PIPELINE,
        stage: 'reviewAmbiguousMatches',
        description: description,
        values: group.match_type_count,
        updatedAt: updatedAt,
        updatedBy: usersMap.get(group.updated_by) || group.updated_by,
        match_updated_by: group.updated_by
      })
    }
  })

  return history.sort(sortHistory)
}

type MetadataByBatchParams = {
  batchId: string
  orgId: string
} & PaginationParams

export const getMetadataByBatch = async (params: MetadataByBatchParams) => {
  const { batchId, orgId, page = 0, pageSize = 10, ...rest } = params || {}
  const offset = page * pageSize
  const queryStringParams = {
    offset,
    limit: pageSize,
    org_id: orgId,
    ...rest
  }

  return api.get<PaginationData<{ metadata: Metadata }>>(
    `/data/metadata/batch/${batchId}`,
    queryStringParams
  )
}

type MetadataByPipelineParams = {
  pipelineId: string
  orgId: string
} & PaginationParams

export const getMetadataByPipeline = async (
  params: MetadataByPipelineParams
) => {
  const { pipelineId, orgId, page = 0, pageSize = 10, ...rest } = params || {}
  const offset = page * pageSize
  const queryStringParams = {
    offset,
    limit: pageSize,
    org_id: orgId,
    ...rest
  }

  return api.get<PaginationData<PipelineMetadata>>(
    `/data/metadata/pipeline/${pipelineId}`,
    queryStringParams
  )
}
export const getMatchingHistory = async (
  org_id: string,
  pipeline_id: string
) => {
  let data = await api.get<Array<MatchingHistory>>(
    `/data/metadata/pipeline/${pipeline_id}/history/matching?org_id=${org_id}`
  )
  data = data.filter(match => match.updated_by)
  return updateAmbiguousCount(data)
}

export const getFileCorrectionErrors = async (
  params: MetadataByPipelineParams
) => {
  const { pipelineId, orgId, page = 0, pageSize = 10 } = params || {}
  const offset = page * pageSize

  const { data, metadata } = await getMetadataByPipeline({
    pipelineId,
    orgId,
    page,
    pageSize,
    expand: 'errors'
  })

  return {
    data: data.map((d, i) => parseMetadataError(d.metadata, offset + i + 1)),
    metadata
  }
}

export const getFileCorrectionsErrors = (pipelineId: string) =>
  api.get<FileCorrectionsErrors>(
    `/data/metadata/pipeline/${pipelineId}/errors/count`
  )

export const getDroppedDuplicateRecords = (pipelineId: string) =>
  api.get<DroppedDuplicateRecords>(
    `/data/pipelines/${pipelineId}/dropped_duplicates`
  )

export function parseMetadataError(data: Metadata, index: number) {
  return {
    index,
    entity_id: data.entity_id,
    original_fragment_id: data.original_fragment_id,
    ...data.source_metadata?.payload,
    errors: data.errors?.flat() || []
  }
}

export const MATCH_SCENARIO = {
  AMBIGUOUS: 'AMBIGUOUS', //ready for HIL matching,
  SINGLE_MATCH: 'SINGLE_MATCH',
  NO_MATCH: 'NO_MATCH'
}
export const EXPAND = {
  ERRORS: 'errors', //expand file corrections
  MATCHES: 'matches', //expand matches
  COMPLETED_MATCHING: 'completed_matching' // single and no match by user
}

export const getNoMatchSuppliers = async function (
  params: PaginationParams & { pipelineId: string }
) {
  const { page = 0, pageSize = 10, pipelineId } = params
  const offset = page * pageSize
  return await api.get<NoMatch>(
    `/data/metadata/pipeline/${pipelineId}?offset=${offset}&limit=${pageSize}&match_scenario=${MATCH_SCENARIO.NO_MATCH}`
  )
}

export const getEnrichedMetadataByBatch = (
  params: PaginationParams & { batchId: string; orgId: string }
) => {
  const { batchId, page = 0, pageSize = 10, orgId, ...rest } = params
  const offset = page * pageSize
  const queryStringParams = {
    offset,
    limit: pageSize,
    ...rest
  }

  return api.get<PaginationData<Enrichment>>(
    `/data/metadata/batch/${batchId}/enriched`,
    { org_id: orgId, ...queryStringParams }
  )
}

export const getEnrichedMetadataByPipeline = (
  params: PaginationParams & { pipelineId: string; orgId: string }
) => {
  const { pipelineId, page = 0, pageSize = 10, orgId, ...rest } = params
  const offset = page * pageSize
  const queryStringParams = {
    offset,
    limit: pageSize,
    ...rest
  }

  return api.get<PaginationData<Enrichment>>(
    `/data/metadata/pipeline/${pipelineId}/enriched`,
    { org_id: orgId, ...queryStringParams }
  )
}

export const downloadPipelineFile = async (file: FileDownload) => {
  const result = await api.get<PipelineFile>(
    `/data/files/${file.file_storage_id}/download?org_id=${file.org_id}`
  )
  return api.download(result.bucket.signed_url, file.fileName)
}

export const getEnrichedMetadataFileByPipeline = async (
  params: PaginationParams & {
    pipelineId: string
    orgId: string
    type: string
    interval: string
    newFileName: string
  }
) => {
  const {
    pipelineId,
    page = 0,
    pageSize = 10,
    orgId,
    newFileName,
    ...rest
  } = params
  const offset = page * pageSize
  const queryStringParams = {
    offset,
    limit: pageSize,
    ...rest
  }
  const result = await api.get<PipelineFile>(
    `/data/metadata/pipeline/${pipelineId}/enriched/export`,
    { org_id: orgId, ...queryStringParams }
  )
  const fileName = `${newFileName}.${
    result.bucket.file_name?.split('.')[1] || 'csv'
  }`
  return api.download(result.bucket.signed_url, fileName)
}

export const getEnrichedMetadataInfo = (params: {
  pipelineId: string
  orgId: string
}) => {
  const { pipelineId, orgId } = params
  return api.get<EnrichmentMetadataInfo>(
    `/data/metadata/pipeline/${pipelineId}/enriched/info`,
    { org_id: orgId }
  )
}
