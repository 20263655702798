export const HighTrustScoreIcon = () => (
  <svg
    width='25'
    height='25'
    viewBox='0 0 15 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M2.41667 9.3335H1.75C1.19772 9.3335 0.75 9.78121 0.75 10.3335V12.3335C0.75 12.8858 1.19772 13.3335 1.75 13.3335H2.41667C2.96895 13.3335 3.41667 12.8858 3.41667 12.3335V10.3335C3.41667 9.78121 2.96895 9.3335 2.41667 9.3335Z'
      fill='#028383'
    />
    <path
      d='M7.75065 4.6665H7.08398C6.5317 4.6665 6.08398 5.11422 6.08398 5.6665V12.3332C6.08398 12.8855 6.5317 13.3332 7.08399 13.3332H7.75065C8.30294 13.3332 8.75065 12.8855 8.75065 12.3332V5.6665C8.75065 5.11422 8.30294 4.6665 7.75065 4.6665Z'
      fill='#028383'
    />
    <path
      d='M13.0827 0H12.416C11.8637 0 11.416 0.447714 11.416 0.999999V12.3333C11.416 12.8856 11.8637 13.3333 12.416 13.3333H13.0827C13.635 13.3333 14.0827 12.8856 14.0827 12.3333V1C14.0827 0.447715 13.635 0 13.0827 0Z'
      fill='#028383'
    />
  </svg>
)

export const MediumTrustScoreIcon = () => (
  <svg
    width='25'
    height='25'
    viewBox='0 0 15 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M2.41667 9.3335H1.75C1.19772 9.3335 0.75 9.78121 0.75 10.3335V12.3335C0.75 12.8858 1.19772 13.3335 1.75 13.3335H2.41667C2.96895 13.3335 3.41667 12.8858 3.41667 12.3335V10.3335C3.41667 9.78121 2.96895 9.3335 2.41667 9.3335Z'
      fill='#E1AA2E'
    />
    <path
      d='M7.75065 4.6665H7.08398C6.5317 4.6665 6.08398 5.11422 6.08398 5.6665V12.3332C6.08398 12.8855 6.5317 13.3332 7.08399 13.3332H7.75065C8.30294 13.3332 8.75065 12.8855 8.75065 12.3332V5.6665C8.75065 5.11422 8.30294 4.6665 7.75065 4.6665Z'
      fill='#E1AA2E'
    />
    <path
      d='M13.0827 0H12.416C11.8637 0 11.416 0.447714 11.416 0.999999V12.3333C11.416 12.8856 11.8637 13.3333 12.416 13.3333H13.0827C13.635 13.3333 14.0827 12.8856 14.0827 12.3333V1C14.0827 0.447715 13.635 0 13.0827 0Z'
      fill='#6F8282'
    />
  </svg>
)

export const LowTrustScoreIcon = () => (
  <svg
    width='25'
    height='25'
    viewBox='0 0 15 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M2.41667 9.3335H1.75C1.19772 9.3335 0.75 9.78121 0.75 10.3335V12.3335C0.75 12.8858 1.19772 13.3335 1.75 13.3335H2.41667C2.96895 13.3335 3.41667 12.8858 3.41667 12.3335V10.3335C3.41667 9.78121 2.96895 9.3335 2.41667 9.3335Z'
      fill='#FF6A3B'
    />
    <path
      d='M7.75065 4.6665H7.08398C6.5317 4.6665 6.08398 5.11422 6.08398 5.6665V12.3332C6.08398 12.8855 6.5317 13.3332 7.08399 13.3332H7.75065C8.30294 13.3332 8.75065 12.8855 8.75065 12.3332V5.6665C8.75065 5.11422 8.30294 4.6665 7.75065 4.6665Z'
      fill='#6F8282'
    />
    <path
      d='M13.0827 0H12.416C11.8637 0 11.416 0.447714 11.416 0.999999V12.3333C11.416 12.8856 11.8637 13.3333 12.416 13.3333H13.0827C13.635 13.3333 14.0827 12.8856 14.0827 12.3333V1C14.0827 0.447715 13.635 0 13.0827 0Z'
      fill='#6F8282'
    />
  </svg>
)

export const NoTrustScoreIcon = () => (
  <svg
    width='25'
    height='26'
    viewBox='0 0 15 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M2.16573 10.5811H1.5C0.947715 10.5811 0.5 11.0288 0.5 11.5811V13.58C0.5 14.1323 0.947715 14.58 1.5 14.58H2.16573C2.71802 14.58 3.16573 14.1323 3.16573 13.58V11.5811C3.16573 11.0288 2.71802 10.5811 2.16573 10.5811Z'
      fill='#C1CCCC'
    />
    <path
      d='M7.49972 5.91553H6.83398C6.2817 5.91553 5.83398 6.36324 5.83398 6.91553V13.5799C5.83398 14.1322 6.2817 14.5799 6.83398 14.5799H7.49972C8.052 14.5799 8.49972 14.1322 8.49972 13.5799V6.91553C8.49972 6.36324 8.052 5.91553 7.49972 5.91553Z'
      fill='#C1CCCC'
    />
    <path
      d='M12.8298 1.25H12.1641C11.6118 1.25 11.1641 1.69771 11.1641 2.25V13.5799C11.1641 14.1322 11.6118 14.5799 12.1641 14.5799H12.8298C13.3821 14.5799 13.8298 14.1322 13.8298 13.5799V2.25C13.8298 1.69772 13.3821 1.25 12.8298 1.25Z'
      fill='#C1CCCC'
    />
    <path
      d='M0.544922 1.24536L13.98 14.6804'
      stroke='#576666'
      strokeLinecap='round'
    />
  </svg>
)

export const PartialHighIcon = () => (
  <svg
    width='25'
    height='25'
    viewBox='0 0 15 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M2.01628 9.3335H1.34961C0.797325 9.3335 0.349609 9.78121 0.349609 10.3335V12.3335C0.349609 12.8858 0.797325 13.3335 1.34961 13.3335H2.01628C2.56856 13.3335 3.01628 12.8858 3.01628 12.3335V10.3335C3.01628 9.78121 2.56856 9.3335 2.01628 9.3335Z'
      fill='#028383'
    />
    <path
      d='M7.35026 4.6665H6.68359C6.13131 4.6665 5.68359 5.11422 5.68359 5.6665V12.3332C5.68359 12.8855 6.13131 13.3332 6.68359 13.3332H7.35026C7.90255 13.3332 8.35026 12.8855 8.35026 12.3332V5.6665C8.35026 5.11422 7.90254 4.6665 7.35026 4.6665Z'
      fill='#028383'
    />
    <path
      d='M12.6823 0H12.0156C11.4633 0 11.0156 0.447714 11.0156 0.999999V12.3333C11.0156 12.8856 11.4633 13.3333 12.0156 13.3333H12.6823C13.2346 13.3333 13.6823 12.8856 13.6823 12.3333V1C13.6823 0.447715 13.2346 0 12.6823 0Z'
      fill='#6F8282'
    />
  </svg>
)
