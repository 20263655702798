import { useSetRecoilState } from 'recoil'
import { useCallback } from 'react'
import { notificationsState, Notification } from '../state/notifications'

const useNotify = () => {
  const setNotifications = useSetRecoilState(notificationsState)
  return useCallback(
    (newNotification: Omit<Notification, 'key'>) => {
      setNotifications({
        key: new Date().getTime(),
        ...newNotification
      })
    },
    [setNotifications]
  )
}

export default useNotify
