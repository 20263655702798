import {
  BreFormData,
  BreFormRow,
  BreConfigResponse,
  DatapackConfig,
  DatapackConfigItem,
  DataScenario,
  TrustScore,
  BreConfigRules
} from 'types'
import { Stack, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { FormRow } from './FormRow'
import { useState } from 'react'
import {
  breConfigRulesToFormData,
  formDataToBreConfigRules
} from './breDataMappers'
import dottie from 'dottie'
import { BreFormActionBar } from './BreFormActionBar'
import useNotify from 'hooks/useNotify'
import { WarningPrompt } from 'components/Dialog/WarningPrompt'
import { AttributesInfo, RecommendationInfo } from './BreFromInfoSection'
import { unstable_useBlocker as useBlocker } from 'react-router-dom'

type BreFormProps = {
  breConfig: BreConfigResponse
  dataPackConfig: DatapackConfig
  onFormSubmit: (data: BreConfigRules) => void
}

export type ScenarioSelectParams = {
  section: string
  field: string
  selectedScenario: DataScenario
  type?: string
}

export type BreFormSectionProps = {
  title: string
  rows: Record<string, BreFormRow> | Record<string, Record<string, BreFormRow>>
  datapackFields: DatapackConfigItem | DatapackConfigItem[]
  onDataScenarioSelect: (params: ScenarioSelectParams) => void
  onTrustScoreSelect: (
    params: ScenarioSelectParams & { trustScore: TrustScore }
  ) => void
  filterOnType?: boolean
  hasVeryHighTrustScore?: boolean
}

const BreFormSection = ({
  title,
  rows,
  datapackFields,
  onTrustScoreSelect,
  onDataScenarioSelect,
  filterOnType,
  hasVeryHighTrustScore
}: BreFormSectionProps) => {
  const { t } = useTranslation('bre', { keyPrefix: 'sections' })

  const filterAttributeFieldsByDatapack = ([fieldName, _]: [
    string,
    BreFormRow
  ]): boolean => {
    const fields = Array.isArray(datapackFields)
      ? datapackFields[0].fields
      : datapackFields.fields
    return fields.includes(fieldName)
  }

  const filterTypedFieldsByDatapack = ([typeName, _]: [string, BreFormRow]) => {
    const types = Array.isArray(datapackFields)
      ? datapackFields.map(item => item.type)
      : Array.of(datapackFields.type)

    return types.includes(typeName)
  }

  return (
    <>
      <Typography padding='1rem 1.25rem' fontWeight='bold'>
        {t(title)}
      </Typography>
      {Object.entries(rows)
        .sort((a, b) => b[0].localeCompare(a[0]))
        .filter(
          filterOnType
            ? filterTypedFieldsByDatapack
            : filterAttributeFieldsByDatapack
        )
        .map(([field, config]) => (
          <FormRow
            section={title}
            field={field}
            config={config}
            onDataScenarioSelect={onDataScenarioSelect}
            onTrustScoreSelect={onTrustScoreSelect}
            key={`${title}.${field}`}
            hasVeryHighTrustScore={hasVeryHighTrustScore}
          />
        ))}
    </>
  )
}

export const BreForm = ({
  breConfig: { rules },
  onFormSubmit,
  dataPackConfig
}: BreFormProps) => {
  const { palette } = useTheme()
  const notify = useNotify()
  const { t } = useTranslation('bre')

  const [formData, setFormData] = useState<BreFormData>({
    ...breConfigRulesToFormData(rules)
  })
  const [hasFormChanges, setHasFormChanges] = useState(false)
  const blocker = useBlocker(hasFormChanges)

  const handleDataScenarioSelect = ({
    selectedScenario,
    section,
    type,
    field
  }: ScenarioSelectParams) => {
    const updatedFormData = { ...formData }

    dottie.set(
      updatedFormData,
      type
        ? [section, type, field, 'selectedScenario']
        : [section, field, 'selectedScenario'],
      selectedScenario
    )

    setFormData(updatedFormData)

    if (!hasFormChanges) {
      setHasFormChanges(true)
    }
  }

  const onTrustScoreSelect = ({
    section,
    field,
    type,
    selectedScenario,
    trustScore
  }: ScenarioSelectParams & {
    trustScore: TrustScore
  }) => {
    let scenarioToUpdate = selectedScenario

    const updatedFormData = { ...formData }
    const path = type ? [section, type, field] : [section, field]

    if (
      selectedScenario === 'unprovided' &&
      dottie.get(updatedFormData, [...path, 'configs', 'dissimilar']) ===
        trustScore
    ) {
      dottie.set(updatedFormData, [...path, 'selectedScenario'], 'always')
      scenarioToUpdate = 'always'
    }

    if (
      selectedScenario === 'dissimilar' &&
      dottie.get(updatedFormData, [...path, 'configs', 'unprovided']) ===
        trustScore
    ) {
      dottie.set(updatedFormData, [...path, 'selectedScenario'], 'always')
      scenarioToUpdate = 'always'
    }

    dottie.set(
      updatedFormData,
      [...path, 'configs', scenarioToUpdate],
      trustScore
    )

    dottie.set(
      updatedFormData,
      [...path, 'configs', selectedScenario],
      trustScore
    )

    setFormData(updatedFormData)

    if (!hasFormChanges) {
      setHasFormChanges(true)
    }
  }

  const handleFormSubmit = () => {
    try {
      if (hasFormChanges) {
        setHasFormChanges(false)
      }
      onFormSubmit(formDataToBreConfigRules(formData))
    } catch (error) {
      notify({
        message: t('saveDataError'),
        severity: 'error'
      })
    }
  }

  return (
    <>
      <WarningPrompt
        openPrompt={blocker.state === 'blocked'}
        onConfirmNavigation={() => {
          if (blocker.state === 'blocked') {
            blocker.proceed()
          }
        }}
        onCancelNavigation={() =>
          blocker.state === 'blocked' && blocker.reset()
        }
      />

      <Stack
        direction='column'
        borderRadius='4px'
        overflow='hidden'
        border={`1px solid ${palette.grey['500']}`}
      >
        <BreFormActionBar onFormSubmit={handleFormSubmit} />
        <RecommendationInfo
          showVeryHigh={
            !!formData.certifications && !!dataPackConfig.certification
          }
        />
        <AttributesInfo />
        {formData.supplier && dataPackConfig.supplier && (
          <BreFormSection
            title='supplier'
            rows={formData.supplier}
            datapackFields={dataPackConfig.supplier}
            onDataScenarioSelect={handleDataScenarioSelect}
            onTrustScoreSelect={onTrustScoreSelect}
          />
        )}
        {formData.address && dataPackConfig.address && (
          <BreFormSection
            title='address'
            rows={formData.address}
            datapackFields={dataPackConfig.address}
            onDataScenarioSelect={handleDataScenarioSelect}
            onTrustScoreSelect={onTrustScoreSelect}
            filterOnType
          />
        )}
        {formData.certifications && dataPackConfig.certification && (
          <BreFormSection
            title='certifications'
            rows={formData.certifications}
            datapackFields={dataPackConfig.certification}
            onDataScenarioSelect={handleDataScenarioSelect}
            onTrustScoreSelect={onTrustScoreSelect}
            filterOnType
            hasVeryHighTrustScore
          />
        )}
      </Stack>
    </>
  )
}
