import { useTranslation } from 'react-i18next'
import SideNavLink, {
  NestedSideNavLinkProps
} from 'components/Sidebar/SideNavLink'
import EqualizerIcon from '@mui/icons-material/Equalizer'

const DiversitySideNav = ({ isSidebarOpen }: { isSidebarOpen: boolean }) => {
  const { t } = useTranslation('apps')

  const diversitySubNavList: NestedSideNavLinkProps[] = [
    {
      text: t('diversity.dashboard'),
      to: '/diversity-report',
      permissions: [],
      open: true
    },
    {
      text: t('diversity.spendReport'),
      to: '/diversity-report/spend-report',
      permissions: [],
      open: true
    },
    {
      text: t('diversity.settings.pageTitle'),
      to: '/diversity-report/settings',
      permissions: [],
      open: true
    }
  ]

  return (
    <SideNavLink
      text={t('diversityReporting')}
      pathTo='/diversity-report'
      icon={<EqualizerIcon />}
      isSidebarOpen={isSidebarOpen}
      subNavList={diversitySubNavList}
    />
  )
}

export default DiversitySideNav
