import { exportCSV } from 'utils/exportData'

export function exportVMFile(data: any[], name: string, id: string) {
  // find out max number of error headers needed if any, and append to the end of the columns
  const maxErrors = data.reduce(
    (max, d) => (d.errors?.length > max ? d.errors.length : max),
    0
  )
  const errorsLoop = Array.from(Array(maxErrors).keys())

  const extractedData: Array<{ [key: string]: string }> = data.map(d => {
    const { errors, ...rest } = d
    return {
      'Internal Supplier ID Source':
        rest.internal_source_name || rest.internal_supplier_id_source,
      'Internal Supplier ID or Vendor Number': rest.internal_supplier_id,
      'Company Name': rest.company_name,
      'Complete Full Address': rest.complete_address,
      'Address (Street Name and Number)': `${
        rest.street_number ? rest.street_number : ''
      } ${rest.street_name ? rest.street_name : ''}`,
      'Address (City)': rest.city,
      'Address (State/Province)': rest.state,
      'Address (Country)': rest.country,
      'Address (ZIP Code / Postal Code)': rest.postal || rest.pcode,
      'Tax ID': rest.taxpayer_id,
      Website: rest.website_url || rest.domain,
      'Contact Email': rest.contact_email,
      'Diversity Status': rest.diversity_status,
      'Preferred Supplier Status': rest.preferred_supplier_status,
      'NAICS Code': rest.naics,
      DUNS: rest.duns_id,
      LEI: rest.lei,
      'Head Count': rest.head_count,
      Original_Fragment_Id: rest.original_fragment_id,
      ...errorsLoop?.reduce(
        (result: { [key: string]: string }, count: number) => {
          const name = errors && errors[count] && errors[count].error_name
          const reason = errors && errors[count] && errors[count].error_reason
          return {
            ...result,
            [`error_${count + 1}_name`]: name,
            [`error_${count + 1}_reason`]: reason
          }
        },
        {}
      )
    }
  })

  exportCSV(extractedData || [], `${name}-for-${id}`)
}
