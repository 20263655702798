import { useTranslation } from 'react-i18next'
import useQuery from 'hooks/useQuery'
import { useParams } from 'react-router-dom'

import { getApiCredentialsById } from 'api/queries'
import ApiCredentialsForms from '../(shared)/ApiCredentialsForms'
import { useSetTitleCluster } from 'state/titleCluster'
import { Loading } from 'components/Loading'

type MyParams = {
  apiCredentialsId: string
}

export default function EditUser() {
  const { t } = useTranslation('apiCredentials')
  const { apiCredentialsId } = useParams() as MyParams

  useSetTitleCluster(t('editApiCredentials'), [
    {
      link: '/opcon/api-credentials',
      label: t('title')
    }
  ])

  const { data: apiCredentials, isLoading } = useQuery({
    queryKey: ['api-credentials', { id: apiCredentialsId }],
    queryFn: () => getApiCredentialsById(apiCredentialsId)
  })

  if (isLoading) return <Loading />

  return <ApiCredentialsForms apiCredentials={apiCredentials} />
}
