import { atom, useRecoilValue } from 'recoil'

export type Session = {
  user: {
    email: string
    firstName: string
    lastName: string
    orgName?: string
  }
  isAuthenticated: boolean
  isStatusKnown: boolean
  userId: string
  orgId: string
  permissions: string[]
}

export const logoffSession: Session = {
  user: {
    email: '',
    firstName: '',
    lastName: ''
  },
  isAuthenticated: false,
  isStatusKnown: true,
  permissions: [],
  userId: '',
  orgId: ''
}

export const sessionState = atom<Session>({
  key: 'session',
  default: {
    ...logoffSession,
    isStatusKnown: false
  }
})

export const useSession = () => useRecoilValue(sessionState)
