import { useNavigate } from 'react-router-dom'
import { createOrg } from 'api/mutations'
import { useTranslation } from 'react-i18next'
import { FieldValues } from 'react-hook-form'
import { OrgsForm } from '../(shared)/OrgForms'
import { Org } from 'types'
import { useSetTitleCluster } from 'state/titleCluster'
import useMutation from 'hooks/useMutation'
import useQuery from 'hooks/useQuery'
import { getAllDatapacks } from 'api/queries'

export const OrgCreate = () => {
  const navigate = useNavigate()
  const { t } = useTranslation('orgs')

  const { data: datapacks = [] } = useQuery({
    queryKey: ['datapacks'],
    queryFn: getAllDatapacks
  })

  useSetTitleCluster(t('createNewClient'), [
    {
      link: '/opcon/orgs',
      label: t('title')
    }
  ])

  const saveNewOrg = useMutation({
    mutationFn: createOrg,
    invalidateQueryKey: ['orgs'],
    successMessage: t('createSuccess'),
    onSuccess: data => navigate(`/opcon/orgs/edit/${data.id}`)
  })
  const onSubmit = (data: FieldValues) => {
    const newOrg: Partial<Org> = {
      name: data.orgName,
      orgTypes: data.orgTypes,
      classicOrgId: data.classicOrgId === '' ? null : data.classicOrgId,
      datapacks: Array.from(data.datapacks)
    }
    saveNewOrg.mutate(newOrg)
  }

  return (
    <OrgsForm
      datapacks={datapacks.sort((a, b) => a.tag.localeCompare(b.tag))}
      onSubmit={onSubmit}
      defaultValues={{
        orgTypes: [],
        orgName: '',
        classicOrgId: '',
        datapacks: new Set(
          datapacks
            ?.filter(datapack => datapack.tag === 'company_firmographics')
            .map(datapack => datapack.tag)
        )
      }}
      disableCustomizeBreButton
    />
  )
}
