import { Control, FieldError, useController } from 'react-hook-form'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import { useTranslation } from 'react-i18next'

type InputProps = TextFieldProps & {
  name: string
  control: Control<any>
  label?: string
  errors?: FieldError
  pattern?: {
    value: RegExp
    message: string
  }
  required?: boolean
  placeholder?: string
}
export default function InputText({
  name,
  control,
  label,
  errors,
  pattern,
  required,
  placeholder,
  ...rest
}: InputProps) {
  const { t } = useTranslation('general')
  const { field } = useController({
    name,
    control,
    rules: {
      required: required && t(`required`),
      pattern: pattern
    }
  })

  return (
    <TextField
      {...field}
      {...rest}
      id={name}
      error={!!errors}
      helperText={errors?.message || null}
      onChange={field.onChange}
      value={field.value}
      label={label}
      variant='outlined'
      size='small'
      required={required}
      placeholder={placeholder}
    />
  )
}
