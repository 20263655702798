import { ReactElement } from 'react'
import { Box, Divider, Stack, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import {
  HighTrustScoreIcon,
  LowTrustScoreIcon,
  MediumTrustScoreIcon,
  NoTrustScoreIcon,
  PartialHighIcon
} from './TrustScoreGraphic'

const TrustScoreItem = (props: {
  icon: ReactElement
  title: string
  sub: string
}) => {
  const theme = useTheme()
  return (
    <Stack
      key={props.title}
      sx={{
        padding: theme.spacing(2.75, 1.25),
        textAlign: 'center',
        width: theme.spacing(20)
      }}
    >
      <Box>{props.icon}</Box>
      <Typography
        variant={'h3'}
        sx={{
          fontSize: theme.typography.body2,
          fontWeight: 'bold',
          lineHeight: theme.spacing(2),
          marginTop: theme.spacing(0.75)
        }}
      >
        {props.title}
        <br />
        {props.sub}
      </Typography>
    </Stack>
  )
}

const ScenarioComponent = (props: { title: string; description: string }) => {
  const theme = useTheme()

  return (
    <Box width={'22%'}>
      <Typography
        variant='h3'
        sx={{
          fontSize: theme.typography.body1,
          fontWeight: theme.typography.fontWeightBold
        }}
      >
        {props.title}
        <br />
      </Typography>
      <Typography variant='caption'>{props.description}</Typography>
    </Box>
  )
}

export type RecommendationInfoProps = {
  showVeryHigh?: boolean
}

export const RecommendationInfo = ({
  showVeryHigh
}: RecommendationInfoProps) => {
  const { t } = useTranslation('bre')
  const theme = useTheme()

  return (
    <Stack
      direction='row'
      spacing='2.5rem'
      alignItems='center'
      justifyContent='space-between'
      padding={theme.spacing(3, 5)}
      bgcolor={theme.palette.grey[100]}
      borderBottom={`2px solid ${theme.palette.common.white}`}
    >
      <Box>
        <Typography
          variant='h3'
          sx={{
            fontSize: theme.typography.h5,
            fontWeight: theme.typography.fontWeightBold,
            marginBottom: theme.spacing(1)
          }}
        >
          {t('recommendationInfo.title')}
        </Typography>
        <Typography variant='body1'>
          {t('recommendationInfo.description')}
        </Typography>
      </Box>
      <Box flexGrow={2} maxWidth='40rem'>
        <Stack
          direction='row'
          alignContent='stretch'
          divider={
            <Divider
              orientation='vertical'
              sx={{ borderRight: `${theme.palette.grey[200]} 2px solid` }}
              flexItem
            />
          }
          sx={{
            background: theme.palette.background.paper,
            borderRadius: theme.spacing(1)
          }}
        >
          {showVeryHigh && (
            <TrustScoreItem
              icon={<HighTrustScoreIcon />}
              title={t('trustScoreLevels.very_high')}
              sub={t('trustScore')}
            />
          )}
          <TrustScoreItem
            icon={showVeryHigh ? <PartialHighIcon /> : <HighTrustScoreIcon />}
            title={t('trustScoreLevels.high')}
            sub={t('trustScore')}
          />
          <TrustScoreItem
            icon={<MediumTrustScoreIcon />}
            title={t('trustScoreLevels.medium')}
            sub={t('trustScore')}
          />
          <TrustScoreItem
            icon={<LowTrustScoreIcon />}
            title={t('trustScoreLevels.low')}
            sub={t('trustScore')}
          />
          <TrustScoreItem
            icon={<NoTrustScoreIcon />}
            title={t('no')}
            sub={t('trustScore')}
          />
        </Stack>
      </Box>
    </Stack>
  )
}

export const AttributesInfo = () => {
  const { t } = useTranslation('bre')
  const theme = useTheme()

  return (
    <Stack
      bgcolor={theme.palette.grey[100]}
      padding={theme.spacing(2, 5, 3, 5)}
      direction='row'
      justifyContent='space-between'
    >
      <Typography
        variant='h3'
        sx={{
          minWidth: '35%',
          fontSize: theme.typography.body1,
          fontWeight: theme.typography.fontWeightBold
        }}
      >
        {t('attributesInfo.title')}
      </Typography>
      <Stack direction='row' gap='1rem' justifyContent='space-between'>
        <ScenarioComponent
          title={t('attributesInfo.alwaysProvide.title')}
          description={t('attributesInfo.alwaysProvide.description')}
        />
        <ScenarioComponent
          title={t('attributesInfo.dissimilar.title')}
          description={t('attributesInfo.dissimilar.description')}
        />
        <ScenarioComponent
          title={t('attributesInfo.unprovided.title')}
          description={t('attributesInfo.unprovided.description')}
        />
        <ScenarioComponent
          title={t('attributesInfo.never.title')}
          description={t('attributesInfo.never.description')}
        />
      </Stack>
    </Stack>
  )
}
