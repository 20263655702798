const w: any = window

export type UserInfo = {
  userId: string
  firstName: string
  lastName: string
  email: string
  orgId: string
  orgName?: string
}

const identifyUser = (userInfo: UserInfo) => {
  if (w.analytics) {
    w.analytics.identify(userInfo.userId, userInfo)
    w.analytics.group(userInfo.orgId, {
      $name: userInfo.orgName,
      orgId: userInfo.orgId
    })
  }
}

const page = (category?: string, name?: string, properties?: object) => {
  if (w.analytics) {
    w.analytics.page(category, name, properties)
  }
}

const track = (event: string, properties?: { [key: string]: any }) => {
  if (w.analytics) {
    w.analytics.track(event, properties)
  }
}

const logout = () => {
  if (w.analytics) {
    w.analytics.reset()
  }
}

const analytics = {
  identifyUser,
  page,
  track,
  logout
}

export default analytics
