import startCase from 'lodash.startcase'
import FileSaver from 'file-saver'

const formatRows = (
  arrayData: Array<{ [key: string]: string | Array<string> }>,
  delimiter: string
) => {
  let CSV = ''
  arrayData.forEach(element => {
    let row = ''
    Object.keys(element).forEach(key => {
      let value: string = ''
      if (typeof element[key] === 'string') {
        value = element[key] as string
      } else if (Array.isArray(element[key])) {
        value = (element[key] as Array<string>).join(
          key === 'classificationCodes' ? '|' : delimiter
        )
      } else if (element[key]) {
        value = element[key] as string
      }
      row += `"${value}",`
    })
    row = row.slice(0, -1)
    CSV += row + '\r\n'
  })
  return CSV
}

export const exportCSV = (
  jsonData: Array<object> | string,
  fileName: string,
  showLabel: boolean = true,
  type: string = 'csv',
  delimiter: string = ', '
) => {
  const arrayData: Array<{ [key: string]: string | Array<string> }> =
    typeof jsonData !== 'object' ? JSON.parse(jsonData) : jsonData
  let CSV = ''
  if (!!arrayData.length) {
    if (showLabel) {
      let row = ''
      Object.keys(arrayData[0]).forEach(key => {
        row += startCase(key) + ','
      })
      row = row.slice(0, -1)
      CSV += row + '\r\n'
    }
    CSV += formatRows(arrayData, delimiter)
  }
  const blob = new Blob([CSV], { type: `text/${type};charset=utf-8` })
  FileSaver.saveAs(blob, `${fileName || 'untitled'}.${type}`)
}
