import useQuery from 'hooks/useQuery'
import { getFileCorrectionErrors, ambiguousMatchesQueries } from 'api/queries'
import { Pipeline } from 'types'

export const useCheckActionRequiredQuery = (
  pipeline: Pipeline | undefined,
  pipelineId: string,
  orgId: string
) => {
  const { data: tnvCorrections } = useQuery({
    queryKey: ['metadata', 'errors', 'tnv', orgId, pipelineId],
    queryFn: () =>
      getFileCorrectionErrors({
        page: 0,
        pageSize: 10,
        pipelineId,
        orgId
      }),
    enabled: !!pipeline && pipeline.pipeline_type !== 'common_certification'
  })

  const { data: ambiguousMatches } = useQuery({
    ...ambiguousMatchesQueries.ambiguousMatchesMetadata(orgId, pipelineId),
    enabled: !!pipeline && pipeline.pipeline_type !== 'common_certification'
  })

  const { data: certMgeAmbiguousMatches } = useQuery({
    ...ambiguousMatchesQueries.uspAmbiguousMatchesOverview(pipelineId, 0, 0),
    enabled: !!pipeline && pipeline.pipeline_type === 'common_certification'
  })

  // consolidate ambiguous match total_count
  const consolidateTotalCount =
    (ambiguousMatches?.metadata.total_count || 0) +
    (certMgeAmbiguousMatches?.metadata.total_count || 0)

  return {
    tnvCorrections,
    ambiguousMatches: { metadata: { total_count: consolidateTotalCount } }
  }
}
