import { useMemo } from 'react'

import { useTranslation } from 'react-i18next'

import AirIcon from '@mui/icons-material/Air'
import PersonIcon from '@mui/icons-material/Person'
import BusinessIcon from '@mui/icons-material/Business'
import WebhookIcon from '@mui/icons-material/Webhook'

import SideNavLink, {
  NestedSideNavLinkProps
} from 'components/Sidebar/SideNavLink'
import { PERMISSION_SETS } from 'hooks/usePermissions'
import { RequirePermission } from 'providers/RequirePermission'

const SideNav = ({ isSidebarOpen }: { isSidebarOpen: boolean }) => {
  const { t } = useTranslation('general')

  const pipelinesSubNavList = useMemo<
    Array<Omit<NestedSideNavLinkProps, 'open'>>
  >(
    () => [
      {
        text: t('pipeline-runner'),
        to: '/opcon/pipelines/runner',
        permissions: PERMISSION_SETS.MANAGE_ALL_PIPELINES
      }
    ],

    [t]
  )

  const usersSubNavList = useMemo<Array<Omit<NestedSideNavLinkProps, 'open'>>>(
    () => [
      {
        text: t('users'),
        to: '/opcon/users',
        permissions: PERMISSION_SETS.READ_USERS
      },
      {
        text: t('userGroups'),
        to: '/opcon/user-groups',
        permissions: PERMISSION_SETS.READ_USER_GROUPS
      }
    ],
    [t]
  )

  return (
    <>
      <RequirePermission permissions={PERMISSION_SETS.READ_PIPELINES}>
        <SideNavLink
          text={t('pipelines')}
          pathTo='/opcon/pipelines/list'
          icon={<AirIcon />}
          isSidebarOpen={isSidebarOpen}
          subNavList={pipelinesSubNavList}
        />
      </RequirePermission>

      <RequirePermission permissions={PERMISSION_SETS.READ_USERS}>
        <SideNavLink
          text={t('userManagement')}
          pathTo='/opcon/users'
          icon={<PersonIcon />}
          isSidebarOpen={isSidebarOpen}
          subNavList={usersSubNavList}
        />
      </RequirePermission>
      <RequirePermission permissions={PERMISSION_SETS.READ_CLIENTS}>
        <SideNavLink
          text={t('orgs')}
          pathTo='/opcon/orgs'
          icon={<BusinessIcon />}
        />
      </RequirePermission>
      <RequirePermission permissions={PERMISSION_SETS.UPDATE_USERS}>
        <SideNavLink
          text={t('apiCredentials')}
          pathTo='/opcon/api-credentials'
          icon={<WebhookIcon />}
        />
      </RequirePermission>
    </>
  )
}

export default SideNav
