import { useMemo } from 'react'
import { useForm, useFieldArray, useWatch } from 'react-hook-form'
import {
  Button,
  MenuItem,
  Stack,
  Typography,
  Checkbox,
  ListItemText,
  Select
} from '@mui/material'
import InputText from 'components/Form/InputText'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import { createUserGroup, updateUserGroup } from 'api/mutations'
import queries, { getAllOrgs, getAvailablePermissions } from 'api/queries'
import { useTranslation } from 'react-i18next'
import useQuery from 'hooks/useQuery'
import useMutation from 'hooks/useMutation'
import { useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import FormSelect from 'components/Form/Select'
import { Permissions } from 'components/Permissions'
import { UserGroup, User, Org } from 'types'
import { find, findIndex, sortBy } from 'lodash'
import { Loading } from 'components/Loading'
import theme from 'styles/theme'
import { formatName } from 'utils/formatName'

type Props = {
  userGroup?: UserGroup
  isLoading?: boolean
}

type FormData = {
  id: string
  displayName: string
  orgId: string
  permissions: string[]
  users: User[]
}

export default function UserGroupFormWithOrgsData(props: Props) {
  const { data, isLoading } = useQuery({
    queryKey: ['orgs'],
    queryFn: getAllOrgs
  })

  if (isLoading || !data) {
    return <Loading />
  }

  return <UserGroupForm orgs={data} {...props} />
}

function UserGroupForm(props: Props & { orgs: Org[] }) {
  const { userGroup, orgs } = props
  const { t } = useTranslation('userGroups')
  const { t: generalT } = useTranslation('general')
  const navigate = useNavigate()

  const defaultSelectedOrgId = orgs.find(
    org => org.name.toLowerCase() === 'tealbook'
  )?.id

  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<FormData>({
    defaultValues: {
      id: userGroup?.id,
      displayName: userGroup?.displayName || '',
      orgId: userGroup?.organization.id || defaultSelectedOrgId,
      permissions: userGroup?.permissions || [],
      users: userGroup?.users ? sortBy(userGroup?.users, 'email') : []
    }
  })

  const orgId = useWatch({ control, name: 'orgId' })

  const queryClient = useQueryClient()

  const { data: availablePermissions } = useQuery({
    queryKey: ['availablePermissions'],
    queryFn: getAvailablePermissions
  })

  const { data: users } = useQuery({
    ...queries.users.allByOrgId(orgId),
    enabled: !!orgId
  })
  const sorterUsers = useMemo(() => {
    if (users) {
      return sortBy(users, 'email')
    }
    return []
  }, [users])

  const update = useMutation({
    mutationFn: updateUserGroup,
    successMessage: 'UserGroup was successfully edited',
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['userGroups'] })
      queryClient.invalidateQueries({ queryKey: ['userGroup'] })
      navigate('/opcon/user-groups')
    }
  })

  // When this mutation succeeds, invalidate any queries with the `userGroups` query key
  const create = useMutation({
    mutationFn: createUserGroup,
    invalidateQueryKey: ['userGroups'],
    successMessage: 'UserGroup was successfully created',
    onSuccess: () => {
      navigate('/opcon/user-groups')
    }
  })

  const onSubmit = (data: FormData) => {
    const payload = {
      displayName: data.displayName || '',
      orgId: data.orgId,
      permissions: data.permissions,
      users: data.users
    }
    if (userGroup) {
      update.mutate({ ...payload, id: userGroup.id })
    } else {
      create.mutate(payload)
    }
  }

  const {
    fields: usersField,
    append: appendUser,
    remove: removeUser
  } = useFieldArray({
    control,
    name: 'users'
  })

  return (
    <Stack spacing={2} mt={4} sx={{ minWidth: 380, maxWidth: 700 }}>
      <Box
        component='div'
        sx={{
          background: theme.palette.common.white,
          p: 3,
          mb: 4
        }}
      >
        <Typography variant='h5' gutterBottom>
          {t('basicInfoHeading')}
        </Typography>
        <FormControl fullWidth sx={{ m: 1 }} variant='outlined'>
          <InputText
            name='displayName'
            control={control}
            label='Name'
            required
            errors={errors.displayName}
          />
        </FormControl>
        <FormControl
          sx={{ m: 1, width: '100%' }}
          size='small'
          error={!!errors.orgId}
        >
          <FormSelect
            name='orgId'
            control={control}
            label='Client'
            required
            disabled={!!userGroup}
            errors={errors.orgId}
          >
            {orgs?.map(org => (
              <MenuItem key={org.id} value={org.id}>
                {org.name}
              </MenuItem>
            ))}
          </FormSelect>
        </FormControl>
      </Box>
      <Box
        component='div'
        sx={{
          background: theme.palette.common.white,
          p: 3,
          mb: 4
        }}
      >
        <Typography variant='h5' gutterBottom>
          {t('permissionsHeading')}
        </Typography>

        {availablePermissions && (
          <Permissions
            control={control}
            groupedPermissions={availablePermissions.globalAdmin}
          />
        )}
      </Box>
      {sorterUsers.length > 0 && (
        <Box
          component='div'
          sx={{
            background: theme.palette.common.white,
            p: 3,
            mb: 4
          }}
        >
          <Typography variant='h5' gutterBottom>
            {t('usersHeading')}
          </Typography>
          <FormControl fullWidth sx={{ m: 1 }} variant='outlined'>
            <Select
              variant='outlined'
              multiple
              value={sorterUsers}
              role='listbox'
            >
              {sorterUsers.map(user => (
                <MenuItem
                  key={user.id}
                  value={`${user.email} - ${formatName(user)}`}
                >
                  <Checkbox
                    checked={!!find(usersField, { email: user.email })}
                    onChange={e => {
                      if (e.target.checked) {
                        appendUser(user)
                      } else {
                        const index = findIndex(usersField, {
                          email: user.email
                        })
                        removeUser(index)
                      }
                    }}
                  />
                  <ListItemText
                    primary={`${user.email} - ${formatName(user)}`}
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {usersField.map((user, i) => (
            <Box
              component='div'
              key={user.id}
              sx={{
                ml: 1,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <div>{`${user.email} - ${formatName(user)}`}</div>
              <Button
                onClick={() => {
                  removeUser(i)
                }}
              >
                {generalT('remove').toLowerCase()}
              </Button>
            </Box>
          ))}
        </Box>
      )}
      <Box>
        <Button
          onClick={handleSubmit(onSubmit)}
          variant='contained'
          sx={{
            m: 1
          }}
        >
          {userGroup?.id ? 'Save' : 'Create'}
        </Button>
      </Box>
    </Stack>
  )
}
