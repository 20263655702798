import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { getPipelineById } from 'api/queries'
import useQuery from 'hooks/useQuery'
import { Loading } from 'components/Loading'
import { usePipelineBreadcrumbs } from '../../(shared)/usePipelineBreadcrumbs'
import { useSetTitleCluster } from 'state/titleCluster'
import VendorAmbiguousMatchesOverview from './(shared)/VendorAmbiguousMatchesOverview'
import { Pipeline } from 'types'
import { CertAmbiguousOverview } from './(shared)/CertAmbiguousOverview'

export default function AmbiguousMatchesOverview() {
  const { orgId, pipelineId } = useParams() as {
    orgId: string
    pipelineId: string
  }

  const { t } = useTranslation('supplierMatch')

  const { data: pipeline } = useQuery<Pipeline>({
    queryKey: ['pipelines', pipelineId, orgId],
    queryFn: () => getPipelineById(orgId, pipelineId)
  })

  const breadcrumbs = usePipelineBreadcrumbs(orgId, pipelineId)
  useSetTitleCluster(t('review_ambiguous_matches'), breadcrumbs.slice(0, 2))

  if (!pipeline) {
    return <Loading />
  }

  return pipeline.pipeline_type === 'common_certification' ? (
    <CertAmbiguousOverview orgId={orgId} pipelineId={pipelineId} />
  ) : (
    <VendorAmbiguousMatchesOverview orgId={orgId} pipelineId={pipelineId} />
  )
}
