import { ReactNode, useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { Box } from '@mui/material'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import theme from 'styles/theme'

type DragItem = {
  index: number
  id: string
}

type Props = {
  type: string
  id: string
  index: number
  onDrag: (dragIndex: number, hoverIndex: number) => void
  children: ReactNode
}

export default function DraggableItem(props: Props) {
  const draggableRef = useRef<HTMLDivElement>(null)
  const previewRef = useRef<HTMLDivElement>(null)

  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: string | null }
  >({
    accept: props.type,
    collect(monitor: any) {
      return {
        handlerId: monitor.getHandlerId(),
        id: props.id
      }
    },
    hover(item: DragItem, monitor) {
      if (!draggableRef.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = props.index

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }

      const hoverBoundingRect = draggableRef.current?.getBoundingClientRect()
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = (clientOffset?.y || 0) - hoverBoundingRect.top

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      props.onDrag(dragIndex, hoverIndex)

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    }
  })

  const [{ isDragging }, drag, preview] = useDrag({
    type: props.type,
    item: () => ({
      index: props.index,
      id: props.id
    }),
    isDragging: monitor => monitor.getItem().id === props.id,
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  })

  drag(draggableRef)
  drop(preview(previewRef))

  return (
    <Box
      ref={previewRef}
      sx={{
        opacity: isDragging ? 0 : 1,
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <Box
        sx={{
          borderRadius: '50%',
          width: '1rem',
          backgroundColor: theme.palette.grey[100],
          textAlign: 'center',
          ml: 1
        }}
      >
        {props.index + 1}
      </Box>

      <Box
        ref={draggableRef}
        data-handler-id={handlerId}
        sx={{
          cursor: 'move',
          display: 'inherit'
        }}
      >
        <DragIndicatorIcon />
      </Box>
      {props.children}
    </Box>
  )
}
