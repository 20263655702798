import { useState } from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import ClientAppBar from 'components/AppBar/ClientAppBar'
import { Outlet, useLocation } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import { Box, Paper } from '@mui/material'
import { ErrorBoundary } from 'react-error-boundary'

import AppSidebar from 'components/Sidebar/AppSidebar'
import { ErrorPage } from 'components/ErrorPage'
import Snackbar from 'components/Snackbar'
import DiversitySideNav from './(shared)/DiversitySideNav'

const Aside = styled('aside')({
  flexShrink: 0,
  minHeight: '100%'
})

const Main = styled('main')({
  flexGrow: 1,
  minHeight: '100%'
})

const Content = styled('div')(({ theme }) => ({
  padding: theme.spacing(3)
}))

export default function Layout() {
  const { pathname } = useLocation()
  const [isSidebarOpen, setIsSidebarOpen] = useState(true)

  const handleOpenSidebar = () => setIsSidebarOpen(true)
  const handleCloseSidebar = () => setIsSidebarOpen(false)

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Snackbar />
      <Aside>
        <AppSidebar
          open={isSidebarOpen}
          onOpen={handleOpenSidebar}
          onClose={handleCloseSidebar}
        >
          <DiversitySideNav isSidebarOpen={isSidebarOpen} />
        </AppSidebar>
      </Aside>
      <Main>
        <ClientAppBar />
        <Content>
          <ErrorBoundary key={pathname} FallbackComponent={ErrorPage}>
            <Outlet />
          </ErrorBoundary>
        </Content>
      </Main>
    </Box>
  )
}

export const CustomPaper = styled(Paper)(({ theme }) => ({
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  borderRadius: theme.spacing(4),
  border: 'none'
}))
