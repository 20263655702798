import {
  Typography,
  Stack,
  Card,
  CardContent,
  Box,
  IconButton
} from '@mui/material'
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import ClearIcon from '@mui/icons-material/Clear'
import { ReactNode } from 'react'
import theme from 'styles/theme'
import { useTranslation } from 'react-i18next'

type Props = {
  children?: ReactNode
  description?: string
  hasIcon?: boolean
  onClose?: () => void
  title?: string
  variant?: 'warning' | 'error' | 'success' | 'info' | 'mge'
} & (
  | {
      title: string
    }
  | {
      description: string
    }
  | {
      children: ReactNode
    }
)

export const Banner = ({
  children,
  description,
  hasIcon = true,
  onClose,
  title,
  variant = 'error'
}: Props) => {
  const { t } = useTranslation('general')

  const color = theme.palette[variant].main
  const background = theme.palette[variant].lighter
  const textColor = variant === 'mge' ? theme.palette[variant].main : 'inherit'

  const Icon =
    variant === 'success' ? CheckCircleOutlineIcon : WarningAmberRoundedIcon

  const isOnlyTitle = title && !description && !children

  return (
    <Card
      variant='outlined'
      sx={{
        backgroundColor: background,
        marginBottom: 2,
        borderColor: color
      }}
    >
      <Stack direction='row' justifyContent='space-between'>
        <CardContent
          sx={{
            '&:last-child': { paddingBottom: isOnlyTitle ? '16px' : undefined }
          }}
        >
          <Stack direction='row' spacing={2}>
            {hasIcon && <Icon sx={{ color }} />}
            <div>
              {title && (
                <Typography
                  sx={{
                    fontWeight: 'fontWeightBold',
                    marginBottom: isOnlyTitle ? 0 : 1,
                    color: textColor
                  }}
                >
                  {title}
                </Typography>
              )}

              {description &&
                description.split('\n').map((line, index) => (
                  <Typography
                    key={'banner-' + index}
                    sx={
                      description.split('\n').length - 1 !== index
                        ? { mb: 1 }
                        : undefined
                    }
                  >
                    {line}
                  </Typography>
                ))}

              {children}
            </div>
          </Stack>
        </CardContent>

        {onClose && (
          <Box sx={{ textAlign: 'end' }}>
            <IconButton
              sx={{ p: 1, color: theme.palette.text.primary }}
              aria-label={t('closeBanner')}
              onClick={onClose}
            >
              <ClearIcon />
            </IconButton>
          </Box>
        )}
      </Stack>
    </Card>
  )
}
