import { Chip as MuiChip, ChipProps } from '@mui/material'
import theme from 'styles/theme'

type Props = {
  strength: 'light' | 'main' | 'dark'
} & ChipProps

export const Chip = ({ strength, color, sx, ...rest }: Props) => {
  if (color !== 'default' && color !== undefined) {
    sx = { ...sx, backgroundColor: theme.palette[color][strength] }
  }

  return <MuiChip color={color} sx={sx} {...rest} />
}
