import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'

type Props = {
  fileName: string
  errors: { code: string; message: string }[]
}

const DropZoneErrors = (props: Props) => {
  const { t } = useTranslation('pipelineRunner')
  const { errors } = props
  const transErrors = errors.map(e => {
    const { code, message } = e
    if (code === 'file-too-small') {
      return t('fileSizeDropZoneMessage')
    } else if (code === 'file-invalid-type') {
      return t('errorMessage', {
        message
      })
    } else {
      return e.message
    }
  })
  return (
    <>
      {transErrors.map(e => (
        <Typography key={e}>{e}</Typography>
      ))}
    </>
  )
}

export default DropZoneErrors
