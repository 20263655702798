import { Outlet, Navigate, RouteObject } from 'react-router-dom'
import Pipelines from './pipelines'
import PipelineOverviewLayout from './pipelines/[pipelineId]/(shared)/PipelineOverviewLayout'
import PipelineOverview from './pipelines/[pipelineId]/overview/overview'
import Users from './users/users'
import UserGroups from './user-groups/user-groups'
import { CreateUserGroup } from './user-groups/create'
import EditUserGroup from './user-groups/[userGroupId]/edit'
import { RequirePermission } from 'providers/RequirePermission'
import Orgs from './orgs'
import { OrgCreate } from './orgs/create'
import OrgEdit from './orgs/edit/[orgId]'
import PipelineRunner from './pipeline-runner'
import { Tnv } from './pipelines/[pipelineId]/tnv/tnv'
import { CreateUser } from './users/create'
import EditUser from './users/[userId]/edit'
import { ErrorPage } from 'components/ErrorPage'
import AmbiguousMatchesOverview from './pipelines/[pipelineId]/ambiguous-matches/ambiguous-matches'
import ApiCredentials from './api-credentials'
import ApiCredentialsCreate from './api-credentials/create'
import ApiCredentialsEdit from './api-credentials/[apiCredentialsId]/edit'
import PipelineHistory from './pipelines/[pipelineId]/history'
import { ResetPassword } from './users/[userId]/reset-password'
import usePermissions, { PERMISSION_SETS } from 'hooks/usePermissions'
import { NoAccessPage } from 'components/NoAccessPage'
import PipelineEnrichment from './pipelines/[pipelineId]/enrichment/enrichment'
import AmbiguousMatchSelect from './pipelines/[pipelineId]/ambiguous-matches/[entityId]/match-suppliers'
import CompletedAmbiguousMatch from './pipelines/[pipelineId]/ambiguous-matches/[entityId]/match-completed'
import { MgeMatchSelect } from './pipelines/[pipelineId]/ambiguous-matches/[entityId]/match-mge-suppliers'
import { BreEdit } from './orgs/edit/bre/BreEdit'
import PipelinesNav from './pipelines/(shared)/PipelinesNav'

const opconRoutes: RouteObject[] = [
  {
    path: 'pipelines',
    element: (
      <RequirePermission permissions={PERMISSION_SETS.READ_PIPELINES}>
        <Outlet />
      </RequirePermission>
    ),
    children: [
      {
        path: 'list',
        element: <PipelinesNav />,
        children: [
          {
            path: 'vendor',
            index: true,
            element: <Pipelines type='client_data_vendor_extended' />
          },
          {
            path: 'spend',
            element: <Pipelines type='client_data_spend' />
          },
          {
            path: 'usp',
            element: <Pipelines type='common_certification' />
          },
          {
            path: '',
            element: <Navigate to={'vendor'} replace />
          }
        ]
      },
      {
        path: 'runner',
        element: (
          <RequirePermission
            permissions={PERMISSION_SETS.MANAGE_ALL_PIPELINES}
            fallback={<NoAccessPage />}
          >
            <PipelineRunner />
          </RequirePermission>
        )
      },
      {
        path: ':orgId/:pipelineId',
        element: <PipelineOverviewLayout />,
        children: [
          {
            path: 'overview',
            element: <PipelineOverview />
          },
          {
            path: 'history',
            element: <PipelineHistory />
          },
          {
            path: 'enrichment',
            element: <PipelineEnrichment />
          }
        ]
      },
      {
        path: ':orgId/:pipelineId/tnv',
        element: <Tnv />
      },
      {
        path: ':orgId/:pipelineId/ambiguous-matches',
        element: <AmbiguousMatchesOverview />
      },
      {
        path: ':orgId/:pipelineId/ambiguous-matches/:entityMetadataId/match-suppliers',
        element: <AmbiguousMatchSelect />
      },
      {
        path: ':orgId/:pipelineId/ambiguous-matches/:entityMetadataId/match-mge-suppliers',
        element: <MgeMatchSelect />
      },
      {
        path: ':orgId/:pipelineId/ambiguous-matches/:matchUpdatedBy/:matchUpdatedAt/match-completed',
        element: <CompletedAmbiguousMatch />
      },
      {
        path: '',
        element: <Navigate to={'list'} replace />
      }
    ]
  },
  {
    path: 'api-credentials',
    element: (
      <RequirePermission
        permissions={PERMISSION_SETS.READ_USERS}
        fallback={<NoAccessPage />}
      >
        <Outlet />
      </RequirePermission>
    ),
    children: [
      {
        index: true,
        element: <ApiCredentials />
      },
      {
        path: 'create',
        element: (
          <RequirePermission
            permissions={PERMISSION_SETS.CREATE_USERS}
            fallback={<NoAccessPage />}
          >
            <ApiCredentialsCreate />
          </RequirePermission>
        )
      },
      {
        path: ':apiCredentialsId/edit',
        element: (
          <RequirePermission
            permissions={PERMISSION_SETS.UPDATE_USERS}
            fallback={<NoAccessPage />}
          >
            <ApiCredentialsEdit />
          </RequirePermission>
        )
      }
    ]
  },
  {
    path: 'orgs',
    element: (
      <RequirePermission
        permissions={PERMISSION_SETS.READ_CLIENTS}
        fallback={<NoAccessPage />}
      >
        <Outlet />
      </RequirePermission>
    ),
    children: [
      {
        index: true,
        element: <Orgs />
      },
      {
        path: 'create',
        element: (
          <RequirePermission
            permissions={PERMISSION_SETS.CREATE_CLIENTS}
            fallback={<NoAccessPage />}
          >
            <OrgCreate />
          </RequirePermission>
        )
      },
      {
        path: 'edit/:orgId',
        element: (
          <RequirePermission
            permissions={PERMISSION_SETS.UPDATE_CLIENTS}
            fallback={<NoAccessPage />}
          >
            <OrgEdit />
          </RequirePermission>
        )
      },
      {
        path: 'edit/:orgId/bre',
        element: (
          <RequirePermission
            permissions={PERMISSION_SETS.UPDATE_CLIENTS}
            fallback={<NoAccessPage />}
          >
            <BreEdit />
          </RequirePermission>
        )
      }
    ]
  },
  {
    path: 'users',
    element: (
      <RequirePermission
        permissions={PERMISSION_SETS.UPDATE_USERS}
        fallback={<NoAccessPage />}
      >
        <Outlet />
      </RequirePermission>
    ),
    children: [
      {
        index: true,
        element: <Users />
      },
      {
        path: 'create',
        element: (
          <RequirePermission
            permissions={PERMISSION_SETS.CREATE_USERS}
            fallback={<NoAccessPage />}
          >
            <CreateUser />
          </RequirePermission>
        )
      },
      {
        path: ':userId/edit',
        element: (
          <RequirePermission
            permissions={PERMISSION_SETS.UPDATE_USERS}
            fallback={<NoAccessPage />}
          >
            <EditUser />
          </RequirePermission>
        )
      },
      {
        path: ':userId/edit/reset-password',
        element: (
          <RequirePermission
            permissions={PERMISSION_SETS.UPDATE_USERS}
            fallback={<NoAccessPage />}
          >
            <ResetPassword />
          </RequirePermission>
        )
      }
    ]
  },
  {
    path: 'user-groups',
    element: (
      <RequirePermission
        permissions={PERMISSION_SETS.UPDATE_USER_GROUPS}
        fallback={<NoAccessPage />}
      >
        <Outlet />
      </RequirePermission>
    ),
    children: [
      {
        index: true,
        element: <UserGroups />
      },
      {
        path: 'create',
        element: (
          <RequirePermission
            permissions={PERMISSION_SETS.CREATE_USER_GROUPS}
            fallback={<NoAccessPage />}
          >
            <CreateUserGroup />
          </RequirePermission>
        )
      },
      {
        path: ':userGroupId/edit',
        element: (
          <RequirePermission
            permissions={PERMISSION_SETS.UPDATE_USER_GROUPS}
            fallback={<NoAccessPage />}
          >
            <EditUserGroup />
          </RequirePermission>
        )
      }
    ]
  },
  {
    path: '',
    element: <NavigateToDefaultRoute />
  }
]

export default opconRoutes

function NavigateToDefaultRoute() {
  const hasPipelinesAccess = usePermissions(PERMISSION_SETS.READ_PIPELINES)
  const hasUsersAccess = usePermissions(PERMISSION_SETS.READ_USERS)

  const defaultPath = hasPipelinesAccess
    ? 'pipelines/list/vendor'
    : hasUsersAccess
    ? 'users'
    : 'orgs'

  return !!defaultPath ? (
    <Navigate to={defaultPath} replace />
  ) : (
    <ErrorPage
      error={{
        statusText: 'No access. Please speak with your administrator'
      }}
    />
  )
}
