import { createTheme } from '@mui/material/styles'
import type {} from '@mui/x-data-grid/themeAugmentation'

const APP_BACKGROUND = '#F7F7F7'
const PRIMARY = '#028383'
const TEXT_PRIMARY = '#454545'

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    delete: true
  }
}

let theme = createTheme({
  palette: {
    background: {
      default: APP_BACKGROUND
    },
    primary: {
      lighter: '#51E4D9',
      light: '#01C0B5',
      main: PRIMARY,
      contrastText: '#FFFFFF'
    },
    secondary: {
      main: '#E5E5E5',
      contrastText: '#00101D'
    },
    success: {
      lighter: '#F3FAF6',
      light: '#CDEDCF',
      main: '#159948',
      contrastText: '#00101D'
    },
    error: {
      lighter: '#FEF8F7',
      light: '#FFD2DA',
      main: '#C90D2D',
      contrastText: '#00101D'
    },
    warning: {
      lighter: '#FFFAEF',
      light: '#FFE9B7',
      main: '#E5780F',
      contrastText: '#00101D'
    },
    info: {
      lighter: '#FFFFFF',
      main: '#353231'
    },
    corrections: {
      main: '#CDEDED',
      contrastText: '#00101D'
    },
    matching: {
      main: '#E1CDED',
      contrastText: '#00101D'
    },
    mge: {
      main: '#00556C',
      lighter: '#E6F4F9'
    },
    custom: {
      sungold: '#FFC33B',
      crimson: '#E8173A',
      blueWhale: '#062B48',
      bahamaBlue: '#006098',
      sirocco: '#6F8282',
      blackHaze: '#F0F2F2'
    },
    text: {
      primary: TEXT_PRIMARY
    },
    grey: {
      100: '#F0F2F2'
    },
    contrastThreshold: 4.5
  },
  typography: {
    fontFamily: '"Lato","Roboto","Helvetica","Arial",sans-serif',
    fontSize: 16,
    h1: {
      fontWeight: 700,
      fontSize: '32px'
    },
    h2: {
      fontSize: '1.25rem',
      fontWeight: 500,
      padding: '0.25rem 0'
    },
    h6: {
      fontSize: '19px',
      fontWeight: 700
    },
    body1: {
      fontSize: '1rem'
    },
    button: {
      textTransform: 'none'
    }
  }
})

theme = createTheme(theme, {
  components: {
    MuiPaper: {
      defaultProps: {
        variant: 'outlined'
      },
      styleOverrides: {
        rounded: {
          borderRadius: '8px'
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#03101C'
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderColor: '#CCCCCC',
          fontSize: '1rem'
        },
        head: {
          fontWeight: 'bold',
          fontStyle: 'normal'
        }
      }
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          background: '#F0F2F2'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        sizeMedium: {
          fontSize: '1rem',
          lineHeight: '1.25rem',
          fontWeight: '700'
        },
        containedSizeMedium: {
          paddingTop: 8,
          paddingBottom: 8,
          paddingLeft: 12,
          paddingRight: 12,
          fontWeight: 700
        }
      },
      variants: [
        {
          props: { variant: 'delete' as any },
          style: {
            color: theme.palette.custom?.crimson,
            backgroundColor: theme.palette.custom?.blackHaze,
            border: `1px solid #00101D1A`
          }
        }
      ]
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: '#979797',
          '& [data-testid="RadioButtonUncheckedIcon"]': {
            border: '1px solid currentColor',
            borderRadius: '100%',
            boxSizing: 'border-box',
            color: 'currentColor',
            fill: 'transparent',
            transitionProperty: 'border-color, border-width',
            transitionDuration: '200ms',
            transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
            transitionDelay: '0ms'
          },
          '& [data-testid="RadioButtonCheckedIcon"]': {
            display: 'none'
          },
          '&.Mui-checked ': {
            color: PRIMARY,
            '& [data-testid="RadioButtonUncheckedIcon"]': {
              borderWidth: '9px'
            }
          },
          '&.PrivateSwitchBase-edgeStart ': {
            marginLeft: '-9px'
          },
          '&.PrivateSwitchBase-edgeEnd ': {
            marginRight: '-9px'
          }
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        colorInherit: {
          backgroundColor: APP_BACKGROUND
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          '&.MuiInputBase-input:invalid': {
            marginBottom: 0
          }
        }
      }
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          background: '#FFFFFF'
        },
        columnHeaders: {
          backgroundColor: '#F0F2F2'
        },
        columnHeader: {
          '&:focus': {
            outline: 'none'
          },
          '&--sorted, &--sorted .MuiDataGrid-sortIcon': {
            color: PRIMARY
          }
        },
        columnHeaderTitle: {
          fontWeight: '700'
        },
        iconSeparator: {
          display: 'none'
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: TEXT_PRIMARY,
          marginBottom: 1
        }
      }
    },
    MuiPopper: {
      defaultProps: {
        sx: {
          '& .MuiTooltip-tooltip': {
            border: '1px solid #DADADA',
            color: 'text.primary',
            boxShadow: '1px 1px 6px rgba(0, 0, 0, 0.08)',
            backgroundColor: 'primary.contrastText',
            borderRadius: '4px'
          }
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: 'white'
        }
      }
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '20px 24px'
        }
      }
    }
  }
})

export default theme
