import i18n from '../i18n/i18n'

export const formatName = ({
  firstName,
  lastName,
  fallback
}: {
  firstName?: string
  lastName?: string
  fallback?: string
}) => {
  if (firstName && lastName) {
    return `${firstName} ${lastName}`
  }

  if (firstName) {
    return firstName
  }

  if (lastName) {
    return lastName
  }

  return fallback || i18n.t('general:unknown')
}
