import {
  Divider,
  Typography,
  Box,
  FormControlLabel,
  Checkbox,
  Switch
} from '@mui/material'
import { Control, useController } from 'react-hook-form'
import CheckboxGroup from 'components/Form/CheckboxGroup/CheckboxGroup'
import { SettingsFormData } from '../settings'
import { useMemo, useState } from 'react'

type Props = {
  control: Control<SettingsFormData>
  fieldName: 'subCategories' | 'agencies' | 'sourceTypes'
  checkboxOptions: string[]
  optionLabelsTranslationKey: string
  title: string
  checkAllLabel: string
  canRankOptions?: boolean
}

export const SettingsCheckboxSection = (props: Props) => {
  const [enableSubcategoryRanking, setEnableSubcategoryRanking] =
    useState(false)
  const { field } = useController({
    name: props.fieldName,
    control: props.control
  })

  const handleSelectAllChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      field.onChange(props.checkboxOptions)
    } else {
      field.onChange([])
    }
  }

  const unselectedOptions = useMemo(
    () => props.checkboxOptions.filter(option => !field.value.includes(option)),
    [props.checkboxOptions, field.value]
  )

  return (
    <>
      <Typography variant='subtitle2' sx={{ fontWeight: 'bold', mt: '20px' }}>
        {props.title}
      </Typography>
      <Box sx={{ display: 'flex', mb: 2 }}>
        <FormControlLabel
          label={props.checkAllLabel}
          control={
            <Checkbox
              checked={field.value.length === props.checkboxOptions.length}
              onChange={handleSelectAllChange}
              name={`all${props.fieldName}`}
            />
          }
        />
        {props.canRankOptions && (
          <FormControlLabel
            label='Subcategory Ranking'
            control={
              <Switch
                name='enableSubcategoryRanking'
                onChange={(e, checked) => setEnableSubcategoryRanking(checked)}
                checked={field.value.length > 0 && enableSubcategoryRanking}
                disabled={field.value.length === 0}
              />
            }
          />
        )}
      </Box>
      <Divider />
      <CheckboxGroup
        field={field}
        checkboxOptions={
          props.canRankOptions && enableSubcategoryRanking
            ? field.value
            : props.checkboxOptions
        }
        labelsTranslationKey={`diversity.${props.optionLabelsTranslationKey}`}
        enableDragMode={field.value.length > 0 && enableSubcategoryRanking}
      />
      {props.canRankOptions && enableSubcategoryRanking && (
        <CheckboxGroup
          field={field}
          checkboxOptions={unselectedOptions}
          labelsTranslationKey={`diversity.${props.optionLabelsTranslationKey}`}
        />
      )}
    </>
  )
}
