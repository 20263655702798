import { forwardRef, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps
} from 'react-router-dom'

import dayjs from 'dayjs'

import { Button } from '@mui/material'
import {
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridRowId
} from '@mui/x-data-grid'

import { getApiCredentialsByPage } from 'api/queries'
import { ExpandableTextCell } from 'components/DataTable'
import createColDefs from 'components/DataTable/createColDefs'
import DataTableServerSide from 'components/DataTable/DataTableServerSide'
import usePermissions, { PERMISSION_SETS } from 'hooks/usePermissions'

import CheckIcon from '@mui/icons-material/Check'
import XIcon from '@mui/icons-material/Close'
import EditIcon from '@mui/icons-material/Edit'

const Link = forwardRef<HTMLAnchorElement, RouterLinkProps>(
  (itemProps, ref) => <RouterLink ref={ref} {...itemProps} />
)

const EditButton = ({ id }: { id: GridRowId }) => {
  const { t } = useTranslation('general')
  return (
    <Button
      variant='text'
      startIcon={<EditIcon />}
      component={Link}
      to={`${id}/edit`}
    >
      {t('edit')}
    </Button>
  )
}

export default function ApiCredentials() {
  const { t } = useTranslation('apiCredentials')
  const canUpdateUsers = usePermissions(PERMISSION_SETS.UPDATE_USERS)
  const canCreateUsers = usePermissions(PERMISSION_SETS.CREATE_USERS)

  const columnDefs = useMemo(() => {
    const baseDefs = [
      { field: 'id' },
      { field: 'title', filterable: false, headerName: t('titleColumn') },
      { field: 'organizationName', filterable: false, headerName: t('client') },
      {
        field: 'enabled',
        filterable: false,
        disableColumnMenu: true,
        sortable: false,
        headerName: t('enabled'),
        maxWidth: 90,
        align: 'center',
        renderCell: (params: GridRenderCellParams) =>
          params.value ? <CheckIcon /> : <XIcon />
      },
      {
        field: 'updatedAt',
        filterable: false,
        disableColumnMenu: true,
        sortable: false,
        headerName: t('lastUpdate'),
        renderCell: (params: GridCellParams) => (
          <ExpandableTextCell
            value={
              dayjs(params.value).isValid()
                ? dayjs(params.value).format('YYYY-MM-DD HH:mm')
                : '-'
            }
          />
        )
      },
      {
        field: 'updatedBy',
        filterable: false,
        disableColumnMenu: true,
        sortable: false,
        headerName: t('lastUpdateBy')
      },
      { field: 'clientId', filterable: false, headerName: t('clientId') }
    ]

    const actionColDef = {
      field: 'Action',
      minWidth: 50,
      maxWidth: 110,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params: GridCellParams) => <EditButton id={params.id} />
    }

    return createColDefs(
      canUpdateUsers
        ? ([...baseDefs, actionColDef] as GridColDef[])
        : (baseDefs as GridColDef[])
    )
  }, [canUpdateUsers, t])

  return (
    <DataTableServerSide
      title={t('title')}
      columns={columnDefs}
      queryKey='api-credentials'
      query={getApiCredentialsByPage}
      createPageLink={
        canCreateUsers ? '/opcon/api-credentials/create' : undefined
      }
      createButtonTitle={canCreateUsers ? t('createApiCredentials') : undefined}
      initialState={{
        sorting: {
          sortModel: [{ field: 'updatedAt', sort: 'desc' }]
        }
      }}
      searchOnColumns={['organizationName']}
    />
  )
}
