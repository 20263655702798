import { PipelineStatus } from 'types'

export const getEnrichmentsDisabled = ({
  noEnrichedDataText,
  pipelineNotCompleteText,
  certificatesDisabledText,
  isNoEnrichmentData,
  isSingleBatch,
  orgHasCertificationsDatapack,
  pipelineStatus,
  hasNoCertifications
}: {
  noEnrichedDataText: string
  pipelineNotCompleteText: string
  certificatesDisabledText: string
  isNoEnrichmentData: boolean
  isSingleBatch: boolean
  orgHasCertificationsDatapack: boolean
  pipelineStatus: PipelineStatus | undefined
  hasNoCertifications: boolean
}) => {
  const originalFirmographic = {
    disabled: false,
    tooltip: ''
  }
  const originalCertificates = {
    disabled: false,
    tooltip: ''
  }
  const cumulativeFirmographic = {
    disabled: false,
    tooltip: ''
  }
  const cumulativeCertificates = {
    disabled: false,
    tooltip: ''
  }

  const isPipelineInProgress =
    !pipelineStatus ||
    pipelineStatus === 'queued' ||
    pipelineStatus === 'pending' ||
    pipelineStatus === 'running'

  if (isNoEnrichmentData) {
    originalFirmographic.disabled = true
    originalCertificates.disabled = true
    cumulativeFirmographic.disabled = true
    cumulativeCertificates.disabled = true

    originalFirmographic.tooltip = noEnrichedDataText
    originalCertificates.tooltip = noEnrichedDataText
    cumulativeFirmographic.tooltip = noEnrichedDataText
    cumulativeCertificates.tooltip = noEnrichedDataText
  }

  if (isSingleBatch && isPipelineInProgress) {
    originalFirmographic.disabled = true
    originalCertificates.disabled = true
    originalFirmographic.tooltip = pipelineNotCompleteText
    originalCertificates.tooltip = pipelineNotCompleteText
  }

  if (pipelineStatus !== 'complete') {
    cumulativeFirmographic.disabled = true
    cumulativeCertificates.disabled = true
    cumulativeFirmographic.tooltip = pipelineNotCompleteText
    cumulativeCertificates.tooltip = pipelineNotCompleteText
  }

  if (!orgHasCertificationsDatapack) {
    originalCertificates.disabled = true
    cumulativeCertificates.disabled = true
    originalCertificates.tooltip = certificatesDisabledText
    cumulativeCertificates.tooltip = certificatesDisabledText
  }

  if (hasNoCertifications) {
    originalCertificates.disabled = true
    originalCertificates.tooltip = noEnrichedDataText
  }

  return {
    originalFirmographic,
    originalCertificates,
    cumulativeFirmographic,
    cumulativeCertificates
  }
}
