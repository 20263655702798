import usePermissions from 'hooks/usePermissions'

type Props = {
  children: JSX.Element
  fallback?: JSX.Element
  permissions: string[]
}
export const RequirePermission = ({
  children,
  permissions,
  fallback
}: Props): JSX.Element | null => {
  const hasAccess = usePermissions(permissions)
  if (!hasAccess) {
    if (fallback) {
      return fallback
    }
    return null
  }
  return children
}
