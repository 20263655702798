import Typography from '@mui/material/Typography'
import { Stack } from '@mui/material'

import theme from 'styles/theme'
import { Breadcrumbs } from './Breadcrumbs'
import { useTitleCluster } from 'state/titleCluster'

export const TitleCluster = () => {
  const { title, breadcrumbs } = useTitleCluster()

  return breadcrumbs.length > 0 ? (
    <Stack>
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <Typography
        variant='h1'
        sx={{
          top: theme.spacing(6),
          padding: 0,
          margin: 0
          // marginBottom: theme.spacing(1)
        }}
      >
        {title}
      </Typography>
    </Stack>
  ) : (
    <Typography variant='h1' m={0} p={0}>
      {title}
    </Typography>
  )
}
