import DraggableItem from './DraggableItem'
import { ControllerRenderProps } from 'react-hook-form'
import { Checkbox } from '../Checkbox'
import { Box } from '@mui/material'

type Props = {
  onDrag: (dragIndex: number, hoverIndex: number) => void
  index: number
  option: string
  field: ControllerRenderProps<any, any>
  label: string
  enableDragMode?: boolean
}

export default function DraggableCheckbox(props: Props) {
  if (props.enableDragMode && props.field.value.includes(props.option)) {
    return (
      <DraggableItem
        id={props.option}
        index={props.index}
        onDrag={props.onDrag}
        type='checkbox'
      >
        <Checkbox {...props} />
      </DraggableItem>
    )
  }

  return (
    <Box>
      <Checkbox {...props} />
    </Box>
  )
}
