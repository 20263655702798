import {
  QueryKey,
  useQuery as useTanstackQuery,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import useNotify from './useNotify'

/**
 * Extends the "useQuery" hook from @tanstack/react-query with some extra
 * implementation and defaults
 * @param options passed to "useQuery" from @tanstack/react-query
 * @returns UseQueryResult
 */
export default function useQuery<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(
  options: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>
): UseQueryResult<TData, TError> {
  const { t } = useTranslation('general')
  const notify = useNotify()
  const [queryHasError, setQueryHasError] = useState(false)

  return useTanstackQuery({
    // these default options can be overwritten
    staleTime: 5 * 60 * 1000,

    ...options,

    // disabled the query once there is an error
    enabled: (options.enabled ?? true) && !queryHasError,

    // notification error when the query fails
    onError: err => {
      console.error(err)

      notify({
        message: t('error'),
        severity: 'error'
      })

      setQueryHasError(true)

      if (options.onError) {
        options.onError(err)
      }
    }
  })
}
