import { useState, useEffect } from 'react'
import {
  Card,
  Box,
  CardContent,
  Typography,
  Button,
  IconButton,
  Collapse
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import { useNavigate } from 'react-router-dom'

import { StatusChip, VariantType } from 'components/StatusChip'
import usePermissions, { PERMISSION_SETS } from 'hooks/usePermissions'
import { useAnalyticsWithPipeline } from 'hooks/useAnalytics'
import { PipelineStatus, PipelineInput } from 'types'

import { PipelinesFilesTable } from './PipelineFilesTable'

type Props = {
  inputs: Array<PipelineInput>
  isComplete?: boolean
  orgId: string
  pipelineId: string
  pipelineStatus: PipelineStatus
}

export const FileCorrectionsSection = ({
  inputs,
  isComplete,
  orgId,
  pipelineId,
  pipelineStatus
}: Props) => {
  const { t } = useTranslation('pipelines')
  const navigate = useNavigate()
  const analytics = useAnalyticsWithPipeline({
    clientId: orgId,
    pipelineId: pipelineId
  })

  const hasManageAllPipelinesPermissions = usePermissions(
    PERMISSION_SETS.MANAGE_ALL_PIPELINES
  )

  const [expandFileNames, setExpandFileNames] = useState(true)
  const [expandFileCorrections, setExpandFileCorrections] = useState(
    pipelineStatus === 'readyforcorrections' || pipelineStatus === 'reviewing'
  )

  useEffect(() => {
    setExpandFileCorrections(
      pipelineStatus === 'readyforcorrections' || pipelineStatus === 'reviewing'
    )
  }, [pipelineStatus])

  let status: VariantType
  if (
    pipelineStatus === 'reviewing' ||
    pipelineStatus === 'complete' ||
    pipelineStatus === 'readyforcorrections'
  ) {
    status = pipelineStatus
  } else if (pipelineStatus === 'readyformatching' || isComplete) {
    status = 'complete'
  } else {
    status = 'not_ready'
  }

  const handleViewErrors = () => {
    analytics.track('View Errors Clicked', {
      eventSource: 'Pipeline Overview Page',
      eventCategory: 'user'
    })

    navigate(`/opcon/pipelines/${orgId}/${pipelineId}/tnv`)
  }

  return (
    <Card variant='outlined'>
      <CardContent>
        <Box
          sx={{
            marginTop: '3px',
            display: 'flex',
            justifyContent: 'space-between',
            marginRight: '6px'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <Typography variant='h2' fontWeight='bold'>
              {t('file_corrections')}
            </Typography>
            {status === 'complete' && (
              <IconButton
                size='small'
                aria-label='expandFileCorrections'
                onClick={() => setExpandFileCorrections(value => !value)}
              >
                {expandFileCorrections ? (
                  <ExpandMoreIcon />
                ) : (
                  <ExpandLessIcon />
                )}
              </IconButton>
            )}
          </Box>
          <StatusChip label={status} variant={status} isStartCase />
        </Box>
        {status === 'not_ready' && (
          <Typography>{t('overview.file_not_processed')}</Typography>
        )}
        {status !== 'not_ready' && hasManageAllPipelinesPermissions && (
          <Collapse in={expandFileCorrections} timeout='auto' unmountOnExit>
            {(status === 'readyforcorrections' || status === 'reviewing') && (
              <>
                <Typography>{t('correct_errors')}</Typography>
                <Button
                  sx={{ mb: '16px', mt: '20px' }}
                  variant='contained'
                  onClick={handleViewErrors}
                >
                  {t('view_errors')}
                </Button>
              </>
            )}

            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography> {t('file_history')}</Typography>
              <IconButton
                size='small'
                aria-label='expandFileHistory'
                onClick={() => setExpandFileNames(value => !value)}
              >
                {expandFileNames ? <ExpandMoreIcon /> : <ExpandLessIcon />}
              </IconButton>
            </Box>
            <Collapse in={expandFileNames} timeout='auto' unmountOnExit>
              <PipelinesFilesTable inputs={inputs} />
            </Collapse>
          </Collapse>
        )}
      </CardContent>
    </Card>
  )
}
