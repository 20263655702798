import { FormControl, Stack, FormLabel, Box, Typography } from '@mui/material'
import { Control } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'

import { InfoLabel } from 'components/Info'
import theme from 'styles/theme'
import Select from 'components/Form/Select'

import { RunnerFormData } from './PipelineRunnerForm'

export const SpendFileFields = ({
  control
}: {
  control: Control<RunnerFormData, any>
}) => {
  const { t } = useTranslation('pipelineRunner')

  return (
    <>
      <FormControl sx={{ my: 1, textAlign: 'start' }}>
        <Stack direction='row' alignItems='center'>
          <InfoLabel
            displayInfo={
              <Trans
                t={t}
                i18nKey='spendFilesMappedTooltip'
                components={{ 1: <br /> }}
              />
            }
            info={t('spendFilesMappedTooltip')}
            label={
              <FormLabel htmlFor='vendorMaster'>{t('vendorMaster')}</FormLabel>
            }
          />
        </Stack>
        <Select
          name='vendorMaster'
          control={control}
          placeholder={t('selectVendorMaster')}
        />
      </FormControl>
      <Box
        sx={{
          padding: '1rem',
          border: '1px solid black',
          borderRadius: '4px',
          backgroundColor: theme.palette.grey[100]
        }}
      >
        <Typography>
          <Trans t={t} i18nKey='spendFilesMappedToVendor'>
            <strong>Note:</strong> Spend files are mapped against vendor
            masters.
          </Trans>
        </Typography>
      </Box>
    </>
  )
}
