import { useTranslation } from 'react-i18next'

import UserForm from '../(shared)/UserForm'
import { useSetTitleCluster } from 'state/titleCluster'

export function CreateUser() {
  const { t } = useTranslation('users')

  useSetTitleCluster(t('createNewUser'), [
    {
      link: '/opcon/users',
      label: t('title')
    }
  ])

  return <UserForm />
}
