import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  Paper,
  Button,
  Box
} from '@mui/material'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ChangeEvent, useState } from 'react'
import formatDateTime from 'utils/formatDateTime'
import { ViewMatchesDialog } from './(shared)/ViewMatchesDialog'
import useQuery from 'hooks/useQuery'
import {
  downloadPipelineFile,
  getPipelineHistory,
  getCompletedAmbiguousMatchesByUser,
  getDroppedDuplicateRecords
} from 'api/queries'
import { PipelineHistory } from 'types'
import useNotify from 'hooks/useNotify'
import { Loading } from 'components/Loading'
import { useAnalyticsWithPipeline } from 'hooks/useAnalytics'
import { exportVMFile } from '../[pipelineId]/tnv/(shared)/exportVMFile'

export default function History() {
  const { t } = useTranslation('pipelines')
  const [searchStr, setSearchStr] = useState('')

  const { orgId, pipelineId } = useParams() as {
    orgId: string
    pipelineId: string
  }

  const { isLoading: isLoadingHistory, data } = useQuery({
    queryKey: ['pipelines', 'history', orgId, pipelineId],
    queryFn: () => getPipelineHistory(orgId, pipelineId),
    enabled: !!pipelineId
  })

  if (isLoadingHistory) {
    return <Loading />
  }

  return (
    <Box mt={4}>
      <TextField
        name='searchHistory'
        aria-label={t('history.searchPlaceholder')}
        variant='outlined'
        fullWidth
        placeholder={t('history.searchPlaceholder')}
        size='small'
        value={searchStr}
        onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
          setSearchStr(e.target.value)
        }
      />
      {data && (
        <Paper sx={{ mt: 3 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('history.tableHeader.source')}</TableCell>
                <TableCell>{t('history.tableHeader.stage')}</TableCell>
                <TableCell>{t('history.tableHeader.description')}</TableCell>
                <TableCell>{t('history.tableHeader.updatedBy')}</TableCell>
                <TableCell>{t('history.tableHeader.updatedAt')}</TableCell>
                <TableCell>{t('history.tableHeader.action')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data
                .filter(h => searchFunction(searchStr, h))
                .map((h, i) => (
                  <HistoryRow
                    key={`history-${i}`}
                    history={h}
                    orgId={orgId}
                    pipelineId={pipelineId}
                  />
                ))}
            </TableBody>
          </Table>
        </Paper>
      )}
    </Box>
  )
}

function HistoryRow({
  history,
  orgId,
  pipelineId
}: {
  history: PipelineHistory
  orgId: string
  pipelineId: string
}) {
  const { t } = useTranslation('pipelines', { keyPrefix: 'history' })
  const { t: generalT } = useTranslation('general')
  const exportErrorMessage = generalT('noExportData')

  const [openViewMatchesDialog, setOpenViewMatchesDialog] = useState(false)
  const [currentHistoryRow, setCurrentHistoryRow] = useState(history)

  const notify = useNotify()
  const analytics = useAnalyticsWithPipeline({ clientId: orgId, pipelineId })

  const { data: completedAmbiguousMatches } = useQuery({
    queryKey: [
      'completedAmbiguousMatches',
      pipelineId,
      currentHistoryRow.updatedAt,
      currentHistoryRow.match_updated_by
    ],
    queryFn: () =>
      getCompletedAmbiguousMatchesByUser({
        page: 0,
        pageSize: 4000,
        pipelineId: pipelineId,
        match_updated_at: currentHistoryRow.updatedAt,
        match_updated_by: currentHistoryRow.match_updated_by
      }),
    enabled: !!currentHistoryRow.updatedBy && openViewMatchesDialog
  })

  const { data: droppedDuplicateRecords } = useQuery({
    queryKey: ['pipelines', 'duplicateRecords', pipelineId],
    queryFn: () => getDroppedDuplicateRecords(pipelineId)
  })

  const handleClickViewMatches = (currentHistory: PipelineHistory) => {
    setCurrentHistoryRow(currentHistory)
    setOpenViewMatchesDialog(true)
    analytics.track('View Matches Clicked', {
      eventSource: 'Pipeline History Page',
      eventCategory: 'user'
    })
  }

  const handleFileDownload = async (history: PipelineHistory) => {
    try {
      await downloadPipelineFile({
        file_storage_id: history.file_storage_id!,
        fileName: history.filename!!,
        org_id: history.org_id!
      })
      analytics.track('History File Exported', {
        eventSource: 'Pipeline History Page',
        eventCategory: 'user',
        sourceName: history.filename!!,
        historyDate: history.updatedAt,
        historyMadeBy: history.updatedBy
      })
    } catch (error) {
      notify({
        message: exportErrorMessage,
        severity: 'error'
      })
    }
  }

  const handleExportDuplicateRecords = () => {
    const exportData = droppedDuplicateRecords?.map(record => ({
      ...record.payload,
      errors: [
        {
          error_name: 'dropped_duplicate_record',
          error_reason: t('duplicateRecordErrorReason', {
            fileRowNumber: record.file_row_number
          })
        }
      ]
    }))
    exportVMFile(exportData || [], 'vm-duplicate-records', pipelineId)
  }

  return (
    <>
      <TableRow>
        <TableCell>{t(history.source, history.source)}</TableCell>
        <TableCell>{t(history.stage, history.stage)}</TableCell>
        <TableCell>
          {t(history.description, history.description, history.values)}
        </TableCell>
        <TableCell>{t(history.updatedBy, history.updatedBy)}</TableCell>
        <TableCell>{formatDateTime(history.updatedAt)}</TableCell>
        <TableCell>
          {history.file_storage_id || history.stage === 'Data Enrichment' ? (
            <Button
              size='small'
              onClick={() => handleFileDownload(history)}
              aria-label={
                history.file_storage_id
                  ? t('exportOriginalFile')
                  : t('exportEnrichedFile')
              }
            >
              {t('exportFile')}
            </Button>
          ) : history.stage === 'reviewAmbiguousMatches' ? (
            <Button
              size='small'
              onClick={() => handleClickViewMatches(history)}
            >
              {t('viewMatches')}
            </Button>
          ) : history.stage === 'deduplication' ? (
            <Button size='small' onClick={() => handleExportDuplicateRecords()}>
              {t('exportDuplicateRows')}
            </Button>
          ) : null}
        </TableCell>
      </TableRow>
      <ViewMatchesDialog
        open={openViewMatchesDialog}
        onClose={() => setOpenViewMatchesDialog(false)}
        orgId={orgId}
        pipelineId={pipelineId}
        completedAmbiguousMatches={completedAmbiguousMatches}
        matchUpdatedBy={currentHistoryRow.match_updated_by}
        updatedAt={currentHistoryRow.updatedAt}
        updatedBy={currentHistoryRow.updatedBy}
      />
    </>
  )
}

function searchFunction(searchStr: string, history: PipelineHistory): boolean {
  const normalizedSS = searchStr.toLowerCase()
  return (
    !searchStr ||
    history.source.toLowerCase().indexOf(normalizedSS) !== -1 ||
    history.description.toLowerCase().indexOf(normalizedSS) !== -1 ||
    history.stage.toLowerCase().indexOf(normalizedSS) !== -1 ||
    history.updatedBy.toLowerCase().indexOf(normalizedSS) !== -1
  )
}
