import { Control, FieldError, useController } from 'react-hook-form'
import {
  FormHelperText,
  InputLabel,
  Select as MuiSelect,
  MenuItem,
  SelectProps
} from '@mui/material'

interface Props extends SelectProps {
  name: string
  control: Control<any>
  errors?: FieldError | { hasError: boolean; message: string }
  rules?: any
}

export default function Select({
  name,
  control,
  label,
  errors,
  children,
  rules,
  required,
  placeholder,
  ...rest
}: Props) {
  const { field } = useController({
    name,
    control,
    rules: {
      ...rules,
      required: required
    }
  })
  return (
    <>
      {label && (
        <InputLabel id={`${name}-label`} required={required} error={!!errors}>
          {label}
        </InputLabel>
      )}
      <MuiSelect
        size='small'
        {...field}
        {...rest}
        id={name}
        error={!!errors}
        onChange={field.onChange}
        value={field.value || ''}
        labelId={`${name}-label`}
        label={label}
        variant='outlined'
        required={required}
        displayEmpty
        placeholder={placeholder}
      >
        {placeholder && (
          <MenuItem value='' disabled sx={{ display: 'none' }}>
            {placeholder}
          </MenuItem>
        )}
        {children}
      </MuiSelect>
      {errors && errors.message && (
        <FormHelperText error>{errors.message}</FormHelperText>
      )}
    </>
  )
}
