import { FormRowProps } from './FormRow'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Button,
  Divider,
  Stack,
  Typography,
  useTheme
} from '@mui/material'

type DataScenarioSelectorButtonProps = {
  title: string
  value?: string
  selected?: boolean
  onClick: () => void
  first?: boolean
  last?: boolean
  active?: boolean
}
const DataScenarioSelectorButton = ({
  selected,
  onClick,
  value,
  title,
  first,
  last,
  active
}: DataScenarioSelectorButtonProps) => {
  const { spacing, palette } = useTheme()

  const bgcolor = selected
    ? 'rgba(2, 131, 131, 1)'
    : !first && !last
    ? 'none'
    : 'rgba(2, 131, 131, 0.2)'
  const borderRadius = first
    ? '7px 0px 0px 7px'
    : last
    ? '0px 7px 7px 0px'
    : undefined
  const padding = first || last ? spacing(0.45) : undefined
  const wrapperProps = { bgcolor, borderRadius, padding }

  return (
    <Box {...wrapperProps}>
      <Button
        onClick={onClick}
        sx={{
          width: '6rem',
          padding: value ? '0.1rem 1rem' : '0.4rem 1rem 0.7rem 1rem',
          background: selected ? `${palette.common.white} !important` : 'none'
        }}
      >
        <Stack>
          <Typography
            fontSize='0.85rem'
            fontWeight='bold'
            marginBottom='-0.25rem'
            color={active ? palette.common.white : undefined}
          >
            {title}
          </Typography>

          {value && (
            <Typography
              color={selected ? palette.common.black : palette.grey[500]}
              fontWeight='bold'
              textTransform='uppercase'
              fontSize='0.65rem'
            >
              {value}
            </Typography>
          )}
        </Stack>
      </Button>
    </Box>
  )
}

const DataScenarioSelectorCenterSelections = ({
  field,
  section,
  type,
  onDataScenarioSelect,
  config: { selectedScenario, hasTrustScore, configs }
}: FormRowProps) => {
  const { t } = useTranslation('bre')
  const { palette, spacing } = useTheme()

  const isActive =
    selectedScenario === 'unprovided' || selectedScenario === 'dissimilar'

  const divider = (
    <Divider
      flexItem
      variant='middle'
      orientation='vertical'
      sx={{
        borderRightColor: isActive ? '#01C0B5' : '#028383',
        height: '1.25rem',
        margin: '0.6rem 0 0.6rem 0.25rem !important'
      }}
    />
  )

  return (
    <Stack
      direction='row'
      spacing='0'
      alignItems='center'
      divider={divider}
      bgcolor={isActive ? 'rgba(2, 131, 131, 1)' : 'rgba(2, 131, 131, 0.2)'}
      padding={spacing(hasTrustScore ? 0.2 : 0.45, 0.45)}
      borderLeft={`1px solid ${palette.common.white}`}
      borderRight={`1px solid ${palette.common.white}`}
    >
      <DataScenarioSelectorButton
        title={t('attributeScenarios.unprovided')}
        value={
          hasTrustScore
            ? t(`trustScoreLevels.${configs.unprovided}`, {
                keySeparator: '.'
              })
            : undefined
        }
        selected={selectedScenario === 'unprovided'}
        active={isActive && selectedScenario !== 'unprovided'}
        onClick={() =>
          onDataScenarioSelect({
            section,
            field,
            type,
            selectedScenario: 'unprovided'
          })
        }
      />
      {hasTrustScore && (
        <DataScenarioSelectorButton
          title={t('attributeScenarios.dissimilar')}
          value={t(`trustScoreLevels.${configs.dissimilar}`, {
            keySeparator: '.'
          })}
          selected={selectedScenario === 'dissimilar'}
          active={isActive && selectedScenario !== 'dissimilar'}
          onClick={() =>
            onDataScenarioSelect({
              section,
              field,
              type,
              selectedScenario: 'dissimilar'
            })
          }
        />
      )}
    </Stack>
  )
}

export const DataScenarioSelector = (props: FormRowProps) => {
  const { t } = useTranslation('bre')

  const {
    section,
    field,
    type,
    onDataScenarioSelect,
    config: { configs, hasTrustScore, selectedScenario }
  } = props

  return (
    <Stack
      direction='row'
      spacing='0'
      alignItems='center'
      justifyContent='flex-end'
      role='group'
      width='40%'
    >
      <DataScenarioSelectorButton
        first
        title={t('attributeScenarios.never')}
        value={hasTrustScore ? t('provide') : undefined}
        selected={selectedScenario === 'never'}
        onClick={() =>
          onDataScenarioSelect({
            section,
            field,
            type,
            selectedScenario: 'never'
          })
        }
      />
      <DataScenarioSelectorCenterSelections {...props} />
      <DataScenarioSelectorButton
        last
        title={t('attributeScenarios.always')}
        selected={selectedScenario === 'always'}
        value={
          hasTrustScore
            ? t(`trustScoreLevels.${configs.always}`, { keySeparator: '.' })
            : undefined
        }
        onClick={() =>
          onDataScenarioSelect({
            section,
            field,
            type,
            selectedScenario: 'always'
          })
        }
      />
    </Stack>
  )
}
