import { TableRow, TableBody, TableHead } from '@mui/material'
import ChevronRight from '@mui/icons-material/ChevronRight'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { startCase } from 'lodash'

import { Table } from 'components/Table'
import { StatusChip } from 'components/StatusChip'
import { Loading } from 'components/Loading'
import { Dialog } from 'components/Dialog'
import { getCompletedAmbiguousMatchesByUser } from 'api/queries'
import formatToDateString from 'utils/formatToDateString'

import { Cell } from 'components/Table'

type Props = {
  completedAmbiguousMatches?: Awaited<
    ReturnType<typeof getCompletedAmbiguousMatchesByUser>
  >
  matchUpdatedBy?: string
  onClose: () => void
  open: boolean
  orgId: string
  pipelineId: string
  updatedAt: string
  updatedBy: string
}

export function ViewMatchesDialog({
  completedAmbiguousMatches,
  matchUpdatedBy,
  onClose,
  open,
  orgId,
  pipelineId,
  updatedAt,
  updatedBy
}: Props) {
  const { t } = useTranslation('supplierMatch')
  const navigate = useNavigate()

  const handleClickCompletedMatch = (index: number) => {
    navigate(
      `/opcon/pipelines/${orgId}/${pipelineId}/ambiguous-matches/${matchUpdatedBy}/${updatedAt}/match-completed`,
      { state: { from: index } }
    )
  }

  return (
    <Dialog
      open={open}
      title={t('viewMatchesTitle', {
        updatedBy: updatedBy,
        updatedAt: formatToDateString(updatedAt)
      })}
      onClose={onClose}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '850px'
          }
        }
      }}
    >
      {!completedAmbiguousMatches ? (
        <Loading />
      ) : (
        <Table>
          <TableHead>
            <TableRow>
              <Cell width={100} align='center'>
                {t('row')}
              </Cell>
              <Cell width={200}>{t('internalSupplierId')}</Cell>
              <Cell width={250}>{t('record')}</Cell>
              <Cell>{t('matchStatus')}</Cell>
              <Cell></Cell>
            </TableRow>
          </TableHead>
          <TableBody>
            {completedAmbiguousMatches.completedMatches.map((rows, index) => {
              return (
                <TableRow
                  key={index + 1}
                  onClick={() => handleClickCompletedMatch(index)}
                >
                  <Cell align='center'>{index + 1}</Cell>
                  <Cell>{rows.incomingEntity.internal_supplier_id}</Cell>
                  <Cell sx={{ fontWeight: 'bold' }}>
                    {startCase(rows.incomingEntity.company_name)}
                  </Cell>

                  <Cell>
                    <StatusChip
                      label={'complete'}
                      variant='complete'
                      isStartCase
                    />
                  </Cell>
                  <Cell>
                    <ChevronRight />
                  </Cell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      )}
    </Dialog>
  )
}
