import { ReactNode } from 'react'

import { Tooltip, IconButton } from '@mui/material'
import { InfoOutlined } from '@mui/icons-material'

type InfoProps = {
  displayInfo?: ReactNode
  info: string
  label?: string | ReactNode
}

export const InfoLabel = ({ displayInfo, info, label }: InfoProps) =>
  label ? (
    <span>
      {label}
      <InfoIcon displayInfo={displayInfo} info={info} />
    </span>
  ) : (
    <InfoIcon displayInfo={displayInfo} info={info} />
  )

export const InfoIcon = ({ displayInfo, info }: InfoProps) => (
  <Tooltip title={displayInfo ? displayInfo : info} placement='right-start'>
    <IconButton aria-label={info} size='small'>
      <InfoOutlined fontSize='small' sx={{ color: 'primary.main' }} />
    </IconButton>
  </Tooltip>
)
