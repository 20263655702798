import { Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { FileError } from 'react-dropzone'

import { FileRejections } from 'types'
import { Banner } from 'components/Banner'

import parseUploadErrors from './parseUploadErrors'
import DropZoneErrors from './DropZoneErrors'
import HeadingErrors from './HeadingErrors'

export default function DroppedFileErrors({
  fileName,
  dropZoneErrors,
  onClose,
  uploadServerError
}: {
  fileName: string
  dropZoneErrors?: FileError[]
  onClose: () => void
  uploadServerError?: FileRejections
}) {
  const { t } = useTranslation('pipelineRunner')
  const {
    unknownHeading,
    incorrectUploadType,
    missingRequiredHeading,
    missingRequiredField,
    duplicatedKey,
    futureDatedSpend,
    invalidIsGovernment
  } = parseUploadErrors(uploadServerError?.validationErrors || [])

  return (
    <Banner variant='error' onClose={onClose}>
      <Stack spacing={1}>
        <Typography sx={{ fontWeight: 'bold' }}>
          {t('errorsFor')}
          {fileName}
        </Typography>

        {dropZoneErrors && (
          <DropZoneErrors errors={dropZoneErrors} fileName={fileName} />
        )}
        {incorrectUploadType && (
          <Typography>{t('incorrectUploadTypeDropzoneMessage')}</Typography>
        )}
        {unknownHeading && (
          <>
            <Typography>{t('schemaErrorDropzoneMessage')}</Typography>
            <HeadingErrors errors={unknownHeading} />
          </>
        )}
        {missingRequiredHeading &&
          (missingRequiredHeading.length > 1 ? (
            <Typography>
              {t('missingRequiredHeadingDropzone.key_other', {
                headings: missingRequiredHeading.join(', ')
              })}
            </Typography>
          ) : (
            <Typography>
              {t('missingRequiredHeadingDropzone.key_one', {
                heading: missingRequiredHeading.join(', ')
              })}
            </Typography>
          ))}

        {missingRequiredField && (
          <>
            <Typography>{t('missingRequiredFieldDropZone')}</Typography>
            <ul>
              {missingRequiredField.map(
                (field: { missingField: string; row: string[] }) => (
                  <li key={field.missingField}>
                    <Typography>
                      {`${field.missingField} for rows: ${field.row.join(
                        ', '
                      )}`}
                    </Typography>
                  </li>
                )
              )}
            </ul>
          </>
        )}
        {duplicatedKey && (
          <>
            <Typography>{t('duplicatedKeyDropZone')}</Typography>
            <ul>
              {duplicatedKey.map(
                (field: { duplicatedField: string; row: string[] }) => (
                  <li key={field.duplicatedField}>
                    <Typography>
                      {`${field.duplicatedField} for rows: ${field.row.join(
                        ', '
                      )}`}
                    </Typography>
                  </li>
                )
              )}
            </ul>
          </>
        )}
        {futureDatedSpend && (
          <Typography>
            {t('futureDatedSpendDropZone', {
              rows: futureDatedSpend.join(', ')
            })}
          </Typography>
        )}
        {invalidIsGovernment && (
          <Typography>
            {t('invalidIsGovernmentDropZone', {
              rows: invalidIsGovernment.join(', ')
            })}
          </Typography>
        )}
      </Stack>
    </Banner>
  )
}
