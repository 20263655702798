import { Typography, Stack, Button } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'

import StatusSection from 'components/StatusSection'

export const PipelineSuccess = ({
  hideSuccess,
  handleViewPipelines
}: {
  hideSuccess: () => void
  handleViewPipelines: () => void
}) => {
  const { t } = useTranslation('pipelineRunner')

  return (
    <StatusSection title={t('loadInProgress')}>
      <Typography>
        <Trans t={t} i18nKey='fileRunningThroughPipeline'>
          View <strong>Pipelines</strong> to check on the status of your loads.
          You will receive an email when a load is complete or when action is
          required.
        </Trans>
      </Typography>
      <Stack direction='row' spacing={1}>
        <Button variant='outlined' onClick={hideSuccess}>
          {t('startAnotherLoad')}
        </Button>
        <Button variant='contained' onClick={handleViewPipelines}>
          {t('viewPipelines')}
        </Button>
      </Stack>
    </StatusSection>
  )
}
